import ISchool from "../interfaces/School";

export const SCHOOLS_BASEBALL: ISchool[] = [
  {
    school_code: "afa",
    name: "Air Force",
    division: 1,
    org_id: "721",
    mascot: "Falcons",
    encoded_h: "Y2FmYQ==",
    encoded_c: "Y2FmYV9j",
    encoded_q: "Y2FmYV9x",
    encoded_p: "Y2FmYV9w",
  },
  {
    school_code: "akoh",
    name: "Akron",
    division: 1,
    org_id: "5",
    mascot: "Zips",
    encoded_h: "Y2Frb2g=",
    encoded_c: "Y2Frb2hfYw==",
    encoded_q: "Y2Frb2hfcQ==",
    encoded_p: "Y2Frb2hfcA==",
  },
  {
    school_code: "alab",
    name: "Alabama",
    division: 1,
    org_id: "8",
    mascot: "Crimson Tide",
    encoded_h: "Y2FsYWI=",
    encoded_c: "Y2FsYWJfYw==",
    encoded_q: "Y2FsYWJfcQ==",
    encoded_p: "Y2FsYWJfcA==",
  },
  {
    school_code: "alam",
    name: "Alabama A&M",
    division: 1,
    org_id: "6",
    mascot: "Bulldogs",
    encoded_h: "Y2FsYW0=",
    encoded_c: "Y2FsYW1fYw==",
    encoded_q: "Y2FsYW1fcQ==",
    encoded_p: "Y2FsYW1fcA==",
  },
  {
    school_code: "alb",
    schedule_id: 84567,
    name: "Albany",
    division: 1,
    org_id: "14",
    mascot: "Great Danes",
    encoded_h: "Y2FsYg==",
    encoded_c: "Y2FsYl9j",
    encoded_q: "Y2FsYl9x",
    encoded_p: "Y2FsYl9w",
  },
  {
    school_code: "albr",
    name: "UAB",
    division: 1,
    org_id: "9",
    mascot: "Blazers",
    encoded_h: "Y2FsYnI=",
    encoded_c: "Y2FsYnJfYw==",
    encoded_q: "Y2FsYnJfcQ==",
    encoded_p: "Y2FsYnJfcA==",
  },
  {
    school_code: "alc",
    name: "Alcorn State",
    division: 1,
    org_id: "17",
    mascot: "Braves",
    encoded_h: "Y2FsYw==",
    encoded_c: "Y2FsY19j",
    encoded_q: "Y2FsY19x",
    encoded_p: "Y2FsY19w",
  },
  {
    school_code: "alsu",
    name: "Alabama State",
    division: 1,
    org_id: "7",
    mascot: "Hornets",
    encoded_h: "Y2Fsc3U=",
    encoded_c: "Y2Fsc3VfYw==",
    encoded_q: "Y2Fsc3VfcQ==",
    encoded_p: "Y2Fsc3VfcA==",
  },
  {
    school_code: "appa",
    schedule_id: 104512,
    name: "Appalachian State",
    division: 1,
    org_id: "27",
    mascot: "Mountaineers",
    encoded_h: "Y2FwcGE=",
    encoded_c: "Y2FwcGFfYw==",
    encoded_q: "Y2FwcGFfcQ==",
    encoded_p: "Y2FwcGFfcA==",
  },
  {
    school_code: "ariz",
    schedule_id: 750081,
    name: "Arizona",
    division: 1,
    org_id: "29",
    mascot: "Wildcats",
    encoded_h: "Y2FyaXo=",
    encoded_c: "Y2FyaXpfYw==",
    encoded_q: "Y2FyaXpfcQ==",
    encoded_p: "Y2FyaXpfcA==",
  },
  {
    school_code: "ark",
    schedule_id: 30669,
    name: "Arkansas",
    division: 1,
    org_id: "31",
    mascot: "Razorbacks",
    encoded_h: "Y2Fyaw==",
    encoded_c: "Y2Fya19j",
    encoded_q: "Y2Fya19x",
    encoded_p: "Y2Fya19w",
  },
  {
    school_code: "arkl",
    schedule_id: 35700,
    name: "Arkansas Little Rock",
    division: 1,
    org_id: "32",
    mascot: "Trojans",
    encoded_h: "Y2Fya2w=",
    encoded_c: "Y2Fya2xfYw==",
    encoded_q: "Y2Fya2xfcQ==",
    encoded_p: "Y2Fya2xfcA==",
  },
  {
    school_code: "arkp",
    name: "Arkansas - Pine Bluff",
    division: 1,
    org_id: "2678",
    mascot: "Golden Lions",
    encoded_h: "Y2Fya3A=",
    encoded_c: "Y2Fya3BfYw==",
    encoded_q: "Y2Fya3BfcQ==",
    encoded_p: "Y2Fya3BfcA==",
  },
  {
    school_code: "arks",
    schedule_id: 35023,
    name: "Arkansas State",
    division: 1,
    org_id: "30",
    mascot: "Red Wolves",
    encoded_h: "Y2Fya3M=",
    encoded_c: "Y2Fya3NfYw==",
    encoded_q: "Y2Fya3NfcQ==",
    encoded_p: "Y2Fya3NfcA==",
  },
  {
    school_code: "army",
    name: "Army",
    division: 1,
    org_id: "725",
    mascot: "West Point Black Knights",
    encoded_h: "Y2FybXk=",
    encoded_c: "Y2FybXlfYw==",
    encoded_q: "Y2FybXlfcQ==",
    encoded_p: "Y2FybXlfcA==",
  },
  {
    school_code: "asu",
    schedule_id: 749681,
    name: "Arizona State",
    division: 1,
    org_id: "28",
    mascot: "Sun Devils",
    encoded_h: "Y2FzdQ==",
    encoded_c: "Y2FzdV9j",
    encoded_q: "Y2FzdV9x",
    encoded_p: "Y2FzdV9w",
  },
  {
    school_code: "aub",
    name: "Auburn",
    division: 1,
    org_id: "37",
    mascot: "Tigers",
    encoded_h: "Y2F1Yg==",
    encoded_c: "Y2F1Yl9j",
    encoded_q: "Y2F1Yl9x",
    encoded_p: "Y2F1Yl9w",
  },
  {
    school_code: "ausp",
    schedule_id: 85696,
    name: "Austin Peay",
    division: 1,
    org_id: "43",
    mascot: "Governors",
    encoded_h: "Y2F1c3A=",
    encoded_c: "Y2F1c3BfYw==",
    encoded_q: "Y2F1c3BfcQ==",
    encoded_p: "Y2F1c3BfcA==",
  },
  {
    school_code: "ball",
    schedule_id: 62141,
    name: "Ball State",
    division: 1,
    org_id: "47",
    mascot: "Cardinals",
    encoded_h: "Y2JhbGw=",
    encoded_c: "Y2JhbGxfYw==",
    encoded_q: "Y2JhbGxfcQ==",
    encoded_p: "Y2JhbGxfcA==",
  },
  {
    school_code: "bay",
    name: "Baylor",
    division: 1,
    org_id: "51",
    mascot: "Bears",
    encoded_h: "Y2JheQ==",
    encoded_c: "Y2JheV9j",
    encoded_q: "Y2JheV9x",
    encoded_p: "Y2JheV9w",
  },
  {
    school_code: "bc",
    name: "Boston College",
    division: 1,
    org_id: "67",
    mascot: "Eagles",
    encoded_h: "Y2Jj",
    encoded_c: "Y2JjX2M=",
    encoded_q: "Y2JjX3E=",
    encoded_p: "Y2JjX3A=",
  },
  {
    school_code: "belm",
    name: "Belmont",
    division: 1,
    org_id: "14927",
    mascot: "Bruins",
    encoded_h: "Y2JlbG0=",
    encoded_c: "Y2JlbG1fYw==",
    encoded_q: "Y2JlbG1fcQ==",
    encoded_p: "Y2JlbG1fcA==",
  },
  {
    school_code: "beth",
    schedule_id: 659224,
    name: "Bethune-Cookman",
    division: 1,
    org_id: "61",
    mascot: "Wildcats",
    encoded_h: "Y2JldGg=",
    encoded_c: "Y2JldGhfYw==",
    encoded_q: "Y2JldGhfcQ==",
    encoded_p: "Y2JldGhfcA==",
  },
  {
    school_code: "bgu",
    name: "Bowling Green",
    division: 1,
    org_id: "71",
    mascot: "Falcons",
    encoded_h: "Y2JndQ==",
    encoded_c: "Y2JndV9j",
    encoded_q: "Y2JndV9x",
    encoded_p: "Y2JndV9w",
  },
  {
    school_code: "bing",
    name: "Binghamton",
    division: 1,
    org_id: "62",
    mascot: "Bearcats",
    encoded_h: "Y2Jpbmc=",
    encoded_c: "Y2JpbmdfYw==",
    encoded_q: "Y2JpbmdfcQ==",
    encoded_p: "Y2JpbmdfcA==",
  },
  {
    school_code: "brad",
    schedule_id: 19411,
    name: "Bradley",
    division: 1,
    org_id: "72",
    mascot: "Braves",
    encoded_h: "Y2JyYWQ=",
    encoded_c: "Y2JyYWRfYw==",
    encoded_q: "Y2JyYWRfcQ==",
    encoded_p: "Y2JyYWRfcA==",
  },
  {
    school_code: "66",
    name: "Boise St.",
    division: 1,
    org_id: "66",
    mascot: "",
    encoded_h: "YzY2",
    encoded_c: "YzY2X2M=",
    encoded_q: "YzY2X3E=",
    encoded_p: "YzY2X3A=",
  },
  {
    school_code: "brow",
    name: "Brown",
    division: 1,
    org_id: "80",
    mascot: "Bears",
    encoded_h: "Y2Jyb3c=",
    encoded_c: "Y2Jyb3dfYw==",
    encoded_q: "Y2Jyb3dfcQ==",
    encoded_p: "Y2Jyb3dfcA==",
  },
  {
    school_code: "bryc",
    name: "Bryant",
    division: 1,
    org_id: "81",
    mascot: "Bulldogs",
    encoded_h: "Y2JyeWM=",
    encoded_c: "Y2JyeWNfYw==",
    encoded_q: "Y2JyeWNfcQ==",
    encoded_p: "Y2JyeWNfcA==",
  },
  {
    school_code: "buck",
    schedule_id: 810379,
    name: "Bucknell",
    division: 1,
    org_id: "83",
    mascot: "Bison",
    encoded_h: "Y2J1Y2s=",
    encoded_c: "Y2J1Y2tfYw==",
    encoded_q: "Y2J1Y2tfcQ==",
    encoded_p: "Y2J1Y2tfcA==",
  },
  {
    school_code: "buff",
    name: "Buffalo",
    division: 1,
    org_id: "86",
    mascot: "Bulls",
    encoded_h: "Y2J1ZmY=",
    encoded_c: "Y2J1ZmZfYw==",
    encoded_q: "Y2J1ZmZfcQ==",
    encoded_p: "Y2J1ZmZfcA==",
  },
  {
    school_code: "butl",
    name: "Butler",
    division: 1,
    org_id: "87",
    mascot: "Bulldogs",
    encoded_h: "Y2J1dGw=",
    encoded_c: "Y2J1dGxfYw==",
    encoded_q: "Y2J1dGxfcQ==",
    encoded_p: "Y2J1dGxfcA==",
  },
  {
    school_code: "byu",
    name: "BYU",
    division: 1,
    org_id: "77",
    mascot: "Cougars",
    encoded_h: "Y2J5dQ==",
    encoded_c: "Y2J5dV9j",
    encoded_q: "Y2J5dV9x",
    encoded_p: "Y2J5dV9w",
  },
  {
    school_code: "cal",
    schedule_id: 749481,
    name: "California",
    division: 1,
    org_id: "107",
    mascot: "Golden Bears",
    encoded_h: "Y2NhbA==",
    encoded_c: "Y2NhbF9j",
    encoded_q: "Y2NhbF9x",
    encoded_p: "Y2NhbF9w",
  },
  {
    school_code: "camp",
    name: "Campbell",
    division: 1,
    org_id: "115",
    mascot: "Fighting Camels",
    encoded_h: "Y2NhbXA=",
    encoded_c: "Y2NhbXBfYw==",
    encoded_q: "Y2NhbXBfcQ==",
    encoded_p: "Y2NhbXBfcA==",
  },
  {
    school_code: "cani",
    schedule_id: 97882,
    name: "Canisius",
    division: 1,
    org_id: "116",
    mascot: "Golden Griffins",
    encoded_h: "Y2Nhbmk=",
    encoded_c: "Y2NhbmlfYw==",
    encoded_q: "Y2NhbmlfcQ==",
    encoded_p: "Y2NhbmlfcA==",
  },
  {
    school_code: "ccon",
    name: "CCSU",
    division: 1,
    org_id: "127",
    mascot: "Blue Devils",
    encoded_h: "Y2Njb24=",
    encoded_c: "Y2Njb25fYw==",
    encoded_q: "Y2Njb25fcQ==",
    encoded_p: "Y2Njb25fcA==",
  },
  {
    school_code: "cena",
    name: "Central Arkansas",
    division: 1,
    org_id: "1004",
    mascot: "Bears",
    encoded_h: "Y2NlbmE=",
    encoded_c: "Y2NlbmFfYw==",
    encoded_q: "Y2NlbmFfcQ==",
    encoded_p: "Y2NlbmFfcA==",
  },
  {
    school_code: "chis",
    name: "Chicago State",
    division: 1,
    org_id: "136",
    mascot: "Cougars",
    encoded_h: "Y2NoaXM=",
    encoded_c: "Y2NoaXNfYw==",
    encoded_q: "Y2NoaXNfcQ==",
    encoded_p: "Y2NoaXNfcA==",
  },
  {
    school_code: "chso",
    name: "Charleston Southern",
    division: 1,
    org_id: "48",
    mascot: "Buccaneers",
    encoded_h: "Y2Noc28=",
    encoded_c: "Y2Noc29fYw==",
    encoded_q: "Y2Noc29fcQ==",
    encoded_p: "Y2Noc29fcA==",
  },
  {
    school_code: "cinn",
    name: "Cincinnati",
    division: 1,
    org_id: "140",
    mascot: "Bearcats",
    encoded_h: "Y2Npbm4=",
    encoded_c: "Y2Npbm5fYw==",
    encoded_q: "Y2Npbm5fcQ==",
    encoded_p: "Y2Npbm5fcA==",
  },
  {
    school_code: "cita",
    name: "Citadel",
    division: 1,
    org_id: "141",
    mascot: "Bulldogs",
    encoded_h: "Y2NpdGE=",
    encoded_c: "Y2NpdGFfYw==",
    encoded_q: "Y2NpdGFfcQ==",
    encoded_p: "Y2NpdGFfcA==",
  },
  {
    school_code: "clem",
    schedule_id: 657825,
    name: "Clemson",
    division: 1,
    org_id: "147",
    mascot: "Tigers",
    encoded_h: "Y2NsZW0=",
    encoded_c: "Y2NsZW1fYw==",
    encoded_q: "Y2NsZW1fcQ==",
    encoded_p: "Y2NsZW1fcA==",
  },
  {
    school_code: "cmu",
    schedule_id: 46417,
    name: "Central Michigan",
    division: 1,
    org_id: "129",
    mascot: "Chippewas",
    encoded_h: "Y2NtdQ==",
    encoded_c: "Y2NtdV9j",
    encoded_q: "Y2NtdV9x",
    encoded_p: "Y2NtdV9w",
  },
  {
    school_code: "coas",
    name: "Coastal Carolina",
    division: 1,
    org_id: "149",
    mascot: "Chanticleers",
    encoded_h: "Y2NvYXM=",
    encoded_c: "Y2NvYXNfYw==",
    encoded_q: "Y2NvYXNfcQ==",
    encoded_p: "Y2NvYXNfcA==",
  },
  {
    school_code: "colc",
    schedule_id: 64072,
    name: "College of Charleston",
    division: 1,
    org_id: "1014",
    mascot: "Cougars",
    encoded_h: "Y2NvbGM=",
    encoded_c: "Y2NvbGNfYw==",
    encoded_q: "Y2NvbGNfcQ==",
    encoded_p: "Y2NvbGNfcA==",
  },
  {
    school_code: "colm",
    schedule_id: 43642,
    name: "Columbia",
    division: 1,
    org_id: "158",
    mascot: "Lions",
    encoded_h: "Y2NvbG0=",
    encoded_c: "Y2NvbG1fYw==",
    encoded_q: "Y2NvbG1fcQ==",
    encoded_p: "Y2NvbG1fcA==",
  },
  {
    school_code: "conn",
    name: "Connecticut",
    division: 1,
    org_id: "164",
    mascot: "Huskies",
    encoded_h: "Y2Nvbm4=",
    encoded_c: "Y2Nvbm5fYw==",
    encoded_q: "Y2Nvbm5fcQ==",
    encoded_p: "Y2Nvbm5fcA==",
  },
  {
    school_code: "copp",
    name: "Coppin State",
    division: 1,
    org_id: "165",
    mascot: "Eagles",
    encoded_h: "Y2NvcHA=",
    encoded_c: "Y2NvcHBfYw==",
    encoded_q: "Y2NvcHBfcQ==",
    encoded_p: "Y2NvcHBfcA==",
  },
  {
    school_code: "corn",
    name: "Cornell",
    division: 1,
    org_id: "167",
    mascot: "Big Red",
    encoded_h: "Y2Nvcm4=",
    encoded_c: "Y2Nvcm5fYw==",
    encoded_q: "Y2Nvcm5fcQ==",
    encoded_p: "Y2Nvcm5fcA==",
  },
  {
    school_code: "crei",
    schedule_id: 89375,
    name: "Creighton",
    division: 1,
    org_id: "169",
    mascot: "Bluejays",
    encoded_h: "Y2NyZWk=",
    encoded_c: "Y2NyZWlfYw==",
    encoded_q: "Y2NyZWlfcQ==",
    encoded_p: "Y2NyZWlfcA==",
  },
  {
    school_code: "csb",
    schedule_id: 58071,
    name: "Cal State Bakersfield",
    division: 1,
    org_id: "94",
    mascot: "Roadrunners",
    encoded_h: "Y2NzYg==",
    encoded_c: "Y2NzYl9j",
    encoded_q: "Y2NzYl9x",
    encoded_p: "Y2NzYl9w",
  },
  {
    school_code: "csfu",
    name: "Cal State Fullerton",
    division: 1,
    org_id: "97",
    mascot: "Titans",
    encoded_h: "Y2NzZnU=",
    encoded_c: "Y2NzZnVfYw==",
    encoded_q: "Y2NzZnVfcQ==",
    encoded_p: "Y2NzZnVfcA==",
  },
  {
    school_code: "cslo",
    name: "Cal Poly",
    division: 1,
    org_id: "90",
    mascot: "Mustangs",
    encoded_h: "Y2NzbG8=",
    encoded_c: "Y2NzbG9fYw==",
    encoded_q: "Y2NzbG9fcQ==",
    encoded_p: "Y2NzbG9fcA==",
  },
  {
    school_code: "csno",
    name: "Cal State Northridge",
    division: 1,
    org_id: "101",
    mascot: "Matadors",
    encoded_h: "Y2Nzbm8=",
    encoded_c: "Y2Nzbm9fYw==",
    encoded_q: "Y2Nzbm9fcQ==",
    encoded_p: "Y2Nzbm9fcA==",
  },
  {
    school_code: "dart",
    schedule_id: 48774,
    name: "Dartmouth",
    division: 1,
    org_id: "172",
    mascot: "Big Green",
    encoded_h: "Y2RhcnQ=",
    encoded_c: "Y2RhcnRfYw==",
    encoded_q: "Y2RhcnRfcQ==",
    encoded_p: "Y2RhcnRfcA==",
  },
  {
    school_code: "dav",
    name: "Davidson",
    division: 1,
    org_id: "173",
    mascot: "Wildcats",
    encoded_h: "Y2Rhdg==",
    encoded_c: "Y2Rhdl9j",
    encoded_q: "Y2Rhdl9x",
    encoded_p: "Y2Rhdl9w",
  },
  {
    school_code: "dayt",
    name: "Dayton",
    division: 1,
    org_id: "175",
    mascot: "Flyers",
    encoded_h: "Y2RheXQ=",
    encoded_c: "Y2RheXRfYw==",
    encoded_q: "Y2RheXRfcQ==",
    encoded_p: "Y2RheXRfcA==",
  },
  {
    school_code: "dbap",
    name: "Dallas Baptist",
    division: 1,
    org_id: "1045",
    mascot: "Patriots",
    encoded_h: "Y2RiYXA=",
    encoded_c: "Y2RiYXBfYw==",
    encoded_q: "Y2RiYXBfcQ==",
    encoded_p: "Y2RiYXBfcA==",
  },
  {
    school_code: "del",
    schedule_id: 670282,
    name: "Delaware",
    division: 1,
    org_id: "180",
    mascot: "Fightin Blue Hens",
    encoded_h: "Y2RlbA==",
    encoded_c: "Y2RlbF9j",
    encoded_q: "Y2RlbF9x",
    encoded_p: "Y2RlbF9w",
  },
  {
    school_code: "dels",
    schedule_id: 82260,
    name: "Delaware State",
    division: 1,
    org_id: "178",
    mascot: "Hornets",
    encoded_h: "Y2RlbHM=",
    encoded_c: "Y2RlbHNfYw==",
    encoded_q: "Y2RlbHNfcQ==",
    encoded_p: "Y2RlbHNfcA==",
  },
  {
    school_code: "duke",
    schedule_id: 22851,
    name: "Duke",
    division: 1,
    org_id: "193",
    mascot: "Blue Devils",
    encoded_h: "Y2R1a2U=",
    encoded_c: "Y2R1a2VfYw==",
    encoded_q: "Y2R1a2VfcQ==",
    encoded_p: "Y2R1a2VfcA==",
  },
  {
    school_code: "ecu",
    name: "East Carolina",
    division: 1,
    org_id: "196",
    mascot: "Pirates",
    encoded_h: "Y2VjdQ==",
    encoded_c: "Y2VjdV9j",
    encoded_q: "Y2VjdV9x",
    encoded_p: "Y2VjdV9w",
  },
  {
    school_code: "eill",
    name: "Eastern Illinois",
    division: 1,
    org_id: "201",
    mascot: "Panthers",
    encoded_h: "Y2VpbGw=",
    encoded_c: "Y2VpbGxfYw==",
    encoded_q: "Y2VpbGxfcQ==",
    encoded_p: "Y2VpbGxfcA==",
  },
  {
    school_code: "eky",
    name: "Eastern Kentucky",
    division: 1,
    org_id: "202",
    mascot: "Colonels",
    encoded_h: "Y2VreQ==",
    encoded_c: "Y2VreV9j",
    encoded_q: "Y2VreV9x",
    encoded_p: "Y2VreV9w",
  },
  {
    school_code: "elon",
    name: "Elon",
    division: 1,
    org_id: "1068",
    mascot: "Phoenix",
    encoded_h: "Y2Vsb24=",
    encoded_c: "Y2Vsb25fYw==",
    encoded_q: "Y2Vsb25fcQ==",
    encoded_p: "Y2Vsb25fcA==",
  },
  {
    school_code: "emu",
    name: "Eastern Michigan",
    division: 1,
    org_id: "204",
    mascot: "Eagles",
    encoded_h: "Y2VtdQ==",
    encoded_c: "Y2VtdV9j",
    encoded_q: "Y2VtdV9x",
    encoded_p: "Y2VtdV9w",
  },
  {
    school_code: "etsu",
    name: "ETSU",
    division: 1,
    org_id: "198",
    mascot: "Buccaneers",
    encoded_h: "Y2V0c3U=",
    encoded_c: "Y2V0c3VfYw==",
    encoded_q: "Y2V0c3VfcQ==",
    encoded_p: "Y2V0c3VfcA==",
  },
  {
    school_code: "evan",
    name: "Evansville",
    division: 1,
    org_id: "219",
    mascot: "Purple Aces",
    encoded_h: "Y2V2YW4=",
    encoded_c: "Y2V2YW5fYw==",
    encoded_q: "Y2V2YW5fcQ==",
    encoded_p: "Y2V2YW5fcA==",
  },
  {
    school_code: "fair",
    name: "Fairfield",
    division: 1,
    org_id: "220",
    mascot: "Stags",
    encoded_h: "Y2ZhaXI=",
    encoded_c: "Y2ZhaXJfYw==",
    encoded_q: "Y2ZhaXJfcQ==",
    encoded_p: "Y2ZhaXJfcA==",
  },
  {
    school_code: "famu",
    schedule_id: 606601,
    name: "Florida A&M",
    division: 1,
    org_id: "228",
    mascot: "Rattlers",
    encoded_h: "Y2ZhbXU=",
    encoded_c: "Y2ZhbXVfYw==",
    encoded_q: "Y2ZhbXVfcQ==",
    encoded_p: "Y2ZhbXVfcA==",
  },
  {
    school_code: "fau",
    name: "Florida Atlantic",
    division: 1,
    org_id: "229",
    mascot: "Owls",
    encoded_h: "Y2ZhdQ==",
    encoded_c: "Y2ZhdV9j",
    encoded_q: "Y2ZhdV9x",
    encoded_p: "Y2ZhdV9w",
  },
  {
    school_code: "fgcu",
    name: "Florida Gulf Coast",
    division: 1,
    org_id: "28755",
    mascot: "Eagles",
    encoded_h: "Y2ZnY3U=",
    encoded_c: "Y2ZnY3VfYw==",
    encoded_q: "Y2ZnY3VfcQ==",
    encoded_p: "Y2ZnY3VfcA==",
  },
  {
    school_code: "fiu",
    name: "Florida International",
    division: 1,
    org_id: "231",
    mascot: "Panthers",
    encoded_h: "Y2ZpdQ==",
    encoded_c: "Y2ZpdV9j",
    encoded_q: "Y2ZpdV9x",
    encoded_p: "Y2ZpdV9w",
  },
  {
    school_code: "fla",
    name: "Florida",
    division: 1,
    org_id: "235",
    mascot: "Gators",
    encoded_h: "Y2ZsYQ==",
    encoded_c: "Y2ZsYV9j",
    encoded_q: "Y2ZsYV9x",
    encoded_p: "Y2ZsYV9w",
  },
  {
    school_code: "flei",
    name: "Fairleigh Dickinson",
    division: 1,
    org_id: "222",
    mascot: "Knights",
    encoded_h: "Y2ZsZWk=",
    encoded_c: "Y2ZsZWlfYw==",
    encoded_q: "Y2ZsZWlfcQ==",
    encoded_p: "Y2ZsZWlfcA==",
  },
  {
    school_code: "ford",
    name: "Fordham",
    division: 1,
    org_id: "236",
    mascot: "Rams",
    encoded_h: "Y2ZvcmQ=",
    encoded_c: "Y2ZvcmRfYw==",
    encoded_q: "Y2ZvcmRfcQ==",
    encoded_p: "Y2ZvcmRfcA==",
  },
  {
    school_code: "fres",
    name: "Fresno State",
    division: 1,
    org_id: "96",
    mascot: "Bulldogs",
    encoded_h: "Y2ZyZXM=",
    encoded_c: "Y2ZyZXNfYw==",
    encoded_q: "Y2ZyZXNfcQ==",
    encoded_p: "Y2ZyZXNfcA==",
  },
  {
    school_code: "fsu",
    name: "Florida State",
    division: 1,
    org_id: "234",
    mascot: "Seminoles",
    encoded_h: "Y2ZzdQ==",
    encoded_c: "Y2ZzdV9j",
    encoded_q: "Y2ZzdV9x",
    encoded_p: "Y2ZzdV9w",
  },
  {
    school_code: "furm",
    name: "Furman",
    division: 1,
    org_id: "244",
    mascot: "Paladins",
    encoded_h: "Y2Z1cm0=",
    encoded_c: "Y2Z1cm1fYw==",
    encoded_q: "Y2Z1cm1fcQ==",
    encoded_p: "Y2Z1cm1fcA==",
  },
  {
    school_code: "gard",
    schedule_id: 46191,
    name: "Gardner-Webb",
    division: 1,
    org_id: "1092",
    mascot: "Runnin' Bulldogs",
    encoded_h: "Y2dhcmQ=",
    encoded_c: "Y2dhcmRfYw==",
    encoded_q: "Y2dhcmRfcQ==",
    encoded_p: "Y2dhcmRfcA==",
  },
  {
    school_code: "gema",
    schedule_id: 612988,
    name: "George Mason",
    division: 1,
    org_id: "248",
    mascot: "Patriots",
    encoded_h: "Y2dlbWE=",
    encoded_c: "Y2dlbWFfYw==",
    encoded_q: "Y2dlbWFfcQ==",
    encoded_p: "Y2dlbWFfcA==",
  },
  {
    school_code: "geo",
    name: "Georgia",
    division: 1,
    org_id: "257",
    mascot: "Bulldogs",
    encoded_h: "Y2dlbw==",
    encoded_c: "Y2dlb19j",
    encoded_q: "Y2dlb19x",
    encoded_p: "Y2dlb19w",
  },
  {
    school_code: "geos",
    name: "Georgia Southern",
    division: 1,
    org_id: "253",
    mascot: "Eagles",
    encoded_h: "Y2dlb3M=",
    encoded_c: "Y2dlb3NfYw==",
    encoded_q: "Y2dlb3NfcQ==",
    encoded_p: "Y2dlb3NfcA==",
  },
  {
    school_code: "geost",
    schedule_id: 53507,
    name: "Georgia State",
    division: 1,
    org_id: "254",
    mascot: "Panthers",
    encoded_h: "Y2dlb3N0",
    encoded_c: "Y2dlb3N0X2M=",
    encoded_q: "Y2dlb3N0X3E=",
    encoded_p: "Y2dlb3N0X3A=",
  },
  {
    school_code: "geot",
    name: "Georgia Tech",
    division: 1,
    org_id: "255",
    mascot: "Yellow Jackets",
    encoded_h: "Y2dlb3Q=",
    encoded_c: "Y2dlb3RfYw==",
    encoded_q: "Y2dlb3RfcQ==",
    encoded_p: "Y2dlb3RfcA==",
  },
  {
    school_code: "gewa",
    name: "George Washington",
    division: 1,
    org_id: "249",
    mascot: "Colonials",
    encoded_h: "Y2dld2E=",
    encoded_c: "Y2dld2FfYw==",
    encoded_q: "Y2dld2FfcQ==",
    encoded_p: "Y2dld2FfcA==",
  },
  {
    school_code: "gonz",
    schedule_id: 627521,
    name: "Gonzaga",
    division: 1,
    org_id: "260",
    mascot: "Bulldogs",
    encoded_h: "Y2dvbno=",
    encoded_c: "Y2dvbnpfYw==",
    encoded_q: "Y2dvbnpfcQ==",
    encoded_p: "Y2dvbnpfcA==",
  },
  {
    school_code: "gramb",
    name: "Grambling",
    division: 1,
    org_id: "261",
    mascot: "Tigers",
    encoded_h: "Y2dyYW1i",
    encoded_c: "Y2dyYW1iX2M=",
    encoded_q: "Y2dyYW1iX3E=",
    encoded_p: "Y2dyYW1iX3A=",
  },
  {
    school_code: "gu",
    name: "Georgetown",
    division: 1,
    org_id: "251",
    mascot: "Hoyas",
    encoded_h: "Y2d1",
    encoded_c: "Y2d1X2M=",
    encoded_q: "Y2d1X3E=",
    encoded_p: "Y2d1X3A=",
  },
  {
    school_code: "hart",
    name: "Hartford",
    division: 1,
    org_id: "272",
    mascot: "Hawks",
    encoded_h: "Y2hhcnQ=",
    encoded_c: "Y2hhcnRfYw==",
    encoded_q: "Y2hhcnRfcQ==",
    encoded_p: "Y2hhcnRfcA==",
  },
  {
    school_code: "harv",
    name: "Harvard",
    division: 1,
    org_id: "275",
    mascot: "Crimson",
    encoded_h: "Y2hhcnY=",
    encoded_c: "Y2hhcnZfYw==",
    encoded_q: "Y2hhcnZfcQ==",
    encoded_p: "Y2hhcnZfcA==",
  },
  {
    school_code: "haw",
    name: "Hawaii",
    division: 1,
    org_id: "277",
    mascot: "Rainbow Warriors",
    encoded_h: "Y2hhdw==",
    encoded_c: "Y2hhd19j",
    encoded_q: "Y2hhd19x",
    encoded_p: "Y2hhd19w",
  },
  {
    school_code: "hbap",
    name: "Houston Baptist",
    division: 1,
    org_id: "287",
    mascot: "Huskies",
    encoded_h: "Y2hiYXA=",
    encoded_c: "Y2hiYXBfYw==",
    encoded_q: "Y2hiYXBfcQ==",
    encoded_p: "Y2hiYXBfcA==",
  },
  {
    school_code: "high",
    name: "High Point",
    division: 1,
    org_id: "19651",
    mascot: "Panthers",
    encoded_h: "Y2hpZ2g=",
    encoded_c: "Y2hpZ2hfYw==",
    encoded_q: "Y2hpZ2hfcQ==",
    encoded_p: "Y2hpZ2hfcA==",
  },
  {
    school_code: "hof",
    schedule_id: 109650,
    name: "Hofstra",
    division: 1,
    org_id: "283",
    mascot: "Pride",
    encoded_h: "Y2hvZg==",
    encoded_c: "Y2hvZl9j",
    encoded_q: "Y2hvZl9x",
    encoded_p: "Y2hvZl9w",
  },
  {
    school_code: "holy",
    name: "Holy Cross",
    division: 1,
    org_id: "285",
    mascot: "Crusaders",
    encoded_h: "Y2hvbHk=",
    encoded_c: "Y2hvbHlfYw==",
    encoded_q: "Y2hvbHlfcQ==",
    encoded_p: "Y2hvbHlfcA==",
  },
  {
    school_code: "hou",
    name: "Houston",
    division: 1,
    org_id: "288",
    mascot: "Cougars",
    encoded_h: "Y2hvdQ==",
    encoded_c: "Y2hvdV9j",
    encoded_q: "Y2hvdV9x",
    encoded_p: "Y2hvdV9w",
  },
  {
    school_code: "ilch",
    name: "UIC",
    division: 1,
    org_id: "302",
    mascot: "Flames",
    encoded_h: "Y2lsY2g=",
    encoded_c: "Y2lsY2hfYw==",
    encoded_q: "Y2lsY2hfcQ==",
    encoded_p: "Y2lsY2hfcA==",
  },
  {
    school_code: "ill",
    name: "Illinois",
    division: 1,
    org_id: "301",
    mascot: "Fighting Illini",
    encoded_h: "Y2lsbA==",
    encoded_c: "Y2lsbF9j",
    encoded_q: "Y2lsbF9x",
    encoded_p: "Y2lsbF9w",
  },
  {
    school_code: "ilsu",
    name: "Illinois State",
    division: 1,
    org_id: "299",
    mascot: "Redbirds",
    encoded_h: "Y2lsc3U=",
    encoded_c: "Y2lsc3VfYw==",
    encoded_q: "Y2lsc3VfcQ==",
    encoded_p: "Y2lsc3VfcA==",
  },
  {
    school_code: "ind",
    name: "Indiana",
    division: 1,
    org_id: "306",
    mascot: "Hoosiers",
    encoded_h: "Y2luZA==",
    encoded_c: "Y2luZF9j",
    encoded_q: "Y2luZF9x",
    encoded_p: "Y2luZF9w",
  },
  {
    school_code: "insu",
    schedule_id: 65130,
    name: "Indiana State",
    division: 1,
    org_id: "305",
    mascot: "Sycamores",
    encoded_h: "Y2luc3U=",
    encoded_c: "Y2luc3VfYw==",
    encoded_q: "Y2luc3VfcQ==",
    encoded_p: "Y2luc3VfcA==",
  },
  {
    school_code: "iona",
    schedule_id: 64300,
    name: "Iona",
    division: 1,
    org_id: "310",
    mascot: "Gaels",
    encoded_h: "Y2lvbmE=",
    encoded_c: "Y2lvbmFfYw==",
    encoded_q: "Y2lvbmFfcQ==",
    encoded_p: "Y2lvbmFfcA==",
  },
  {
    school_code: "iowa",
    name: "Iowa",
    division: 1,
    org_id: "312",
    mascot: "Hawkeyes",
    encoded_h: "Y2lvd2E=",
    encoded_c: "Y2lvd2FfYw==",
    encoded_q: "Y2lvd2FfcQ==",
    encoded_p: "Y2lvd2FfcA==",
  },
  {
    school_code: "ipfw",
    name: "IPFW",
    division: 1,
    org_id: "308",
    mascot: "Mastodons",
    encoded_h: "Y2lwZnc=",
    encoded_c: "Y2lwZndfYw==",
    encoded_q: "Y2lwZndfcQ==",
    encoded_p: "Y2lwZndfcA==",
  },
  {
    school_code: "jack",
    name: "Jacksonville",
    division: 1,
    org_id: "316",
    mascot: "Dolphins",
    encoded_h: "Y2phY2s=",
    encoded_c: "Y2phY2tfYw==",
    encoded_q: "Y2phY2tfcQ==",
    encoded_p: "Y2phY2tfcA==",
  },
  {
    school_code: "jame",
    schedule_id: 62335,
    name: "James Madison",
    division: 1,
    org_id: "317",
    mascot: "Dukes",
    encoded_h: "Y2phbWU=",
    encoded_c: "Y2phbWVfYw==",
    encoded_q: "Y2phbWVfcQ==",
    encoded_p: "Y2phbWVfcA==",
  },
  {
    school_code: "jast",
    schedule_id: 669885,
    name: "Jackson State",
    division: 1,
    org_id: "314",
    mascot: "Tigers",
    encoded_h: "Y2phc3Q=",
    encoded_c: "Y2phc3RfYw==",
    encoded_q: "Y2phc3RfcQ==",
    encoded_p: "Y2phc3RfcA==",
  },
  {
    school_code: "jaxst",
    name: "Jacksonville State",
    division: 1,
    org_id: "315",
    mascot: "Gamecocks",
    encoded_h: "Y2pheHN0",
    encoded_c: "Y2pheHN0X2M=",
    encoded_q: "Y2pheHN0X3E=",
    encoded_p: "Y2pheHN0X3A=",
  },
  {
    school_code: "kan",
    name: "Kansas",
    division: 1,
    org_id: "328",
    mascot: "Jayhawks",
    encoded_h: "Y2thbg==",
    encoded_c: "Y2thbl9j",
    encoded_q: "Y2thbl9x",
    encoded_p: "Y2thbl9w",
  },
  {
    school_code: "kenn",
    name: "Kennesaw State",
    division: 1,
    org_id: "1157",
    mascot: "Owls",
    encoded_h: "Y2tlbm4=",
    encoded_c: "Y2tlbm5fYw==",
    encoded_q: "Y2tlbm5fcQ==",
    encoded_p: "Y2tlbm5fcA==",
  },
  {
    school_code: "kent",
    name: "Kent State",
    division: 1,
    org_id: "331",
    mascot: "Golden Flashes",
    encoded_h: "Y2tlbnQ=",
    encoded_c: "Y2tlbnRfYw==",
    encoded_q: "Y2tlbnRfcQ==",
    encoded_p: "Y2tlbnRfcA==",
  },
  {
    school_code: "ksu",
    name: "Kansas State",
    division: 1,
    org_id: "327",
    mascot: "Wildcats",
    encoded_h: "Y2tzdQ==",
    encoded_c: "Y2tzdV9j",
    encoded_q: "Y2tzdV9x",
    encoded_p: "Y2tzdV9w",
  },
  {
    school_code: "kty",
    name: "Kentucky",
    division: 1,
    org_id: "334",
    mascot: "Wildcats",
    encoded_h: "Y2t0eQ==",
    encoded_c: "Y2t0eV9j",
    encoded_q: "Y2t0eV9x",
    encoded_p: "Y2t0eV9w",
  },
  {
    school_code: "lafa",
    name: "Lafayette",
    division: 1,
    org_id: "342",
    mascot: "Leopards",
    encoded_h: "Y2xhZmE=",
    encoded_c: "Y2xhZmFfYw==",
    encoded_q: "Y2xhZmFfcQ==",
    encoded_p: "Y2xhZmFfcA==",
  },
  {
    school_code: "lala",
    name: "Louisiana Lafayette",
    division: 1,
    org_id: "671",
    mascot: "Ragin' Cajuns",
    encoded_h: "Y2xhbGE=",
    encoded_c: "Y2xhbGFfYw==",
    encoded_q: "Y2xhbGFfcQ==",
    encoded_p: "Y2xhbGFfcA==",
  },
  {
    school_code: "lama",
    name: "Lamar",
    division: 1,
    org_id: "346",
    mascot: "Cardinals",
    encoded_h: "Y2xhbWE=",
    encoded_c: "Y2xhbWFfYw==",
    encoded_q: "Y2xhbWFfcQ==",
    encoded_p: "Y2xhbWFfcA==",
  },
  {
    school_code: "lamo",
    schedule_id: 90637,
    name: "Louisiana Monroe",
    division: 1,
    org_id: "498",
    mascot: "Warhawks",
    encoded_h: "Y2xhbW8=",
    encoded_c: "Y2xhbW9fYw==",
    encoded_q: "Y2xhbW9fcQ==",
    encoded_p: "Y2xhbW9fcA==",
  },
  {
    school_code: "lasa",
    name: "LaSalle",
    division: 1,
    org_id: "340",
    mascot: "Explorers",
    encoded_h: "Y2xhc2E=",
    encoded_c: "Y2xhc2FfYw==",
    encoded_q: "Y2xhc2FfcQ==",
    encoded_p: "Y2xhc2FfcA==",
  },
  {
    school_code: "latc",
    name: "Louisiana Tech",
    division: 1,
    org_id: "366",
    mascot: "Bulldogs",
    encoded_h: "Y2xhdGM=",
    encoded_c: "Y2xhdGNfYw==",
    encoded_q: "Y2xhdGNfcQ==",
    encoded_p: "Y2xhdGNfcA==",
  },
  {
    school_code: "lbst",
    name: "Long Beach State",
    division: 1,
    org_id: "99",
    mascot: "49ers",
    encoded_h: "Y2xic3Q=",
    encoded_c: "Y2xic3RfYw==",
    encoded_q: "Y2xic3RfcQ==",
    encoded_p: "Y2xic3RfcA==",
  },
  {
    school_code: "lehi",
    name: "Lehigh",
    division: 1,
    org_id: "352",
    mascot: "Mountain Hawks",
    encoded_h: "Y2xlaGk=",
    encoded_c: "Y2xlaGlfYw==",
    encoded_q: "Y2xlaGlfcQ==",
    encoded_p: "Y2xlaGlfcA==",
  },
  {
    school_code: "libe",
    name: "Liberty",
    division: 1,
    org_id: "355",
    mascot: "Flames",
    encoded_h: "Y2xpYmU=",
    encoded_c: "Y2xpYmVfYw==",
    encoded_q: "Y2xpYmVfcQ==",
    encoded_p: "Y2xpYmVfcA==",
  },
  {
    school_code: "lips",
    name: "Lipscomb",
    division: 1,
    org_id: "28600",
    mascot: "Bisons",
    encoded_h: "Y2xpcHM=",
    encoded_c: "Y2xpcHNfYw==",
    encoded_q: "Y2xpcHNfcQ==",
    encoded_p: "Y2xpcHNfcA==",
  },
  {
    school_code: "liub",
    name: "LIU Brooklyn",
    division: 1,
    org_id: "361",
    mascot: "Blackbirds",
    encoded_h: "Y2xpdWI=",
    encoded_c: "Y2xpdWJfYw==",
    encoded_q: "Y2xpdWJfcQ==",
    encoded_p: "Y2xpdWJfcA==",
  },
  {
    school_code: "long",
    name: "Longwood",
    division: 1,
    org_id: "363",
    mascot: "Lancers",
    encoded_h: "Y2xvbmc=",
    encoded_c: "Y2xvbmdfYw==",
    encoded_q: "Y2xvbmdfcQ==",
    encoded_p: "Y2xvbmdfcA==",
  },
  {
    school_code: "lou",
    name: "Louisville",
    division: 1,
    org_id: "367",
    mascot: "Cardinals",
    encoded_h: "Y2xvdQ==",
    encoded_c: "Y2xvdV9j",
    encoded_q: "Y2xvdV9x",
    encoded_p: "Y2xvdV9w",
  },
  {
    school_code: "loyo",
    name: "Loyola Marymount",
    division: 1,
    org_id: "370",
    mascot: "Lions",
    encoded_h: "Y2xveW8=",
    encoded_c: "Y2xveW9fYw==",
    encoded_q: "Y2xveW9fcQ==",
    encoded_p: "Y2xveW9fcA==",
  },
  {
    school_code: "lsu",
    schedule_id: 27866,
    name: "LSU",
    division: 1,
    org_id: "365",
    mascot: "Fighting Tigers",
    encoded_h: "Y2xzdQ==",
    encoded_c: "Y2xzdV9j",
    encoded_q: "Y2xzdV9x",
    encoded_p: "Y2xzdV9w",
  },
  {
    school_code: "main",
    name: "Maine",
    division: 1,
    org_id: "380",
    mascot: "Black Bears",
    encoded_h: "Y21haW4=",
    encoded_c: "Y21haW5fYw==",
    encoded_q: "Y21haW5fcQ==",
    encoded_p: "Y21haW5fcA==",
  },
  {
    school_code: "manh",
    schedule_id: 52439,
    name: "Manhattan",
    division: 1,
    org_id: "381",
    mascot: "Jaspers",
    encoded_h: "Y21hbmg=",
    encoded_c: "Y21hbmhfYw==",
    encoded_q: "Y21hbmhfcQ==",
    encoded_p: "Y21hbmhfcA==",
  },
  {
    school_code: "mari",
    schedule_id: 638719,
    name: "Marist",
    division: 1,
    org_id: "386",
    mascot: "Red Foxes",
    encoded_h: "Y21hcmk=",
    encoded_c: "Y21hcmlfYw==",
    encoded_q: "Y21hcmlfcQ==",
    encoded_p: "Y21hcmlfcA==",
  },
  {
    school_code: "mars",
    name: "Marshall",
    division: 1,
    org_id: "388",
    mascot: "Thundering Herd",
    encoded_h: "Y21hcnM=",
    encoded_c: "Y21hcnNfYw==",
    encoded_q: "Y21hcnNfcQ==",
    encoded_p: "Y21hcnNfcA==",
  },
  {
    school_code: "mcne",
    name: "McNeese State",
    division: 1,
    org_id: "402",
    mascot: "Cowboys",
    encoded_h: "Y21jbmU=",
    encoded_c: "Y21jbmVfYw==",
    encoded_q: "Y21jbmVfcQ==",
    encoded_p: "Y21jbmVfcA==",
  },
  {
    school_code: "md",
    schedule_id: 716381,
    name: "Maryland",
    division: 1,
    org_id: "392",
    mascot: "Terrapins",
    encoded_h: "Y21k",
    encoded_c: "Y21kX2M=",
    encoded_q: "Y21kX3E=",
    encoded_p: "Y21kX3A=",
  },
  {
    school_code: "mdbc",
    name: "UMBC",
    division: 1,
    org_id: "391",
    mascot: "Retrievers",
    encoded_h: "Y21kYmM=",
    encoded_c: "Y21kYmNfYw==",
    encoded_q: "Y21kYmNfcQ==",
    encoded_p: "Y21kYmNfcA==",
  },
  {
    school_code: "mdes",
    name: "UMES",
    division: 1,
    org_id: "393",
    mascot: "Hawks",
    encoded_h: "Y21kZXM=",
    encoded_c: "Y21kZXNfYw==",
    encoded_q: "Y21kZXNfcQ==",
    encoded_p: "Y21kZXNfcA==",
  },
  {
    school_code: "mem",
    name: "Memphis",
    division: 1,
    org_id: "404",
    mascot: "Tigers",
    encoded_h: "Y21lbQ==",
    encoded_c: "Y21lbV9j",
    encoded_q: "Y21lbV9x",
    encoded_p: "Y21lbV9w",
  },
  {
    school_code: "merc",
    name: "Mercer",
    division: 1,
    org_id: "406",
    mascot: "Bears",
    encoded_h: "Y21lcmM=",
    encoded_c: "Y21lcmNfYw==",
    encoded_q: "Y21lcmNfcQ==",
    encoded_p: "Y21lcmNfcA==",
  },
  {
    school_code: "mich",
    name: "Michigan",
    division: 1,
    org_id: "418",
    mascot: "Wolverines",
    encoded_h: "Y21pY2g=",
    encoded_c: "Y21pY2hfYw==",
    encoded_q: "Y21pY2hfcQ==",
    encoded_p: "Y21pY2hfcA==",
  },
  {
    school_code: "mifl",
    schedule_id: 658421,
    name: "Miami",
    division: 1,
    org_id: "415",
    mascot: "Hurricanes",
    encoded_h: "Y21pZmw=",
    encoded_c: "Y21pZmxfYw==",
    encoded_q: "Y21pZmxfcQ==",
    encoded_p: "Y21pZmxfcA==",
  },
  {
    school_code: "minn",
    name: "Minnesota",
    division: 1,
    org_id: "428",
    mascot: "Golden Gophers",
    encoded_h: "Y21pbm4=",
    encoded_c: "Y21pbm5fYw==",
    encoded_q: "Y21pbm5fcQ==",
    encoded_p: "Y21pbm5fcA==",
  },
  {
    school_code: "mioh",
    schedule_id: 617718,
    name: "Miami (OH)",
    division: 1,
    org_id: "414",
    mascot: "RedHawks",
    encoded_h: "Y21pb2g=",
    encoded_c: "Y21pb2hfYw==",
    encoded_q: "Y21pb2hfcQ==",
    encoded_p: "Y21pb2hfcA==",
  },
  {
    school_code: "miss",
    name: "Missouri",
    division: 1,
    org_id: "434",
    mascot: "Tigers",
    encoded_h: "Y21pc3M=",
    encoded_c: "Y21pc3NfYw==",
    encoded_q: "Y21pc3NfcQ==",
    encoded_p: "Y21pc3NfcA==",
  },
  {
    school_code: "monm",
    schedule_id: 62238,
    name: "Monmouth",
    division: 1,
    org_id: "439",
    mascot: "Hawks",
    encoded_h: "Y21vbm0=",
    encoded_c: "Y21vbm1fYw==",
    encoded_q: "Y21vbm1fcQ==",
    encoded_p: "Y21vbm1fcA==",
  },
  {
    school_code: "more",
    name: "Morehead State",
    division: 1,
    org_id: "444",
    mascot: "Eagles",
    encoded_h: "Y21vcmU=",
    encoded_c: "Y21vcmVfYw==",
    encoded_q: "Y21vcmVfcQ==",
    encoded_p: "Y21vcmVfcA==",
  },
  {
    school_code: "mosu",
    name: "Missouri State",
    division: 1,
    org_id: "669",
    mascot: "Bears",
    encoded_h: "Y21vc3U=",
    encoded_c: "Y21vc3VfYw==",
    encoded_q: "Y21vc3VfcQ==",
    encoded_p: "Y21vc3VfcA==",
  },
  {
    school_code: "msst",
    schedule_id: 90858,
    name: "Mississippi State",
    division: 1,
    org_id: "430",
    mascot: "Bulldogs",
    encoded_h: "Y21zc3Q=",
    encoded_c: "Y21zc3RfYw==",
    encoded_q: "Y21zc3RfcQ==",
    encoded_p: "Y21zc3RfcA==",
  },
  {
    school_code: "msu",
    name: "Michigan State",
    division: 1,
    org_id: "416",
    mascot: "Spartans",
    encoded_h: "Y21zdQ==",
    encoded_c: "Y21zdV9j",
    encoded_q: "Y21zdV9x",
    encoded_p: "Y21zdV9w",
  },
  {
    school_code: "mtn",
    name: "Middle Tennessee State",
    division: 1,
    org_id: "419",
    mascot: "Blue Raiders",
    encoded_h: "Y210bg==",
    encoded_c: "Y210bl9j",
    encoded_q: "Y210bl9x",
    encoded_p: "Y210bl9w",
  },
  {
    school_code: "mtsm",
    name: "Mount Saint Mary's",
    division: 1,
    org_id: "450",
    mascot: "Mountaineers",
    encoded_h: "Y210c20=",
    encoded_c: "Y210c21fYw==",
    encoded_q: "Y210c21fcQ==",
    encoded_p: "Y210c21fcA==",
  },
  {
    school_code: "must",
    name: "Murray State",
    division: 1,
    org_id: "454",
    mascot: "Racers",
    encoded_h: "Y211c3Q=",
    encoded_c: "Y211c3RfYw==",
    encoded_q: "Y211c3RfcQ==",
    encoded_p: "Y211c3RfcA==",
  },
  {
    school_code: "mvsu",
    name: "Mississippi Valley State",
    division: 1,
    org_id: "432",
    mascot: "Delta Devils",
    encoded_h: "Y212c3U=",
    encoded_c: "Y212c3VfYw==",
    encoded_q: "Y212c3VfcQ==",
    encoded_p: "Y212c3VfcA==",
  },
  {
    school_code: "navy",
    name: "Navy",
    division: 1,
    org_id: "726",
    mascot: "Midshipmen",
    encoded_h: "Y25hdnk=",
    encoded_c: "Y25hdnlfYw==",
    encoded_q: "Y25hdnlfcQ==",
    encoded_p: "Y25hdnlfcA==",
  },
  {
    school_code: "ncat",
    schedule_id: 593447,
    name: "North Carolina A&T",
    division: 1,
    org_id: "488",
    mascot: "Aggies",
    encoded_h: "Y25jYXQ=",
    encoded_c: "Y25jYXRfYw==",
    encoded_q: "Y25jYXRfcQ==",
    encoded_p: "Y25jYXRfcA==",
  },
  {
    school_code: "ncch",
    schedule_id: 363417,
    name: "Charlotte",
    division: 1,
    org_id: "458",
    mascot: "49ers",
    encoded_h: "Y25jY2g=",
    encoded_c: "Y25jY2hfYw==",
    encoded_q: "Y25jY2hfcQ==",
    encoded_p: "Y25jY2hfcA==",
  },
  {
    school_code: "nccu",
    name: "North Carolina Central",
    division: 1,
    org_id: "489",
    mascot: "Eagles",
    encoded_h: "Y25jY3U=",
    encoded_c: "Y25jY3VfYw==",
    encoded_q: "Y25jY3VfcQ==",
    encoded_p: "Y25jY3VfcA==",
  },
  {
    school_code: "ncol",
    name: "Northern Colorado",
    division: 1,
    org_id: "502",
    mascot: "Bears",
    encoded_h: "Y25jb2w=",
    encoded_c: "Y25jb2xfYw==",
    encoded_q: "Y25jb2xfcQ==",
    encoded_p: "Y25jb2xfcA==",
  },
  {
    school_code: "ncst",
    name: "North Carolina State",
    division: 1,
    org_id: "490",
    mascot: "Wolfpack",
    encoded_h: "Y25jc3Q=",
    encoded_c: "Y25jc3RfYw==",
    encoded_q: "Y25jc3RfcQ==",
    encoded_p: "Y25jc3RfcA==",
  },
  {
    school_code: "nd",
    name: "Notre Dame",
    division: 1,
    org_id: "513",
    mascot: "Fighting Irish",
    encoded_h: "Y25k",
    encoded_c: "Y25kX2M=",
    encoded_q: "Y25kX3E=",
    encoded_p: "Y25kX3A=",
  },
  {
    school_code: "ndak",
    schedule_id: 58539,
    name: "North Dakota",
    division: 1,
    org_id: "494",
    mascot: "UND",
    encoded_h: "Y25kYWs=",
    encoded_c: "Y25kYWtfYw==",
    encoded_q: "Y25kYWtfcQ==",
    encoded_p: "Y25kYWtfcA==",
  },
  {
    school_code: "ndsu",
    name: "North Dakota State",
    division: 1,
    org_id: "493",
    mascot: "Bison",
    encoded_h: "Y25kc3U=",
    encoded_c: "Y25kc3VfYw==",
    encoded_q: "Y25kc3VfcQ==",
    encoded_p: "Y25kc3VfcA==",
  },
  {
    school_code: "ne",
    name: "Northeastern",
    division: 1,
    org_id: "500",
    mascot: "Huskies",
    encoded_h: "Y25l",
    encoded_c: "Y25lX2M=",
    encoded_q: "Y25lX3E=",
    encoded_p: "Y25lX3A=",
  },
  {
    school_code: "neb",
    schedule_id: 103,
    name: "Nebraska",
    division: 1,
    org_id: "463",
    mascot: "Cornhuskers",
    encoded_h: "Y25lYg==",
    encoded_c: "Y25lYl9j",
    encoded_q: "Y25lYl9x",
    encoded_p: "Y25lYl9w",
  },
  {
    school_code: "nebo",
    name: "Omaha",
    division: 1,
    org_id: "464",
    mascot: "Mavericks",
    encoded_h: "Y25lYm8=",
    encoded_c: "Y25lYm9fYw==",
    encoded_q: "Y25lYm9fcQ==",
    encoded_p: "Y25lYm9fcA==",
  },
  {
    school_code: "newo",
    name: "New Orleans",
    division: 1,
    org_id: "474",
    mascot: "Privateers",
    encoded_h: "Y25ld28=",
    encoded_c: "Y25ld29fYw==",
    encoded_q: "Y25ld29fcQ==",
    encoded_p: "Y25ld29fcA==",
  },
  {
    school_code: "nfla",
    name: "North Florida",
    division: 1,
    org_id: "2711",
    mascot: "Ospreys",
    encoded_h: "Y25mbGE=",
    encoded_c: "Y25mbGFfYw==",
    encoded_q: "Y25mbGFfcQ==",
    encoded_p: "Y25mbGFfcA==",
  },
  {
    school_code: "niag",
    name: "Niagara",
    division: 1,
    org_id: "482",
    mascot: "Purple Eagles",
    encoded_h: "Y25pYWc=",
    encoded_c: "Y25pYWdfYw==",
    encoded_q: "Y25pYWdfcQ==",
    encoded_p: "Y25pYWdfcA==",
  },
  {
    school_code: "nist",
    name: "Nicholls State",
    division: 1,
    org_id: "483",
    mascot: "Colonels",
    encoded_h: "Y25pc3Q=",
    encoded_c: "Y25pc3RfYw==",
    encoded_q: "Y25pc3RfcQ==",
    encoded_p: "Y25pc3RfcA==",
  },
  {
    school_code: "niu",
    name: "Northern Illinois",
    division: 1,
    org_id: "503",
    mascot: "Huskies",
    encoded_h: "Y25pdQ==",
    encoded_c: "Y25pdV9j",
    encoded_q: "Y25pdV9x",
    encoded_p: "Y25pdV9w",
  },
  {
    school_code: "njit",
    name: "NJIT",
    division: 1,
    org_id: "471",
    mascot: "Highlanders",
    encoded_h: "Y25qaXQ=",
    encoded_c: "Y25qaXRfYw==",
    encoded_q: "Y25qaXRfcQ==",
    encoded_p: "Y25qaXRfcA==",
  },
  {
    school_code: "neky",
    name: "Northern Kentucky",
    division: 1,
    org_id: "505",
    mascot: "Norse",
    encoded_h: "Y25la3k=",
    encoded_c: "Y25la3lfYw==",
    encoded_q: "Y25la3lfcQ==",
    encoded_p: "Y25la3lfcA==",
  },
  {
    school_code: "nm",
    schedule_id: 616918,
    name: "New Mexico",
    division: 1,
    org_id: "473",
    mascot: "Lobos",
    encoded_h: "Y25t",
    encoded_c: "Y25tX2M=",
    encoded_q: "Y25tX3E=",
    encoded_p: "Y25tX3A=",
  },
  {
    school_code: "nmst",
    schedule_id: 9785,
    name: "New Mexico State",
    division: 1,
    org_id: "472",
    mascot: "Aggies",
    encoded_h: "Y25tc3Q=",
    encoded_c: "Y25tc3RfYw==",
    encoded_q: "Y25tc3RfcQ==",
    encoded_p: "Y25tc3RfcA==",
  },
  {
    school_code: "norf",
    name: "Norfolk State",
    division: 1,
    org_id: "485",
    mascot: "Spartans",
    encoded_h: "Y25vcmY=",
    encoded_c: "Y25vcmZfYw==",
    encoded_q: "Y25vcmZfcQ==",
    encoded_p: "Y25vcmZfcA==",
  },
  {
    school_code: "nova",
    name: "Villanova",
    division: 1,
    org_id: "739",
    mascot: "Wildcats",
    encoded_h: "Y25vdmE=",
    encoded_c: "Y25vdmFfYw==",
    encoded_q: "Y25vdmFfcQ==",
    encoded_p: "Y25vdmFfcA==",
  },
  {
    school_code: "nw",
    name: "Northwestern",
    division: 1,
    org_id: "509",
    mascot: "Wildcats",
    encoded_h: "Y253",
    encoded_c: "Y253X2M=",
    encoded_q: "Y253X3E=",
    encoded_p: "Y253X3A=",
  },
  {
    school_code: "nwst",
    name: "Northwestern State",
    division: 1,
    org_id: "508",
    mascot: "Demons",
    encoded_h: "Y253c3Q=",
    encoded_c: "Y253c3RfYw==",
    encoded_q: "Y253c3RfcQ==",
    encoded_p: "Y253c3RfcA==",
  },
  {
    school_code: "nyit",
    name: "NYIT",
    division: 1,
    org_id: "477",
    mascot: "Bears",
    encoded_h: "Y255aXQ=",
    encoded_c: "Y255aXRfYw==",
    encoded_q: "Y255aXRfcQ==",
    encoded_p: "Y255aXRfcA==",
  },
  {
    school_code: "oakl",
    name: "Oakland",
    division: 1,
    org_id: "514",
    mascot: "Golden Grizzlies",
    encoded_h: "Y29ha2w=",
    encoded_c: "Y29ha2xfYw==",
    encoded_q: "Y29ha2xfcQ==",
    encoded_p: "Y29ha2xfcA==",
  },
  {
    school_code: "ohio",
    name: "Ohio",
    division: 1,
    org_id: "519",
    mascot: "Bobcats",
    encoded_h: "Y29oaW8=",
    encoded_c: "Y29oaW9fYw==",
    encoded_q: "Y29oaW9fcQ==",
    encoded_p: "Y29oaW9fcA==",
  },
  {
    school_code: "okla",
    name: "Oklahoma",
    division: 1,
    org_id: "522",
    mascot: "Sooners",
    encoded_h: "Y29rbGE=",
    encoded_c: "Y29rbGFfYw==",
    encoded_q: "Y29rbGFfcQ==",
    encoded_p: "Y29rbGFfcA==",
  },
  {
    school_code: "okst",
    name: "Oklahoma State",
    division: 1,
    org_id: "521",
    mascot: "Cowboys",
    encoded_h: "Y29rc3Q=",
    encoded_c: "Y29rc3RfYw==",
    encoded_q: "Y29rc3RfcQ==",
    encoded_p: "Y29rc3RfcA==",
  },
  {
    school_code: "oldd",
    schedule_id: 750481,
    name: "Old Dominion",
    division: 1,
    org_id: "523",
    mascot: "Monarchs",
    encoded_h: "Y29sZGQ=",
    encoded_c: "Y29sZGRfYw==",
    encoded_q: "Y29sZGRfcQ==",
    encoded_p: "Y29sZGRfcA==",
  },
  {
    school_code: "ole",
    name: "Ole Miss",
    division: 1,
    org_id: "433",
    mascot: "Rebels",
    encoded_h: "Y29sZQ==",
    encoded_c: "Y29sZV9j",
    encoded_q: "Y29sZV9x",
    encoded_p: "Y29sZV9w",
  },
  {
    school_code: "oral",
    schedule_id: 87156,
    name: "Oral Roberts",
    division: 1,
    org_id: "527",
    mascot: "Golden Eagles",
    encoded_h: "Y29yYWw=",
    encoded_c: "Y29yYWxfYw==",
    encoded_q: "Y29yYWxfcQ==",
    encoded_p: "Y29yYWxfcA==",
  },
  {
    school_code: "ore",
    schedule_id: 94835,
    name: "Oregon",
    division: 1,
    org_id: "529",
    mascot: "Ducks",
    encoded_h: "Y29yZQ==",
    encoded_c: "Y29yZV9j",
    encoded_q: "Y29yZV9x",
    encoded_p: "Y29yZV9w",
  },
  {
    school_code: "orst",
    schedule_id: 750179,
    name: "Oregon State",
    division: 1,
    org_id: "528",
    mascot: "Beavers",
    encoded_h: "Y29yc3Q=",
    encoded_c: "Y29yc3RfYw==",
    encoded_q: "Y29yc3RfcQ==",
    encoded_p: "Y29yc3RfcA==",
  },
  {
    school_code: "osu",
    name: "Ohio State",
    division: 1,
    org_id: "518",
    mascot: "Buckeyes",
    encoded_h: "Y29zdQ==",
    encoded_c: "Y29zdV9j",
    encoded_q: "Y29zdV9x",
    encoded_p: "Y29zdV9w",
  },
  {
    school_code: "paci",
    name: "Pacific",
    division: 1,
    org_id: "534",
    mascot: "Tigers",
    encoded_h: "Y3BhY2k=",
    encoded_c: "Y3BhY2lfYw==",
    encoded_q: "Y3BhY2lfcQ==",
    encoded_p: "Y3BhY2lfcA==",
  },
  {
    school_code: "penn",
    schedule_id: 8833,
    name: "Penn",
    division: 1,
    org_id: "540",
    mascot: "Quakers",
    encoded_h: "Y3Blbm4=",
    encoded_c: "Y3Blbm5fYw==",
    encoded_q: "Y3Blbm5fcQ==",
    encoded_p: "Y3Blbm5fcA==",
  },
  {
    school_code: "pepp",
    name: "Pepperdine",
    division: 1,
    org_id: "541",
    mascot: "Waves",
    encoded_h: "Y3BlcHA=",
    encoded_c: "Y3BlcHBfYw==",
    encoded_q: "Y3BlcHBfcQ==",
    encoded_p: "Y3BlcHBfcA==",
  },
  {
    school_code: "pitt",
    name: "Pittsburgh",
    division: 1,
    org_id: "545",
    mascot: "Panthers",
    encoded_h: "Y3BpdHQ=",
    encoded_c: "Y3BpdHRfYw==",
    encoded_q: "Y3BpdHRfcQ==",
    encoded_p: "Y3BpdHRfcA==",
  },
  {
    school_code: "port",
    name: "Portland",
    division: 1,
    org_id: "551",
    mascot: "Pilots",
    encoded_h: "Y3BvcnQ=",
    encoded_c: "Y3BvcnRfYw==",
    encoded_q: "Y3BvcnRfcQ==",
    encoded_p: "Y3BvcnRfcA==",
  },
  {
    school_code: "pres",
    schedule_id: 89791,
    name: "Presbyterian",
    division: 1,
    org_id: "1320",
    mascot: "Blue Hose",
    encoded_h: "Y3ByZXM=",
    encoded_c: "Y3ByZXNfYw==",
    encoded_q: "Y3ByZXNfcQ==",
    encoded_p: "Y3ByZXNfcA==",
  },
  {
    school_code: "prin",
    schedule_id: 46835,
    name: "Princeton",
    division: 1,
    org_id: "554",
    mascot: "Tigers",
    encoded_h: "Y3ByaW4=",
    encoded_c: "Y3ByaW5fYw==",
    encoded_q: "Y3ByaW5fcQ==",
    encoded_p: "Y3ByaW5fcA==",
  },
  {
    school_code: "psu",
    name: "Penn State",
    division: 1,
    org_id: "539",
    mascot: "Nittany Lions",
    encoded_h: "Y3BzdQ==",
    encoded_c: "Y3BzdV9j",
    encoded_q: "Y3BzdV9x",
    encoded_p: "Y3BzdV9w",
  },
  {
    school_code: "pur",
    name: "Purdue",
    division: 1,
    org_id: "559",
    mascot: "Boilermakers",
    encoded_h: "Y3B1cg==",
    encoded_c: "Y3B1cl9j",
    encoded_q: "Y3B1cl9x",
    encoded_p: "Y3B1cl9w",
  },
  {
    school_code: "pvam",
    name: "Prairie View A&M",
    division: 1,
    org_id: "553",
    mascot: "Panthers",
    encoded_h: "Y3B2YW0=",
    encoded_c: "Y3B2YW1fYw==",
    encoded_q: "Y3B2YW1fcQ==",
    encoded_p: "Y3B2YW1fcA==",
  },
  {
    school_code: "quic",
    name: "Quinnipiac",
    division: 1,
    org_id: "562",
    mascot: "Bobcats",
    encoded_h: "Y3F1aWM=",
    encoded_c: "Y3F1aWNfYw==",
    encoded_q: "Y3F1aWNfcQ==",
    encoded_p: "Y3F1aWNfcA==",
  },
  {
    school_code: "radf",
    name: "Radford",
    division: 1,
    org_id: "563",
    mascot: "Highlanders",
    encoded_h: "Y3JhZGY=",
    encoded_c: "Y3JhZGZfYw==",
    encoded_q: "Y3JhZGZfcQ==",
    encoded_p: "Y3JhZGZfcA==",
  },
  {
    school_code: "rice",
    name: "Rice",
    division: 1,
    org_id: "574",
    mascot: "Owls",
    encoded_h: "Y3JpY2U=",
    encoded_c: "Y3JpY2VfYw==",
    encoded_q: "Y3JpY2VfcQ==",
    encoded_p: "Y3JpY2VfcA==",
  },
  {
    school_code: "rich",
    schedule_id: 629617,
    name: "Richmond",
    division: 1,
    org_id: "575",
    mascot: "Spiders",
    encoded_h: "Y3JpY2g=",
    encoded_c: "Y3JpY2hfYw==",
    encoded_q: "Y3JpY2hfcQ==",
    encoded_p: "Y3JpY2hfcA==",
  },
  {
    school_code: "ride",
    schedule_id: 96731,
    name: "Rider",
    division: 1,
    org_id: "576",
    mascot: "Broncs",
    encoded_h: "Y3JpZGU=",
    encoded_c: "Y3JpZGVfYw==",
    encoded_q: "Y3JpZGVfcQ==",
    encoded_p: "Y3JpZGVfcA==",
  },
  {
    school_code: "rutu",
    name: "Rutgers",
    division: 1,
    org_id: "587",
    mascot: "Scarlet Knights",
    encoded_h: "Y3J1dHU=",
    encoded_c: "Y3J1dHVfYw==",
    encoded_q: "Y3J1dHVfcQ==",
    encoded_p: "Y3J1dHVfcA==",
  },
  {
    school_code: "sacl",
    name: "Santa Clara",
    division: 1,
    org_id: "631",
    mascot: "Broncos",
    encoded_h: "Y3NhY2w=",
    encoded_c: "Y3NhY2xfYw==",
    encoded_q: "Y3NhY2xfcQ==",
    encoded_p: "Y3NhY2xfcA==",
  },
  {
    school_code: "sacr",
    name: "Sacred Heart",
    division: 1,
    org_id: "590",
    mascot: "Pioneers",
    encoded_h: "Y3NhY3I=",
    encoded_c: "Y3NhY3JfYw==",
    encoded_q: "Y3NhY3JfcQ==",
    encoded_p: "Y3NhY3JfcA==",
  },
  {
    school_code: "sacs",
    name: "Sacramento State",
    division: 1,
    org_id: "102",
    mascot: "Hornets",
    encoded_h: "Y3NhY3M=",
    encoded_c: "Y3NhY3NfYw==",
    encoded_q: "Y3NhY3NfcQ==",
    encoded_p: "Y3NhY3NfcA==",
  },
  {
    school_code: "salab",
    name: "South Alabama",
    division: 1,
    org_id: "646",
    mascot: "Jaguars",
    encoded_h: "Y3NhbGFi",
    encoded_c: "Y3NhbGFiX2M=",
    encoded_q: "Y3NhbGFiX3E=",
    encoded_p: "Y3NhbGFiX3A=",
  },
  {
    school_code: "samf",
    name: "Samford",
    division: 1,
    org_id: "625",
    mascot: "Bulldogs",
    encoded_h: "Y3NhbWY=",
    encoded_c: "Y3NhbWZfYw==",
    encoded_q: "Y3NhbWZfcQ==",
    encoded_p: "Y3NhbWZfcA==",
  },
  {
    school_code: "samh",
    name: "Sam Houston State",
    division: 1,
    org_id: "624",
    mascot: "Bearkats",
    encoded_h: "Y3NhbWg=",
    encoded_c: "Y3NhbWhfYw==",
    encoded_q: "Y3NhbWhfcQ==",
    encoded_p: "Y3NhbWhfcA==",
  },
  {
    school_code: "sanf",
    name: "San Francisco",
    division: 1,
    org_id: "629",
    mascot: "Dons",
    encoded_h: "Y3NhbmY=",
    encoded_c: "Y3NhbmZfYw==",
    encoded_q: "Y3NhbmZfcQ==",
    encoded_p: "Y3NhbmZfcA==",
  },
  {
    school_code: "sasu",
    name: "Stephen F. Austin",
    division: 1,
    org_id: "676",
    mascot: "Lumberjacks",
    encoded_h: "Y3Nhc3U=",
    encoded_c: "Y3Nhc3VfYw==",
    encoded_q: "Y3Nhc3VfcQ==",
    encoded_p: "Y3Nhc3VfcA==",
  },
  {
    school_code: "savs",
    name: "Savannah State",
    division: 1,
    org_id: "632",
    mascot: "Tigers",
    encoded_h: "Y3NhdnM=",
    encoded_c: "Y3NhdnNfYw==",
    encoded_q: "Y3NhdnNfcQ==",
    encoded_p: "Y3NhdnNfcA==",
  },
  {
    school_code: "scar",
    name: "South Carolina",
    division: 1,
    org_id: "648",
    mascot: "Gamecocks",
    encoded_h: "Y3NjYXI=",
    encoded_c: "Y3NjYXJfYw==",
    encoded_q: "Y3NjYXJfcQ==",
    encoded_p: "Y3NjYXJfcA==",
  },
  {
    school_code: "sdst",
    schedule_id: 64526,
    name: "South Dakota State",
    division: 1,
    org_id: "649",
    mascot: "Jackrabbits",
    encoded_h: "Y3Nkc3Q=",
    encoded_c: "Y3Nkc3RfYw==",
    encoded_q: "Y3Nkc3RfcQ==",
    encoded_p: "Y3Nkc3RfcA==",
  },
  {
    school_code: "sdsu",
    name: "San Diego State",
    division: 1,
    org_id: "626",
    mascot: "Aztecs",
    encoded_h: "Y3Nkc3U=",
    encoded_c: "Y3Nkc3VfYw==",
    encoded_q: "Y3Nkc3VfcQ==",
    encoded_p: "Y3Nkc3VfcA==",
  },
  {
    school_code: "sea",
    schedule_id: 107061,
    name: "Seattle",
    division: 1,
    org_id: "1356",
    mascot: "Redhawks",
    encoded_h: "Y3NlYQ==",
    encoded_c: "Y3NlYV9j",
    encoded_q: "Y3NlYV9x",
    encoded_p: "Y3NlYV9w",
  },
  {
    school_code: "seha",
    name: "Seton Hall",
    division: 1,
    org_id: "635",
    mascot: "Pirates",
    encoded_h: "Y3NlaGE=",
    encoded_c: "Y3NlaGFfYw==",
    encoded_q: "Y3NlaGFfcQ==",
    encoded_p: "Y3NlaGFfcA==",
  },
  {
    school_code: "sela",
    name: "Southeastern Louisiana",
    division: 1,
    org_id: "655",
    mascot: "Lions",
    encoded_h: "Y3NlbGE=",
    encoded_c: "Y3NlbGFfYw==",
    encoded_q: "Y3NlbGFfcQ==",
    encoded_p: "Y3NlbGFfcA==",
  },
  {
    school_code: "semo",
    name: "Southeast Missouri",
    division: 1,
    org_id: "654",
    mascot: "Redhawks",
    encoded_h: "Y3NlbW8=",
    encoded_c: "Y3NlbW9fYw==",
    encoded_q: "Y3NlbW9fcQ==",
    encoded_p: "Y3NlbW9fcA==",
  },
  {
    school_code: "sfla",
    schedule_id: 36642,
    name: "South Florida",
    division: 1,
    org_id: "651",
    mascot: "Bulls",
    encoded_h: "Y3NmbGE=",
    encoded_c: "Y3NmbGFfYw==",
    encoded_q: "Y3NmbGFfcQ==",
    encoded_p: "Y3NmbGFfcA==",
  },
  {
    school_code: "sien",
    name: "Siena",
    division: 1,
    org_id: "639",
    mascot: "Saints",
    encoded_h: "Y3NpZW4=",
    encoded_c: "Y3NpZW5fYw==",
    encoded_q: "Y3NpZW5fcQ==",
    encoded_p: "Y3NpZW5fcA==",
  },
  {
    school_code: "silu",
    name: "Southern Illinois",
    division: 1,
    org_id: "659",
    mascot: "Salukis",
    encoded_h: "Y3NpbHU=",
    encoded_c: "Y3NpbHVfYw==",
    encoded_q: "Y3NpbHVfcQ==",
    encoded_p: "Y3NpbHVfcA==",
  },
  {
    school_code: "siue",
    name: "SIUE",
    division: 1,
    org_id: "660",
    mascot: "Cougars",
    encoded_h: "Y3NpdWU=",
    encoded_c: "Y3NpdWVfYw==",
    encoded_q: "Y3NpdWVfcQ==",
    encoded_p: "Y3NpdWVfcA==",
  },
  {
    school_code: "sjsu",
    name: "San Jose State",
    division: 1,
    org_id: "630",
    mascot: "Spartans",
    encoded_h: "Y3Nqc3U=",
    encoded_c: "Y3Nqc3VfYw==",
    encoded_q: "Y3Nqc3VfcQ==",
    encoded_p: "Y3Nqc3VfcA==",
  },
  {
    school_code: "smis",
    name: "Southern Miss",
    division: 1,
    org_id: "664",
    mascot: "Golden Eagles",
    encoded_h: "Y3NtaXM=",
    encoded_c: "Y3NtaXNfYw==",
    encoded_q: "Y3NtaXNfcQ==",
    encoded_p: "Y3NtaXNfcA==",
  },
  {
    school_code: "south",
    schedule_id: 641642,
    name: "Southern",
    division: 1,
    org_id: "665",
    mascot: "Jaguars",
    encoded_h: "Y3NvdXRo",
    encoded_c: "Y3NvdXRoX2M=",
    encoded_q: "Y3NvdXRoX3E=",
    encoded_p: "Y3NvdXRoX3A=",
  },
  {
    school_code: "stan",
    schedule_id: 749981,
    name: "Stanford",
    division: 1,
    org_id: "674",
    mascot: "Cardinal",
    encoded_h: "Y3N0YW4=",
    encoded_c: "Y3N0YW5fYw==",
    encoded_q: "Y3N0YW5fcQ==",
    encoded_p: "Y3N0YW5fcA==",
  },
  {
    school_code: "stbo",
    name: "St. Bonaventure",
    division: 1,
    org_id: "596",
    mascot: "Bonnies",
    encoded_h: "Y3N0Ym8=",
    encoded_c: "Y3N0Ym9fYw==",
    encoded_q: "Y3N0Ym9fcQ==",
    encoded_p: "Y3N0Ym9fcA==",
  },
  {
    school_code: "stet",
    schedule_id: 61812,
    name: "Stetson",
    division: 1,
    org_id: "678",
    mascot: "Hatters",
    encoded_h: "Y3N0ZXQ=",
    encoded_c: "Y3N0ZXRfYw==",
    encoded_q: "Y3N0ZXRfcQ==",
    encoded_p: "Y3N0ZXRfcA==",
  },
  {
    school_code: "stjo",
    name: "St. John's",
    division: 1,
    org_id: "603",
    mascot: "Red Storm",
    encoded_h: "Y3N0am8=",
    encoded_c: "Y3N0am9fYw==",
    encoded_q: "Y3N0am9fcQ==",
    encoded_p: "Y3N0am9fcA==",
  },
  {
    school_code: "stjs",
    schedule_id: 750579,
    name: "Saint Joseph's",
    division: 1,
    org_id: "606",
    mascot: "Hawks",
    encoded_h: "Y3N0anM=",
    encoded_c: "Y3N0anNfYw==",
    encoded_q: "Y3N0anNfcQ==",
    encoded_p: "Y3N0anNfcA==",
  },
  {
    school_code: "stlo",
    schedule_id: 632618,
    name: "Saint Louis",
    division: 1,
    org_id: "609",
    mascot: "Billikens",
    encoded_h: "Y3N0bG8=",
    encoded_c: "Y3N0bG9fYw==",
    encoded_q: "Y3N0bG9fcQ==",
    encoded_p: "Y3N0bG9fcA==",
  },
  {
    school_code: "stma",
    schedule_id: 101603,
    name: "Saint Mary's College",
    division: 1,
    org_id: "610",
    mascot: "Gaels",
    encoded_h: "Y3N0bWE=",
    encoded_c: "Y3N0bWFfYw==",
    encoded_q: "Y3N0bWFfcQ==",
    encoded_p: "Y3N0bWFfcA==",
  },
  {
    school_code: "ston",
    name: "Stony Brook",
    division: 1,
    org_id: "683",
    mascot: "Seawolves",
    encoded_h: "Y3N0b24=",
    encoded_c: "Y3N0b25fYw==",
    encoded_q: "Y3N0b25fcQ==",
    encoded_p: "Y3N0b25fcA==",
  },
  {
    school_code: "stpe",
    name: "St. Peters",
    division: 1,
    org_id: "617",
    mascot: "Peacocks",
    encoded_h: "Y3N0cGU=",
    encoded_c: "Y3N0cGVfYw==",
    encoded_q: "Y3N0cGVfcQ==",
    encoded_p: "Y3N0cGVfcA==",
  },
  {
    school_code: "tam",
    schedule_id: 63271,
    name: "Texas A&M",
    division: 1,
    org_id: "697",
    mascot: "Aggies",
    encoded_h: "Y3RhbQ==",
    encoded_c: "Y3RhbV9j",
    encoded_q: "Y3RhbV9x",
    encoded_p: "Y3RhbV9w",
  },
  {
    school_code: "tamcc",
    name: "Texas A&M Corpus Christi",
    division: 1,
    org_id: "26172",
    mascot: "Islanders",
    encoded_h: "Y3RhbWNj",
    encoded_c: "Y3RhbWNjX2M=",
    encoded_q: "Y3RhbWNjX3E=",
    encoded_p: "Y3RhbWNjX3A=",
  },
  {
    school_code: "tcu",
    name: "TCU",
    division: 1,
    org_id: "698",
    mascot: "Horned Frogs",
    encoded_h: "Y3RjdQ==",
    encoded_c: "Y3RjdV9j",
    encoded_q: "Y3RjdV9x",
    encoded_p: "Y3RjdV9w",
  },
  {
    school_code: "tem",
    name: "Temple",
    division: 1,
    org_id: "690",
    mascot: "Owls",
    encoded_h: "Y3RlbQ==",
    encoded_c: "Y3RlbV9j",
    encoded_q: "Y3RlbV9x",
    encoded_p: "Y3RlbV9w",
  },
  {
    school_code: "tenm",
    name: "UT-Martin",
    division: 1,
    org_id: "695",
    mascot: "Skyhawks",
    encoded_h: "Y3Rlbm0=",
    encoded_c: "Y3Rlbm1fYw==",
    encoded_q: "Y3Rlbm1fcQ==",
    encoded_p: "Y3Rlbm1fcA==",
  },
  {
    school_code: "tenn",
    name: "Tennessee",
    division: 1,
    org_id: "694",
    mascot: "Volunteers",
    encoded_h: "Y3Rlbm4=",
    encoded_c: "Y3Rlbm5fYw==",
    encoded_q: "Y3Rlbm5fcQ==",
    encoded_p: "Y3Rlbm5fcA==",
  },
  {
    school_code: "tex",
    name: "Texas",
    division: 1,
    org_id: "703",
    mascot: "Longhorns",
    encoded_h: "Y3RleA==",
    encoded_c: "Y3RleF9j",
    encoded_q: "Y3RleF9x",
    encoded_p: "Y3RleF9w",
  },
  {
    school_code: "text",
    name: "Texas Tech",
    division: 1,
    org_id: "700",
    mascot: "Red Raiders",
    encoded_h: "Y3RleHQ=",
    encoded_c: "Y3RleHRfYw==",
    encoded_q: "Y3RleHRfcQ==",
    encoded_p: "Y3RleHRfcA==",
  },
  {
    school_code: "tol",
    schedule_id: 89697,
    name: "Toledo",
    division: 1,
    org_id: "709",
    mascot: "Rockets",
    encoded_h: "Y3RvbA==",
    encoded_c: "Y3RvbF9j",
    encoded_q: "Y3RvbF9x",
    encoded_p: "Y3RvbF9w",
  },
  {
    school_code: "tows",
    schedule_id: 101509,
    name: "Towson",
    division: 1,
    org_id: "711",
    mascot: "Tigers",
    encoded_h: "Y3Rvd3M=",
    encoded_c: "Y3Rvd3NfYw==",
    encoded_q: "Y3Rvd3NfcQ==",
    encoded_p: "Y3Rvd3NfcA==",
  },
  {
    school_code: "troy",
    name: "Troy",
    division: 1,
    org_id: "716",
    mascot: "Trojans",
    encoded_h: "Y3Ryb3k=",
    encoded_c: "Y3Ryb3lfYw==",
    encoded_q: "Y3Ryb3lfcQ==",
    encoded_p: "Y3Ryb3lfcA==",
  },
  {
    school_code: "tul",
    name: "Tulane",
    division: 1,
    org_id: "718",
    mascot: "Green Wave",
    encoded_h: "Y3R1bA==",
    encoded_c: "Y3R1bF9j",
    encoded_q: "Y3R1bF9x",
    encoded_p: "Y3R1bF9w",
  },
  {
    school_code: "txar",
    name: "UT Arlington",
    division: 1,
    org_id: "702",
    mascot: "Mavericks",
    encoded_h: "Y3R4YXI=",
    encoded_c: "Y3R4YXJfYw==",
    encoded_q: "Y3R4YXJfcQ==",
    encoded_p: "Y3R4YXJfcA==",
  },
  {
    school_code: "txsa",
    name: "UTSA",
    division: 1,
    org_id: "706",
    mascot: "Roadrunners",
    encoded_h: "Y3R4c2E=",
    encoded_c: "Y3R4c2FfYw==",
    encoded_q: "Y3R4c2FfcQ==",
    encoded_p: "Y3R4c2FfcA==",
  },
  {
    school_code: "txso",
    name: "Texas Southern",
    division: 1,
    org_id: "699",
    mascot: "Tigers",
    encoded_h: "Y3R4c28=",
    encoded_c: "Y3R4c29fYw==",
    encoded_q: "Y3R4c29fcQ==",
    encoded_p: "Y3R4c29fcA==",
  },
  {
    school_code: "txst",
    name: "Texas State",
    division: 1,
    org_id: "670",
    mascot: "Bobcats",
    encoded_h: "Y3R4c3Q=",
    encoded_c: "Y3R4c3RfYw==",
    encoded_q: "Y3R4c3RfcQ==",
    encoded_p: "Y3R4c3RfcA==",
  },
  {
    school_code: "ucda",
    name: "UC Davis",
    division: 1,
    org_id: "108",
    mascot: "Aggies",
    encoded_h: "Y3VjZGE=",
    encoded_c: "Y3VjZGFfYw==",
    encoded_q: "Y3VjZGFfcQ==",
    encoded_p: "Y3VjZGFfcA==",
  },
  {
    school_code: "ucf",
    name: "UCF",
    division: 1,
    org_id: "128",
    mascot: "Knights",
    encoded_h: "Y3VjZg==",
    encoded_c: "Y3VjZl9j",
    encoded_q: "Y3VjZl9x",
    encoded_p: "Y3VjZl9w",
  },
  {
    school_code: "ucir",
    name: "UC Irvine",
    division: 1,
    org_id: "109",
    mascot: "Anteaters",
    encoded_h: "Y3VjaXI=",
    encoded_c: "Y3VjaXJfYw==",
    encoded_q: "Y3VjaXJfcQ==",
    encoded_p: "Y3VjaXJfcA==",
  },
  {
    school_code: "ucla",
    schedule_id: 749881,
    name: "UCLA",
    division: 1,
    org_id: "110",
    mascot: "Bruins",
    encoded_h: "Y3VjbGE=",
    encoded_c: "Y3VjbGFfYw==",
    encoded_q: "Y3VjbGFfcQ==",
    encoded_p: "Y3VjbGFfcA==",
  },
  {
    school_code: "ucr",
    name: "UC Riverside",
    division: 1,
    org_id: "111",
    mascot: "Highlanders",
    encoded_h: "Y3Vjcg==",
    encoded_c: "Y3Vjcl9j",
    encoded_q: "Y3Vjcl9x",
    encoded_p: "Y3Vjcl9w",
  },
  {
    school_code: "ucsb",
    name: "UC Santa Barbara",
    division: 1,
    org_id: "104",
    mascot: "Gauchos",
    encoded_h: "Y3Vjc2I=",
    encoded_c: "Y3Vjc2JfYw==",
    encoded_q: "Y3Vjc2JfcQ==",
    encoded_p: "Y3Vjc2JfcA==",
  },
  {
    school_code: "ucscu",
    name: "USC Upstate",
    division: 1,
    org_id: "10411",
    mascot: "Spartans",
    encoded_h: "Y3Vjc2N1",
    encoded_c: "Y3Vjc2N1X2M=",
    encoded_q: "Y3Vjc2N1X3E=",
    encoded_p: "Y3Vjc2N1X3A=",
  },
  {
    school_code: "umas",
    name: "Massachusetts",
    division: 1,
    org_id: "400",
    mascot: "Minutemen",
    encoded_h: "Y3VtYXM=",
    encoded_c: "Y3VtYXNfYw==",
    encoded_q: "Y3VtYXNfcQ==",
    encoded_p: "Y3VtYXNfcA==",
  },
  {
    school_code: "unc",
    schedule_id: 668153,
    name: "North Carolina",
    division: 1,
    org_id: "457",
    mascot: "Tar Heels",
    encoded_h: "Y3VuYw==",
    encoded_c: "Y3VuY19j",
    encoded_q: "Y3VuY19x",
    encoded_p: "Y3VuY19w",
  },
  {
    school_code: "unca",
    schedule_id: 92351,
    name: "UNC Asheville",
    division: 1,
    org_id: "456",
    mascot: "Bulldogs",
    encoded_h: "Y3VuY2E=",
    encoded_c: "Y3VuY2FfYw==",
    encoded_q: "Y3VuY2FfcQ==",
    encoded_p: "Y3VuY2FfcA==",
  },
  {
    school_code: "uncg",
    schedule_id: 810474,
    name: "UNCG",
    division: 1,
    org_id: "459",
    mascot: "Spartans",
    encoded_h: "Y3VuY2c=",
    encoded_c: "Y3VuY2dfYw==",
    encoded_q: "Y3VuY2dfcQ==",
    encoded_p: "Y3VuY2dfcA==",
  },
  {
    school_code: "uncw",
    schedule_id: 92915,
    name: "UNC Wilmington",
    division: 1,
    org_id: "460",
    mascot: "Seahawks",
    encoded_h: "Y3VuY3c=",
    encoded_c: "Y3VuY3dfYw==",
    encoded_q: "Y3VuY3dfcQ==",
    encoded_p: "Y3VuY3dfcA==",
  },
  {
    school_code: "unlv",
    name: "UNLV",
    division: 1,
    org_id: "465",
    mascot: "Rebels",
    encoded_h: "Y3VubHY=",
    encoded_c: "Y3VubHZfYw==",
    encoded_q: "Y3VubHZfcQ==",
    encoded_p: "Y3VubHZfcA==",
  },
  {
    school_code: "unv",
    name: "Nevada",
    division: 1,
    org_id: "466",
    mascot: "Wolf Pack",
    encoded_h: "Y3Vudg==",
    encoded_c: "Y3Vudl9j",
    encoded_q: "Y3Vudl9x",
    encoded_p: "Y3Vudl9w",
  },
  {
    school_code: "uri",
    name: "Rhode Island",
    division: 1,
    org_id: "572",
    mascot: "Rams",
    encoded_h: "Y3VyaQ==",
    encoded_c: "Y3VyaV9j",
    encoded_q: "Y3VyaV9x",
    encoded_p: "Y3VyaV9w",
  },
  {
    school_code: "usc",
    name: "USC",
    division: 1,
    org_id: "657",
    mascot: "Trojans",
    encoded_h: "Y3VzYw==",
    encoded_c: "Y3VzY19j",
    encoded_q: "Y3VzY19x",
    encoded_p: "Y3VzY19w",
  },
  {
    school_code: "usd",
    name: "San Diego",
    division: 1,
    org_id: "627",
    mascot: "Toreros",
    encoded_h: "Y3VzZA==",
    encoded_c: "Y3VzZF9j",
    encoded_q: "Y3VzZF9x",
    encoded_p: "Y3VzZF9w",
  },
  {
    school_code: "utah",
    name: "Utah",
    division: 1,
    org_id: "732",
    mascot: "Utes",
    encoded_h: "Y3V0YWg=",
    encoded_c: "Y3V0YWhfYw==",
    encoded_q: "Y3V0YWhfcQ==",
    encoded_p: "Y3V0YWhfcA==",
  },
  {
    school_code: "utpa",
    name: "UTRGV",
    division: 1,
    org_id: "536",
    mascot: "Vaqueros",
    encoded_h: "Y3V0cGE=",
    encoded_c: "Y3V0cGFfYw==",
    encoded_q: "Y3V0cGFfcQ==",
    encoded_p: "Y3V0cGFfcA==",
  },
  {
    school_code: "utva",
    name: "Utah Valley",
    division: 1,
    org_id: "30024",
    mascot: "Wolverines",
    encoded_h: "Y3V0dmE=",
    encoded_c: "Y3V0dmFfYw==",
    encoded_q: "Y3V0dmFfcQ==",
    encoded_p: "Y3V0dmFfcA==",
  },
  {
    school_code: "va",
    name: "Virginia",
    division: 1,
    org_id: "746",
    mascot: "Cavaliers",
    encoded_h: "Y3Zh",
    encoded_c: "Y3ZhX2M=",
    encoded_q: "Y3ZhX3E=",
    encoded_p: "Y3ZhX3A=",
  },
  {
    school_code: "val",
    name: "Valparaiso",
    division: 1,
    org_id: "735",
    mascot: "Crusaders",
    encoded_h: "Y3ZhbA==",
    encoded_c: "Y3ZhbF9j",
    encoded_q: "Y3ZhbF9x",
    encoded_p: "Y3ZhbF9w",
  },
  {
    school_code: "vand",
    name: "Vanderbilt",
    division: 1,
    org_id: "736",
    mascot: "Commodores",
    encoded_h: "Y3ZhbmQ=",
    encoded_c: "Y3ZhbmRfYw==",
    encoded_q: "Y3ZhbmRfcQ==",
    encoded_p: "Y3ZhbmRfcA==",
  },
  {
    school_code: "vcu",
    name: "VCU",
    division: 1,
    org_id: "740",
    mascot: "Rams",
    encoded_h: "Y3ZjdQ==",
    encoded_c: "Y3ZjdV9j",
    encoded_q: "Y3ZjdV9x",
    encoded_p: "Y3ZjdV9w",
  },
  {
    school_code: "vmi",
    schedule_id: 44000,
    name: "VMI",
    division: 1,
    org_id: "741",
    mascot: "Keydets",
    encoded_h: "Y3ZtaQ==",
    encoded_c: "Y3ZtaV9j",
    encoded_q: "Y3ZtaV9x",
    encoded_p: "Y3ZtaV9w",
  },
  {
    school_code: "vtec",
    name: "Virginia Tech",
    division: 1,
    org_id: "742",
    mascot: "Hokies",
    encoded_h: "Y3Z0ZWM=",
    encoded_c: "Y3Z0ZWNfYw==",
    encoded_q: "Y3Z0ZWNfcQ==",
    encoded_p: "Y3Z0ZWNfcA==",
  },
  {
    school_code: "wag",
    name: "Wagner",
    division: 1,
    org_id: "748",
    mascot: "Seahawks",
    encoded_h: "Y3dhZw==",
    encoded_c: "Y3dhZ19j",
    encoded_q: "Y3dhZ19x",
    encoded_p: "Y3dhZ19w",
  },
  {
    school_code: "wake",
    name: "Wake Forest",
    division: 1,
    org_id: "749",
    mascot: "Demon Deacons",
    encoded_h: "Y3dha2U=",
    encoded_c: "Y3dha2VfYw==",
    encoded_q: "Y3dha2VfcQ==",
    encoded_p: "Y3dha2VfcA==",
  },
  {
    school_code: "wam",
    schedule_id: 606364,
    name: "William & Mary",
    division: 1,
    org_id: "786",
    mascot: "Tribe",
    encoded_h: "Y3dhbQ==",
    encoded_c: "Y3dhbV9j",
    encoded_q: "Y3dhbV9x",
    encoded_p: "Y3dhbV9w",
  },
  {
    school_code: "wash",
    schedule_id: 749581,
    name: "Washington",
    division: 1,
    org_id: "756",
    mascot: "Huskies",
    encoded_h: "Y3dhc2g=",
    encoded_c: "Y3dhc2hfYw==",
    encoded_q: "Y3dhc2hfcQ==",
    encoded_p: "Y3dhc2hfcA==",
  },
  {
    school_code: "washs",
    schedule_id: 749781,
    name: "Washington State",
    division: 1,
    org_id: "754",
    mascot: "Cougars",
    encoded_h: "Y3dhc2hz",
    encoded_c: "Y3dhc2hzX2M=",
    encoded_q: "Y3dhc2hzX3E=",
    encoded_p: "Y3dhc2hzX3A=",
  },
  {
    school_code: "wcar",
    name: "Western Carolina",
    division: 1,
    org_id: "769",
    mascot: "Catamounts",
    encoded_h: "Y3djYXI=",
    encoded_c: "Y3djYXJfYw==",
    encoded_q: "Y3djYXJfcQ==",
    encoded_p: "Y3djYXJfcA==",
  },
  {
    school_code: "wich",
    schedule_id: 61169,
    name: "Wichita State",
    division: 1,
    org_id: "782",
    mascot: "Shockers",
    encoded_h: "Y3dpY2g=",
    encoded_c: "Y3dpY2hfYw==",
    encoded_q: "Y3dpY2hfcQ==",
    encoded_p: "Y3dpY2hfcA==",
  },
  {
    school_code: "wil",
    schedule_id: 49930,
    name: "Western Illinois",
    division: 1,
    org_id: "771",
    mascot: "Leathernecks",
    encoded_h: "Y3dpbA==",
    encoded_c: "Y3dpbF9j",
    encoded_q: "Y3dpbF9x",
    encoded_p: "Y3dpbF9w",
  },
  {
    school_code: "wiml",
    name: "Milwaukee",
    division: 1,
    org_id: "797",
    mascot: "Panthers",
    encoded_h: "Y3dpbWw=",
    encoded_c: "Y3dpbWxfYw==",
    encoded_q: "Y3dpbWxfcQ==",
    encoded_p: "Y3dpbWxfcA==",
  },
  {
    school_code: "wint",
    name: "Winthrop",
    division: 1,
    org_id: "792",
    mascot: "Eagles",
    encoded_h: "Y3dpbnQ=",
    encoded_c: "Y3dpbnRfYw==",
    encoded_q: "Y3dpbnRfcQ==",
    encoded_p: "Y3dpbnRfcA==",
  },
  {
    school_code: "wky",
    name: "Western Kentucky",
    division: 1,
    org_id: "772",
    mascot: "Hilltoppers",
    encoded_h: "Y3dreQ==",
    encoded_c: "Y3dreV9j",
    encoded_q: "Y3dreV9x",
    encoded_p: "Y3dreV9w",
  },
  {
    school_code: "wmu",
    schedule_id: 24479,
    name: "Western Michigan",
    division: 1,
    org_id: "774",
    mascot: "Broncos",
    encoded_h: "Y3dtdQ==",
    encoded_c: "Y3dtdV9j",
    encoded_q: "Y3dtdV9x",
    encoded_p: "Y3dtdV9w",
  },
  {
    school_code: "woff",
    name: "Wofford College",
    division: 1,
    org_id: "2915",
    mascot: "Terriers",
    encoded_h: "Y3dvZmY=",
    encoded_c: "Y3dvZmZfYw==",
    encoded_q: "Y3dvZmZfcQ==",
    encoded_p: "Y3dvZmZfcA==",
  },
  {
    school_code: "wrst",
    schedule_id: 630818,
    name: "Wright State",
    division: 1,
    org_id: "810",
    mascot: "Raiders",
    encoded_h: "Y3dyc3Q=",
    encoded_c: "Y3dyc3RfYw==",
    encoded_q: "Y3dyc3RfcQ==",
    encoded_p: "Y3dyc3RfcA==",
  },
  {
    school_code: "wvir",
    name: "West Virginia",
    division: 1,
    org_id: "768",
    mascot: "Mountaineers",
    encoded_h: "Y3d2aXI=",
    encoded_c: "Y3d2aXJfYw==",
    encoded_q: "Y3d2aXJfcQ==",
    encoded_p: "Y3d2aXJfcA==",
  },
  {
    school_code: "xavi",
    name: "Xavier",
    division: 1,
    org_id: "812",
    mascot: "Musketeers",
    encoded_h: "Y3hhdmk=",
    encoded_c: "Y3hhdmlfYw==",
    encoded_q: "Y3hhdmlfcQ==",
    encoded_p: "Y3hhdmlfcA==",
  },
  {
    school_code: "yale",
    name: "Yale",
    division: 1,
    org_id: "813",
    mascot: "Bulldogs",
    encoded_h: "Y3lhbGU=",
    encoded_c: "Y3lhbGVfYw==",
    encoded_q: "Y3lhbGVfcQ==",
    encoded_p: "Y3lhbGVfcA==",
  },
  {
    school_code: "youn",
    name: "Youngstown State",
    division: 1,
    org_id: "817",
    mascot: "Penguins",
    encoded_h: "Y3lvdW4=",
    encoded_c: "Y3lvdW5fYw==",
    encoded_q: "Y3lvdW5fcQ==",
    encoded_p: "Y3lvdW5fcA==",
  },
  {
    school_code: "2",
    name: "Abilene Christian",
    division: 1,
    org_id: "2",
    mascot: "Wildcats",
    encoded_h: "YzI=",
    encoded_c: "YzJfYw==",
    encoded_q: "YzJfcQ==",
    encoded_p: "YzJfcA==",
  },
  {
    school_code: "30123",
    name: "Academy of Art",
    division: 2,
    org_id: "30123",
    mascot: "Urban Knights",
    encoded_h: "YzMwMTIz",
    encoded_c: "YzMwMTIzX2M=",
    encoded_q: "YzMwMTIzX3E=",
    encoded_p: "YzMwMTIzX3A=",
  },
  {
    school_code: "929",
    name: "Adams St.",
    division: 2,
    org_id: "929",
    mascot: "Grizzlies",
    encoded_h: "YzkyOQ==",
    encoded_c: "YzkyOV9j",
    encoded_q: "YzkyOV9x",
    encoded_p: "YzkyOV9w",
  },
  {
    school_code: "3",
    name: "Adelphi",
    division: 2,
    org_id: "3",
    mascot: "Panthers",
    encoded_h: "YzM=",
    encoded_c: "YzNfYw==",
    encoded_q: "YzNfcQ==",
    encoded_p: "YzNfcA==",
  },
  {
    school_code: "10",
    name: "Ala.-Huntsville",
    division: 2,
    org_id: "10",
    mascot: "Chargers",
    encoded_h: "YzEw",
    encoded_c: "YzEwX2M=",
    encoded_q: "YzEwX3E=",
    encoded_p: "YzEwX3A=",
  },
  {
    school_code: "13",
    name: "Albany St. (GA)",
    division: 2,
    org_id: "13",
    mascot: "Golden Rams",
    encoded_h: "YzEz",
    encoded_c: "YzEzX2M=",
    encoded_q: "YzEzX3E=",
    encoded_p: "YzEzX3A=",
  },
  {
    school_code: "934",
    name: "Alderson Broaddus",
    division: 2,
    org_id: "934",
    mascot: "Battlers",
    encoded_h: "YzkzNA==",
    encoded_c: "YzkzNF9j",
    encoded_q: "YzkzNF9x",
    encoded_p: "YzkzNF9w",
  },
  {
    school_code: "22",
    name: "American Int'l",
    division: 2,
    org_id: "22",
    mascot: "Yellow Jackets",
    encoded_h: "YzIy",
    encoded_c: "YzIyX2M=",
    encoded_q: "YzIyX3E=",
    encoded_p: "YzIyX3A=",
  },
  {
    school_code: "13028",
    name: "Anderson (SC)",
    division: 2,
    org_id: "13028",
    mascot: "Trojans",
    encoded_h: "YzEzMDI4",
    encoded_c: "YzEzMDI4X2M=",
    encoded_q: "YzEzMDI4X3E=",
    encoded_p: "YzEzMDI4X3A=",
  },
  {
    school_code: "25",
    name: "Angelo St.",
    division: 2,
    org_id: "25",
    mascot: "Rams",
    encoded_h: "YzI1",
    encoded_c: "YzI1X2M=",
    encoded_q: "YzI1X3E=",
    encoded_p: "YzI1X3A=",
  },
  {
    school_code: "30105",
    name: "Ark.-Fort Smith",
    division: 2,
    org_id: "30105",
    mascot: "Lions",
    encoded_h: "YzMwMTA1",
    encoded_c: "YzMwMTA1X2M=",
    encoded_q: "YzMwMTA1X3E=",
    encoded_p: "YzMwMTA1X3A=",
  },
  {
    school_code: "8411",
    name: "Ark.-Monticello",
    division: 2,
    org_id: "8411",
    mascot: "Boll Weevils",
    encoded_h: "Yzg0MTE=",
    encoded_c: "Yzg0MTFfYw==",
    encoded_q: "Yzg0MTFfcQ==",
    encoded_p: "Yzg0MTFfcA==",
  },
  {
    school_code: "947",
    name: "Arkansas Tech",
    division: 2,
    org_id: "947",
    mascot: "Wonder Boys",
    encoded_h: "Yzk0Nw==",
    encoded_c: "Yzk0N19j",
    encoded_q: "Yzk0N19x",
    encoded_p: "Yzk0N19w",
  },
  {
    school_code: "33",
    name: "Armstrong",
    division: 2,
    org_id: "33",
    mascot: "Pirates",
    encoded_h: "YzMz",
    encoded_c: "YzMzX2M=",
    encoded_q: "YzMzX3E=",
    encoded_p: "YzMzX3A=",
  },
  {
    school_code: "35",
    name: "Ashland",
    division: 2,
    org_id: "35",
    mascot: "Eagles",
    encoded_h: "YzM1",
    encoded_c: "YzM1X2M=",
    encoded_q: "YzM1X3E=",
    encoded_p: "YzM1X3A=",
  },
  {
    school_code: "36",
    name: "Assumption",
    division: 2,
    org_id: "36",
    mascot: "Greyhounds",
    encoded_h: "YzM2",
    encoded_c: "YzM2X2M=",
    encoded_q: "YzM2X3E=",
    encoded_p: "YzM2X3A=",
  },
  {
    school_code: "41",
    name: "Augustana (SD)",
    division: 2,
    org_id: "41",
    mascot: "Vikings",
    encoded_h: "YzQx",
    encoded_c: "YzQxX2M=",
    encoded_q: "YzQxX3E=",
    encoded_p: "YzQxX3A=",
  },
  {
    school_code: "30093",
    name: "AUM",
    division: 2,
    org_id: "30093",
    mascot: "Warhawks",
    encoded_h: "YzMwMDkz",
    encoded_c: "YzMwMDkzX2M=",
    encoded_q: "YzMwMDkzX3E=",
    encoded_p: "YzMwMDkzX3A=",
  },
  {
    school_code: "30146",
    name: "Azusa Pacific",
    division: 2,
    org_id: "30146",
    mascot: "Cougars",
    encoded_h: "YzMwMTQ2",
    encoded_c: "YzMwMTQ2X2M=",
    encoded_q: "YzMwMTQ2X3E=",
    encoded_p: "YzMwMTQ2X3A=",
  },
  {
    school_code: "49",
    name: "Barry",
    division: 2,
    org_id: "49",
    mascot: "Buccaneers",
    encoded_h: "YzQ5",
    encoded_c: "YzQ5X2M=",
    encoded_q: "YzQ5X3E=",
    encoded_p: "YzQ5X3A=",
  },
  {
    school_code: "15646",
    name: "Barton",
    division: 2,
    org_id: "15646",
    mascot: "Bulldogs",
    encoded_h: "YzE1NjQ2",
    encoded_c: "YzE1NjQ2X2M=",
    encoded_q: "YzE1NjQ2X3E=",
    encoded_p: "YzE1NjQ2X3A=",
  },
  {
    school_code: "52",
    name: "Bellarmine",
    division: 2,
    org_id: "52",
    mascot: "Knights",
    encoded_h: "YzUy",
    encoded_c: "YzUyX2M=",
    encoded_q: "YzUyX3E=",
    encoded_p: "YzUyX3A=",
  },
  {
    school_code: "2683",
    name: "Belmont Abbey",
    division: 2,
    org_id: "2683",
    mascot: "Crusaders",
    encoded_h: "YzI2ODM=",
    encoded_c: "YzI2ODNfYw==",
    encoded_q: "YzI2ODNfcQ==",
    encoded_p: "YzI2ODNfcA==",
  },
  {
    school_code: "54",
    name: "Bemidji St.",
    division: 2,
    org_id: "54",
    mascot: "Beavers",
    encoded_h: "YzU0",
    encoded_c: "YzU0X2M=",
    encoded_q: "YzU0X3E=",
    encoded_p: "YzU0X3A=",
  },
  {
    school_code: "55",
    name: "Benedict",
    division: 2,
    org_id: "55",
    mascot: "Tigers",
    encoded_h: "YzU1",
    encoded_c: "YzU1X2M=",
    encoded_q: "YzU1X3E=",
    encoded_p: "YzU1X3A=",
  },
  {
    school_code: "56",
    name: "Bentley",
    division: 2,
    org_id: "56",
    mascot: "Falcons",
    encoded_h: "YzU2",
    encoded_c: "YzU2X2M=",
    encoded_q: "YzU2X3E=",
    encoded_p: "YzU2X3A=",
  },
  {
    school_code: "30220",
    name: "Biola",
    division: 2,
    org_id: "30220",
    mascot: "Eagles",
    encoded_h: "YzMwMjIw",
    encoded_c: "YzMwMjIwX2M=",
    encoded_q: "YzMwMjIwX3E=",
    encoded_p: "YzMwMjIwX3A=",
  },
  {
    school_code: "984",
    name: "Bloomfield",
    division: 2,
    org_id: "984",
    mascot: "Bears",
    encoded_h: "Yzk4NA==",
    encoded_c: "Yzk4NF9j",
    encoded_q: "Yzk4NF9x",
    encoded_p: "Yzk4NF9w",
  },
  {
    school_code: "65",
    name: "Bloomsburg",
    division: 2,
    org_id: "65",
    mascot: "Huskies",
    encoded_h: "YzY1",
    encoded_c: "YzY1X2M=",
    encoded_q: "YzY1X3E=",
    encoded_p: "YzY1X3A=",
  },
  {
    school_code: "985",
    name: "Bluefield St.",
    division: 2,
    org_id: "985",
    mascot: "Big Blues",
    encoded_h: "Yzk4NQ==",
    encoded_c: "Yzk4NV9j",
    encoded_q: "Yzk4NV9x",
    encoded_p: "Yzk4NV9w",
  },
  {
    school_code: "30094",
    name: "Brevard",
    division: 2,
    org_id: "30094",
    mascot: "Tornados",
    encoded_h: "YzMwMDk0",
    encoded_c: "YzMwMDk0X2M=",
    encoded_q: "YzMwMDk0X3E=",
    encoded_p: "YzMwMDk0X3A=",
  },
  {
    school_code: "74",
    name: "Bridgeport",
    division: 2,
    org_id: "74",
    mascot: "Purple Knights",
    encoded_h: "Yzc0",
    encoded_c: "Yzc0X2M=",
    encoded_q: "Yzc0X3E=",
    encoded_p: "Yzc0X3A=",
  },
  {
    school_code: "91",
    name: "Cal Poly Pomona",
    division: 2,
    org_id: "91",
    mascot: "Broncos",
    encoded_h: "Yzkx",
    encoded_c: "YzkxX2M=",
    encoded_q: "YzkxX3E=",
    encoded_p: "YzkxX3A=",
  },
  {
    school_code: "95",
    name: "Cal St. Chico",
    division: 2,
    org_id: "95",
    mascot: "Wildcats",
    encoded_h: "Yzk1",
    encoded_c: "Yzk1X2M=",
    encoded_q: "Yzk1X3E=",
    encoded_p: "Yzk1X3A=",
  },
  {
    school_code: "92",
    name: "Cal St. Dom. Hills",
    division: 2,
    org_id: "92",
    mascot: "Toros",
    encoded_h: "Yzky",
    encoded_c: "YzkyX2M=",
    encoded_q: "YzkyX3E=",
    encoded_p: "YzkyX3A=",
  },
  {
    school_code: "98",
    name: "Cal St. East Bay",
    division: 2,
    org_id: "98",
    mascot: "Pioneers",
    encoded_h: "Yzk4",
    encoded_c: "Yzk4X2M=",
    encoded_q: "Yzk4X3E=",
    encoded_p: "Yzk4X3A=",
  },
  {
    school_code: "100",
    name: "Cal St. L.A.",
    division: 2,
    org_id: "100",
    mascot: "Golden Eagles",
    encoded_h: "YzEwMA==",
    encoded_c: "YzEwMF9j",
    encoded_q: "YzEwMF9x",
    encoded_p: "YzEwMF9w",
  },
  {
    school_code: "30055",
    name: "Cal St. Monterey Bay",
    division: 2,
    org_id: "30055",
    mascot: "Otters",
    encoded_h: "YzMwMDU1",
    encoded_c: "YzMwMDU1X2M=",
    encoded_q: "YzMwMDU1X3E=",
    encoded_p: "YzMwMDU1X3A=",
  },
  {
    school_code: "93",
    name: "Cal St. San B'dino",
    division: 2,
    org_id: "93",
    mascot: "Coyotes",
    encoded_h: "Yzkz",
    encoded_c: "YzkzX2M=",
    encoded_q: "YzkzX3E=",
    encoded_p: "YzkzX3A=",
  },
  {
    school_code: "103",
    name: "Cal St. Stanislaus",
    division: 2,
    org_id: "103",
    mascot: "Warriors",
    encoded_h: "YzEwMw==",
    encoded_c: "YzEwM19j",
    encoded_q: "YzEwM19x",
    encoded_p: "YzEwM19w",
  },
  {
    school_code: "30198",
    name: "Cal St. San Marcos",
    division: 2,
    org_id: "30198",
    mascot: "Cougars",
    encoded_h: "YzMwMTk4",
    encoded_c: "YzMwMTk4X2M=",
    encoded_q: "YzMwMTk4X3E=",
    encoded_p: "YzMwMTk4X3A=",
  },
  {
    school_code: "995",
    name: "Caldwell",
    division: 2,
    org_id: "995",
    mascot: "Cougars",
    encoded_h: "Yzk5NQ==",
    encoded_c: "Yzk5NV9j",
    encoded_q: "Yzk5NV9x",
    encoded_p: "Yzk5NV9w",
  },
  {
    school_code: "106",
    name: "California (PA)",
    division: 2,
    org_id: "106",
    mascot: "Vulcans",
    encoded_h: "YzEwNg==",
    encoded_c: "YzEwNl9j",
    encoded_q: "YzEwNl9x",
    encoded_p: "YzEwNl9w",
  },
  {
    school_code: "30135",
    name: "California Baptist",
    division: 2,
    org_id: "30135",
    mascot: "Lancers",
    encoded_h: "YzMwMTM1",
    encoded_c: "YzMwMTM1X2M=",
    encoded_q: "YzMwMTM1X3E=",
    encoded_p: "YzMwMTM1X3A=",
  },
  {
    school_code: "996",
    name: "Cameron",
    division: 2,
    org_id: "996",
    mascot: "Aggies",
    encoded_h: "Yzk5Ng==",
    encoded_c: "Yzk5Nl9j",
    encoded_q: "Yzk5Nl9x",
    encoded_p: "Yzk5Nl9w",
  },
  {
    school_code: "1000",
    name: "Carson-Newman",
    division: 2,
    org_id: "1000",
    mascot: "Eagles",
    encoded_h: "YzEwMDA=",
    encoded_c: "YzEwMDBfYw==",
    encoded_q: "YzEwMDBfcQ==",
    encoded_p: "YzEwMDBfcA==",
  },
  {
    school_code: "1001",
    name: "Catawba",
    division: 2,
    org_id: "1001",
    mascot: "Catawba Indians",
    encoded_h: "YzEwMDE=",
    encoded_c: "YzEwMDFfYw==",
    encoded_q: "YzEwMDFfcQ==",
    encoded_p: "YzEwMDFfcA==",
  },
  {
    school_code: "30124",
    name: "Cedarville",
    division: 2,
    org_id: "30124",
    mascot: "Yellow Jackets",
    encoded_h: "YzMwMTI0",
    encoded_c: "YzMwMTI0X2M=",
    encoded_q: "YzMwMTI0X3E=",
    encoded_p: "YzMwMTI0X3A=",
  },
  {
    school_code: "130",
    name: "Central Mo.",
    division: 2,
    org_id: "130",
    mascot: "Mules",
    encoded_h: "YzEzMA==",
    encoded_c: "YzEzMF9j",
    encoded_q: "YzEzMF9x",
    encoded_p: "YzEzMF9w",
  },
  {
    school_code: "1009",
    name: "Central Okla.",
    division: 2,
    org_id: "1009",
    mascot: "Bronchos",
    encoded_h: "YzEwMDk=",
    encoded_c: "YzEwMDlfYw==",
    encoded_q: "YzEwMDlfcQ==",
    encoded_p: "YzEwMDlfcA==",
  },
  {
    school_code: "1010",
    name: "Central Wash.",
    division: 2,
    org_id: "1010",
    mascot: "Wildcats",
    encoded_h: "YzEwMTA=",
    encoded_c: "YzEwMTBfYw==",
    encoded_q: "YzEwMTBfcQ==",
    encoded_p: "YzEwMTBfcA==",
  },
  {
    school_code: "1013",
    name: "Charleston (WV)",
    division: 2,
    org_id: "1013",
    mascot: "Golden Eagles",
    encoded_h: "YzEwMTM=",
    encoded_c: "YzEwMTNfYw==",
    encoded_q: "YzEwMTNfcQ==",
    encoded_p: "YzEwMTNfcA==",
  },
  {
    school_code: "21323",
    name: "Chestnut Hill",
    division: 2,
    org_id: "21323",
    mascot: "Griffins",
    encoded_h: "YzIxMzIz",
    encoded_c: "YzIxMzIzX2M=",
    encoded_q: "YzIxMzIzX3E=",
    encoded_p: "YzIxMzIzX3A=",
  },
  {
    school_code: "8875",
    name: "Chowan",
    division: 2,
    org_id: "8875",
    mascot: "Hawks",
    encoded_h: "Yzg4NzU=",
    encoded_c: "Yzg4NzVfYw==",
    encoded_q: "Yzg4NzVfcQ==",
    encoded_p: "Yzg4NzVfcA==",
  },
  {
    school_code: "1015",
    name: "Christian Bros.",
    division: 2,
    org_id: "1015",
    mascot: "Buccaneers",
    encoded_h: "YzEwMTU=",
    encoded_c: "YzEwMTVfYw==",
    encoded_q: "YzEwMTVfcQ==",
    encoded_p: "YzEwMTVfcA==",
  },
  {
    school_code: "30058",
    name: "Claflin",
    division: 2,
    org_id: "30058",
    mascot: "Panthers",
    encoded_h: "YzMwMDU4",
    encoded_c: "YzMwMDU4X2M=",
    encoded_q: "YzMwMDU4X3E=",
    encoded_p: "YzMwMDU4X3A=",
  },
  {
    school_code: "143",
    name: "Clarion",
    division: 2,
    org_id: "143",
    mascot: "Golden Eagles",
    encoded_h: "YzE0Mw==",
    encoded_c: "YzE0M19j",
    encoded_q: "YzE0M19x",
    encoded_p: "YzE0M19w",
  },
  {
    school_code: "144",
    name: "Clark Atlanta",
    division: 2,
    org_id: "144",
    mascot: "Panthers",
    encoded_h: "YzE0NA==",
    encoded_c: "YzE0NF9j",
    encoded_q: "YzE0NF9x",
    encoded_p: "YzE0NF9w",
  },
  {
    school_code: "1023",
    name: "Coker",
    division: 2,
    org_id: "1023",
    mascot: "Cobras",
    encoded_h: "YzEwMjM=",
    encoded_c: "YzEwMjNfYw==",
    encoded_q: "YzEwMjNfcQ==",
    encoded_p: "YzEwMjNfcA==",
  },
  {
    school_code: "11403",
    name: "Colo. Christian",
    division: 2,
    org_id: "11403",
    mascot: "Cougars",
    encoded_h: "YzExNDAz",
    encoded_c: "YzExNDAzX2M=",
    encoded_q: "YzExNDAzX3E=",
    encoded_p: "YzExNDAzX3A=",
  },
  {
    school_code: "11416",
    name: "Colorado Mesa",
    division: 2,
    org_id: "11416",
    mascot: "Mavericks",
    encoded_h: "YzExNDE2",
    encoded_c: "YzExNDE2X2M=",
    encoded_q: "YzExNDE2X3E=",
    encoded_p: "YzExNDE2X3A=",
  },
  {
    school_code: "155",
    name: "Colorado Mines",
    division: 2,
    org_id: "155",
    mascot: "Orediggers",
    encoded_h: "YzE1NQ==",
    encoded_c: "YzE1NV9j",
    encoded_q: "YzE1NV9x",
    encoded_p: "YzE1NV9w",
  },
  {
    school_code: "2720",
    name: "Colorado St.-Pueblo",
    division: 2,
    org_id: "2720",
    mascot: "ThunderWolves",
    encoded_h: "YzI3MjA=",
    encoded_c: "YzI3MjBfYw==",
    encoded_q: "YzI3MjBfcQ==",
    encoded_p: "YzI3MjBfcA==",
  },
  {
    school_code: "159",
    name: "Columbus St.",
    division: 2,
    org_id: "159",
    mascot: "Cougars",
    encoded_h: "YzE1OQ==",
    encoded_c: "YzE1OV9j",
    encoded_q: "YzE1OV9x",
    encoded_p: "YzE1OV9w",
  },
  {
    school_code: "1028",
    name: "Concord",
    division: 2,
    org_id: "1028",
    mascot: "Mountain Lions",
    encoded_h: "YzEwMjg=",
    encoded_c: "YzEwMjhfYw==",
    encoded_q: "YzEwMjhfcQ==",
    encoded_p: "YzEwMjhfcA==",
  },
  {
    school_code: "30199",
    name: "Concordia (CA)",
    division: 2,
    org_id: "30199",
    mascot: "Eagles",
    encoded_h: "YzMwMTk5",
    encoded_c: "YzMwMTk5X2M=",
    encoded_q: "YzMwMTk5X3E=",
    encoded_p: "YzMwMTk5X3A=",
  },
  {
    school_code: "162",
    name: "Concordia (NY)",
    division: 2,
    org_id: "162",
    mascot: "Clippers",
    encoded_h: "YzE2Mg==",
    encoded_c: "YzE2Ml9j",
    encoded_q: "YzE2Ml9x",
    encoded_p: "YzE2Ml9w",
  },
  {
    school_code: "30200",
    name: "Concordia Portland",
    division: 2,
    org_id: "30200",
    mascot: "Cavaliers",
    encoded_h: "YzMwMjAw",
    encoded_c: "YzMwMjAwX2M=",
    encoded_q: "YzMwMjAwX3E=",
    encoded_p: "YzMwMjAwX3A=",
  },
  {
    school_code: "9081",
    name: "Concordia-St. Paul",
    division: 2,
    org_id: "9081",
    mascot: "Golden Bears",
    encoded_h: "YzkwODE=",
    encoded_c: "YzkwODFfYw==",
    encoded_q: "YzkwODFfcQ==",
    encoded_p: "YzkwODFfcA==",
  },
  {
    school_code: "174",
    name: "Davis & Elkins",
    division: 2,
    org_id: "174",
    mascot: "Senators",
    encoded_h: "YzE3NA==",
    encoded_c: "YzE3NF9j",
    encoded_q: "YzE3NF9x",
    encoded_p: "YzE3NF9w",
  },
  {
    school_code: "181",
    name: "Delta St.",
    division: 2,
    org_id: "181",
    mascot: "Statesmen",
    encoded_h: "YzE4MQ==",
    encoded_c: "YzE4MV9j",
    encoded_q: "YzE4MV9x",
    encoded_p: "YzE4MV9w",
  },
  {
    school_code: "30095",
    name: "Utah Tech",
    division: 2,
    org_id: "30095",
    mascot: "Trailblazer",
    encoded_h: "YzMwMDk1",
    encoded_c: "YzMwMDk1X2M=",
    encoded_q: "YzMwMDk1X3E=",
    encoded_p: "YzMwMDk1X3A=",
  },
  {
    school_code: "8956",
    name: "Dominican (NY)",
    division: 2,
    org_id: "8956",
    mascot: "Chargers",
    encoded_h: "Yzg5NTY=",
    encoded_c: "Yzg5NTZfYw==",
    encoded_q: "Yzg5NTZfcQ==",
    encoded_p: "Yzg5NTZfcA==",
  },
  {
    school_code: "2692",
    name: "Dowling",
    division: 2,
    org_id: "2692",
    mascot: "Golden Lions",
    encoded_h: "YzI2OTI=",
    encoded_c: "YzI2OTJfYw==",
    encoded_q: "YzI2OTJfcQ==",
    encoded_p: "YzI2OTJfcA==",
  },
  {
    school_code: "1057",
    name: "Drury",
    division: 2,
    org_id: "1057",
    mascot: "Panthers",
    encoded_h: "YzEwNTc=",
    encoded_c: "YzEwNTdfYw==",
    encoded_q: "YzEwNTdfcQ==",
    encoded_p: "YzEwNTdfcA==",
  },
  {
    school_code: "8965",
    name: "East Central",
    division: 2,
    org_id: "8965",
    mascot: "Tigers",
    encoded_h: "Yzg5NjU=",
    encoded_c: "Yzg5NjVfYw==",
    encoded_q: "Yzg5NjVfcQ==",
    encoded_p: "Yzg5NjVfcA==",
  },
  {
    school_code: "197",
    name: "East Stroudsburg",
    division: 2,
    org_id: "197",
    mascot: "Warriors",
    encoded_h: "YzE5Nw==",
    encoded_c: "YzE5N19j",
    encoded_q: "YzE5N19x",
    encoded_p: "YzE5N19w",
  },
  {
    school_code: "206",
    name: "Eastern N.M.",
    division: 2,
    org_id: "206",
    mascot: "Greyhounds",
    encoded_h: "YzIwNg==",
    encoded_c: "YzIwNl9j",
    encoded_q: "YzIwNl9x",
    encoded_p: "YzIwNl9w",
  },
  {
    school_code: "208",
    name: "Eckerd",
    division: 2,
    org_id: "208",
    mascot: "Tritons",
    encoded_h: "YzIwOA==",
    encoded_c: "YzIwOF9j",
    encoded_q: "YzIwOF9x",
    encoded_p: "YzIwOF9w",
  },
  {
    school_code: "210",
    name: "Elizabeth City St.",
    division: 2,
    org_id: "210",
    mascot: "Vikings",
    encoded_h: "YzIxMA==",
    encoded_c: "YzIxMF9j",
    encoded_q: "YzIxMF9x",
    encoded_p: "YzIxMF9w",
  },
  {
    school_code: "9680",
    name: "Embry-Riddle (FL)",
    division: 2,
    org_id: "9680",
    mascot: "Eagles",
    encoded_h: "Yzk2ODA=",
    encoded_c: "Yzk2ODBfYw==",
    encoded_q: "Yzk2ODBfcQ==",
    encoded_p: "Yzk2ODBfcA==",
  },
  {
    school_code: "1071",
    name: "Emporia St.",
    division: 2,
    org_id: "1071",
    mascot: "Hornets",
    encoded_h: "YzEwNzE=",
    encoded_c: "YzEwNzFfYw==",
    encoded_q: "YzEwNzFfcQ==",
    encoded_p: "YzEwNzFfcA==",
  },
  {
    school_code: "1072",
    name: "Erskine",
    division: 2,
    org_id: "1072",
    mascot: "The Flying Fleet",
    encoded_h: "YzEwNzI=",
    encoded_c: "YzEwNzJfYw==",
    encoded_q: "YzEwNzJfcQ==",
    encoded_p: "YzEwNzJfcA==",
  },
  {
    school_code: "1076",
    name: "Fairmont St.",
    division: 2,
    org_id: "1076",
    mascot: "Falcons",
    encoded_h: "YzEwNzY=",
    encoded_c: "YzEwNzZfYw==",
    encoded_q: "YzEwNzZfcQ==",
    encoded_p: "YzEwNzZfcA==",
  },
  {
    school_code: "23157",
    name: "Felician",
    division: 2,
    org_id: "23157",
    mascot: "Golden Falcons",
    encoded_h: "YzIzMTU3",
    encoded_c: "YzIzMTU3X2M=",
    encoded_q: "YzIzMTU3X3E=",
    encoded_p: "YzIzMTU3X3A=",
  },
  {
    school_code: "1079",
    name: "Findlay",
    division: 2,
    org_id: "1079",
    mascot: "Oilers",
    encoded_h: "YzEwNzk=",
    encoded_c: "YzEwNzlfYw==",
    encoded_q: "YzEwNzlfcQ==",
    encoded_p: "YzEwNzlfcA==",
  },
  {
    school_code: "233",
    name: "Fla. Southern",
    division: 2,
    org_id: "233",
    mascot: "Moccasins",
    encoded_h: "YzIzMw==",
    encoded_c: "YzIzM19j",
    encoded_q: "YzIzM19x",
    encoded_p: "YzIzM19w",
  },
  {
    school_code: "30092",
    name: "Flagler",
    division: 2,
    org_id: "30092",
    mascot: "Saints",
    encoded_h: "YzMwMDky",
    encoded_c: "YzMwMDkyX2M=",
    encoded_q: "YzMwMDkyX3E=",
    encoded_p: "YzMwMDkyX3A=",
  },
  {
    school_code: "230",
    name: "Florida Tech",
    division: 2,
    org_id: "230",
    mascot: "Panthers",
    encoded_h: "YzIzMA==",
    encoded_c: "YzIzMF9j",
    encoded_q: "YzIzMF9x",
    encoded_p: "YzIzMF9w",
  },
  {
    school_code: "9011",
    name: "Fort Hays St.",
    division: 2,
    org_id: "9011",
    mascot: "Tigers",
    encoded_h: "YzkwMTE=",
    encoded_c: "YzkwMTFfYw==",
    encoded_q: "YzkwMTFfcQ==",
    encoded_p: "YzkwMTFfcA==",
  },
  {
    school_code: "1085",
    name: "Francis Marion",
    division: 2,
    org_id: "1085",
    mascot: "Patriots",
    encoded_h: "YzEwODU=",
    encoded_c: "YzEwODVfYw==",
    encoded_q: "YzEwODVfcQ==",
    encoded_p: "YzEwODVfcA==",
  },
  {
    school_code: "241",
    name: "Franklin Pierce",
    division: 2,
    org_id: "241",
    mascot: "Ravens",
    encoded_h: "YzI0MQ==",
    encoded_c: "YzI0MV9j",
    encoded_q: "YzI0MV9x",
    encoded_p: "YzI0MV9w",
  },
  {
    school_code: "30147",
    name: "Fresno Pacific",
    division: 2,
    org_id: "30147",
    mascot: "Sunbirds",
    encoded_h: "YzMwMTQ3",
    encoded_c: "YzMwMTQ3X2M=",
    encoded_q: "YzMwMTQ3X3E=",
    encoded_p: "YzMwMTQ3X3A=",
  },
  {
    school_code: "39",
    name: "Augusta",
    division: 2,
    org_id: "39",
    mascot: "Jaguars",
    encoded_h: "YzM5",
    encoded_c: "YzM5X2M=",
    encoded_q: "YzM5X3E=",
    encoded_p: "YzM5X3A=",
  },
  {
    school_code: "1097",
    name: "Ga. Southwestern",
    division: 2,
    org_id: "1097",
    mascot: "Hurricanes",
    encoded_h: "YzEwOTc=",
    encoded_c: "YzEwOTdfYw==",
    encoded_q: "YzEwOTdfcQ==",
    encoded_p: "YzEwOTdfcA==",
  },
  {
    school_code: "246",
    name: "Gannon",
    division: 2,
    org_id: "246",
    mascot: "Golden Knights",
    encoded_h: "YzI0Ng==",
    encoded_c: "YzI0Nl9j",
    encoded_q: "YzI0Nl9x",
    encoded_p: "YzI0Nl9w",
  },
  {
    school_code: "1096",
    name: "Georgia College",
    division: 2,
    org_id: "1096",
    mascot: "Bobcats",
    encoded_h: "YzEwOTY=",
    encoded_c: "YzEwOTZfYw==",
    encoded_q: "YzEwOTZfcQ==",
    encoded_p: "YzEwOTZfcA==",
  },
  {
    school_code: "1098",
    name: "Glenville St.",
    division: 2,
    org_id: "1098",
    mascot: "Pioneers",
    encoded_h: "YzEwOTg=",
    encoded_c: "YzEwOThfYw==",
    encoded_q: "YzEwOThfcQ==",
    encoded_p: "YzEwOThfcA==",
  },
  {
    school_code: "8366",
    name: "Goldey-Beacom",
    division: 2,
    org_id: "8366",
    mascot: "Lightning",
    encoded_h: "YzgzNjY=",
    encoded_c: "YzgzNjZfYw==",
    encoded_q: "YzgzNjZfcQ==",
    encoded_p: "YzgzNjZfcA==",
  },
  {
    school_code: "1104",
    name: "Grand Canyon",
    division: 1,
    org_id: "1104",
    mascot: "Antelopes",
    encoded_h: "YzExMDQ=",
    encoded_c: "YzExMDRfYw==",
    encoded_q: "YzExMDRfcQ==",
    encoded_p: "YzExMDRfcA==",
  },
  {
    school_code: "262",
    name: "Grand Valley St.",
    division: 2,
    org_id: "262",
    mascot: "Lakers",
    encoded_h: "YzI2Mg==",
    encoded_c: "YzI2Ml9j",
    encoded_q: "YzI2Ml9x",
    encoded_p: "YzI2Ml9w",
  },
  {
    school_code: "1116",
    name: "Harding",
    division: 2,
    org_id: "1116",
    mascot: "Bisons",
    encoded_h: "YzExMTY=",
    encoded_c: "YzExMTZfYw==",
    encoded_q: "YzExMTZfcQ==",
    encoded_p: "YzExMTZfcA==",
  },
  {
    school_code: "2697",
    name: "Hawaii Hilo",
    division: 2,
    org_id: "2697",
    mascot: "Vulcans",
    encoded_h: "YzI2OTc=",
    encoded_c: "YzI2OTdfYw==",
    encoded_q: "YzI2OTdfcQ==",
    encoded_p: "YzI2OTdfcA==",
  },
  {
    school_code: "2696",
    name: "Hawaii Pacific",
    division: 2,
    org_id: "2696",
    mascot: "Sharks",
    encoded_h: "YzI2OTY=",
    encoded_c: "YzI2OTZfYw==",
    encoded_q: "YzI2OTZfcQ==",
    encoded_p: "YzI2OTZfcA==",
  },
  {
    school_code: "1123",
    name: "Henderson St.",
    division: 2,
    org_id: "1123",
    mascot: "Reddies",
    encoded_h: "YzExMjM=",
    encoded_c: "YzExMjNfYw==",
    encoded_q: "YzExMjNfcQ==",
    encoded_p: "YzExMjNfcA==",
  },
  {
    school_code: "280",
    name: "Hillsdale",
    division: 2,
    org_id: "280",
    mascot: "Chargers",
    encoded_h: "YzI4MA==",
    encoded_c: "YzI4MF9j",
    encoded_q: "YzI4MF9x",
    encoded_p: "YzI4MF9w",
  },
  {
    school_code: "30050",
    name: "Ill.-Springfield",
    division: 2,
    org_id: "30050",
    mascot: "Prairie Stars",
    encoded_h: "YzMwMDUw",
    encoded_c: "YzMwMDUwX2M=",
    encoded_q: "YzMwMDUwX3E=",
    encoded_p: "YzMwMDUwX3A=",
  },
  {
    school_code: "2743",
    name: "Incarnate Word",
    division: 1,
    org_id: "2743",
    mascot: "Cardinals",
    encoded_h: "YzI3NDM=",
    encoded_c: "YzI3NDNfYw==",
    encoded_q: "YzI3NDNfcQ==",
    encoded_p: "YzI3NDNfcA==",
  },
  {
    school_code: "307",
    name: "Indiana (PA)",
    division: 2,
    org_id: "307",
    mascot: "Crimson Hawks",
    encoded_h: "YzMwNw==",
    encoded_c: "YzMwN19j",
    encoded_q: "YzMwN19x",
    encoded_p: "YzMwN19w",
  },
  {
    school_code: "309",
    name: "Indianapolis",
    division: 2,
    org_id: "309",
    mascot: "Greyhounds",
    encoded_h: "YzMwOQ==",
    encoded_c: "YzMwOV9j",
    encoded_q: "YzMwOV9x",
    encoded_p: "YzMwOV9w",
  },
  {
    school_code: "332",
    name: "Kentucky St.",
    division: 2,
    org_id: "332",
    mascot: "Thorobreds",
    encoded_h: "YzMzMg==",
    encoded_c: "YzMzMl9j",
    encoded_q: "YzMzMl9x",
    encoded_p: "YzMzMl9w",
  },
  {
    school_code: "30051",
    name: "King (TN)",
    division: 2,
    org_id: "30051",
    mascot: "Tornado",
    encoded_h: "YzMwMDUx",
    encoded_c: "YzMwMDUxX2M=",
    encoded_q: "YzMwMDUxX3E=",
    encoded_p: "YzMwMDUxX3A=",
  },
  {
    school_code: "339",
    name: "Kutztown",
    division: 2,
    org_id: "339",
    mascot: "Golden Bears",
    encoded_h: "YzMzOQ==",
    encoded_c: "YzMzOV9j",
    encoded_q: "YzMzOV9x",
    encoded_p: "YzMzOV9w",
  },
  {
    school_code: "333",
    name: "Ky. Wesleyan",
    division: 2,
    org_id: "333",
    mascot: "Panthers",
    encoded_h: "YzMzMw==",
    encoded_c: "YzMzM19j",
    encoded_q: "YzMzM19x",
    encoded_p: "YzMzM19w",
  },
  {
    school_code: "362",
    name: "LIU Post",
    division: 2,
    org_id: "362",
    mascot: "Pioneers",
    encoded_h: "YzM2Mg==",
    encoded_c: "YzM2Ml9j",
    encoded_q: "YzM2Ml9x",
    encoded_p: "YzM2Ml9w",
  },
  {
    school_code: "2746",
    name: "Lake Erie",
    division: 2,
    org_id: "2746",
    mascot: "Storm",
    encoded_h: "YzI3NDY=",
    encoded_c: "YzI3NDZfYw==",
    encoded_q: "YzI3NDZfcQ==",
    encoded_p: "YzI3NDZfcA==",
  },
  {
    school_code: "1167",
    name: "Lander",
    division: 2,
    org_id: "1167",
    mascot: "Bearcats",
    encoded_h: "YzExNjc=",
    encoded_c: "YzExNjdfYw==",
    encoded_q: "YzExNjdfcQ==",
    encoded_p: "YzExNjdfcA==",
  },
  {
    school_code: "347",
    name: "Lane",
    division: 2,
    org_id: "347",
    mascot: "Dragons",
    encoded_h: "YzM0Nw==",
    encoded_c: "YzM0N19j",
    encoded_q: "YzM0N19x",
    encoded_p: "YzM0N19w",
  },
  {
    school_code: "349",
    name: "Le Moyne",
    division: 2,
    org_id: "349",
    mascot: "Dolphins",
    encoded_h: "YzM0OQ==",
    encoded_c: "YzM0OV9j",
    encoded_q: "YzM0OV9x",
    encoded_p: "YzM0OV9w",
  },
  {
    school_code: "350",
    name: "LeMoyne-Owen",
    division: 2,
    org_id: "350",
    mascot: "Magicians",
    encoded_h: "YzM1MA==",
    encoded_c: "YzM1MF9j",
    encoded_q: "YzM1MF9x",
    encoded_p: "YzM1MF9w",
  },
  {
    school_code: "1170",
    name: "Lenoir-Rhyne",
    division: 2,
    org_id: "1170",
    mascot: "Bears",
    encoded_h: "YzExNzA=",
    encoded_c: "YzExNzBfYw==",
    encoded_q: "YzExNzBfcQ==",
    encoded_p: "YzExNzBfcA==",
  },
  {
    school_code: "354",
    name: "Lewis",
    division: 2,
    org_id: "354",
    mascot: "Flyers",
    encoded_h: "YzM1NA==",
    encoded_c: "YzM1NF9j",
    encoded_q: "YzM1NF9x",
    encoded_p: "YzM1NF9w",
  },
  {
    school_code: "1174",
    name: "Limestone",
    division: 2,
    org_id: "1174",
    mascot: "Saints",
    encoded_h: "YzExNzQ=",
    encoded_c: "YzExNzRfYw==",
    encoded_q: "YzExNzRfcQ==",
    encoded_p: "YzExNzRfcA==",
  },
  {
    school_code: "356",
    name: "Lincoln (MO)",
    division: 2,
    org_id: "356",
    mascot: "Blue Tigers",
    encoded_h: "YzM1Ng==",
    encoded_c: "YzM1Nl9j",
    encoded_q: "YzM1Nl9x",
    encoded_p: "YzM1Nl9w",
  },
  {
    school_code: "357",
    name: "Lincoln (PA)",
    division: 2,
    org_id: "357",
    mascot: "Lions",
    encoded_h: "YzM1Nw==",
    encoded_c: "YzM1N19j",
    encoded_q: "YzM1N19x",
    encoded_p: "YzM1N19w",
  },
  {
    school_code: "1176",
    name: "Lincoln Memorial",
    division: 2,
    org_id: "1176",
    mascot: "Railsplitters",
    encoded_h: "YzExNzY=",
    encoded_c: "YzExNzZfYw==",
    encoded_q: "YzExNzZfcQ==",
    encoded_p: "YzExNzZfcA==",
  },
  {
    school_code: "30136",
    name: "Lindenwood (MO)",
    division: 2,
    org_id: "30136",
    mascot: "Lions",
    encoded_h: "YzMwMTM2",
    encoded_c: "YzMwMTM2X2M=",
    encoded_q: "YzMwMTM2X3E=",
    encoded_p: "YzMwMTM2X3A=",
  },
  {
    school_code: "360",
    name: "Lock Haven",
    division: 2,
    org_id: "360",
    mascot: "Bald Eagles",
    encoded_h: "YzM2MA==",
    encoded_c: "YzM2MF9j",
    encoded_q: "YzM2MF9x",
    encoded_p: "YzM2MF9w",
  },
  {
    school_code: "20794",
    name: "Lynn",
    division: 2,
    org_id: "20794",
    mascot: "Fighting Knights",
    encoded_h: "YzIwNzk0",
    encoded_c: "YzIwNzk0X2M=",
    encoded_q: "YzIwNzk0X3E=",
    encoded_p: "YzIwNzk0X3A=",
  },
  {
    school_code: "30137",
    name: "Malone",
    division: 2,
    org_id: "30137",
    mascot: "Pioneers",
    encoded_h: "YzMwMTM3",
    encoded_c: "YzMwMTM3X2M=",
    encoded_q: "YzMwMTM3X3E=",
    encoded_p: "YzMwMTM3X3A=",
  },
  {
    school_code: "384",
    name: "Mansfield",
    division: 2,
    org_id: "384",
    mascot: "Mountaineers",
    encoded_h: "YzM4NA==",
    encoded_c: "YzM4NF9j",
    encoded_q: "YzM4NF9x",
    encoded_p: "YzM4NF9w",
  },
  {
    school_code: "1199",
    name: "Mars Hill",
    division: 2,
    org_id: "1199",
    mascot: "Lions",
    encoded_h: "YzExOTk=",
    encoded_c: "YzExOTlfYw==",
    encoded_q: "YzExOTlfcQ==",
    encoded_p: "YzExOTlfcA==",
  },
  {
    school_code: "30075",
    name: "Mary",
    division: 2,
    org_id: "30075",
    mascot: "Marauders",
    encoded_h: "YzMwMDc1",
    encoded_c: "YzMwMDc1X2M=",
    encoded_q: "YzMwMDc1X3E=",
    encoded_p: "YzMwMDc1X3A=",
  },
  {
    school_code: "395",
    name: "Maryville (MO)",
    division: 2,
    org_id: "395",
    mascot: "Saints",
    encoded_h: "YzM5NQ==",
    encoded_c: "YzM5NV9j",
    encoded_q: "YzM5NV9x",
    encoded_p: "YzM5NV9w",
  },
  {
    school_code: "30138",
    name: "McKendree",
    division: 2,
    org_id: "30138",
    mascot: "Bearcats",
    encoded_h: "YzMwMTM4",
    encoded_c: "YzMwMTM4X2M=",
    encoded_q: "YzMwMTM4X3E=",
    encoded_p: "YzMwMTM4X3A=",
  },
  {
    school_code: "8530",
    name: "McMurry",
    division: 2,
    org_id: "8530",
    mascot: "War Hawks",
    encoded_h: "Yzg1MzA=",
    encoded_c: "Yzg1MzBfYw==",
    encoded_q: "Yzg1MzBfcQ==",
    encoded_p: "Yzg1MzBfcA==",
  },
  {
    school_code: "407",
    name: "Mercy",
    division: 2,
    org_id: "407",
    mascot: "Mavericks",
    encoded_h: "YzQwNw==",
    encoded_c: "YzQwN19j",
    encoded_q: "YzQwN19x",
    encoded_p: "YzQwN19w",
  },
  {
    school_code: "408",
    name: "Mercyhurst",
    division: 2,
    org_id: "408",
    mascot: "Lakers",
    encoded_h: "YzQwOA==",
    encoded_c: "YzQwOF9j",
    encoded_q: "YzQwOF9x",
    encoded_p: "YzQwOF9w",
  },
  {
    school_code: "410",
    name: "Merrimack",
    division: 2,
    org_id: "410",
    mascot: "Warriors",
    encoded_h: "YzQxMA==",
    encoded_c: "YzQxMF9j",
    encoded_q: "YzQxMF9x",
    encoded_p: "YzQxMF9w",
  },
  {
    school_code: "413",
    name: "Metro St.",
    division: 2,
    org_id: "413",
    mascot: "Roadrunners",
    encoded_h: "YzQxMw==",
    encoded_c: "YzQxM19j",
    encoded_q: "YzQxM19x",
    encoded_p: "YzQxM19w",
  },
  {
    school_code: "422",
    name: "Miles",
    division: 2,
    org_id: "422",
    mascot: "Golden Bears",
    encoded_h: "YzQyMg==",
    encoded_c: "YzQyMl9j",
    encoded_q: "YzQyMl9x",
    encoded_p: "YzQyMl9w",
  },
  {
    school_code: "423",
    name: "Millersville",
    division: 2,
    org_id: "423",
    mascot: "Marauders",
    encoded_h: "YzQyMw==",
    encoded_c: "YzQyM19j",
    encoded_q: "YzQyM19x",
    encoded_p: "YzQyM19w",
  },
  {
    school_code: "427",
    name: "Minn. Duluth",
    division: 2,
    org_id: "427",
    mascot: "Bulldogs",
    encoded_h: "YzQyNw==",
    encoded_c: "YzQyN19j",
    encoded_q: "YzQyN19x",
    encoded_p: "YzQyN19w",
  },
  {
    school_code: "383",
    name: "Minn. St. Mankato",
    division: 2,
    org_id: "383",
    mascot: "Mavericks",
    encoded_h: "YzM4Mw==",
    encoded_c: "YzM4M19j",
    encoded_q: "YzM4M19x",
    encoded_p: "YzM4M19w",
  },
  {
    school_code: "27854",
    name: "Minn.-Crookston",
    division: 2,
    org_id: "27854",
    mascot: "Golden Eagles",
    encoded_h: "YzI3ODU0",
    encoded_c: "YzI3ODU0X2M=",
    encoded_q: "YzI3ODU0X3E=",
    encoded_p: "YzI3ODU0X3A=",
  },
  {
    school_code: "30125",
    name: "Minot St.",
    division: 2,
    org_id: "30125",
    mascot: "Beavers",
    encoded_h: "YzMwMTI1",
    encoded_c: "YzMwMTI1X2M=",
    encoded_q: "YzMwMTI1X3E=",
    encoded_p: "YzMwMTI1X3A=",
  },
  {
    school_code: "435",
    name: "Missouri S&T",
    division: 2,
    org_id: "435",
    mascot: "Miners",
    encoded_h: "YzQzNQ==",
    encoded_c: "YzQzNV9j",
    encoded_q: "YzQzNV9x",
    encoded_p: "YzQzNV9w",
  },
  {
    school_code: "9012",
    name: "Mo. Southern St.",
    division: 2,
    org_id: "9012",
    mascot: "Lions",
    encoded_h: "YzkwMTI=",
    encoded_c: "YzkwMTJfYw==",
    encoded_q: "YzkwMTJfcQ==",
    encoded_p: "YzkwMTJfcA==",
  },
  {
    school_code: "9013",
    name: "Mo. Western St.",
    division: 2,
    org_id: "9013",
    mascot: "Griffons",
    encoded_h: "YzkwMTM=",
    encoded_c: "YzkwMTNfYw==",
    encoded_q: "YzkwMTNfcQ==",
    encoded_p: "YzkwMTNfcA==",
  },
  {
    school_code: "436",
    name: "Mo.-St. Louis",
    division: 2,
    org_id: "436",
    mascot: "Tritons",
    encoded_h: "YzQzNg==",
    encoded_c: "YzQzNl9j",
    encoded_q: "YzQzNl9x",
    encoded_p: "YzQzNl9w",
  },
  {
    school_code: "437",
    name: "Molloy",
    division: 2,
    org_id: "437",
    mascot: "Lions",
    encoded_h: "YzQzNw==",
    encoded_c: "YzQzN19j",
    encoded_q: "YzQzN19x",
    encoded_p: "YzQzN19w",
  },
  {
    school_code: "205",
    name: "Mont. St. Billings",
    division: 2,
    org_id: "205",
    mascot: "Yellowjackets",
    encoded_h: "YzIwNQ==",
    encoded_c: "YzIwNV9j",
    encoded_q: "YzIwNV9x",
    encoded_p: "YzIwNV9w",
  },
  {
    school_code: "1238",
    name: "Montevallo",
    division: 2,
    org_id: "1238",
    mascot: "Falcons",
    encoded_h: "YzEyMzg=",
    encoded_c: "YzEyMzhfYw==",
    encoded_q: "YzEyMzhfcQ==",
    encoded_p: "YzEyMzhfcA==",
  },
  {
    school_code: "445",
    name: "Morehouse",
    division: 2,
    org_id: "445",
    mascot: "Maroon Tigers",
    encoded_h: "YzQ0NQ==",
    encoded_c: "YzQ0NV9j",
    encoded_q: "YzQ0NV9x",
    encoded_p: "YzQ0NV9w",
  },
  {
    school_code: "1245",
    name: "Mount Olive",
    division: 2,
    org_id: "1245",
    mascot: "Trojans",
    encoded_h: "YzEyNDU=",
    encoded_c: "YzEyNDVfYw==",
    encoded_q: "YzEyNDVfcQ==",
    encoded_p: "YzEyNDVfcA==",
  },
  {
    school_code: "8577",
    name: "N.M. Highlands",
    division: 2,
    org_id: "8577",
    mascot: "Cowboys",
    encoded_h: "Yzg1Nzc=",
    encoded_c: "Yzg1NzdfYw==",
    encoded_q: "Yzg1NzdfcQ==",
    encoded_p: "Yzg1NzdfcA==",
  },
  {
    school_code: "1156",
    name: "Neb.-Kearney",
    division: 2,
    org_id: "1156",
    mascot: "Antelopes",
    encoded_h: "YzExNTY=",
    encoded_c: "YzExNTZfYw==",
    encoded_q: "YzExNTZfcQ==",
    encoded_p: "YzExNTZfcA==",
  },
  {
    school_code: "470",
    name: "New Haven",
    division: 2,
    org_id: "470",
    mascot: "Chargers",
    encoded_h: "YzQ3MA==",
    encoded_c: "YzQ3MF9j",
    encoded_q: "YzQ3MF9x",
    encoded_p: "YzQ3MF9w",
  },
  {
    school_code: "1257",
    name: "Newberry",
    division: 2,
    org_id: "1257",
    mascot: "Wolves",
    encoded_h: "YzEyNTc=",
    encoded_c: "YzEyNTdfYw==",
    encoded_q: "YzEyNTdfcQ==",
    encoded_p: "YzEyNTdfcA==",
  },
  {
    school_code: "30054",
    name: "Newman",
    division: 2,
    org_id: "30054",
    mascot: "Jets",
    encoded_h: "YzMwMDU0",
    encoded_c: "YzMwMDU0X2M=",
    encoded_q: "YzMwMDU0X3E=",
    encoded_p: "YzMwMDU0X3A=",
  },
  {
    school_code: "487",
    name: "North Ala.",
    division: 2,
    org_id: "487",
    mascot: "Lions",
    encoded_h: "YzQ4Nw==",
    encoded_c: "YzQ4N19j",
    encoded_q: "YzQ4N19x",
    encoded_p: "YzQ4N19w",
  },
  {
    school_code: "30052",
    name: "North Georgia",
    division: 2,
    org_id: "30052",
    mascot: "Nighthawks",
    encoded_h: "YzMwMDUy",
    encoded_c: "YzMwMDUyX2M=",
    encoded_q: "YzMwMDUyX3E=",
    encoded_p: "YzMwMDUyX3A=",
  },
  {
    school_code: "9223",
    name: "North Greenville",
    division: 2,
    org_id: "9223",
    mascot: "Crusaders",
    encoded_h: "YzkyMjM=",
    encoded_c: "YzkyMjNfYw==",
    encoded_q: "YzkyMjNfcQ==",
    encoded_p: "YzkyMjNfcA==",
  },
  {
    school_code: "12810",
    name: "Northeastern St.",
    division: 2,
    org_id: "12810",
    mascot: "RiverHawks",
    encoded_h: "YzEyODEw",
    encoded_c: "YzEyODEwX2M=",
    encoded_q: "YzEyODEwX3E=",
    encoded_p: "YzEyODEwX3A=",
  },
  {
    school_code: "1266",
    name: "Northern St.",
    division: 2,
    org_id: "1266",
    mascot: "Wolves",
    encoded_h: "YzEyNjY=",
    encoded_c: "YzEyNjZfYw==",
    encoded_q: "YzEyNjZfcQ==",
    encoded_p: "YzEyNjZfcA==",
  },
  {
    school_code: "507",
    name: "Northwest Mo. St.",
    division: 2,
    org_id: "507",
    mascot: "Bearcats",
    encoded_h: "YzUwNw==",
    encoded_c: "YzUwN19j",
    encoded_q: "YzUwN19x",
    encoded_p: "YzUwN19w",
  },
  {
    school_code: "1274",
    name: "Northwest Nazarene",
    division: 2,
    org_id: "1274",
    mascot: "Crusaders",
    encoded_h: "YzEyNzQ=",
    encoded_c: "YzEyNzRfYw==",
    encoded_q: "YzEyNzRfcQ==",
    encoded_p: "YzEyNzRfcA==",
  },
  {
    school_code: "510",
    name: "Northwood (MI)",
    division: 2,
    org_id: "510",
    mascot: "Timberwolves",
    encoded_h: "YzUxMA==",
    encoded_c: "YzUxMF9j",
    encoded_q: "YzUxMF9x",
    encoded_p: "YzUxMF9w",
  },
  {
    school_code: "30126",
    name: "Notre Dame (OH)",
    division: 2,
    org_id: "30126",
    mascot: "Falcons",
    encoded_h: "YzMwMTI2",
    encoded_c: "YzMwMTI2X2M=",
    encoded_q: "YzMwMTI2X3E=",
    encoded_p: "YzMwMTI2X3A=",
  },
  {
    school_code: "24639",
    name: "Nova Southeastern",
    division: 2,
    org_id: "24639",
    mascot: "Sharks",
    encoded_h: "YzI0NjM5",
    encoded_c: "YzI0NjM5X2M=",
    encoded_q: "YzI0NjM5X3E=",
    encoded_p: "YzI0NjM5X3A=",
  },
  {
    school_code: "1277",
    name: "Nyack",
    division: 2,
    org_id: "1277",
    mascot: "Warriors",
    encoded_h: "YzEyNzc=",
    encoded_c: "YzEyNzdfYw==",
    encoded_q: "YzEyNzdfcQ==",
    encoded_p: "YzEyNzdfcA==",
  },
  {
    school_code: "1278",
    name: "Oakland City",
    division: 2,
    org_id: "1278",
    mascot: "Mighty Oaks",
    encoded_h: "YzEyNzg=",
    encoded_c: "YzEyNzhfYw==",
    encoded_q: "YzEyNzhfcQ==",
    encoded_p: "YzEyNzhfcA==",
  },
  {
    school_code: "30119",
    name: "Ohio Dominican",
    division: 2,
    org_id: "30119",
    mascot: "Panthers",
    encoded_h: "YzMwMTE5",
    encoded_c: "YzMwMTE5X2M=",
    encoded_q: "YzMwMTE5X3E=",
    encoded_p: "YzMwMTE5X3A=",
  },
  {
    school_code: "15207",
    name: "Ohio Valley",
    division: 2,
    org_id: "15207",
    mascot: "Fighting Scots",
    encoded_h: "YzE1MjA3",
    encoded_c: "YzE1MjA3X2M=",
    encoded_q: "YzE1MjA3X3E=",
    encoded_p: "YzE1MjA3X3A=",
  },
  {
    school_code: "24342",
    name: "Okla. Panhandle",
    division: 2,
    org_id: "24342",
    mascot: "Aggies",
    encoded_h: "YzI0MzQy",
    encoded_c: "YzI0MzQyX2M=",
    encoded_q: "YzI0MzQyX3E=",
    encoded_p: "YzI0MzQyX3A=",
  },
  {
    school_code: "1289",
    name: "Ouachita Baptist",
    division: 2,
    org_id: "1289",
    mascot: "Tigers",
    encoded_h: "YzEyODk=",
    encoded_c: "YzEyODlfYw==",
    encoded_q: "YzEyODlfcQ==",
    encoded_p: "YzEyODlfcA==",
  },
  {
    school_code: "2682",
    name: "P.R.-Bayamon",
    division: 2,
    org_id: "2682",
    mascot: "Cowboys",
    encoded_h: "YzI2ODI=",
    encoded_c: "YzI2ODJfYw==",
    encoded_q: "YzI2ODJfcQ==",
    encoded_p: "YzI2ODJfcA==",
  },
  {
    school_code: "1321",
    name: "P.R.-Cayey",
    division: 2,
    org_id: "1321",
    mascot: "",
    encoded_h: "YzEzMjE=",
    encoded_c: "YzEzMjFfYw==",
    encoded_q: "YzEzMjFfcQ==",
    encoded_p: "YzEzMjFfcA==",
  },
  {
    school_code: "27859",
    name: "P.R.-Humacao",
    division: 2,
    org_id: "27859",
    mascot: "",
    encoded_h: "YzI3ODU5",
    encoded_c: "YzI3ODU5X2M=",
    encoded_q: "YzI3ODU5X3E=",
    encoded_p: "YzI3ODU5X3A=",
  },
  {
    school_code: "24361",
    name: "P.R.-Mayaguez",
    division: 2,
    org_id: "24361",
    mascot: "Tarzans",
    encoded_h: "YzI0MzYx",
    encoded_c: "YzI0MzYxX2M=",
    encoded_q: "YzI0MzYxX3E=",
    encoded_p: "YzI0MzYxX3A=",
  },
  {
    school_code: "2716",
    name: "P.R.-Rio Piedras",
    division: 2,
    org_id: "2716",
    mascot: "Gallitos",
    encoded_h: "YzI3MTY=",
    encoded_c: "YzI3MTZfYw==",
    encoded_q: "YzI3MTZfcQ==",
    encoded_p: "YzI3MTZfcA==",
  },
  {
    school_code: "533",
    name: "Pace",
    division: 2,
    org_id: "533",
    mascot: "Setters",
    encoded_h: "YzUzMw==",
    encoded_c: "YzUzM19j",
    encoded_q: "YzUzM19x",
    encoded_p: "YzUzM19w",
  },
  {
    school_code: "535",
    name: "Paine",
    division: 2,
    org_id: "535",
    mascot: "Lions",
    encoded_h: "YzUzNQ==",
    encoded_c: "YzUzNV9j",
    encoded_q: "YzUzNV9x",
    encoded_p: "YzUzNV9w",
  },
  {
    school_code: "30045",
    name: "Palm Beach Atl.",
    division: 2,
    org_id: "30045",
    mascot: "Sailfish",
    encoded_h: "YzMwMDQ1",
    encoded_c: "YzMwMDQ1X2M=",
    encoded_q: "YzMwMDQ1X3E=",
    encoded_p: "YzMwMDQ1X3A=",
  },
  {
    school_code: "542",
    name: "Pfeiffer",
    division: 2,
    org_id: "542",
    mascot: "Falcons",
    encoded_h: "YzU0Mg==",
    encoded_c: "YzU0Ml9j",
    encoded_q: "YzU0Ml9x",
    encoded_p: "YzU0Ml9w",
  },
  {
    school_code: "543",
    name: "Jefferson",
    division: 2,
    org_id: "543",
    mascot: "Rams",
    encoded_h: "YzU0Mw==",
    encoded_c: "YzU0M19j",
    encoded_q: "YzU0M19x",
    encoded_p: "YzU0M19w",
  },
  {
    school_code: "546",
    name: "Pitt.-Johnstown",
    division: 2,
    org_id: "546",
    mascot: "Mountain Cats",
    encoded_h: "YzU0Ng==",
    encoded_c: "YzU0Nl9j",
    encoded_q: "YzU0Nl9x",
    encoded_p: "YzU0Nl9w",
  },
  {
    school_code: "1314",
    name: "Pittsburg St.",
    division: 2,
    org_id: "1314",
    mascot: "Gorillas",
    encoded_h: "YzEzMTQ=",
    encoded_c: "YzEzMTRfYw==",
    encoded_q: "YzEzMTRfcQ==",
    encoded_p: "YzEzMTRfcA==",
  },
  {
    school_code: "30149",
    name: "Point Loma",
    division: 2,
    org_id: "30149",
    mascot: "Sea Lions",
    encoded_h: "YzMwMTQ5",
    encoded_c: "YzMwMTQ5X2M=",
    encoded_q: "YzMwMTQ5X3E=",
    encoded_p: "YzMwMTQ5X3A=",
  },
  {
    school_code: "1318",
    name: "Post",
    division: 2,
    org_id: "1318",
    mascot: "Eagles",
    encoded_h: "YzEzMTg=",
    encoded_c: "YzEzMThfYw==",
    encoded_q: "YzEzMThfcQ==",
    encoded_p: "YzEzMThfcA==",
  },
  {
    school_code: "560",
    name: "Queens (NY)",
    division: 2,
    org_id: "560",
    mascot: "Knights",
    encoded_h: "YzU2MA==",
    encoded_c: "YzU2MF9j",
    encoded_q: "YzU2MF9x",
    encoded_p: "YzU2MF9w",
  },
  {
    school_code: "11504",
    name: "Queens (NC)",
    division: 2,
    org_id: "11504",
    mascot: "Royals",
    encoded_h: "YzExNTA0",
    encoded_c: "YzExNTA0X2M=",
    encoded_q: "YzExNTA0X3E=",
    encoded_p: "YzExNTA0X3A=",
  },
  {
    school_code: "561",
    name: "Quincy",
    division: 2,
    org_id: "561",
    mascot: "Hawks",
    encoded_h: "YzU2MQ==",
    encoded_c: "YzU2MV9j",
    encoded_q: "YzU2MV9x",
    encoded_p: "YzU2MV9w",
  },
  {
    school_code: "568",
    name: "Regis (CO)",
    division: 2,
    org_id: "568",
    mascot: "Rangers",
    encoded_h: "YzU2OA==",
    encoded_c: "YzU2OF9j",
    encoded_q: "YzU2OF9x",
    encoded_p: "YzU2OF9w",
  },
  {
    school_code: "1328",
    name: "Rockhurst",
    division: 2,
    org_id: "1328",
    mascot: "Hawks",
    encoded_h: "YzEzMjg=",
    encoded_c: "YzEzMjhfYw==",
    encoded_q: "YzEzMjhfcQ==",
    encoded_p: "YzEzMjhfcA==",
  },
  {
    school_code: "584",
    name: "Rollins",
    division: 2,
    org_id: "584",
    mascot: "Tars",
    encoded_h: "YzU4NA==",
    encoded_c: "YzU4NF9j",
    encoded_q: "YzU4NF9x",
    encoded_p: "YzU4NF9w",
  },
  {
    school_code: "11276",
    name: "S.C. Aiken",
    division: 2,
    org_id: "11276",
    mascot: "Pacers",
    encoded_h: "YzExMjc2",
    encoded_c: "YzExMjc2X2M=",
    encoded_q: "YzExMjc2X3E=",
    encoded_p: "YzExMjc2X3A=",
  },
  {
    school_code: "591",
    name: "Saginaw Valley",
    division: 2,
    org_id: "591",
    mascot: "Cardinals",
    encoded_h: "YzU5MQ==",
    encoded_c: "YzU5MV9j",
    encoded_q: "YzU5MV9x",
    encoded_p: "YzU5MV9w",
  },
  {
    school_code: "608",
    name: "Saint Leo",
    division: 2,
    org_id: "608",
    mascot: "Lions",
    encoded_h: "YzYwOA==",
    encoded_c: "YzYwOF9j",
    encoded_q: "YzYwOF9x",
    encoded_p: "YzYwOF9w",
  },
  {
    school_code: "1343",
    name: "Saint Martin's",
    division: 2,
    org_id: "1343",
    mascot: "Saints",
    encoded_h: "YzEzNDM=",
    encoded_c: "YzEzNDNfYw==",
    encoded_q: "YzEzNDNfcQ==",
    encoded_p: "YzEzNDNfcA==",
  },
  {
    school_code: "613",
    name: "Saint Michael's",
    division: 2,
    org_id: "613",
    mascot: "Purple Knights",
    encoded_h: "YzYxMw==",
    encoded_c: "YzYxM19j",
    encoded_q: "YzYxM19x",
    encoded_p: "YzYxM19w",
  },
  {
    school_code: "1347",
    name: "Saint Rose",
    division: 2,
    org_id: "1347",
    mascot: "Golden Knights",
    encoded_h: "YzEzNDc=",
    encoded_c: "YzEzNDdfYw==",
    encoded_q: "YzEzNDdfcQ==",
    encoded_p: "YzEzNDdfcA==",
  },
  {
    school_code: "19117",
    name: "Salem Int'l",
    division: 2,
    org_id: "19117",
    mascot: "Tigers",
    encoded_h: "YzE5MTE3",
    encoded_c: "YzE5MTE3X2M=",
    encoded_q: "YzE5MTE3X3E=",
    encoded_p: "YzE5MTE3X3A=",
  },
  {
    school_code: "628",
    name: "San Fran. St.",
    division: 2,
    org_id: "628",
    mascot: "Gators",
    encoded_h: "YzYyOA==",
    encoded_c: "YzYyOF9j",
    encoded_q: "YzYyOF9x",
    encoded_p: "YzYyOF9w",
  },
  {
    school_code: "30063",
    name: "Seton Hill",
    division: 2,
    org_id: "30063",
    mascot: "Griffins",
    encoded_h: "YzMwMDYz",
    encoded_c: "YzMwMDYzX2M=",
    encoded_q: "YzMwMDYzX3E=",
    encoded_p: "YzMwMDYzX3A=",
  },
  {
    school_code: "636",
    name: "Shaw",
    division: 2,
    org_id: "636",
    mascot: "Bears",
    encoded_h: "YzYzNg==",
    encoded_c: "YzYzNl9j",
    encoded_q: "YzYzNl9x",
    encoded_p: "YzYzNl9w",
  },
  {
    school_code: "10870",
    name: "Shepherd",
    division: 2,
    org_id: "10870",
    mascot: "Rams",
    encoded_h: "YzEwODcw",
    encoded_c: "YzEwODcwX2M=",
    encoded_q: "YzEwODcwX3E=",
    encoded_p: "YzEwODcwX3A=",
  },
  {
    school_code: "638",
    name: "Shippensburg",
    division: 2,
    org_id: "638",
    mascot: "Raiders",
    encoded_h: "YzYzOA==",
    encoded_c: "YzYzOF9j",
    encoded_q: "YzYzOF9x",
    encoded_p: "YzYzOF9w",
  },
  {
    school_code: "30151",
    name: "Shorter",
    division: 2,
    org_id: "30151",
    mascot: "Hawks",
    encoded_h: "YzMwMTUx",
    encoded_c: "YzMwMTUxX2M=",
    encoded_q: "YzMwMTUxX3E=",
    encoded_p: "YzMwMTUxX3A=",
  },
  {
    school_code: "30128",
    name: "Sioux Falls",
    division: 2,
    org_id: "30128",
    mascot: "Cougars",
    encoded_h: "YzMwMTI4",
    encoded_c: "YzMwMTI4X2M=",
    encoded_q: "YzMwMTI4X3E=",
    encoded_p: "YzMwMTI4X3A=",
  },
  {
    school_code: "643",
    name: "Slippery Rock",
    division: 2,
    org_id: "643",
    mascot: "The Rock",
    encoded_h: "YzY0Mw==",
    encoded_c: "YzY0M19j",
    encoded_q: "YzY0M19x",
    encoded_p: "YzY0M19w",
  },
  {
    school_code: "645",
    name: "Sonoma St.",
    division: 2,
    org_id: "645",
    mascot: "Seawolves",
    encoded_h: "YzY0NQ==",
    encoded_c: "YzY0NV9j",
    encoded_q: "YzY0NV9x",
    encoded_p: "YzY0NV9w",
  },
  {
    school_code: "1371",
    name: "Southeastern Okla.",
    division: 2,
    org_id: "1371",
    mascot: "Savage Storm",
    encoded_h: "YzEzNzE=",
    encoded_c: "YzEzNzFfYw==",
    encoded_q: "YzEzNzFfcQ==",
    encoded_p: "YzEzNzFfcA==",
  },
  {
    school_code: "1376",
    name: "Southern Ark.",
    division: 2,
    org_id: "1376",
    mascot: "Muleriders",
    encoded_h: "YzEzNzY=",
    encoded_c: "YzEzNzZfYw==",
    encoded_q: "YzEzNzZfcQ==",
    encoded_p: "YzEzNzZfcA==",
  },
  {
    school_code: "658",
    name: "Southern Conn. St.",
    division: 2,
    org_id: "658",
    mascot: "Owls",
    encoded_h: "YzY1OA==",
    encoded_c: "YzY1OF9j",
    encoded_q: "YzY1OF9x",
    encoded_p: "YzY1OF9w",
  },
  {
    school_code: "661",
    name: "Southern Ind.",
    division: 2,
    org_id: "661",
    mascot: "Screaming Eagles",
    encoded_h: "YzY2MQ==",
    encoded_c: "YzY2MV9j",
    encoded_q: "YzY2MV9x",
    encoded_p: "YzY2MV9w",
  },
  {
    school_code: "468",
    name: "Southern N.H.",
    division: 2,
    org_id: "468",
    mascot: "Penmen",
    encoded_h: "YzQ2OA==",
    encoded_c: "YzQ2OF9j",
    encoded_q: "YzQ2OF9x",
    encoded_p: "YzQ2OF9w",
  },
  {
    school_code: "30152",
    name: "Southern Nazarene",
    division: 2,
    org_id: "30152",
    mascot: "Crimson Storm",
    encoded_h: "YzMwMTUy",
    encoded_c: "YzMwMTUyX2M=",
    encoded_q: "YzMwMTUyX3E=",
    encoded_p: "YzMwMTUyX3A=",
  },
  {
    school_code: "2755",
    name: "Southwest Baptist",
    division: 2,
    org_id: "2755",
    mascot: "Bearcats",
    encoded_h: "YzI3NTU=",
    encoded_c: "YzI3NTVfYw==",
    encoded_q: "YzI3NTVfcQ==",
    encoded_p: "YzI3NTVfcA==",
  },
  {
    school_code: "8736",
    name: "Southwest Minn. St.",
    division: 2,
    org_id: "8736",
    mascot: "Mustangs",
    encoded_h: "Yzg3MzY=",
    encoded_c: "Yzg3MzZfYw==",
    encoded_q: "Yzg3MzZfcQ==",
    encoded_p: "Yzg3MzZfcA==",
  },
  {
    school_code: "8744",
    name: "Southwestern Okla.",
    division: 2,
    org_id: "8744",
    mascot: "Bulldogs",
    encoded_h: "Yzg3NDQ=",
    encoded_c: "Yzg3NDRfYw==",
    encoded_q: "Yzg3NDRfcQ==",
    encoded_p: "Yzg3NDRfcA==",
  },
  {
    school_code: "30191",
    name: "Spring Hill",
    division: 2,
    org_id: "30191",
    mascot: "Badgers",
    encoded_h: "YzMwMTkx",
    encoded_c: "YzMwMTkxX2M=",
    encoded_q: "YzMwMTkxX3E=",
    encoded_p: "YzMwMTkxX3A=",
  },
  {
    school_code: "30192",
    name: "Southern Wesleyan",
    division: 2,
    org_id: "30192",
    mascot: "Warriors",
    encoded_h: "YzMwMTky",
    encoded_c: "YzMwMTkyX2M=",
    encoded_q: "YzMwMTkyX3E=",
    encoded_p: "YzMwMTkyX3A=",
  },
  {
    school_code: "593",
    name: "St. Anselm",
    division: 2,
    org_id: "593",
    mascot: "Hawks",
    encoded_h: "YzU5Mw==",
    encoded_c: "YzU5M19j",
    encoded_q: "YzU5M19x",
    encoded_p: "YzU5M19w",
  },
  {
    school_code: "594",
    name: "St. Augustine's",
    division: 2,
    org_id: "594",
    mascot: "Falcons",
    encoded_h: "YzU5NA==",
    encoded_c: "YzU5NF9j",
    encoded_q: "YzU5NF9x",
    encoded_p: "YzU5NF9w",
  },
  {
    school_code: "598",
    name: "St. Cloud St.",
    division: 2,
    org_id: "598",
    mascot: "Huskies",
    encoded_h: "YzU5OA==",
    encoded_c: "YzU5OF9j",
    encoded_q: "YzU5OF9x",
    encoded_p: "YzU5OF9w",
  },
  {
    school_code: "1334",
    name: "St. Edward's",
    division: 2,
    org_id: "1334",
    mascot: "Hilltoppers",
    encoded_h: "YzEzMzQ=",
    encoded_c: "YzEzMzRfYw==",
    encoded_q: "YzEzMzRfcQ==",
    encoded_p: "YzEzMzRfcA==",
  },
  {
    school_code: "604",
    name: "St. Joseph's (IN)",
    division: 2,
    org_id: "604",
    mascot: "Pumas",
    encoded_h: "YzYwNA==",
    encoded_c: "YzYwNF9j",
    encoded_q: "YzYwNF9x",
    encoded_p: "YzYwNF9w",
  },
  {
    school_code: "1346",
    name: "St. Mary's (TX)",
    division: 2,
    org_id: "1346",
    mascot: "Rattlers",
    encoded_h: "YzEzNDY=",
    encoded_c: "YzEzNDZfYw==",
    encoded_q: "YzEzNDZfcQ==",
    encoded_p: "YzEzNDZfcA==",
  },
  {
    school_code: "1348",
    name: "St. Thomas Aquinas",
    division: 2,
    org_id: "1348",
    mascot: "Spartans",
    encoded_h: "YzEzNDg=",
    encoded_c: "YzEzNDhfYw==",
    encoded_q: "YzEzNDhfcQ==",
    encoded_p: "YzEzNDhfcA==",
  },
  {
    school_code: "680",
    name: "Stillman",
    division: 2,
    org_id: "680",
    mascot: "Tigers",
    encoded_h: "YzY4MA==",
    encoded_c: "YzY4MF9j",
    encoded_q: "YzY4MF9x",
    encoded_p: "YzY4MF9w",
  },
  {
    school_code: "682",
    name: "Stonehill",
    division: 2,
    org_id: "682",
    mascot: "Skyhawks",
    encoded_h: "YzY4Mg==",
    encoded_c: "YzY4Ml9j",
    encoded_q: "YzY4Ml9x",
    encoded_p: "YzY4Ml9w",
  },
  {
    school_code: "689",
    name: "Tampa",
    division: 2,
    org_id: "689",
    mascot: "Spartans",
    encoded_h: "YzY4OQ==",
    encoded_c: "YzY4OV9j",
    encoded_q: "YzY4OV9x",
    encoded_p: "YzY4OV9w",
  },
  {
    school_code: "1395",
    name: "Tarleton St.",
    division: 2,
    org_id: "1395",
    mascot: "Texans",
    encoded_h: "YzEzOTU=",
    encoded_c: "YzEzOTVfYw==",
    encoded_q: "YzEzOTVfcQ==",
    encoded_p: "YzEzOTVfcA==",
  },
  {
    school_code: "30090",
    name: "Tex. A&M Int'l",
    division: 2,
    org_id: "30090",
    mascot: "Dustdevils",
    encoded_h: "YzMwMDkw",
    encoded_c: "YzMwMDkwX2M=",
    encoded_q: "YzMwMDkwX3E=",
    encoded_p: "YzMwMDkwX3A=",
  },
  {
    school_code: "696",
    name: "Tex. A&M-Kingsville",
    division: 2,
    org_id: "696",
    mascot: "Javelinas",
    encoded_h: "YzY5Ng==",
    encoded_c: "YzY5Nl9j",
    encoded_q: "YzY5Nl9x",
    encoded_p: "YzY5Nl9w",
  },
  {
    school_code: "30088",
    name: "Tex. Permian Basin",
    division: 2,
    org_id: "30088",
    mascot: "Falcons",
    encoded_h: "YzMwMDg4",
    encoded_c: "YzMwMDg4X2M=",
    encoded_q: "YzMwMDg4X3E=",
    encoded_p: "YzMwMDg4X3A=",
  },
  {
    school_code: "1403",
    name: "Tiffin",
    division: 2,
    org_id: "1403",
    mascot: "Dragons",
    encoded_h: "YzE0MDM=",
    encoded_c: "YzE0MDNfYw==",
    encoded_q: "YzE0MDNfcQ==",
    encoded_p: "YzE0MDNfcA==",
  },
  {
    school_code: "30002",
    name: "Trevecca Nazarene",
    division: 2,
    org_id: "30002",
    mascot: "Trojans",
    encoded_h: "YzMwMDAy",
    encoded_c: "YzMwMDAyX2M=",
    encoded_q: "YzMwMDAyX3E=",
    encoded_p: "YzMwMDAyX3A=",
  },
  {
    school_code: "499",
    name: "Truman",
    division: 2,
    org_id: "499",
    mascot: "Bulldogs",
    encoded_h: "YzQ5OQ==",
    encoded_c: "YzQ5OV9j",
    encoded_q: "YzQ5OV9x",
    encoded_p: "YzQ5OV9w",
  },
  {
    school_code: "12830",
    name: "Tusculum",
    division: 2,
    org_id: "12830",
    mascot: "Pioneers",
    encoded_h: "YzEyODMw",
    encoded_c: "YzEyODMwX2M=",
    encoded_q: "YzEyODMwX3E=",
    encoded_p: "YzEyODMwX3A=",
  },
  {
    school_code: "720",
    name: "Tuskegee",
    division: 2,
    org_id: "720",
    mascot: "Golden Tigers",
    encoded_h: "YzcyMA==",
    encoded_c: "YzcyMF9j",
    encoded_q: "YzcyMF9x",
    encoded_p: "YzcyMF9w",
  },
  {
    school_code: "112",
    name: "UC San Diego",
    division: 2,
    org_id: "112",
    mascot: "Tritons",
    encoded_h: "YzExMg==",
    encoded_c: "YzExMl9j",
    encoded_q: "YzExMl9x",
    encoded_p: "YzExMl9w",
  },
  {
    school_code: "368",
    name: "UMass Lowell",
    division: 1,
    org_id: "368",
    mascot: "River Hawks",
    encoded_h: "YzM2OA==",
    encoded_c: "YzM2OF9j",
    encoded_q: "YzM2OF9x",
    encoded_p: "YzM2OF9w",
  },
  {
    school_code: "9754",
    name: "UC-Colo. Springs",
    division: 2,
    org_id: "9754",
    mascot: "Mountain Lions",
    encoded_h: "Yzk3NTQ=",
    encoded_c: "Yzk3NTRfYw==",
    encoded_q: "Yzk3NTRfcQ==",
    encoded_p: "Yzk3NTRfcA==",
  },
  {
    school_code: "537",
    name: "UNC Pembroke",
    division: 2,
    org_id: "537",
    mascot: "Braves",
    encoded_h: "YzUzNw==",
    encoded_c: "YzUzN19j",
    encoded_q: "YzUzN19x",
    encoded_p: "YzUzN19w",
  },
  {
    school_code: "28594",
    name: "USciences",
    division: 2,
    org_id: "28594",
    mascot: "Devils",
    encoded_h: "YzI4NTk0",
    encoded_c: "YzI4NTk0X2M=",
    encoded_q: "YzI4NTk0X3E=",
    encoded_p: "YzI4NTk0X3A=",
  },
  {
    school_code: "30259",
    name: "USC Beaufort",
    division: 2,
    org_id: "30259",
    mascot: "Sand Sharks",
    encoded_h: "YzMwMjU5",
    encoded_c: "YzMwMjU5X2M=",
    encoded_q: "YzMwMjU5X3E=",
    encoded_p: "YzMwMjU5X3A=",
  },
  {
    school_code: "30153",
    name: "Union (TN)",
    division: 2,
    org_id: "30153",
    mascot: "Bulldogs",
    encoded_h: "YzMwMTUz",
    encoded_c: "YzMwMTUzX2M=",
    encoded_q: "YzMwMTUzX3E=",
    encoded_p: "YzMwMTUzX3A=",
  },
  {
    school_code: "728",
    name: "Upper Iowa",
    division: 2,
    org_id: "728",
    mascot: "Peacocks",
    encoded_h: "YzcyOA==",
    encoded_c: "YzcyOF9j",
    encoded_q: "YzcyOF9x",
    encoded_p: "YzcyOF9w",
  },
  {
    school_code: "30104",
    name: "Urbana",
    division: 2,
    org_id: "30104",
    mascot: "Blue Knights",
    encoded_h: "YzMwMTA0",
    encoded_c: "YzMwMTA0X2M=",
    encoded_q: "YzMwMTA0X3E=",
    encoded_p: "YzMwMTA0X3A=",
  },
  {
    school_code: "734",
    name: "Valdosta St.",
    division: 2,
    org_id: "734",
    mascot: "Blazers",
    encoded_h: "YzczNA==",
    encoded_c: "YzczNF9j",
    encoded_q: "YzczNF9x",
    encoded_p: "YzczNF9w",
  },
  {
    school_code: "743",
    name: "Virginia St.",
    division: 2,
    org_id: "743",
    mascot: "Trojans",
    encoded_h: "Yzc0Mw==",
    encoded_c: "Yzc0M19j",
    encoded_q: "Yzc0M19x",
    encoded_p: "Yzc0M19w",
  },
  {
    school_code: "30141",
    name: "Walsh",
    division: 2,
    org_id: "30141",
    mascot: "Cavaliers",
    encoded_h: "YzMwMTQx",
    encoded_c: "YzMwMTQxX2M=",
    encoded_q: "YzMwMTQxX3E=",
    encoded_p: "YzMwMTQxX3A=",
  },
  {
    school_code: "2814",
    name: "Washburn",
    division: 2,
    org_id: "2814",
    mascot: "Ichabods",
    encoded_h: "YzI4MTQ=",
    encoded_c: "YzI4MTRfYw==",
    encoded_q: "YzI4MTRfcQ==",
    encoded_p: "YzI4MTRfcA==",
  },
  {
    school_code: "757",
    name: "Wayne St. (MI)",
    division: 2,
    org_id: "757",
    mascot: "Warriors",
    encoded_h: "Yzc1Nw==",
    encoded_c: "Yzc1N19j",
    encoded_q: "Yzc1N19x",
    encoded_p: "Yzc1N19w",
  },
  {
    school_code: "1433",
    name: "Wayne St. (NE)",
    division: 2,
    org_id: "1433",
    mascot: "Wildcats",
    encoded_h: "YzE0MzM=",
    encoded_c: "YzE0MzNfYw==",
    encoded_q: "YzE0MzNfcQ==",
    encoded_p: "YzE0MzNfcA==",
  },
  {
    school_code: "358",
    name: "West Ala.",
    division: 2,
    org_id: "358",
    mascot: "Tigers",
    encoded_h: "YzM1OA==",
    encoded_c: "YzM1OF9j",
    encoded_q: "YzM1OF9x",
    encoded_p: "YzM1OF9w",
  },
  {
    school_code: "765",
    name: "West Chester",
    division: 2,
    org_id: "765",
    mascot: "Golden Rams",
    encoded_h: "Yzc2NQ==",
    encoded_c: "Yzc2NV9j",
    encoded_q: "Yzc2NV9x",
    encoded_p: "Yzc2NV9w",
  },
  {
    school_code: "11740",
    name: "West Florida",
    division: 2,
    org_id: "11740",
    mascot: "Argonauts",
    encoded_h: "YzExNzQw",
    encoded_c: "YzExNzQwX2M=",
    encoded_q: "YzExNzQwX3E=",
    encoded_p: "YzExNzQwX3A=",
  },
  {
    school_code: "766",
    name: "West Ga.",
    division: 2,
    org_id: "766",
    mascot: "Wolves",
    encoded_h: "Yzc2Ng==",
    encoded_c: "Yzc2Nl9j",
    encoded_q: "Yzc2Nl9x",
    encoded_p: "Yzc2Nl9w",
  },
  {
    school_code: "1438",
    name: "West Liberty",
    division: 2,
    org_id: "1438",
    mascot: "Hilltoppers",
    encoded_h: "YzE0Mzg=",
    encoded_c: "YzE0MzhfYw==",
    encoded_q: "YzE0MzhfcQ==",
    encoded_p: "YzE0MzhfcA==",
  },
  {
    school_code: "767",
    name: "West Tex. A&M",
    division: 2,
    org_id: "767",
    mascot: "Buffaloes",
    encoded_h: "Yzc2Nw==",
    encoded_c: "Yzc2N19j",
    encoded_q: "Yzc2N19x",
    encoded_p: "Yzc2N19w",
  },
  {
    school_code: "9630",
    name: "West Va. Wesleyan",
    division: 2,
    org_id: "9630",
    mascot: "Bobcats",
    encoded_h: "Yzk2MzA=",
    encoded_c: "Yzk2MzBfYw==",
    encoded_q: "Yzk2MzBfcQ==",
    encoded_p: "Yzk2MzBfcA==",
  },
  {
    school_code: "1439",
    name: "West Virginia St.",
    division: 2,
    org_id: "1439",
    mascot: "Yellow Jackets",
    encoded_h: "YzE0Mzk=",
    encoded_c: "YzE0MzlfYw==",
    encoded_q: "YzE0MzlfcQ==",
    encoded_p: "YzE0MzlfcA==",
  },
  {
    school_code: "1446",
    name: "Western Ore.",
    division: 2,
    org_id: "1446",
    mascot: "Wolves",
    encoded_h: "YzE0NDY=",
    encoded_c: "YzE0NDZfYw==",
    encoded_q: "YzE0NDZfcQ==",
    encoded_p: "YzE0NDZfcA==",
  },
  {
    school_code: "12799",
    name: "Wheeling Jesuit",
    division: 2,
    org_id: "12799",
    mascot: "Cardinals",
    encoded_h: "YzEyNzk5",
    encoded_c: "YzEyNzk5X2M=",
    encoded_q: "YzEyNzk5X3E=",
    encoded_p: "YzEyNzk5X3A=",
  },
  {
    school_code: "30120",
    name: "William Jewell",
    division: 2,
    org_id: "30120",
    mascot: "Cardinals",
    encoded_h: "YzMwMTIw",
    encoded_c: "YzMwMTIwX2M=",
    encoded_q: "YzMwMTIwX3E=",
    encoded_p: "YzMwMTIwX3A=",
  },
  {
    school_code: "1460",
    name: "Wilmington (DE)",
    division: 2,
    org_id: "1460",
    mascot: "Wildcats",
    encoded_h: "YzE0NjA=",
    encoded_c: "YzE0NjBfYw==",
    encoded_q: "YzE0NjBfcQ==",
    encoded_p: "YzE0NjBfcA==",
  },
  {
    school_code: "1462",
    name: "Wingate",
    division: 2,
    org_id: "1462",
    mascot: "Bulldogs",
    encoded_h: "YzE0NjI=",
    encoded_c: "YzE0NjJfYw==",
    encoded_q: "YzE0NjJfcQ==",
    encoded_p: "YzE0NjJfcA==",
  },
  {
    school_code: "790",
    name: "Winona St.",
    division: 2,
    org_id: "790",
    mascot: "Warriors",
    encoded_h: "Yzc5MA==",
    encoded_c: "Yzc5MF9j",
    encoded_q: "Yzc5MF9x",
    encoded_p: "Yzc5MF9w",
  },
  {
    school_code: "791",
    name: "Winston-Salem",
    division: 2,
    org_id: "791",
    mascot: "Rams",
    encoded_h: "Yzc5MQ==",
    encoded_c: "Yzc5MV9j",
    encoded_q: "Yzc5MV9x",
    encoded_p: "Yzc5MV9w",
  },
  {
    school_code: "799",
    name: "Wis.-Parkside",
    division: 2,
    org_id: "799",
    mascot: "Rangers",
    encoded_h: "Yzc5OQ==",
    encoded_c: "Yzc5OV9j",
    encoded_q: "Yzc5OV9x",
    encoded_p: "Yzc5OV9w",
  },
  {
    school_code: "30154",
    name: "Young Harris",
    division: 2,
    org_id: "30154",
    mascot: "Mountain Lions",
    encoded_h: "YzMwMTU0",
    encoded_c: "YzMwMTU0X2M=",
    encoded_q: "YzMwMTU0X3E=",
    encoded_p: "YzMwMTU0X3A=",
  },
  {
    school_code: "4",
    name: "Adrian",
    division: 3,
    org_id: "4",
    mascot: "Bulldogs",
    encoded_h: "YzQ=",
    encoded_c: "YzRfYw==",
    encoded_q: "YzRfcQ==",
    encoded_p: "YzRfcA==",
  },
  {
    school_code: "2798",
    name: "Albertus Magnus",
    division: 3,
    org_id: "2798",
    mascot: "Falcons",
    encoded_h: "YzI3OTg=",
    encoded_c: "YzI3OThfYw==",
    encoded_q: "YzI3OThfcQ==",
    encoded_p: "YzI3OThfcA==",
  },
  {
    school_code: "15",
    name: "Albion",
    division: 3,
    org_id: "15",
    mascot: "Britons",
    encoded_h: "YzE1",
    encoded_c: "YzE1X2M=",
    encoded_q: "YzE1X3E=",
    encoded_p: "YzE1X3A=",
  },
  {
    school_code: "16",
    name: "Albright",
    division: 3,
    org_id: "16",
    mascot: "Lions",
    encoded_h: "YzE2",
    encoded_c: "YzE2X2M=",
    encoded_q: "YzE2X3E=",
    encoded_p: "YzE2X3A=",
  },
  {
    school_code: "30170",
    name: "Alfred St",
    division: 3,
    org_id: "30170",
    mascot: "Pioneers",
    encoded_h: "YzMwMTcw",
    encoded_c: "YzMwMTcwX2M=",
    encoded_q: "YzMwMTcwX3E=",
    encoded_p: "YzMwMTcwX3A=",
  },
  {
    school_code: "19",
    name: "Allegheny",
    division: 3,
    org_id: "19",
    mascot: "Gators",
    encoded_h: "YzE5",
    encoded_c: "YzE5X2M=",
    encoded_q: "YzE5X3E=",
    encoded_p: "YzE5X3A=",
  },
  {
    school_code: "21",
    name: "Alma",
    division: 3,
    org_id: "21",
    mascot: "Scots",
    encoded_h: "YzIx",
    encoded_c: "YzIxX2M=",
    encoded_q: "YzIxX3E=",
    encoded_p: "YzIxX3A=",
  },
  {
    school_code: "938",
    name: "Alvernia",
    division: 3,
    org_id: "938",
    mascot: "Crusaders",
    encoded_h: "YzkzOA==",
    encoded_c: "YzkzOF9j",
    encoded_q: "YzkzOF9x",
    encoded_p: "YzkzOF9w",
  },
  {
    school_code: "24",
    name: "Amherst",
    division: 3,
    org_id: "24",
    mascot: "Lord Jeffs",
    encoded_h: "YzI0",
    encoded_c: "YzI0X2M=",
    encoded_q: "YzI0X3E=",
    encoded_p: "YzI0X3A=",
  },
  {
    school_code: "939",
    name: "Anderson (IN)",
    division: 3,
    org_id: "939",
    mascot: "Ravens",
    encoded_h: "YzkzOQ==",
    encoded_c: "YzkzOV9j",
    encoded_q: "YzkzOV9x",
    encoded_p: "YzkzOV9w",
  },
  {
    school_code: "26",
    name: "Anna Maria",
    division: 3,
    org_id: "26",
    mascot: "AMCATS",
    encoded_h: "YzI2",
    encoded_c: "YzI2X2M=",
    encoded_q: "YzI2X3E=",
    encoded_p: "YzI2X3A=",
  },
  {
    school_code: "966",
    name: "Arcadia",
    division: 3,
    org_id: "966",
    mascot: "Knights",
    encoded_h: "Yzk2Ng==",
    encoded_c: "Yzk2Nl9j",
    encoded_q: "Yzk2Nl9x",
    encoded_p: "Yzk2Nl9w",
  },
  {
    school_code: "30243",
    name: "Asbury",
    division: 3,
    org_id: "30243",
    mascot: "Eagles",
    encoded_h: "YzMwMjQz",
    encoded_c: "YzMwMjQzX2M=",
    encoded_q: "YzMwMjQzX3E=",
    encoded_p: "YzMwMjQzX3A=",
  },
  {
    school_code: "38",
    name: "Augsburg",
    division: 3,
    org_id: "38",
    mascot: "Auggies",
    encoded_h: "YzM4",
    encoded_c: "YzM4X2M=",
    encoded_q: "YzM4X3E=",
    encoded_p: "YzM4X3A=",
  },
  {
    school_code: "40",
    name: "Augustana (IL)",
    division: 3,
    org_id: "40",
    mascot: "Vikings",
    encoded_h: "YzQw",
    encoded_c: "YzQwX2M=",
    encoded_q: "YzQwX3E=",
    encoded_p: "YzQwX3A=",
  },
  {
    school_code: "42",
    name: "Aurora",
    division: 3,
    org_id: "42",
    mascot: "Spartans",
    encoded_h: "YzQy",
    encoded_c: "YzQyX2M=",
    encoded_q: "YzQyX3E=",
    encoded_p: "YzQyX3A=",
  },
  {
    school_code: "953",
    name: "Austin",
    division: 3,
    org_id: "953",
    mascot: "Kangaroos",
    encoded_h: "Yzk1Mw==",
    encoded_c: "Yzk1M19j",
    encoded_q: "Yzk1M19x",
    encoded_p: "Yzk1M19w",
  },
  {
    school_code: "44",
    name: "Averett",
    division: 3,
    org_id: "44",
    mascot: "Cougars",
    encoded_h: "YzQ0",
    encoded_c: "YzQ0X2M=",
    encoded_q: "YzQ0X3E=",
    encoded_p: "YzQ0X3A=",
  },
  {
    school_code: "45",
    name: "Babson",
    division: 3,
    org_id: "45",
    mascot: "Beavers",
    encoded_h: "YzQ1",
    encoded_c: "YzQ1X2M=",
    encoded_q: "YzQ1X3E=",
    encoded_p: "YzQ1X3A=",
  },
  {
    school_code: "46",
    name: "Baldwin Wallace",
    division: 3,
    org_id: "46",
    mascot: "Yellow Jackets",
    encoded_h: "YzQ2",
    encoded_c: "YzQ2X2M=",
    encoded_q: "YzQ2X3E=",
    encoded_p: "YzQ2X3A=",
  },
  {
    school_code: "958",
    name: "Baptist Bible (PA)",
    division: 3,
    org_id: "958",
    mascot: "Defenders",
    encoded_h: "Yzk1OA==",
    encoded_c: "Yzk1OF9j",
    encoded_q: "Yzk1OF9x",
    encoded_p: "Yzk1OF9w",
  },
  {
    school_code: "962",
    name: "Bard",
    division: 3,
    org_id: "962",
    mascot: "Raptors",
    encoded_h: "Yzk2Mg==",
    encoded_c: "Yzk2Ml9j",
    encoded_q: "Yzk2Ml9x",
    encoded_p: "Yzk2Ml9w",
  },
  {
    school_code: "58",
    name: "Baruch",
    division: 3,
    org_id: "58",
    mascot: "Bearcats",
    encoded_h: "YzU4",
    encoded_c: "YzU4X2M=",
    encoded_q: "YzU4X3E=",
    encoded_p: "YzU4X3A=",
  },
  {
    school_code: "50",
    name: "Bates",
    division: 3,
    org_id: "50",
    mascot: "Bobcats",
    encoded_h: "YzUw",
    encoded_c: "YzUwX2M=",
    encoded_q: "YzUwX3E=",
    encoded_p: "YzUwX3A=",
  },
  {
    school_code: "26107",
    name: "Becker",
    division: 3,
    org_id: "26107",
    mascot: "Hawks",
    encoded_h: "YzI2MTA3",
    encoded_c: "YzI2MTA3X2M=",
    encoded_q: "YzI2MTA3X3E=",
    encoded_p: "YzI2MTA3X3A=",
  },
  {
    school_code: "30197",
    name: "Belhaven",
    division: 3,
    org_id: "30197",
    mascot: "Blazers",
    encoded_h: "YzMwMTk3",
    encoded_c: "YzMwMTk3X2M=",
    encoded_q: "YzMwMTk3X3E=",
    encoded_p: "YzMwMTk3X3A=",
  },
  {
    school_code: "53",
    name: "Beloit",
    division: 3,
    org_id: "53",
    mascot: "Buccaneers",
    encoded_h: "YzUz",
    encoded_c: "YzUzX2M=",
    encoded_q: "YzUzX3E=",
    encoded_p: "YzUzX3A=",
  },
  {
    school_code: "296",
    name: "Benedictine (IL)",
    division: 3,
    org_id: "296",
    mascot: "Eagles",
    encoded_h: "YzI5Ng==",
    encoded_c: "YzI5Nl9j",
    encoded_q: "YzI5Nl9x",
    encoded_p: "YzI5Nl9w",
  },
  {
    school_code: "30171",
    name: "Berea",
    division: 3,
    org_id: "30171",
    mascot: "Mountaineers",
    encoded_h: "YzMwMTcx",
    encoded_c: "YzMwMTcxX2M=",
    encoded_q: "YzMwMTcxX3E=",
    encoded_p: "YzMwMTcxX3A=",
  },
  {
    school_code: "30173",
    name: "Iowa Wesleyan",
    division: 3,
    org_id: "30173",
    mascot: "Tigers",
    encoded_h: "YzMwMTcz",
    encoded_c: "YzMwMTczX2M=",
    encoded_q: "YzMwMTczX3E=",
    encoded_p: "YzMwMTczX3A=",
  },
  {
    school_code: "973",
    name: "Berry",
    division: 3,
    org_id: "973",
    mascot: "Vikings",
    encoded_h: "Yzk3Mw==",
    encoded_c: "Yzk3M19j",
    encoded_q: "Yzk3M19x",
    encoded_p: "Yzk3M19w",
  },
  {
    school_code: "59",
    name: "Bethany (WV)",
    division: 3,
    org_id: "59",
    mascot: "Bison",
    encoded_h: "YzU5",
    encoded_c: "YzU5X2M=",
    encoded_q: "YzU5X3E=",
    encoded_p: "YzU5X3A=",
  },
  {
    school_code: "30038",
    name: "Bethany Lutheran",
    division: 3,
    org_id: "30038",
    mascot: "Vikings",
    encoded_h: "YzMwMDM4",
    encoded_c: "YzMwMDM4X2M=",
    encoded_q: "YzMwMDM4X3E=",
    encoded_p: "YzMwMDM4X3A=",
  },
  {
    school_code: "60",
    name: "Bethel (MN)",
    division: 3,
    org_id: "60",
    mascot: "Royals",
    encoded_h: "YzYw",
    encoded_c: "YzYwX2M=",
    encoded_q: "YzYwX3E=",
    encoded_p: "YzYwX3A=",
  },
  {
    school_code: "28593",
    name: "Birmingham-So.",
    division: 3,
    org_id: "28593",
    mascot: "Panthers",
    encoded_h: "YzI4NTkz",
    encoded_c: "YzI4NTkzX2M=",
    encoded_q: "YzI4NTkzX3E=",
    encoded_p: "YzI4NTkzX3A=",
  },
  {
    school_code: "64",
    name: "Blackburn",
    division: 3,
    org_id: "64",
    mascot: "Battlin' Beavers",
    encoded_h: "YzY0",
    encoded_c: "YzY0X2M=",
    encoded_q: "YzY0X3E=",
    encoded_p: "YzY0X3A=",
  },
  {
    school_code: "987",
    name: "Bluffton",
    division: 3,
    org_id: "987",
    mascot: "Beavers",
    encoded_h: "Yzk4Nw==",
    encoded_c: "Yzk4N19j",
    encoded_q: "Yzk4N19x",
    encoded_p: "Yzk4N19w",
  },
  {
    school_code: "69",
    name: "Bowdoin",
    division: 3,
    org_id: "69",
    mascot: "Polar Bears",
    encoded_h: "YzY5",
    encoded_c: "YzY5X2M=",
    encoded_q: "YzY5X3E=",
    encoded_p: "YzY5X3A=",
  },
  {
    school_code: "73",
    name: "Brandeis",
    division: 3,
    org_id: "73",
    mascot: "Judges",
    encoded_h: "Yzcz",
    encoded_c: "YzczX2M=",
    encoded_q: "YzczX3E=",
    encoded_p: "YzczX3A=",
  },
  {
    school_code: "75",
    name: "Bridgewater (VA)",
    division: 3,
    org_id: "75",
    mascot: "Eagles",
    encoded_h: "Yzc1",
    encoded_c: "Yzc1X2M=",
    encoded_q: "Yzc1X3E=",
    encoded_p: "Yzc1X3A=",
  },
  {
    school_code: "76",
    name: "Bridgewater St.",
    division: 3,
    org_id: "76",
    mascot: "Bears",
    encoded_h: "Yzc2",
    encoded_c: "Yzc2X2M=",
    encoded_q: "Yzc2X3E=",
    encoded_p: "Yzc2X3A=",
  },
  {
    school_code: "78",
    name: "Brockport",
    division: 3,
    org_id: "78",
    mascot: "Golden Eagles",
    encoded_h: "Yzc4",
    encoded_c: "Yzc4X2M=",
    encoded_q: "Yzc4X3E=",
    encoded_p: "Yzc4X3A=",
  },
  {
    school_code: "84",
    name: "Buena Vista",
    division: 3,
    org_id: "84",
    mascot: "Beavers",
    encoded_h: "Yzg0",
    encoded_c: "Yzg0X2M=",
    encoded_q: "Yzg0X3E=",
    encoded_p: "Yzg0X3A=",
  },
  {
    school_code: "88",
    name: "Cabrini",
    division: 3,
    org_id: "88",
    mascot: "Cavaliers",
    encoded_h: "Yzg4",
    encoded_c: "Yzg4X2M=",
    encoded_q: "Yzg4X3E=",
    encoded_p: "Yzg4X3A=",
  },
  {
    school_code: "480",
    name: "CCNY",
    division: 3,
    org_id: "480",
    mascot: "Beavers",
    encoded_h: "YzQ4MA==",
    encoded_c: "YzQ4MF9j",
    encoded_q: "YzQ4MF9x",
    encoded_p: "YzQ4MF9w",
  },
  {
    school_code: "1305",
    name: "Cairn",
    division: 3,
    org_id: "1305",
    mascot: "Highlanders",
    encoded_h: "YzEzMDU=",
    encoded_c: "YzEzMDVfYw==",
    encoded_q: "YzEzMDVfcQ==",
    encoded_p: "YzEzMDVfcA==",
  },
  {
    school_code: "105",
    name: "Cal Lutheran",
    division: 3,
    org_id: "105",
    mascot: "Kingsmen",
    encoded_h: "YzEwNQ==",
    encoded_c: "YzEwNV9j",
    encoded_q: "YzEwNV9x",
    encoded_p: "YzEwNV9w",
  },
  {
    school_code: "89",
    name: "Caltech",
    division: 3,
    org_id: "89",
    mascot: "Beavers",
    encoded_h: "Yzg5",
    encoded_c: "Yzg5X2M=",
    encoded_q: "Yzg5X3E=",
    encoded_p: "Yzg5X3A=",
  },
  {
    school_code: "114",
    name: "Calvin",
    division: 3,
    org_id: "114",
    mascot: "Knights",
    encoded_h: "YzExNA==",
    encoded_c: "YzExNF9j",
    encoded_q: "YzExNF9x",
    encoded_p: "YzExNF9w",
  },
  {
    school_code: "117",
    name: "Capital",
    division: 3,
    org_id: "117",
    mascot: "Crusaders",
    encoded_h: "YzExNw==",
    encoded_c: "YzExN19j",
    encoded_q: "YzExN19x",
    encoded_p: "YzExN19w",
  },
  {
    school_code: "118",
    name: "Carleton",
    division: 3,
    org_id: "118",
    mascot: "Knights",
    encoded_h: "YzExOA==",
    encoded_c: "YzExOF9j",
    encoded_q: "YzExOF9x",
    encoded_p: "YzExOF9w",
  },
  {
    school_code: "120",
    name: "Carroll (WI)",
    division: 3,
    org_id: "120",
    mascot: "Pioneers",
    encoded_h: "YzEyMA==",
    encoded_c: "YzEyMF9j",
    encoded_q: "YzEyMF9x",
    encoded_p: "YzEyMF9w",
  },
  {
    school_code: "121",
    name: "Carthage",
    division: 3,
    org_id: "121",
    mascot: "Red Men",
    encoded_h: "YzEyMQ==",
    encoded_c: "YzEyMV9j",
    encoded_q: "YzEyMV9x",
    encoded_p: "YzEyMV9w",
  },
  {
    school_code: "122",
    name: "Case",
    division: 3,
    org_id: "122",
    mascot: "Spartans",
    encoded_h: "YzEyMg==",
    encoded_c: "YzEyMl9j",
    encoded_q: "YzEyMl9x",
    encoded_p: "YzEyMl9w",
  },
  {
    school_code: "123",
    name: "Castleton",
    division: 3,
    org_id: "123",
    mascot: "Spartans",
    encoded_h: "YzEyMw==",
    encoded_c: "YzEyM19j",
    encoded_q: "YzEyM19x",
    encoded_p: "YzEyM19w",
  },
  {
    school_code: "124",
    name: "Catholic",
    division: 3,
    org_id: "124",
    mascot: "Cardinals",
    encoded_h: "YzEyNA==",
    encoded_c: "YzEyNF9j",
    encoded_q: "YzEyNF9x",
    encoded_p: "YzEyNF9w",
  },
  {
    school_code: "23624",
    name: "Cazenovia",
    division: 3,
    org_id: "23624",
    mascot: "Wildcats",
    encoded_h: "YzIzNjI0",
    encoded_c: "YzIzNjI0X2M=",
    encoded_q: "YzIzNjI0X3E=",
    encoded_p: "YzIzNjI0X3A=",
  },
  {
    school_code: "125",
    name: "Centenary (LA)",
    division: 3,
    org_id: "125",
    mascot: "Gents",
    encoded_h: "YzEyNQ==",
    encoded_c: "YzEyNV9j",
    encoded_q: "YzEyNV9x",
    encoded_p: "YzEyNV9w",
  },
  {
    school_code: "12884",
    name: "Centenary (NJ)",
    division: 3,
    org_id: "12884",
    mascot: "Cyclones",
    encoded_h: "YzEyODg0",
    encoded_c: "YzEyODg0X2M=",
    encoded_q: "YzEyODg0X3E=",
    encoded_p: "YzEyODg0X3A=",
  },
  {
    school_code: "126",
    name: "Central (IA)",
    division: 3,
    org_id: "126",
    mascot: "Dutch",
    encoded_h: "YzEyNg==",
    encoded_c: "YzEyNl9j",
    encoded_q: "YzEyNl9x",
    encoded_p: "YzEyNl9w",
  },
  {
    school_code: "132",
    name: "Centre",
    division: 3,
    org_id: "132",
    mascot: "Colonels",
    encoded_h: "YzEzMg==",
    encoded_c: "YzEzMl9j",
    encoded_q: "YzEzMl9x",
    encoded_p: "YzEzMl9w",
  },
  {
    school_code: "134",
    name: "Chapman",
    division: 3,
    org_id: "134",
    mascot: "Panthers",
    encoded_h: "YzEzNA==",
    encoded_c: "YzEzNF9j",
    encoded_q: "YzEzNF9x",
    encoded_p: "YzEzNF9w",
  },
  {
    school_code: "10972",
    name: "Chatham",
    division: 3,
    org_id: "10972",
    encoded_h: "YzEwOTcy",
    encoded_c: "YzEwOTcyX2M=",
    encoded_q: "YzEwOTcyX3E=",
    encoded_p: "YzEwOTcyX3A=",
  },
  {
    school_code: "137",
    name: "Chicago",
    division: 3,
    org_id: "137",
    mascot: "Maroons",
    encoded_h: "YzEzNw==",
    encoded_c: "YzEzN19j",
    encoded_q: "YzEzN19x",
    encoded_p: "YzEzN19w",
  },
  {
    school_code: "139",
    name: "Chris. Newport",
    division: 3,
    org_id: "139",
    mascot: "Captains",
    encoded_h: "YzEzOQ==",
    encoded_c: "YzEzOV9j",
    encoded_q: "YzEzOV9x",
    encoded_p: "YzEzOV9w",
  },
  {
    school_code: "142",
    name: "Claremont-M-S",
    division: 3,
    org_id: "142",
    mascot: "Stags",
    encoded_h: "YzE0Mg==",
    encoded_c: "YzE0Ml9j",
    encoded_q: "YzE0Ml9x",
    encoded_p: "YzE0Ml9w",
  },
  {
    school_code: "145",
    name: "Clark (MA)",
    division: 3,
    org_id: "145",
    mascot: "Cougars",
    encoded_h: "YzE0NQ==",
    encoded_c: "YzE0NV9j",
    encoded_q: "YzE0NV9x",
    encoded_p: "YzE0NV9w",
  },
  {
    school_code: "1020",
    name: "Clarke",
    division: 3,
    org_id: "1020",
    mascot: "Crusaders",
    encoded_h: "YzEwMjA=",
    encoded_c: "YzEwMjBfYw==",
    encoded_q: "YzEwMjBfcQ==",
    encoded_p: "YzEwMjBfcA==",
  },
  {
    school_code: "146",
    name: "Clarkson",
    division: 3,
    org_id: "146",
    mascot: "Golden Knights",
    encoded_h: "YzE0Ng==",
    encoded_c: "YzE0Nl9j",
    encoded_q: "YzE0Nl9x",
    encoded_p: "YzE0Nl9w",
  },
  {
    school_code: "722",
    name: "Coast Guard",
    division: 3,
    org_id: "722",
    mascot: "Bears",
    encoded_h: "YzcyMg==",
    encoded_c: "YzcyMl9j",
    encoded_q: "YzcyMl9x",
    encoded_p: "YzcyMl9w",
  },
  {
    school_code: "30083",
    name: "Cobleskill St.",
    division: 3,
    org_id: "30083",
    mascot: "Fighting Tigers",
    encoded_h: "YzMwMDgz",
    encoded_c: "YzMwMDgzX2M=",
    encoded_q: "YzMwMDgzX3E=",
    encoded_p: "YzMwMDgzX3A=",
  },
  {
    school_code: "150",
    name: "Coe",
    division: 3,
    org_id: "150",
    mascot: "Kohawks",
    encoded_h: "YzE1MA==",
    encoded_c: "YzE1MF9j",
    encoded_q: "YzE1MF9x",
    encoded_p: "YzE1MF9w",
  },
  {
    school_code: "151",
    name: "Colby",
    division: 3,
    org_id: "151",
    mascot: "Mules",
    encoded_h: "YzE1MQ==",
    encoded_c: "YzE1MV9j",
    encoded_q: "YzE1MV9x",
    encoded_p: "YzE1MV9w",
  },
  {
    school_code: "152",
    name: "Colby-Sawyer",
    division: 3,
    org_id: "152",
    mascot: "Chargers",
    encoded_h: "YzE1Mg==",
    encoded_c: "YzE1Ml9j",
    encoded_q: "YzE1Ml9x",
    encoded_p: "YzE1Ml9w",
  },
  {
    school_code: "24400",
    name: "Concordia (TX)",
    division: 3,
    org_id: "24400",
    mascot: "Tornados",
    encoded_h: "YzI0NDAw",
    encoded_c: "YzI0NDAwX2M=",
    encoded_q: "YzI0NDAwX3E=",
    encoded_p: "YzI0NDAwX3A=",
  },
  {
    school_code: "1036",
    name: "Concordia (WI)",
    division: 3,
    org_id: "1036",
    mascot: "Falcons",
    encoded_h: "YzEwMzY=",
    encoded_c: "YzEwMzZfYw==",
    encoded_q: "YzEwMzZfcQ==",
    encoded_p: "YzEwMzZfcA==",
  },
  {
    school_code: "160",
    name: "Concordia Chicago",
    division: 3,
    org_id: "160",
    mascot: "Cougars",
    encoded_h: "YzE2MA==",
    encoded_c: "YzE2MF9j",
    encoded_q: "YzE2MF9x",
    encoded_p: "YzE2MF9w",
  },
  {
    school_code: "161",
    name: "Concordia-M'head",
    division: 3,
    org_id: "161",
    mascot: "Cobbers",
    encoded_h: "YzE2MQ==",
    encoded_c: "YzE2MV9j",
    encoded_q: "YzE2MV9x",
    encoded_p: "YzE2MV9w",
  },
  {
    school_code: "166",
    name: "Cornell College",
    division: 3,
    org_id: "166",
    mascot: "Rams",
    encoded_h: "YzE2Ng==",
    encoded_c: "YzE2Nl9j",
    encoded_q: "YzE2Nl9x",
    encoded_p: "YzE2Nl9w",
  },
  {
    school_code: "30111",
    name: "Covenant",
    division: 3,
    org_id: "30111",
    mascot: "Scots",
    encoded_h: "YzMwMTEx",
    encoded_c: "YzMwMTExX2M=",
    encoded_q: "YzMwMTExX3E=",
    encoded_p: "YzMwMTExX3A=",
  },
  {
    school_code: "30035",
    name: "Crown (MN)",
    division: 3,
    org_id: "30035",
    mascot: "Storm",
    encoded_h: "YzMwMDM1",
    encoded_c: "YzMwMDM1X2M=",
    encoded_q: "YzMwMDM1X3E=",
    encoded_p: "YzMwMDM1X3A=",
  },
  {
    school_code: "170",
    name: "Curry",
    division: 3,
    org_id: "170",
    mascot: "Colonels",
    encoded_h: "YzE3MA==",
    encoded_c: "YzE3MF9j",
    encoded_q: "YzE3MF9x",
    encoded_p: "YzE3MF9w",
  },
  {
    school_code: "1059",
    name: "D'Youville",
    division: 3,
    org_id: "1059",
    mascot: "Spartans",
    encoded_h: "YzEwNTk=",
    encoded_c: "YzEwNTlfYw==",
    encoded_q: "YzEwNTlfcQ==",
    encoded_p: "YzEwNTlfcA==",
  },
  {
    school_code: "1044",
    name: "Dallas",
    division: 3,
    org_id: "1044",
    mascot: "Crusaders",
    encoded_h: "YzEwNDQ=",
    encoded_c: "YzEwNDRfYw==",
    encoded_q: "YzEwNDRfcQ==",
    encoded_p: "YzEwNDRfcA==",
  },
  {
    school_code: "2691",
    name: "Daniel Webster",
    division: 3,
    org_id: "2691",
    mascot: "Eagles",
    encoded_h: "YzI2OTE=",
    encoded_c: "YzI2OTFfYw==",
    encoded_q: "YzI2OTFfcQ==",
    encoded_p: "YzI2OTFfcA==",
  },
  {
    school_code: "30221",
    name: "Davenport",
    division: 2,
    org_id: "30221",
    mascot: "Panthers",
    encoded_h: "YzMwMjIx",
    encoded_c: "YzMwMjIxX2M=",
    encoded_q: "YzMwMjIxX3E=",
    encoded_p: "YzMwMjIxX3A=",
  },
  {
    school_code: "177",
    name: "DePauw",
    division: 3,
    org_id: "177",
    mascot: "Tigers",
    encoded_h: "YzE3Nw==",
    encoded_c: "YzE3N19j",
    encoded_q: "YzE3N19x",
    encoded_p: "YzE3N19w",
  },
  {
    school_code: "20",
    name: "DeSales",
    division: 3,
    org_id: "20",
    mascot: "Bulldogs",
    encoded_h: "YzIw",
    encoded_c: "YzIwX2M=",
    encoded_q: "YzIwX3E=",
    encoded_p: "YzIwX3A=",
  },
  {
    school_code: "1050",
    name: "Defiance",
    division: 3,
    org_id: "1050",
    mascot: "Yellow Jackets",
    encoded_h: "YzEwNTA=",
    encoded_c: "YzEwNTBfYw==",
    encoded_q: "YzEwNTBfcQ==",
    encoded_p: "YzEwNTBfcA==",
  },
  {
    school_code: "179",
    name: "Delaware Valley",
    division: 3,
    org_id: "179",
    mascot: "Aggies",
    encoded_h: "YzE3OQ==",
    encoded_c: "YzE3OV9j",
    encoded_q: "YzE3OV9x",
    encoded_p: "YzE3OV9w",
  },
  {
    school_code: "182",
    name: "Denison",
    division: 3,
    org_id: "182",
    mascot: "Big Red",
    encoded_h: "YzE4Mg==",
    encoded_c: "YzE4Ml9j",
    encoded_q: "YzE4Ml9x",
    encoded_p: "YzE4Ml9w",
  },
  {
    school_code: "185",
    name: "Dickinson",
    division: 3,
    org_id: "185",
    mascot: "Red Devils",
    encoded_h: "YzE4NQ==",
    encoded_c: "YzE4NV9j",
    encoded_q: "YzE4NV9x",
    encoded_p: "YzE4NV9w",
  },
  {
    school_code: "1332",
    name: "Dominican (IL)",
    division: 3,
    org_id: "1332",
    mascot: "Stars",
    encoded_h: "YzEzMzI=",
    encoded_c: "YzEzMzJfYw==",
    encoded_q: "YzEzMzJfcQ==",
    encoded_p: "YzEzMzJfcA==",
  },
  {
    school_code: "190",
    name: "Drew",
    division: 3,
    org_id: "190",
    mascot: "Rangers",
    encoded_h: "YzE5MA==",
    encoded_c: "YzE5MF9j",
    encoded_q: "YzE5MF9x",
    encoded_p: "YzE5MF9w",
  },
  {
    school_code: "192",
    name: "Dubuque",
    division: 3,
    org_id: "192",
    mascot: "Spartans",
    encoded_h: "YzE5Mg==",
    encoded_c: "YzE5Ml9j",
    encoded_q: "YzE5Ml9x",
    encoded_p: "YzE5Ml9w",
  },
  {
    school_code: "195",
    name: "Earlham",
    division: 3,
    org_id: "195",
    mascot: "Quakers",
    encoded_h: "YzE5NQ==",
    encoded_c: "YzE5NV9j",
    encoded_q: "YzE5NV9x",
    encoded_p: "YzE5NV9w",
  },
  {
    school_code: "1062",
    name: "East Tex. Baptist",
    division: 3,
    org_id: "1062",
    mascot: "Tigers",
    encoded_h: "YzEwNjI=",
    encoded_c: "YzEwNjJfYw==",
    encoded_q: "YzEwNjJfcQ==",
    encoded_p: "YzEwNjJfcA==",
  },
  {
    school_code: "203",
    name: "East. Mennonite",
    division: 3,
    org_id: "203",
    mascot: "Royals",
    encoded_h: "YzIwMw==",
    encoded_c: "YzIwM19j",
    encoded_q: "YzIwM19x",
    encoded_p: "YzIwM19w",
  },
  {
    school_code: "8968",
    name: "Eastern",
    division: 3,
    org_id: "8968",
    mascot: "Eagles",
    encoded_h: "Yzg5Njg=",
    encoded_c: "Yzg5NjhfYw==",
    encoded_q: "Yzg5NjhfcQ==",
    encoded_p: "Yzg5NjhfcA==",
  },
  {
    school_code: "200",
    name: "Eastern Conn. St.",
    division: 3,
    org_id: "200",
    mascot: "Warriors",
    encoded_h: "YzIwMA==",
    encoded_c: "YzIwMF9j",
    encoded_q: "YzIwMF9x",
    encoded_p: "YzIwMF9w",
  },
  {
    school_code: "1064",
    name: "Eastern Nazarene",
    division: 3,
    org_id: "1064",
    mascot: "Lions",
    encoded_h: "YzEwNjQ=",
    encoded_c: "YzEwNjRfYw==",
    encoded_q: "YzEwNjRfcQ==",
    encoded_p: "YzEwNjRfcA==",
  },
  {
    school_code: "1065",
    name: "Eastern Ore.",
    division: 3,
    org_id: "1065",
    mascot: "Mountaineers",
    encoded_h: "YzEwNjU=",
    encoded_c: "YzEwNjVfYw==",
    encoded_q: "YzEwNjVfcQ==",
    encoded_p: "YzEwNjVfcA==",
  },
  {
    school_code: "1066",
    name: "Edgewood",
    division: 3,
    org_id: "1066",
    mascot: "Eagles",
    encoded_h: "YzEwNjY=",
    encoded_c: "YzEwNjZfYw==",
    encoded_q: "YzEwNjZfcQ==",
    encoded_p: "YzEwNjZfcA==",
  },
  {
    school_code: "211",
    name: "Elizabethtown",
    division: 3,
    org_id: "211",
    mascot: "Blue Jays",
    encoded_h: "YzIxMQ==",
    encoded_c: "YzIxMV9j",
    encoded_q: "YzIxMV9x",
    encoded_p: "YzIxMV9w",
  },
  {
    school_code: "212",
    name: "Elmhurst",
    division: 3,
    org_id: "212",
    mascot: "Bluejays",
    encoded_h: "YzIxMg==",
    encoded_c: "YzIxMl9j",
    encoded_q: "YzIxMl9x",
    encoded_p: "YzIxMl9w",
  },
  {
    school_code: "213",
    name: "Elmira",
    division: 3,
    org_id: "213",
    mascot: "Soaring Eagles",
    encoded_h: "YzIxMw==",
    encoded_c: "YzIxM19j",
    encoded_q: "YzIxM19x",
    encoded_p: "YzIxM19w",
  },
  {
    school_code: "532",
    name: "Elms",
    division: 3,
    org_id: "532",
    mascot: "Blazers",
    encoded_h: "YzUzMg==",
    encoded_c: "YzUzMl9j",
    encoded_q: "YzUzMl9x",
    encoded_p: "YzUzMl9w",
  },
  {
    school_code: "214",
    name: "Emerson",
    division: 3,
    org_id: "214",
    mascot: "Lions",
    encoded_h: "YzIxNA==",
    encoded_c: "YzIxNF9j",
    encoded_q: "YzIxNF9x",
    encoded_p: "YzIxNF9w",
  },
  {
    school_code: "217",
    name: "Emory",
    division: 3,
    org_id: "217",
    mascot: "Eagles",
    encoded_h: "YzIxNw==",
    encoded_c: "YzIxN19j",
    encoded_q: "YzIxN19x",
    encoded_p: "YzIxN19w",
  },
  {
    school_code: "216",
    name: "Emory & Henry",
    division: 3,
    org_id: "216",
    mascot: "Wasps",
    encoded_h: "YzIxNg==",
    encoded_c: "YzIxNl9j",
    encoded_q: "YzIxNl9x",
    encoded_p: "YzIxNl9w",
  },
  {
    school_code: "8981",
    name: "Endicott",
    division: 3,
    org_id: "8981",
    mascot: "Gulls",
    encoded_h: "Yzg5ODE=",
    encoded_c: "Yzg5ODFfYw==",
    encoded_q: "Yzg5ODFfcQ==",
    encoded_p: "Yzg5ODFfcA==",
  },
  {
    school_code: "218",
    name: "Eureka",
    division: 3,
    org_id: "218",
    mascot: "Red Devils",
    encoded_h: "YzIxOA==",
    encoded_c: "YzIxOF9j",
    encoded_q: "YzIxOF9x",
    encoded_p: "YzIxOF9w",
  },
  {
    school_code: "221",
    name: "FDU-Florham",
    division: 3,
    org_id: "221",
    mascot: "Devils",
    encoded_h: "YzIyMQ==",
    encoded_c: "YzIyMV9j",
    encoded_q: "YzIyMV9x",
    encoded_p: "YzIyMV9w",
  },
  {
    school_code: "27892",
    name: "Farmingdale St.",
    division: 3,
    org_id: "27892",
    mascot: "Rams",
    encoded_h: "YzI3ODky",
    encoded_c: "YzI3ODkyX2M=",
    encoded_q: "YzI3ODkyX3E=",
    encoded_p: "YzI3ODkyX3A=",
  },
  {
    school_code: "225",
    name: "Ferrum",
    division: 3,
    org_id: "225",
    mascot: "Panthers",
    encoded_h: "YzIyNQ==",
    encoded_c: "YzIyNV9j",
    encoded_q: "YzIyNV9x",
    encoded_p: "YzIyNV9w",
  },
  {
    school_code: "30032",
    name: "Finlandia",
    division: 3,
    org_id: "30032",
    mascot: "Lions",
    encoded_h: "YzMwMDMy",
    encoded_c: "YzMwMDMyX2M=",
    encoded_q: "YzMwMDMyX3E=",
    encoded_p: "YzMwMDMyX3A=",
  },
  {
    school_code: "226",
    name: "Fisk",
    division: 3,
    org_id: "226",
    mascot: "Bulldogs",
    encoded_h: "YzIyNg==",
    encoded_c: "YzIyNl9j",
    encoded_q: "YzIyNl9x",
    encoded_p: "YzIyNl9w",
  },
  {
    school_code: "227",
    name: "Fitchburg St.",
    division: 3,
    org_id: "227",
    mascot: "Falcons",
    encoded_h: "YzIyNw==",
    encoded_c: "YzIyN19j",
    encoded_q: "YzIyN19x",
    encoded_p: "YzIyN19w",
  },
  {
    school_code: "11538",
    name: "Fontbonne",
    division: 3,
    org_id: "11538",
    mascot: "Griffins",
    encoded_h: "YzExNTM4",
    encoded_c: "YzExNTM4X2M=",
    encoded_q: "YzExNTM4X3E=",
    encoded_p: "YzExNTM4X3A=",
  },
  {
    school_code: "238",
    name: "Framingham St.",
    division: 3,
    org_id: "238",
    mascot: "Rams",
    encoded_h: "YzIzOA==",
    encoded_c: "YzIzOF9j",
    encoded_q: "YzIzOF9x",
    encoded_p: "YzIzOF9w",
  },
  {
    school_code: "30069",
    name: "Franciscan",
    division: 3,
    org_id: "30069",
    mascot: "Barons",
    encoded_h: "YzMwMDY5",
    encoded_c: "YzMwMDY5X3E=",
    encoded_q: "YzMwMDY5X2M=",
    encoded_p: "YzMwMDY5X3A=",
  },
  {
    school_code: "239",
    name: "Frank. & Marsh.",
    division: 3,
    org_id: "239",
    mascot: "Diplomats",
    encoded_h: "YzIzOQ==",
    encoded_c: "YzIzOV9j",
    encoded_q: "YzIzOV9x",
    encoded_p: "YzIzOV9w",
  },
  {
    school_code: "2694",
    name: "Franklin",
    division: 3,
    org_id: "2694",
    mascot: "Grizzlies",
    encoded_h: "YzI2OTQ=",
    encoded_c: "YzI2OTRfYw==",
    encoded_q: "YzI2OTRfcQ==",
    encoded_p: "YzI2OTRfcA==",
  },
  {
    school_code: "242",
    name: "Fredonia St.",
    division: 3,
    org_id: "242",
    mascot: "Blue Devils",
    encoded_h: "YzI0Mg==",
    encoded_c: "YzI0Ml9j",
    encoded_q: "YzI0Ml9x",
    encoded_p: "YzI0Ml9w",
  },
  {
    school_code: "243",
    name: "Frostburg St.",
    division: 3,
    org_id: "243",
    mascot: "Bobcats",
    encoded_h: "YzI0Mw==",
    encoded_c: "YzI0M19j",
    encoded_q: "YzI0M19x",
    encoded_p: "YzI0M19w",
  },
  {
    school_code: "245",
    name: "Gallaudet",
    division: 3,
    org_id: "245",
    mascot: "Bison",
    encoded_h: "YzI0NQ==",
    encoded_c: "YzI0NV9j",
    encoded_q: "YzI0NV9x",
    encoded_p: "YzI0NV9w",
  },
  {
    school_code: "30084",
    name: "Geneva",
    division: 3,
    org_id: "30084",
    mascot: "Golden Tornadoes",
    encoded_h: "YzMwMDg0",
    encoded_c: "YzMwMDg0X2M=",
    encoded_q: "YzMwMDg0X3E=",
    encoded_p: "YzMwMDg0X3A=",
  },
  {
    school_code: "1094",
    name: "George Fox",
    division: 3,
    org_id: "1094",
    mascot: "Bruins",
    encoded_h: "YzEwOTQ=",
    encoded_c: "YzEwOTRfYw==",
    encoded_q: "YzEwOTRfcQ==",
    encoded_p: "YzEwOTRfcA==",
  },
  {
    school_code: "258",
    name: "Gettysburg",
    division: 3,
    org_id: "258",
    mascot: "Bullets",
    encoded_h: "YzI1OA==",
    encoded_c: "YzI1OF9j",
    encoded_q: "YzI1OF9x",
    encoded_p: "YzI1OF9w",
  },
  {
    school_code: "1099",
    name: "Gordon",
    division: 3,
    org_id: "1099",
    mascot: "Fighting Scots",
    encoded_h: "YzEwOTk=",
    encoded_c: "YzEwOTlfYw==",
    encoded_q: "YzEwOTlfcQ==",
    encoded_p: "YzEwOTlfcA==",
  },
  {
    school_code: "263",
    name: "Greensboro",
    division: 3,
    org_id: "263",
    mascot: "Pride",
    encoded_h: "YzI2Mw==",
    encoded_c: "YzI2M19j",
    encoded_q: "YzI2M19x",
    encoded_p: "YzI2M19w",
  },
  {
    school_code: "1111",
    name: "Greenville",
    division: 3,
    org_id: "1111",
    mascot: "Panthers",
    encoded_h: "YzExMTE=",
    encoded_c: "YzExMTFfYw==",
    encoded_q: "YzExMTFfcQ==",
    encoded_p: "YzExMTFfcA==",
  },
  {
    school_code: "264",
    name: "Grinnell",
    division: 3,
    org_id: "264",
    mascot: "Pioneers",
    encoded_h: "YzI2NA==",
    encoded_c: "YzI2NF9j",
    encoded_q: "YzI2NF9x",
    encoded_p: "YzI2NF9w",
  },
  {
    school_code: "265",
    name: "Grove City",
    division: 3,
    org_id: "265",
    mascot: "Wolverines",
    encoded_h: "YzI2NQ==",
    encoded_c: "YzI2NV9j",
    encoded_q: "YzI2NV9x",
    encoded_p: "YzI2NV9w",
  },
  {
    school_code: "1112",
    name: "Guilford",
    division: 3,
    org_id: "1112",
    mascot: "Quakers",
    encoded_h: "YzExMTI=",
    encoded_c: "YzExMTJfYw==",
    encoded_q: "YzExMTJfcQ==",
    encoded_p: "YzExMTJfcA==",
  },
  {
    school_code: "266",
    name: "Gust. Adolphus",
    division: 3,
    org_id: "266",
    mascot: "Golden Gusties",
    encoded_h: "YzI2Ng==",
    encoded_c: "YzI2Nl9j",
    encoded_q: "YzI2Nl9x",
    encoded_p: "YzI2Nl9w",
  },
  {
    school_code: "12399",
    name: "Gwynedd Mercy",
    division: 3,
    org_id: "12399",
    mascot: "Griffins",
    encoded_h: "YzEyMzk5",
    encoded_c: "YzEyMzk5X2M=",
    encoded_q: "YzEyMzk5X3E=",
    encoded_p: "YzEyMzk5X3A=",
  },
  {
    school_code: "267",
    name: "Hamilton",
    division: 3,
    org_id: "267",
    mascot: "Continentals",
    encoded_h: "YzI2Nw==",
    encoded_c: "YzI2N19j",
    encoded_q: "YzI2N19x",
    encoded_p: "YzI2N19w",
  },
  {
    school_code: "268",
    name: "Hamline",
    division: 3,
    org_id: "268",
    mascot: "Pipers",
    encoded_h: "YzI2OA==",
    encoded_c: "YzI2OF9j",
    encoded_q: "YzI2OF9x",
    encoded_p: "YzI2OF9w",
  },
  {
    school_code: "269",
    name: "Hampden-Sydney",
    division: 3,
    org_id: "269",
    mascot: "Tigers",
    encoded_h: "YzI2OQ==",
    encoded_c: "YzI2OV9j",
    encoded_q: "YzI2OV9x",
    encoded_p: "YzI2OV9w",
  },
  {
    school_code: "1115",
    name: "Hanover",
    division: 3,
    org_id: "1115",
    mascot: "Panthers",
    encoded_h: "YzExMTU=",
    encoded_c: "YzExMTVfYw==",
    encoded_q: "YzExMTVfcQ==",
    encoded_p: "YzExMTVfcA==",
  },
  {
    school_code: "271",
    name: "Hardin-Simmons",
    division: 3,
    org_id: "271",
    mascot: "Cowboys",
    encoded_h: "YzI3MQ==",
    encoded_c: "YzI3MV9j",
    encoded_q: "YzI3MV9x",
    encoded_p: "YzI3MV9w",
  },
  {
    school_code: "273",
    name: "Hartwick",
    division: 3,
    org_id: "273",
    mascot: "Hawks",
    encoded_h: "YzI3Mw==",
    encoded_c: "YzI3M19j",
    encoded_q: "YzI3M19x",
    encoded_p: "YzI3M19w",
  },
  {
    school_code: "276",
    name: "Haverford",
    division: 3,
    org_id: "276",
    mascot: "Fords",
    encoded_h: "YzI3Ng==",
    encoded_c: "YzI3Nl9j",
    encoded_q: "YzI3Nl9x",
    encoded_p: "YzI3Nl9w",
  },
  {
    school_code: "278",
    name: "Heidelberg",
    division: 3,
    org_id: "278",
    mascot: "The Student Prince",
    encoded_h: "YzI3OA==",
    encoded_c: "YzI3OF9j",
    encoded_q: "YzI3OF9x",
    encoded_p: "YzI3OF9w",
  },
  {
    school_code: "1124",
    name: "Hendrix",
    division: 3,
    org_id: "1124",
    mascot: "Warriors",
    encoded_h: "YzExMjQ=",
    encoded_c: "YzExMjRfYw==",
    encoded_q: "YzExMjRfcQ==",
    encoded_p: "YzExMjRfcA==",
  },
  {
    school_code: "8398",
    name: "Hilbert",
    division: 3,
    org_id: "8398",
    mascot: "Hawks",
    encoded_h: "YzgzOTg=",
    encoded_c: "YzgzOThfYw==",
    encoded_q: "YzgzOThfcQ==",
    encoded_p: "YzgzOThfcA==",
  },
  {
    school_code: "281",
    name: "Hiram",
    division: 3,
    org_id: "281",
    mascot: "Terriers",
    encoded_h: "YzI4MQ==",
    encoded_c: "YzI4MV9j",
    encoded_q: "YzI4MV9x",
    encoded_p: "YzI4MV9w",
  },
  {
    school_code: "282",
    name: "Hobart",
    division: 3,
    org_id: "282",
    mascot: "Statesmen",
    encoded_h: "YzI4Mg==",
    encoded_c: "YzI4Ml9j",
    encoded_q: "YzI4Ml9x",
    encoded_p: "YzI4Ml9w",
  },
  {
    school_code: "8404",
    name: "Hood",
    division: 3,
    org_id: "8404",
    mascot: "Blazers",
    encoded_h: "Yzg0MDQ=",
    encoded_c: "Yzg0MDRfYw==",
    encoded_q: "Yzg0MDRfcQ==",
    encoded_p: "Yzg0MDRfcA==",
  },
  {
    school_code: "286",
    name: "Hope",
    division: 3,
    org_id: "286",
    mascot: "Flying Dutchmen",
    encoded_h: "YzI4Ng==",
    encoded_c: "YzI4Nl9j",
    encoded_q: "YzI4Nl9x",
    encoded_p: "YzI4Nl9w",
  },
  {
    school_code: "30160",
    name: "Houghton",
    division: 3,
    org_id: "30160",
    mascot: "Highlanders",
    encoded_h: "YzMwMTYw",
    encoded_c: "YzMwMTYwX2M=",
    encoded_q: "YzMwMTYwX3E=",
    encoded_p: "YzMwMTYwX3A=",
  },
  {
    school_code: "2741",
    name: "Howard Payne",
    division: 3,
    org_id: "2741",
    mascot: "Yellow Jackets",
    encoded_h: "YzI3NDE=",
    encoded_c: "YzI3NDFfYw==",
    encoded_q: "YzI3NDFfcQ==",
    encoded_p: "YzI3NDFfcA==",
  },
  {
    school_code: "1130",
    name: "Huntingdon",
    division: 3,
    org_id: "1130",
    mascot: "Hawks",
    encoded_h: "YzExMzA=",
    encoded_c: "YzExMzBfYw==",
    encoded_q: "YzExMzBfcQ==",
    encoded_p: "YzExMzBfcA==",
  },
  {
    school_code: "1133",
    name: "Husson",
    division: 3,
    org_id: "1133",
    mascot: "Eagles",
    encoded_h: "YzExMzM=",
    encoded_c: "YzExMzNfYw==",
    encoded_q: "YzExMzNfcQ==",
    encoded_p: "YzExMzNfcA==",
  },
  {
    school_code: "300",
    name: "Ill. Wesleyan",
    division: 3,
    org_id: "300",
    mascot: "Titans",
    encoded_h: "YzMwMA==",
    encoded_c: "YzMwMF9j",
    encoded_q: "YzMwMF9x",
    encoded_p: "YzMwMF9w",
  },
  {
    school_code: "30172",
    name: "IIT",
    division: 3,
    org_id: "30172",
    mascot: "Scarlet Hawks",
    encoded_h: "YzMwMTcy",
    encoded_c: "YzMwMTcyX2M=",
    encoded_q: "YzMwMTcyX3E=",
    encoded_p: "YzMwMTcyX3A=",
  },
  {
    school_code: "297",
    name: "Illinois Col.",
    division: 3,
    org_id: "297",
    mascot: "Blueboys",
    encoded_h: "YzI5Nw==",
    encoded_c: "YzI5N19j",
    encoded_q: "YzI5N19x",
    encoded_p: "YzI5N19w",
  },
  {
    school_code: "303",
    name: "Immaculata",
    division: 3,
    org_id: "303",
    mascot: "Mighty Macs",
    encoded_h: "YzMwMw==",
    encoded_c: "YzMwM19j",
    encoded_q: "YzMwM19x",
    encoded_p: "YzMwM19w",
  },
  {
    school_code: "313",
    name: "Ithaca",
    division: 3,
    org_id: "313",
    mascot: "Bombers",
    encoded_h: "YzMxMw==",
    encoded_c: "YzMxM19j",
    encoded_q: "YzMxM19x",
    encoded_p: "YzMxM19w",
  },
  {
    school_code: "320",
    name: "John Carroll",
    division: 3,
    org_id: "320",
    mascot: "Blue Streaks",
    encoded_h: "YzMyMA==",
    encoded_c: "YzMyMF9j",
    encoded_q: "YzMyMF9x",
    encoded_p: "YzMyMF9w",
  },
  {
    school_code: "321",
    name: "John Jay",
    division: 3,
    org_id: "321",
    mascot: "Bloodhounds",
    encoded_h: "YzMyMQ==",
    encoded_c: "YzMyMV9j",
    encoded_q: "YzMyMV9x",
    encoded_p: "YzMyMV9w",
  },
  {
    school_code: "322",
    name: "Johns Hopkins",
    division: 3,
    org_id: "322",
    mascot: "Blue Jays",
    encoded_h: "YzMyMg==",
    encoded_c: "YzMyMl9j",
    encoded_q: "YzMyMl9x",
    encoded_p: "YzMyMl9w",
  },
  {
    school_code: "22626",
    name: "Johnson & Wales (RI)",
    division: 3,
    org_id: "22626",
    mascot: "Wildcats",
    encoded_h: "YzIyNjI2",
    encoded_c: "YzIyNjI2X2M=",
    encoded_q: "YzIyNjI2X3E=",
    encoded_p: "YzIyNjI2X3A=",
  },
  {
    school_code: "325",
    name: "Juniata",
    division: 3,
    org_id: "325",
    mascot: "Eagles",
    encoded_h: "YzMyNQ==",
    encoded_c: "YzMyNV9j",
    encoded_q: "YzMyNV9x",
    encoded_p: "YzMyNV9w",
  },
  {
    school_code: "326",
    name: "Kalamazoo",
    division: 3,
    org_id: "326",
    mascot: "Hornets",
    encoded_h: "YzMyNg==",
    encoded_c: "YzMyNl9j",
    encoded_q: "YzMyNl9x",
    encoded_p: "YzMyNl9w",
  },
  {
    school_code: "329",
    name: "Kean",
    division: 3,
    org_id: "329",
    mascot: "Cougars",
    encoded_h: "YzMyOQ==",
    encoded_c: "YzMyOV9j",
    encoded_q: "YzMyOV9x",
    encoded_p: "YzMyOV9w",
  },
  {
    school_code: "330",
    name: "Keene St.",
    division: 3,
    org_id: "330",
    mascot: "Owls",
    encoded_h: "YzMzMA==",
    encoded_c: "YzMzMF9j",
    encoded_q: "YzMzMF9x",
    encoded_p: "YzMzMF9w",
  },
  {
    school_code: "335",
    name: "Kenyon",
    division: 3,
    org_id: "335",
    mascot: "Lords",
    encoded_h: "YzMzNQ==",
    encoded_c: "YzMzNV9j",
    encoded_q: "YzMzNV9x",
    encoded_p: "YzMzNV9w",
  },
  {
    school_code: "1159",
    name: "Keuka",
    division: 3,
    org_id: "1159",
    mascot: "Wolfpack",
    encoded_h: "YzExNTk=",
    encoded_c: "YzExNTlfYw==",
    encoded_q: "YzExNTlfcQ==",
    encoded_p: "YzExNTlfcA==",
  },
  {
    school_code: "30047",
    name: "Keystone",
    division: 3,
    org_id: "30047",
    mascot: "Giants",
    encoded_h: "YzMwMDQ3",
    encoded_c: "YzMwMDQ3X2M=",
    encoded_q: "YzMwMDQ3X3E=",
    encoded_p: "YzMwMDQ3X3A=",
  },
  {
    school_code: "336",
    name: "King's (PA)",
    division: 3,
    org_id: "336",
    mascot: "Monarchs",
    encoded_h: "YzMzNg==",
    encoded_c: "YzMzNl9j",
    encoded_q: "YzMzNl9x",
    encoded_p: "YzMzNl9w",
  },
  {
    school_code: "337",
    name: "Knox",
    division: 3,
    org_id: "337",
    mascot: "Prairie Fire",
    encoded_h: "YzMzNw==",
    encoded_c: "YzMzN19j",
    encoded_q: "YzMzN19x",
    encoded_p: "YzMzN19w",
  },
  {
    school_code: "1163",
    name: "La Roche",
    division: 3,
    org_id: "1163",
    mascot: "Redhawks",
    encoded_h: "YzExNjM=",
    encoded_c: "YzExNjNfYw==",
    encoded_q: "YzExNjNfcQ==",
    encoded_p: "YzExNjNfcA==",
  },
  {
    school_code: "341",
    name: "La Verne",
    division: 3,
    org_id: "341",
    mascot: "Leopards",
    encoded_h: "YzM0MQ==",
    encoded_c: "YzM0MV9j",
    encoded_q: "YzM0MV9x",
    encoded_p: "YzM0MV9w",
  },
  {
    school_code: "1162",
    name: "LaGrange",
    division: 3,
    org_id: "1162",
    mascot: "Panthers",
    encoded_h: "YzExNjI=",
    encoded_c: "YzExNjJfYw==",
    encoded_q: "YzExNjJfcQ==",
    encoded_p: "YzExNjJfcA==",
  },
  {
    school_code: "1164",
    name: "Lakeland",
    division: 3,
    org_id: "1164",
    mascot: "Muskies",
    encoded_h: "YzExNjQ=",
    encoded_c: "YzExNjRfYw==",
    encoded_q: "YzExNjRfcQ==",
    encoded_p: "YzExNjRfcA==",
  },
  {
    school_code: "30085",
    name: "Lancaster Bible",
    division: 3,
    org_id: "30085",
    mascot: "Chargers",
    encoded_h: "YzMwMDg1",
    encoded_c: "YzMwMDg1X2M=",
    encoded_q: "YzMwMDg1X3E=",
    encoded_p: "YzMwMDg1X3A=",
  },
  {
    school_code: "8486",
    name: "Lasell",
    division: 3,
    org_id: "8486",
    mascot: "Lasers",
    encoded_h: "Yzg0ODY=",
    encoded_c: "Yzg0ODZfYw==",
    encoded_q: "Yzg0ODZfcQ==",
    encoded_p: "Yzg0ODZfcA==",
  },
  {
    school_code: "348",
    name: "Lawrence",
    division: 3,
    org_id: "348",
    mascot: "Vikings",
    encoded_h: "YzM0OA==",
    encoded_c: "YzM0OF9j",
    encoded_q: "YzM0OF9x",
    encoded_p: "YzM0OF9w",
  },
  {
    school_code: "8490",
    name: "LeTourneau",
    division: 3,
    org_id: "8490",
    mascot: "YellowJackets",
    encoded_h: "Yzg0OTA=",
    encoded_c: "Yzg0OTBfYw==",
    encoded_q: "Yzg0OTBfcQ==",
    encoded_p: "Yzg0OTBfcA==",
  },
  {
    school_code: "351",
    name: "Lebanon Valley",
    division: 3,
    org_id: "351",
    mascot: "Flying Dutchmen",
    encoded_h: "YzM1MQ==",
    encoded_c: "YzM1MV9j",
    encoded_q: "YzM1MV9x",
    encoded_p: "YzM1MV9w",
  },
  {
    school_code: "279",
    name: "Lehman",
    division: 3,
    org_id: "279",
    mascot: "Lightning",
    encoded_h: "YzI3OQ==",
    encoded_c: "YzI3OV9j",
    encoded_q: "YzI3OV9x",
    encoded_p: "YzI3OV9w",
  },
  {
    school_code: "24317",
    name: "Lesley",
    division: 3,
    org_id: "24317",
    mascot: "Lynx",
    encoded_h: "YzI0MzE3",
    encoded_c: "YzI0MzE3X2M=",
    encoded_q: "YzI0MzE3X3E=",
    encoded_p: "YzI0MzE3X3A=",
  },
  {
    school_code: "22235",
    name: "Lewis & Clark",
    division: 3,
    org_id: "22235",
    mascot: "Pioneers",
    encoded_h: "YzIyMjM1",
    encoded_c: "YzIyMjM1X2M=",
    encoded_q: "YzIyMjM1X3E=",
    encoded_p: "YzIyMjM1X3A=",
  },
  {
    school_code: "1179",
    name: "Linfield",
    division: 3,
    org_id: "1179",
    mascot: "Wildcats",
    encoded_h: "YzExNzk=",
    encoded_c: "YzExNzlfYw==",
    encoded_q: "YzExNzlfcQ==",
    encoded_p: "YzExNzlfcA==",
  },
  {
    school_code: "364",
    name: "Loras",
    division: 3,
    org_id: "364",
    mascot: "Duhawks",
    encoded_h: "YzM2NA==",
    encoded_c: "YzM2NF9j",
    encoded_q: "YzM2NF9x",
    encoded_p: "YzM2NF9w",
  },
  {
    school_code: "1182",
    name: "Louisiana Col.",
    division: 3,
    org_id: "1182",
    mascot: "Wildcats",
    encoded_h: "YzExODI=",
    encoded_c: "YzExODJfYw==",
    encoded_q: "YzExODJfcQ==",
    encoded_p: "YzExODJfcA==",
  },
  {
    school_code: "372",
    name: "Luther",
    division: 3,
    org_id: "372",
    mascot: "Norse",
    encoded_h: "YzM3Mg==",
    encoded_c: "YzM3Ml9j",
    encoded_q: "YzM3Ml9x",
    encoded_p: "YzM3Ml9w",
  },
  {
    school_code: "373",
    name: "Lycoming",
    division: 3,
    org_id: "373",
    encoded_h: "YzM3Mw==",
    encoded_c: "YzM3M19j",
    encoded_q: "YzM3M19x",
    encoded_p: "YzM3M19w",
  },
  {
    school_code: "374",
    name: "Lynchburg",
    division: 3,
    org_id: "374",
    mascot: "Hornets",
    encoded_h: "YzM3NA==",
    encoded_c: "YzM3NF9j",
    encoded_q: "YzM3NF9x",
    encoded_p: "YzM3NF9w",
  },
  {
    school_code: "30065",
    name: "Lyndon St.",
    division: 3,
    org_id: "30065",
    mascot: "Hornets",
    encoded_h: "YzMwMDY1",
    encoded_c: "YzMwMDY1X2M=",
    encoded_q: "YzMwMDY1X3E=",
    encoded_p: "YzMwMDY1X3A=",
  },
  {
    school_code: "22989",
    name: "Lyon",
    division: 3,
    org_id: "22989",
    mascot: "Lyon",
    encoded_h: "YzIyOTg5",
    encoded_c: "YzIyOTg5X2M=",
    encoded_q: "YzIyOTg5X3E=",
    encoded_p: "YzIyOTg5X3A=",
  },
  {
    school_code: "486",
    name: "MCLA",
    division: 3,
    org_id: "486",
    mascot: "Trailblazers",
    encoded_h: "YzQ4Ng==",
    encoded_c: "YzQ4Nl9j",
    encoded_q: "YzQ4Nl9x",
    encoded_p: "YzQ4Nl9w",
  },
  {
    school_code: "398",
    name: "MIT",
    division: 3,
    org_id: "398",
    mascot: "Engineers",
    encoded_h: "YzM5OA==",
    encoded_c: "YzM5OF9j",
    encoded_q: "YzM5OF9x",
    encoded_p: "YzM5OF9w",
  },
  {
    school_code: "20220",
    name: "MSOE",
    division: 3,
    org_id: "20220",
    mascot: "Raiders",
    encoded_h: "YzIwMjIw",
    encoded_c: "YzIwMjIwX2M=",
    encoded_q: "YzIwMjIwX3E=",
    encoded_p: "YzIwMjIwX3A=",
  },
  {
    school_code: "377",
    name: "MacMurray",
    division: 3,
    org_id: "377",
    mascot: "Highlanders",
    encoded_h: "YzM3Nw==",
    encoded_c: "YzM3N19j",
    encoded_q: "YzM3N19x",
    encoded_p: "YzM3N19w",
  },
  {
    school_code: "376",
    name: "Macalester",
    division: 3,
    org_id: "376",
    mascot: "Scots",
    encoded_h: "YzM3Ng==",
    encoded_c: "YzM3Nl9j",
    encoded_q: "YzM3Nl9x",
    encoded_p: "YzM3Nl9w",
  },
  {
    school_code: "1192",
    name: "Manchester",
    division: 3,
    org_id: "1192",
    mascot: "Spartans",
    encoded_h: "YzExOTI=",
    encoded_c: "YzExOTJfYw==",
    encoded_q: "YzExOTJfcQ==",
    encoded_p: "YzExOTJfcA==",
  },
  {
    school_code: "382",
    name: "Manhattanville",
    division: 3,
    org_id: "382",
    mascot: "Valiants",
    encoded_h: "YzM4Mg==",
    encoded_c: "YzM4Ml9j",
    encoded_q: "YzM4Ml9x",
    encoded_p: "YzM4Ml9w",
  },
  {
    school_code: "1194",
    name: "Maranatha Baptist",
    division: 3,
    org_id: "1194",
    mascot: "Sabercats",
    encoded_h: "YzExOTQ=",
    encoded_c: "YzExOTRfYw==",
    encoded_q: "YzExOTRfcQ==",
    encoded_p: "YzExOTRfcA==",
  },
  {
    school_code: "1196",
    name: "Marian (WI)",
    division: 3,
    org_id: "1196",
    mascot: "Sabres",
    encoded_h: "YzExOTY=",
    encoded_c: "YzExOTZfYw==",
    encoded_q: "YzExOTZfcQ==",
    encoded_p: "YzExOTZfcA==",
  },
  {
    school_code: "385",
    name: "Marietta",
    division: 3,
    org_id: "385",
    mascot: "Pioneers",
    encoded_h: "YzM4NQ==",
    encoded_c: "YzM4NV9j",
    encoded_q: "YzM4NV9x",
    encoded_p: "YzM4NV9w",
  },
  {
    school_code: "8597",
    name: "Martin Luther",
    division: 3,
    org_id: "8597",
    mascot: "Knights",
    encoded_h: "Yzg1OTc=",
    encoded_c: "Yzg1OTdfYw==",
    encoded_q: "Yzg1OTdfcQ==",
    encoded_p: "Yzg1OTdfcA==",
  },
  {
    school_code: "1203",
    name: "Mary Hardin-Baylor",
    division: 3,
    org_id: "1203",
    mascot: "Crusaders",
    encoded_h: "YzEyMDM=",
    encoded_c: "YzEyMDNfYw==",
    encoded_q: "YzEyMDNfcQ==",
    encoded_p: "YzEyMDNfcA==",
  },
  {
    school_code: "390",
    name: "Mary Washington",
    division: 3,
    org_id: "390",
    mascot: "Eagles",
    encoded_h: "YzM5MA==",
    encoded_c: "YzM5MF9j",
    encoded_q: "YzM5MF9x",
    encoded_p: "YzM5MF9w",
  },
  {
    school_code: "394",
    name: "Marymount (VA)",
    division: 3,
    org_id: "394",
    mascot: "Saints",
    encoded_h: "YzM5NA==",
    encoded_c: "YzM5NF9j",
    encoded_q: "YzM5NF9x",
    encoded_p: "YzM5NF9w",
  },
  {
    school_code: "396",
    name: "Maryville (TN)",
    division: 3,
    org_id: "396",
    mascot: "Scots",
    encoded_h: "YzM5Ng==",
    encoded_c: "YzM5Nl9j",
    encoded_q: "YzM5Nl9x",
    encoded_p: "YzM5Nl9w",
  },
  {
    school_code: "397",
    name: "Marywood",
    division: 3,
    org_id: "397",
    mascot: "Pacers",
    encoded_h: "YzM5Nw==",
    encoded_c: "YzM5N19j",
    encoded_q: "YzM5N19x",
    encoded_p: "YzM5N19w",
  },
  {
    school_code: "399",
    name: "Mass. Maritime",
    division: 3,
    org_id: "399",
    mascot: "Buccaneers",
    encoded_h: "YzM5OQ==",
    encoded_c: "YzM5OV9j",
    encoded_q: "YzM5OV9x",
    encoded_p: "YzM5OV9w",
  },
  {
    school_code: "773",
    name: "McDaniel",
    division: 3,
    org_id: "773",
    mascot: "Green Terror",
    encoded_h: "Yzc3Mw==",
    encoded_c: "Yzc3M19j",
    encoded_q: "Yzc3M19x",
    encoded_p: "Yzc3M19w",
  },
  {
    school_code: "379",
    name: "Me.-Farmington",
    division: 3,
    org_id: "379",
    mascot: "Beavers",
    encoded_h: "YzM3OQ==",
    encoded_c: "YzM3OV9j",
    encoded_q: "YzM3OV9x",
    encoded_p: "YzM3OV9w",
  },
  {
    school_code: "30040",
    name: "Me.-Presque Isle",
    division: 3,
    org_id: "30040",
    mascot: "Owls",
    encoded_h: "YzMwMDQw",
    encoded_c: "YzMwMDQwX2M=",
    encoded_q: "YzMwMDQwX3E=",
    encoded_p: "YzMwMDQwX3A=",
  },
  {
    school_code: "23725",
    name: "Medaille",
    division: 3,
    org_id: "23725",
    mascot: "Mavericks",
    encoded_h: "YzIzNzI1",
    encoded_c: "YzIzNzI1X2M=",
    encoded_q: "YzIzNzI1X3E=",
    encoded_p: "YzIzNzI1X3A=",
  },
  {
    school_code: "405",
    name: "Menlo",
    division: 3,
    org_id: "405",
    mascot: "Oaks",
    encoded_h: "YzQwNQ==",
    encoded_c: "YzQwNV9j",
    encoded_q: "YzQwNV9x",
    encoded_p: "YzQwNV9w",
  },
  {
    school_code: "724",
    name: "Merchant Marine",
    division: 3,
    org_id: "724",
    mascot: "Mariners",
    encoded_h: "YzcyNA==",
    encoded_c: "YzcyNF9j",
    encoded_q: "YzcyNF9x",
    encoded_p: "YzcyNF9w",
  },
  {
    school_code: "411",
    name: "Messiah",
    division: 3,
    org_id: "411",
    mascot: "Falcons",
    encoded_h: "YzQxMQ==",
    encoded_c: "YzQxMV9j",
    encoded_q: "YzQxMV9x",
    encoded_p: "YzQxMV9w",
  },
  {
    school_code: "412",
    name: "Methodist",
    division: 3,
    org_id: "412",
    mascot: "Monarchs",
    encoded_h: "YzQxMg==",
    encoded_c: "YzQxMl9j",
    encoded_q: "YzQxMl9x",
    encoded_p: "YzQxMl9w",
  },
  {
    school_code: "420",
    name: "Middlebury",
    division: 3,
    org_id: "420",
    mascot: "Panthers",
    encoded_h: "YzQyMA==",
    encoded_c: "YzQyMF9j",
    encoded_q: "YzQyMF9x",
    encoded_p: "YzQyMF9w",
  },
  {
    school_code: "424",
    name: "Millikin",
    division: 3,
    org_id: "424",
    mascot: "Big Blue",
    encoded_h: "YzQyNA==",
    encoded_c: "YzQyNF9j",
    encoded_q: "YzQyNF9x",
    encoded_p: "YzQyNF9w",
  },
  {
    school_code: "426",
    name: "Millsaps",
    division: 3,
    org_id: "426",
    mascot: "Majors",
    encoded_h: "YzQyNg==",
    encoded_c: "YzQyNl9j",
    encoded_q: "YzQyNl9x",
    encoded_p: "YzQyNl9w",
  },
  {
    school_code: "1226",
    name: "Minn.-Morris",
    division: 3,
    org_id: "1226",
    mascot: "Cougars",
    encoded_h: "YzEyMjY=",
    encoded_c: "YzEyMjZfYw==",
    encoded_q: "YzEyMjZfcQ==",
    encoded_p: "YzEyMjZfcA==",
  },
  {
    school_code: "16142",
    name: "Misericordia",
    division: 3,
    org_id: "16142",
    mascot: "Cougars",
    encoded_h: "YzE2MTQy",
    encoded_c: "YzE2MTQyX2M=",
    encoded_q: "YzE2MTQyX3E=",
    encoded_p: "YzE2MTQyX3A=",
  },
  {
    school_code: "429",
    name: "Mississippi Col.",
    division: 3,
    org_id: "429",
    mascot: "Choctaws",
    encoded_h: "YzQyOQ==",
    encoded_c: "YzQyOV9j",
    encoded_q: "YzQyOV9x",
    encoded_p: "YzQyOV9w",
  },
  {
    school_code: "30042",
    name: "Mitchell",
    division: 3,
    org_id: "30042",
    mascot: "Mariners",
    encoded_h: "YzMwMDQy",
    encoded_c: "YzMwMDQyX2M=",
    encoded_q: "YzMwMDQyX3E=",
    encoded_p: "YzMwMDQyX3A=",
  },
  {
    school_code: "438",
    name: "Monmouth (IL)",
    division: 3,
    org_id: "438",
    mascot: "Fighting Scots",
    encoded_h: "YzQzOA==",
    encoded_c: "YzQzOF9j",
    encoded_q: "YzQzOF9x",
    encoded_p: "YzQzOF9w",
  },
  {
    school_code: "442",
    name: "Montclair St.",
    division: 3,
    org_id: "442",
    mascot: "Red Hawks",
    encoded_h: "YzQ0Mg==",
    encoded_c: "YzQ0Ml9j",
    encoded_q: "YzQ0Ml9x",
    encoded_p: "YzQ0Ml9w",
  },
  {
    school_code: "443",
    name: "Moravian",
    division: 3,
    org_id: "443",
    mascot: "Greyhounds",
    encoded_h: "YzQ0Mw==",
    encoded_c: "YzQ0M19j",
    encoded_q: "YzQ0M19x",
    encoded_p: "YzQ0M19w",
  },
  {
    school_code: "452",
    name: "Mount Union",
    division: 3,
    org_id: "452",
    mascot: "Purple Raiders",
    encoded_h: "YzQ1Mg==",
    encoded_c: "YzQ1Ml9j",
    encoded_q: "YzQ1Ml9x",
    encoded_p: "YzQ1Ml9w",
  },
  {
    school_code: "30036",
    name: "Mt. Aloysius",
    division: 3,
    org_id: "30036",
    mascot: "Mounties",
    encoded_h: "YzMwMDM2",
    encoded_c: "YzMwMDM2X2M=",
    encoded_q: "YzMwMDM2X3E=",
    encoded_p: "YzMwMDM2X3A=",
  },
  {
    school_code: "8567",
    name: "Mt. St. Joseph",
    division: 3,
    org_id: "8567",
    mascot: "Lions",
    encoded_h: "Yzg1Njc=",
    encoded_c: "Yzg1NjdfYw==",
    encoded_q: "Yzg1NjdfcQ==",
    encoded_p: "Yzg1NjdfcA==",
  },
  {
    school_code: "1468",
    name: "Mt. St. Mary (NY)",
    division: 3,
    org_id: "1468",
    mascot: "Knights",
    encoded_h: "YzE0Njg=",
    encoded_c: "YzE0NjhfYw==",
    encoded_q: "YzE0NjhfcQ==",
    encoded_p: "YzE0NjhfcA==",
  },
  {
    school_code: "451",
    name: "Mt. St. Vincent",
    division: 3,
    org_id: "451",
    mascot: "Dolphins",
    encoded_h: "YzQ1MQ==",
    encoded_c: "YzQ1MV9j",
    encoded_q: "YzQ1MV9x",
    encoded_p: "YzQ1MV9w",
  },
  {
    school_code: "453",
    name: "Muhlenberg",
    division: 3,
    org_id: "453",
    mascot: "Mules",
    encoded_h: "YzQ1Mw==",
    encoded_c: "YzQ1M19j",
    encoded_q: "YzQ1M19x",
    encoded_p: "YzQ1M19w",
  },
  {
    school_code: "455",
    name: "Muskingum",
    division: 3,
    org_id: "455",
    mascot: "Fighting Muskies",
    encoded_h: "YzQ1NQ==",
    encoded_c: "YzQ1NV9j",
    encoded_q: "YzQ1NV9x",
    encoded_p: "YzQ1NV9w",
  },
  {
    school_code: "431",
    name: "MUW",
    division: 3,
    org_id: "431",
    encoded_h: "YzQzMQ==",
    encoded_c: "YzQzMV9j",
    encoded_q: "YzQzMV9x",
    encoded_p: "YzQzMV9w",
  },
  {
    school_code: "491",
    name: "N.C. Wesleyan",
    division: 3,
    org_id: "491",
    mascot: "Battling Bishops",
    encoded_h: "YzQ5MQ==",
    encoded_c: "YzQ5MV9j",
    encoded_q: "YzQ5MV9x",
    encoded_p: "YzQ5MV9w",
  },
  {
    school_code: "462",
    name: "Neb. Wesleyan",
    division: 3,
    org_id: "462",
    mascot: "Prairie Wolves",
    encoded_h: "YzQ2Mg==",
    encoded_c: "YzQ2Ml9j",
    encoded_q: "YzQ2Ml9x",
    encoded_p: "YzQ2Ml9w",
  },
  {
    school_code: "1254",
    name: "Neumann",
    division: 3,
    org_id: "1254",
    mascot: "Knights",
    encoded_h: "YzEyNTQ=",
    encoded_c: "YzEyNTRfYw==",
    encoded_q: "YzEyNTRfcQ==",
    encoded_p: "YzEyNTRfcA==",
  },
  {
    school_code: "467",
    name: "New England Col.",
    division: 3,
    org_id: "467",
    mascot: "Pilgrims",
    encoded_h: "YzQ2Nw==",
    encoded_c: "YzQ2N19j",
    encoded_q: "YzQ2N19x",
    encoded_p: "YzQ2N19w",
  },
  {
    school_code: "319",
    name: "New Jersey City",
    division: 3,
    org_id: "319",
    mascot: "Gothic Knights",
    encoded_h: "YzMxOQ==",
    encoded_c: "YzMxOV9j",
    encoded_q: "YzMxOV9x",
    encoded_p: "YzMxOV9w",
  },
  {
    school_code: "476",
    name: "New Rochelle",
    division: 3,
    org_id: "476",
    encoded_h: "YzQ3Ng==",
    encoded_c: "YzQ3Nl9j",
    encoded_q: "YzQ3Nl9x",
    encoded_p: "YzQ3Nl9w",
  },
  {
    school_code: "21772",
    name: "Newbury",
    division: 3,
    org_id: "21772",
    mascot: "Nighthawks",
    encoded_h: "YzIxNzcy",
    encoded_c: "YzIxNzcyX2M=",
    encoded_q: "YzIxNzcyX3E=",
    encoded_p: "YzIxNzcyX3A=",
  },
  {
    school_code: "484",
    name: "Nichols",
    division: 3,
    org_id: "484",
    mascot: "Bison",
    encoded_h: "YzQ4NA==",
    encoded_c: "YzQ4NF9j",
    encoded_q: "YzQ4NF9x",
    encoded_p: "YzQ4NF9w",
  },
  {
    school_code: "492",
    name: "North Central (IL)",
    division: 3,
    org_id: "492",
    mascot: "Cardinals",
    encoded_h: "YzQ5Mg==",
    encoded_c: "YzQ5Ml9j",
    encoded_q: "YzQ5Ml9x",
    encoded_p: "YzQ5Ml9w",
  },
  {
    school_code: "30039",
    name: "North Central (MN)",
    division: 3,
    org_id: "30039",
    mascot: "Rams",
    encoded_h: "YzMwMDM5",
    encoded_c: "YzMwMDM5X2M=",
    encoded_q: "YzMwMDM5X3E=",
    encoded_p: "YzMwMDM5X3A=",
  },
  {
    school_code: "496",
    name: "North Park",
    division: 3,
    org_id: "496",
    mascot: "Vikings",
    encoded_h: "YzQ5Ng==",
    encoded_c: "YzQ5Nl9j",
    encoded_q: "YzQ5Nl9x",
    encoded_p: "YzQ5Nl9w",
  },
  {
    school_code: "1267",
    name: "Northland",
    division: 3,
    org_id: "1267",
    mascot: "Lumberjacks",
    encoded_h: "YzEyNjc=",
    encoded_c: "YzEyNjdfYw==",
    encoded_q: "YzEyNjdfcQ==",
    encoded_p: "YzEyNjdfcA==",
  },
  {
    school_code: "30031",
    name: "Northwestern-St. Paul",
    division: 3,
    org_id: "30031",
    mascot: "Eagles",
    encoded_h: "YzMwMDMx",
    encoded_c: "YzMwMDMxX2M=",
    encoded_q: "YzMwMDMxX3E=",
    encoded_p: "YzMwMDMxX3A=",
  },
  {
    school_code: "511",
    name: "Norwich",
    division: 3,
    org_id: "511",
    mascot: "Cadets",
    encoded_h: "YzUxMQ==",
    encoded_c: "YzUxMV9j",
    encoded_q: "YzUxMV9x",
    encoded_p: "YzUxMV9w",
  },
  {
    school_code: "479",
    name: "NYU",
    division: 3,
    org_id: "479",
    mascot: "Violets",
    encoded_h: "YzQ3OQ==",
    encoded_c: "YzQ3OV9j",
    encoded_q: "YzQ3OV9x",
    encoded_p: "YzQ3OV9w",
  },
  {
    school_code: "515",
    name: "Oberlin",
    division: 3,
    org_id: "515",
    mascot: "Yeomen",
    encoded_h: "YzUxNQ==",
    encoded_c: "YzUxNV9j",
    encoded_q: "YzUxNV9x",
    encoded_p: "YzUxNV9w",
  },
  {
    school_code: "516",
    name: "Occidental",
    division: 3,
    org_id: "516",
    mascot: "Tigers",
    encoded_h: "YzUxNg==",
    encoded_c: "YzUxNl9j",
    encoded_q: "YzUxNl9x",
    encoded_p: "YzUxNl9w",
  },
  {
    school_code: "2713",
    name: "Oglethorpe",
    division: 3,
    org_id: "2713",
    mascot: "Stormy Petrels",
    encoded_h: "YzI3MTM=",
    encoded_c: "YzI3MTNfYw==",
    encoded_q: "YzI3MTNfcQ==",
    encoded_p: "YzI3MTNfcA==",
  },
  {
    school_code: "517",
    name: "Ohio Northern",
    division: 3,
    org_id: "517",
    mascot: "Polar Bears",
    encoded_h: "YzUxNw==",
    encoded_c: "YzUxN19j",
    encoded_q: "YzUxN19x",
    encoded_p: "YzUxN19w",
  },
  {
    school_code: "520",
    name: "Ohio Wesleyan",
    division: 3,
    org_id: "520",
    mascot: "Battling Bishops",
    encoded_h: "YzUyMA==",
    encoded_c: "YzUyMF9j",
    encoded_q: "YzUyMF9x",
    encoded_p: "YzUyMF9w",
  },
  {
    school_code: "524",
    name: "Old Westbury",
    division: 3,
    org_id: "524",
    mascot: "Panthers",
    encoded_h: "YzUyNA==",
    encoded_c: "YzUyNF9j",
    encoded_q: "YzUyNF9x",
    encoded_p: "YzUyNF9w",
  },
  {
    school_code: "525",
    name: "Olivet",
    division: 3,
    org_id: "525",
    mascot: "Comets",
    encoded_h: "YzUyNQ==",
    encoded_c: "YzUyNV9j",
    encoded_q: "YzUyNV9x",
    encoded_p: "YzUyNV9w",
  },
  {
    school_code: "530",
    name: "Oswego St.",
    division: 3,
    org_id: "530",
    mascot: "Lakers",
    encoded_h: "YzUzMA==",
    encoded_c: "YzUzMF9j",
    encoded_q: "YzUzMF9x",
    encoded_p: "YzUzMF9w",
  },
  {
    school_code: "531",
    name: "Otterbein",
    division: 3,
    org_id: "531",
    mascot: "Cardinals",
    encoded_h: "YzUzMQ==",
    encoded_c: "YzUzMV9j",
    encoded_q: "YzUzMV9x",
    encoded_p: "YzUzMV9w",
  },
  {
    school_code: "23008",
    name: "Ozarks (AR)",
    division: 3,
    org_id: "23008",
    mascot: "Eagles",
    encoded_h: "YzIzMDA4",
    encoded_c: "YzIzMDA4X2M=",
    encoded_q: "YzIzMDA4X3E=",
    encoded_p: "YzIzMDA4X3A=",
  },
  {
    school_code: "2751",
    name: "Pacific (OR)",
    division: 3,
    org_id: "2751",
    mascot: "Boxers",
    encoded_h: "YzI3NTE=",
    encoded_c: "YzI3NTFfYw==",
    encoded_q: "YzI3NTFfcQ==",
    encoded_p: "YzI3NTFfcA==",
  },
  {
    school_code: "1297",
    name: "Pacific Lutheran",
    division: 3,
    org_id: "1297",
    mascot: "Lutes",
    encoded_h: "YzEyOTc=",
    encoded_c: "YzEyOTdfYw==",
    encoded_q: "YzEyOTdfcQ==",
    encoded_p: "YzEyOTdfcA==",
  },
  {
    school_code: "30190",
    name: "Penn College",
    division: 3,
    org_id: "30190",
    mascot: "Wildcats",
    encoded_h: "YzMwMTkw",
    encoded_c: "YzMwMTkwX2M=",
    encoded_q: "YzMwMTkwX3E=",
    encoded_p: "YzMwMTkwX3A=",
  },
  {
    school_code: "30081",
    name: "Penn St. Harrisburg",
    division: 3,
    org_id: "30081",
    mascot: "Lions",
    encoded_h: "YzMwMDgx",
    encoded_c: "YzMwMDgxX2M=",
    encoded_q: "YzMwMDgxX3E=",
    encoded_p: "YzMwMDgxX3A=",
  },
  {
    school_code: "30114",
    name: "Penn St.-Abington",
    division: 3,
    org_id: "30114",
    mascot: "Nittany Lions",
    encoded_h: "YzMwMTE0",
    encoded_c: "YzMwMTE0X2M=",
    encoded_q: "YzMwMTE0X3E=",
    encoded_p: "YzMwMTE0X3A=",
  },
  {
    school_code: "25719",
    name: "Penn St.-Altoona",
    division: 3,
    org_id: "25719",
    mascot: "Lions",
    encoded_h: "YzI1NzE5",
    encoded_c: "YzI1NzE5X2M=",
    encoded_q: "YzI1NzE5X3E=",
    encoded_p: "YzI1NzE5X3A=",
  },
  {
    school_code: "538",
    name: "Penn St.-Behrend",
    division: 3,
    org_id: "538",
    mascot: "Behrend Lions",
    encoded_h: "YzUzOA==",
    encoded_c: "YzUzOF9j",
    encoded_q: "YzUzOF9x",
    encoded_p: "YzUzOF9w",
  },
  {
    school_code: "30044",
    name: "Penn St.-Berks",
    division: 3,
    org_id: "30044",
    mascot: "Nittany Lions",
    encoded_h: "YzMwMDQ0",
    encoded_c: "YzMwMDQ0X2M=",
    encoded_q: "YzMwMDQ0X3E=",
    encoded_p: "YzMwMDQ0X3A=",
  },
  {
    school_code: "21830",
    name: "Piedmont",
    division: 3,
    org_id: "21830",
    mascot: "Lions",
    encoded_h: "YzIxODMw",
    encoded_c: "YzIxODMwX2M=",
    encoded_q: "YzIxODMwX3E=",
    encoded_p: "YzIxODMwX3A=",
  },
  {
    school_code: "544",
    name: "Pine Manor",
    division: 3,
    org_id: "544",
    encoded_h: "YzU0NA==",
    encoded_c: "YzU0NF9j",
    encoded_q: "YzU0NF9x",
    encoded_p: "YzU0NF9w",
  },
  {
    school_code: "1315",
    name: "Pitt.-Bradford",
    division: 3,
    org_id: "1315",
    mascot: "Panthers",
    encoded_h: "YzEzMTU=",
    encoded_c: "YzEzMTVfYw==",
    encoded_q: "YzEzMTVfcQ==",
    encoded_p: "YzEzMTVfcA==",
  },
  {
    school_code: "11839",
    name: "Pitt.-Greensburg",
    division: 3,
    org_id: "11839",
    mascot: "Bobcats",
    encoded_h: "YzExODM5",
    encoded_c: "YzExODM5X2M=",
    encoded_q: "YzExODM5X3E=",
    encoded_p: "YzExODM5X3A=",
  },
  {
    school_code: "547",
    name: "Plattsburgh St.",
    division: 3,
    org_id: "547",
    mascot: "Cardinals",
    encoded_h: "YzU0Nw==",
    encoded_c: "YzU0N19j",
    encoded_q: "YzU0N19x",
    encoded_p: "YzU0N19w",
  },
  {
    school_code: "548",
    name: "Plymouth St.",
    division: 3,
    org_id: "548",
    mascot: "Panthers",
    encoded_h: "YzU0OA==",
    encoded_c: "YzU0OF9j",
    encoded_q: "YzU0OF9x",
    encoded_p: "YzU0OF9w",
  },
  {
    school_code: "481",
    name: "Polytechnic (NY)",
    division: 3,
    org_id: "481",
    mascot: "Blue Jays",
    encoded_h: "YzQ4MQ==",
    encoded_c: "YzQ4MV9j",
    encoded_q: "YzQ4MV9x",
    encoded_p: "YzQ4MV9w",
  },
  {
    school_code: "549",
    name: "Pomona-Pitzer",
    division: 3,
    org_id: "549",
    mascot: "Sagehens",
    encoded_h: "YzU0OQ==",
    encoded_c: "YzU0OV9j",
    encoded_q: "YzU0OV9x",
    encoded_p: "YzU0OV9w",
  },
  {
    school_code: "30034",
    name: "Presentation",
    division: 3,
    org_id: "30034",
    mascot: "Saints",
    encoded_h: "YzMwMDM0",
    encoded_c: "YzMwMDM0X2M=",
    encoded_q: "YzMwMDM0X3E=",
    encoded_p: "YzMwMDM0X3A=",
  },
  {
    school_code: "555",
    name: "Principia",
    division: 3,
    org_id: "555",
    mascot: "Panthers",
    encoded_h: "YzU1NQ==",
    encoded_c: "YzU1NV9j",
    encoded_q: "YzU1NV9x",
    encoded_p: "YzU1NV9w",
  },
  {
    school_code: "557",
    name: "Puget Sound",
    division: 3,
    org_id: "557",
    mascot: "Loggers",
    encoded_h: "YzU1Nw==",
    encoded_c: "YzU1N19j",
    encoded_q: "YzU1N19x",
    encoded_p: "YzU1N19w",
  },
  {
    school_code: "30029",
    name: "Purchase St.",
    division: 3,
    org_id: "30029",
    mascot: "Panthers",
    encoded_h: "YzMwMDI5",
    encoded_c: "YzMwMDI5X2M=",
    encoded_q: "YzMwMDI5X3E=",
    encoded_p: "YzMwMDI5X3A=",
  },
  {
    school_code: "30222",
    name: "Purdue Northwest",
    division: 2,
    org_id: "30222",
    mascot: "Pride",
    encoded_h: "YzMwMjIy",
    encoded_c: "YzMwMjIyX2M=",
    encoded_q: "YzMwMjIyX3E=",
    encoded_p: "YzMwMjIyX3A=",
  },
  {
    school_code: "564",
    name: "Ramapo",
    division: 3,
    org_id: "564",
    mascot: "Roadrunners",
    encoded_h: "YzU2NA==",
    encoded_c: "YzU2NF9j",
    encoded_q: "YzU2NF9x",
    encoded_p: "YzU2NF9w",
  },
  {
    school_code: "565",
    name: "Randolph-Macon",
    division: 3,
    org_id: "565",
    mascot: "Yellow Jackets",
    encoded_h: "YzU2NQ==",
    encoded_c: "YzU2NV9j",
    encoded_q: "YzU2NV9x",
    encoded_p: "YzU2NV9w",
  },
  {
    school_code: "567",
    name: "Redlands",
    division: 3,
    org_id: "567",
    mascot: "Bulldogs",
    encoded_h: "YzU2Nw==",
    encoded_c: "YzU2N19j",
    encoded_q: "YzU2N19x",
    encoded_p: "YzU2N19w",
  },
  {
    school_code: "570",
    name: "Rensselaer",
    division: 3,
    org_id: "570",
    mascot: "Engineers",
    encoded_h: "YzU3MA==",
    encoded_c: "YzU3MF9j",
    encoded_q: "YzU3MF9x",
    encoded_p: "YzU3MF9w",
  },
  {
    school_code: "571",
    name: "Rhode Island Col.",
    division: 3,
    org_id: "571",
    mascot: "Anchormen",
    encoded_h: "YzU3MQ==",
    encoded_c: "YzU3MV9j",
    encoded_q: "YzU3MV9x",
    encoded_p: "YzU3MV9w",
  },
  {
    school_code: "573",
    name: "Rhodes",
    division: 3,
    org_id: "573",
    mascot: "Lynx",
    encoded_h: "YzU3Mw==",
    encoded_c: "YzU3M19j",
    encoded_q: "YzU3M19x",
    encoded_p: "YzU3M19w",
  },
  {
    school_code: "681",
    name: "Richard Stockton",
    division: 3,
    org_id: "681",
    mascot: "Ospreys",
    encoded_h: "YzY4MQ==",
    encoded_c: "YzY4MV9j",
    encoded_q: "YzY4MV9x",
    encoded_p: "YzY4MV9w",
  },
  {
    school_code: "577",
    name: "Ripon",
    division: 3,
    org_id: "577",
    mascot: "Red Hawks",
    encoded_h: "YzU3Nw==",
    encoded_c: "YzU3N19j",
    encoded_q: "YzU3N19x",
    encoded_p: "YzU3N19w",
  },
  {
    school_code: "18422",
    name: "Rivier",
    division: 3,
    org_id: "18422",
    mascot: "Raiders",
    encoded_h: "YzE4NDIy",
    encoded_c: "YzE4NDIyX2M=",
    encoded_q: "YzE4NDIyX3E=",
    encoded_p: "YzE4NDIyX3A=",
  },
  {
    school_code: "578",
    name: "Roanoke",
    division: 3,
    org_id: "578",
    mascot: "Maroons",
    encoded_h: "YzU3OA==",
    encoded_c: "YzU3OF9j",
    encoded_q: "YzU3OF9x",
    encoded_p: "YzU3OF9w",
  },
  {
    school_code: "581",
    name: "Rochester (NY)",
    division: 3,
    org_id: "581",
    mascot: "Yellowjackets",
    encoded_h: "YzU4MQ==",
    encoded_c: "YzU4MV9j",
    encoded_q: "YzU4MV9x",
    encoded_p: "YzU4MV9w",
  },
  {
    school_code: "580",
    name: "Rochester Inst.",
    division: 3,
    org_id: "580",
    mascot: "Tigers",
    encoded_h: "YzU4MA==",
    encoded_c: "YzU4MF9j",
    encoded_q: "YzU4MF9x",
    encoded_p: "YzU4MF9w",
  },
  {
    school_code: "582",
    name: "Rockford",
    division: 3,
    org_id: "582",
    mascot: "Regents",
    encoded_h: "YzU4Mg==",
    encoded_c: "YzU4Ml9j",
    encoded_q: "YzU4Ml9x",
    encoded_p: "YzU4Ml9w",
  },
  {
    school_code: "583",
    name: "Roger Williams",
    division: 3,
    org_id: "583",
    mascot: "Hawks",
    encoded_h: "YzU4Mw==",
    encoded_c: "YzU4M19j",
    encoded_q: "YzU4M19x",
    encoded_p: "YzU4M19w",
  },
  {
    school_code: "585",
    name: "Rose-Hulman",
    division: 3,
    org_id: "585",
    mascot: "Fightin' Engineers",
    encoded_h: "YzU4NQ==",
    encoded_c: "YzU4NV9j",
    encoded_q: "YzU4NV9x",
    encoded_p: "YzU4NV9w",
  },
  {
    school_code: "15882",
    name: "Rosemont",
    division: 3,
    org_id: "15882",
    encoded_h: "YzE1ODgy",
    encoded_c: "YzE1ODgyX2M=",
    encoded_q: "YzE1ODgyX3E=",
    encoded_p: "YzE1ODgyX3A=",
  },
  {
    school_code: "259",
    name: "Rowan",
    division: 3,
    org_id: "259",
    mascot: "Profs",
    encoded_h: "YzI1OQ==",
    encoded_c: "YzI1OV9j",
    encoded_q: "YzI1OV9x",
    encoded_p: "YzI1OV9w",
  },
  {
    school_code: "586",
    name: "Rust",
    division: 3,
    org_id: "586",
    mascot: "Bearcats",
    encoded_h: "YzU4Ng==",
    encoded_c: "YzU4Nl9j",
    encoded_q: "YzU4Nl9x",
    encoded_p: "YzU4Nl9w",
  },
  {
    school_code: "588",
    name: "Rutgers-Camden",
    division: 3,
    org_id: "588",
    mascot: "Scarlet Raptors",
    encoded_h: "YzU4OA==",
    encoded_c: "YzU4OF9j",
    encoded_q: "YzU4OF9x",
    encoded_p: "YzU4OF9w",
  },
  {
    school_code: "589",
    name: "Rutgers-Newark",
    division: 3,
    org_id: "589",
    mascot: "Scarlet Raiders",
    encoded_h: "YzU4OQ==",
    encoded_c: "YzU4OV9j",
    encoded_q: "YzU4OV9x",
    encoded_p: "YzU4OV9w",
  },
  {
    school_code: "16006",
    name: "SCAD",
    division: 3,
    org_id: "16006",
    mascot: "Bees",
    encoded_h: "YzE2MDA2",
    encoded_c: "YzE2MDA2X2M=",
    encoded_q: "YzE2MDA2X3E=",
    encoded_p: "YzE2MDA2X3A=",
  },
  {
    school_code: "30165",
    name: "SUNY Canton",
    division: 3,
    org_id: "30165",
    encoded_h: "YzMwMTY1",
    encoded_c: "YzMwMTY1X2M=",
    encoded_q: "YzMwMTY1X3E=",
    encoded_p: "YzMwMTY1X3A=",
  },
  {
    school_code: "168",
    name: "SUNY Cortland",
    division: 3,
    org_id: "168",
    mascot: "Red Dragons",
    encoded_h: "YzE2OA==",
    encoded_c: "YzE2OF9j",
    encoded_q: "YzE2OF9x",
    encoded_p: "YzE2OF9w",
  },
  {
    school_code: "478",
    name: "SUNY Maritime",
    division: 3,
    org_id: "478",
    mascot: "Privateers",
    encoded_h: "YzQ3OA==",
    encoded_c: "YzQ3OF9j",
    encoded_q: "YzQ3OF9x",
    encoded_p: "YzQ3OF9w",
  },
  {
    school_code: "475",
    name: "SUNY New Paltz",
    division: 3,
    org_id: "475",
    mascot: "Hawks",
    encoded_h: "YzQ3NQ==",
    encoded_c: "YzQ3NV9j",
    encoded_q: "YzQ3NV9x",
    encoded_p: "YzQ3NV9w",
  },
  {
    school_code: "526",
    name: "SUNY Oneonta",
    division: 3,
    org_id: "526",
    mascot: "Red Dragons",
    encoded_h: "YzUyNg==",
    encoded_c: "YzUyNl9j",
    encoded_q: "YzUyNl9x",
    encoded_p: "YzUyNl9w",
  },
  {
    school_code: "552",
    name: "SUNY Potsdam",
    division: 3,
    org_id: "552",
    mascot: "Bears",
    encoded_h: "YzU1Mg==",
    encoded_c: "YzU1Ml9j",
    encoded_q: "YzU1Ml9x",
    encoded_p: "YzU1Ml9w",
  },
  {
    school_code: "9500",
    name: "SUNYIT",
    division: 3,
    org_id: "9500",
    mascot: "Wildcats",
    encoded_h: "Yzk1MDA=",
    encoded_c: "Yzk1MDBfYw==",
    encoded_q: "Yzk1MDBfcQ==",
    encoded_p: "Yzk1MDBfcA==",
  },
  {
    school_code: "602",
    name: "Saint John's (MN)",
    division: 3,
    org_id: "602",
    mascot: "Johnnies",
    encoded_h: "YzYwMg==",
    encoded_c: "YzYwMl9j",
    encoded_q: "YzYwMl9x",
    encoded_p: "YzYwMl9w",
  },
  {
    school_code: "612",
    name: "Saint Mary's (MN)",
    division: 3,
    org_id: "612",
    mascot: "Cardinals",
    encoded_h: "YzYxMg==",
    encoded_c: "YzYxMl9j",
    encoded_q: "YzYxMl9x",
    encoded_p: "YzYxMl9w",
  },
  {
    school_code: "30064",
    name: "Saint Vincent",
    division: 3,
    org_id: "30064",
    mascot: "Bearcats",
    encoded_h: "YzMwMDY0",
    encoded_c: "YzMwMDY0X2M=",
    encoded_q: "YzMwMDY0X3E=",
    encoded_p: "YzMwMDY0X3A=",
  },
  {
    school_code: "621",
    name: "Salem St.",
    division: 3,
    org_id: "621",
    mascot: "Vikings",
    encoded_h: "YzYyMQ==",
    encoded_c: "YzYyMV9j",
    encoded_q: "YzYyMV9x",
    encoded_p: "YzYyMV9w",
  },
  {
    school_code: "622",
    name: "Salisbury",
    division: 3,
    org_id: "622",
    mascot: "Sea Gulls",
    encoded_h: "YzYyMg==",
    encoded_c: "YzYyMl9j",
    encoded_q: "YzYyMl9x",
    encoded_p: "YzYyMl9w",
  },
  {
    school_code: "623",
    name: "Salve Regina",
    division: 3,
    org_id: "623",
    mascot: "Seahawks",
    encoded_h: "YzYyMw==",
    encoded_c: "YzYyM19j",
    encoded_q: "YzYyM19x",
    encoded_p: "YzYyM19w",
  },
  {
    school_code: "1355",
    name: "Schreiner",
    division: 3,
    org_id: "1355",
    mascot: "Mountaineers",
    encoded_h: "YzEzNTU=",
    encoded_c: "YzEzNTVfYw==",
    encoded_q: "YzEzNTVfcQ==",
    encoded_p: "YzEzNTVfcA==",
  },
  {
    school_code: "633",
    name: "Scranton",
    division: 3,
    org_id: "633",
    mascot: "Royals",
    encoded_h: "YzYzMw==",
    encoded_c: "YzYzM19j",
    encoded_q: "YzYzM19x",
    encoded_p: "YzYzM19w",
  },
  {
    school_code: "652",
    name: "Sewanee",
    division: 3,
    org_id: "652",
    mascot: "Tigers",
    encoded_h: "YzY1Mg==",
    encoded_c: "YzY1Ml9j",
    encoded_q: "YzY1Ml9x",
    encoded_p: "YzY1Ml9w",
  },
  {
    school_code: "637",
    name: "Shenandoah",
    division: 3,
    org_id: "637",
    mascot: "Hornets",
    encoded_h: "YzYzNw==",
    encoded_c: "YzYzN19j",
    encoded_q: "YzYzN19x",
    encoded_p: "YzYzN19w",
  },
  {
    school_code: "641",
    name: "Simpson",
    division: 3,
    org_id: "641",
    mascot: "Storm",
    encoded_h: "YzY0MQ==",
    encoded_c: "YzY0MV9j",
    encoded_q: "YzY0MV9x",
    encoded_p: "YzY0MV9w",
  },
  {
    school_code: "642",
    name: "Skidmore",
    division: 3,
    org_id: "642",
    mascot: "Thoroughbreds",
    encoded_h: "YzY0Mg==",
    encoded_c: "YzY0Ml9j",
    encoded_q: "YzY0Ml9x",
    encoded_p: "YzY0Ml9w",
  },
  {
    school_code: "662",
    name: "Southern Me.",
    division: 3,
    org_id: "662",
    mascot: "Huskies",
    encoded_h: "YzY2Mg==",
    encoded_c: "YzY2Ml9j",
    encoded_q: "YzY2Ml9x",
    encoded_p: "YzY2Ml9w",
  },
  {
    school_code: "30164",
    name: "Southern Va.",
    division: 3,
    org_id: "30164",
    mascot: "Knights",
    encoded_h: "YzMwMTY0",
    encoded_c: "YzMwMTY0X2M=",
    encoded_q: "YzMwMTY0X3E=",
    encoded_p: "YzMwMTY0X3A=",
  },
  {
    school_code: "1379",
    name: "Southern Vt.",
    division: 3,
    org_id: "1379",
    mascot: "Mountaineers",
    encoded_h: "YzEzNzk=",
    encoded_c: "YzEzNzlfYw==",
    encoded_q: "YzEzNzlfcQ==",
    encoded_p: "YzEzNzlfcA==",
  },
  {
    school_code: "8746",
    name: "Southwestern (TX)",
    division: 3,
    org_id: "8746",
    mascot: "Pirates",
    encoded_h: "Yzg3NDY=",
    encoded_c: "Yzg3NDZfYw==",
    encoded_q: "Yzg3NDZfcQ==",
    encoded_p: "Yzg3NDZfcA==",
  },
  {
    school_code: "23260",
    name: "Spalding",
    division: 3,
    org_id: "23260",
    mascot: "Golden Eagles",
    encoded_h: "YzIzMjYw",
    encoded_c: "YzIzMjYwX2M=",
    encoded_q: "YzIzMjYwX3E=",
    encoded_p: "YzIzMjYwX3A=",
  },
  {
    school_code: "673",
    name: "Springfield",
    division: 3,
    org_id: "673",
    mascot: "Pride",
    encoded_h: "YzY3Mw==",
    encoded_c: "YzY3M19j",
    encoded_q: "YzY3M19x",
    encoded_p: "YzY3M19w",
  },
  {
    school_code: "601",
    name: "St. John Fisher",
    division: 3,
    org_id: "601",
    mascot: "Cardinals",
    encoded_h: "YzYwMQ==",
    encoded_c: "YzYwMV9j",
    encoded_q: "YzYwMV9x",
    encoded_p: "YzYwMV9w",
  },
  {
    school_code: "30072",
    name: "St. Joseph's (Brkln)",
    division: 3,
    org_id: "30072",
    mascot: "Bears",
    encoded_h: "YzMwMDcy",
    encoded_c: "YzMwMDcyX2M=",
    encoded_q: "YzMwMDcyX3E=",
    encoded_p: "YzMwMDcyX3A=",
  },
  {
    school_code: "1340",
    name: "St. Joseph's (L.I.)",
    division: 3,
    org_id: "1340",
    mascot: "Golden Eagles",
    encoded_h: "YzEzNDA=",
    encoded_c: "YzEzNDBfYw==",
    encoded_q: "YzEzNDBfcQ==",
    encoded_p: "YzEzNDBfcA==",
  },
  {
    school_code: "605",
    name: "St. Joseph's (ME)",
    division: 3,
    org_id: "605",
    mascot: "Monks",
    encoded_h: "YzYwNQ==",
    encoded_c: "YzYwNV9j",
    encoded_q: "YzYwNV9x",
    encoded_p: "YzYwNV9w",
  },
  {
    school_code: "607",
    name: "St. Lawrence",
    division: 3,
    org_id: "607",
    mascot: "Saints",
    encoded_h: "YzYwNw==",
    encoded_c: "YzYwN19j",
    encoded_q: "YzYwN19x",
    encoded_p: "YzYwN19w",
  },
  {
    school_code: "611",
    name: "St. Mary's (MD)",
    division: 3,
    org_id: "611",
    mascot: "Seahawks",
    encoded_h: "YzYxMQ==",
    encoded_c: "YzYxMV9j",
    encoded_q: "YzYxMV9x",
    encoded_p: "YzYxMV9w",
  },
  {
    school_code: "614",
    name: "St. Norbert",
    division: 3,
    org_id: "614",
    mascot: "Green Knights",
    encoded_h: "YzYxNA==",
    encoded_c: "YzYxNF9j",
    encoded_q: "YzYxNF9x",
    encoded_p: "YzYxNF9w",
  },
  {
    school_code: "615",
    name: "St. Olaf",
    division: 3,
    org_id: "615",
    mascot: "Oles",
    encoded_h: "YzYxNQ==",
    encoded_c: "YzYxNV9j",
    encoded_q: "YzYxNV9x",
    encoded_p: "YzYxNV9w",
  },
  {
    school_code: "618",
    name: "St. Scholastica",
    division: 3,
    org_id: "618",
    mascot: "Saints",
    encoded_h: "YzYxOA==",
    encoded_c: "YzYxOF9j",
    encoded_q: "YzYxOF9x",
    encoded_p: "YzYxOF9w",
  },
  {
    school_code: "620",
    name: "St. Thomas (MN)",
    division: 3,
    org_id: "620",
    mascot: "Tommies",
    encoded_h: "YzYyMA==",
    encoded_c: "YzYyMF9j",
    encoded_q: "YzYyMF9x",
    encoded_p: "YzYyMF9w",
  },
  {
    school_code: "2810",
    name: "St. Thomas (TX)",
    division: 3,
    org_id: "2810",
    mascot: "Celts",
    encoded_h: "YzI4MTA=",
    encoded_c: "YzI4MTBfcQ==",
    encoded_q: "YzI4MTBfYw==",
    encoded_p: "YzI4MTBfcA==",
  },
  {
    school_code: "675",
    name: "Staten Island",
    division: 3,
    org_id: "675",
    mascot: "Dolphins",
    encoded_h: "YzY3NQ==",
    encoded_c: "YzY3NV9j",
    encoded_q: "YzY3NV9x",
    encoded_p: "YzY3NV9w",
  },
  {
    school_code: "679",
    name: "Stevens",
    division: 3,
    org_id: "679",
    mascot: "Ducks",
    encoded_h: "YzY3OQ==",
    encoded_c: "YzY3OV9j",
    encoded_q: "YzY3OV9x",
    encoded_p: "YzY3OV9w",
  },
  {
    school_code: "21852",
    name: "Stevenson",
    division: 3,
    org_id: "21852",
    mascot: "Mustangs",
    encoded_h: "YzIxODUy",
    encoded_c: "YzIxODUyX2M=",
    encoded_q: "YzIxODUyX3E=",
    encoded_p: "YzIxODUyX3A=",
  },
  {
    school_code: "684",
    name: "Suffolk",
    division: 3,
    org_id: "684",
    mascot: "Rams",
    encoded_h: "YzY4NA==",
    encoded_c: "YzY4NF9j",
    encoded_q: "YzY4NF9x",
    encoded_p: "YzY4NF9w",
  },
  {
    school_code: "1390",
    name: "Sul Ross St.",
    division: 3,
    org_id: "1390",
    mascot: "Lobos",
    encoded_h: "YzEzOTA=",
    encoded_c: "YzEzOTBfYw==",
    encoded_q: "YzEzOTBfcQ==",
    encoded_p: "YzEzOTBfcA==",
  },
  {
    school_code: "685",
    name: "Susquehanna",
    division: 3,
    org_id: "685",
    mascot: "Crusaders",
    encoded_h: "YzY4NQ==",
    encoded_c: "YzY4NV9j",
    encoded_q: "YzY4NV9x",
    encoded_p: "YzY4NV9w",
  },
  {
    school_code: "686",
    name: "Swarthmore",
    division: 3,
    org_id: "686",
    mascot: "Garnet",
    encoded_h: "YzY4Ng==",
    encoded_c: "YzY4Nl9j",
    encoded_q: "YzY4Nl9x",
    encoded_p: "YzY4Nl9w",
  },
  {
    school_code: "712",
    name: "TCNJ",
    division: 3,
    org_id: "712",
    mascot: "Lions",
    encoded_h: "YzcxMg==",
    encoded_c: "YzcxMl9j",
    encoded_q: "YzcxMl9x",
    encoded_p: "YzcxMl9w",
  },
  {
    school_code: "1400",
    name: "Texas Lutheran",
    division: 3,
    org_id: "1400",
    mascot: "Bulldogs",
    encoded_h: "YzE0MDA=",
    encoded_c: "YzE0MDBfYw==",
    encoded_q: "YzE0MDBfcQ==",
    encoded_p: "YzE0MDBfcA==",
  },
  {
    school_code: "23121",
    name: "Texas-Dallas",
    division: 3,
    org_id: "23121",
    mascot: "Comets",
    encoded_h: "YzIzMTIx",
    encoded_c: "YzIzMTIxX2M=",
    encoded_q: "YzIzMTIxX3E=",
    encoded_p: "YzIzMTIxX3A=",
  },
  {
    school_code: "30028",
    name: "Texas-Tyler",
    division: 3,
    org_id: "30028",
    mascot: "Patriots",
    encoded_h: "YzMwMDI4",
    encoded_c: "YzMwMDI4X2M=",
    encoded_q: "YzMwMDI4X3E=",
    encoded_p: "YzMwMDI4X3A=",
  },
  {
    school_code: "707",
    name: "Thiel",
    division: 3,
    org_id: "707",
    mascot: "Tomcats",
    encoded_h: "YzcwNw==",
    encoded_c: "YzcwN19j",
    encoded_q: "YzcwN19x",
    encoded_p: "YzcwN19w",
  },
  {
    school_code: "2758",
    name: "Thomas (ME)",
    division: 3,
    org_id: "2758",
    mascot: "Terriers",
    encoded_h: "YzI3NTg=",
    encoded_c: "YzI3NThfYw==",
    encoded_q: "YzI3NThfcQ==",
    encoded_p: "YzI3NThfcA==",
  },
  {
    school_code: "1402",
    name: "Thomas More",
    division: 3,
    org_id: "1402",
    mascot: "Saints",
    encoded_h: "YzE0MDI=",
    encoded_c: "YzE0MDJfYw==",
    encoded_q: "YzE0MDJfcQ==",
    encoded_p: "YzE0MDJfcA==",
  },
  {
    school_code: "28749",
    name: "Transylvania",
    division: 3,
    org_id: "28749",
    mascot: "Pioneers",
    encoded_h: "YzI4NzQ5",
    encoded_c: "YzI4NzQ5X2M=",
    encoded_q: "YzI4NzQ5X3E=",
    encoded_p: "YzI4NzQ5X3A=",
  },
  {
    school_code: "30037",
    name: "Trine",
    division: 3,
    org_id: "30037",
    mascot: "Thunder",
    encoded_h: "YzMwMDM3",
    encoded_c: "YzMwMDM3X2M=",
    encoded_q: "YzMwMDM3X3E=",
    encoded_p: "YzMwMDM3X3A=",
  },
  {
    school_code: "713",
    name: "Trinity (CT)",
    division: 3,
    org_id: "713",
    mascot: "Bantams",
    encoded_h: "YzcxMw==",
    encoded_c: "YzcxM19j",
    encoded_q: "YzcxM19x",
    encoded_p: "YzcxM19w",
  },
  {
    school_code: "715",
    name: "Trinity (TX)",
    division: 3,
    org_id: "715",
    mascot: "Tigers",
    encoded_h: "YzcxNQ==",
    encoded_c: "YzcxNV9j",
    encoded_q: "YzcxNV9x",
    encoded_p: "YzcxNV9w",
  },
  {
    school_code: "717",
    name: "Tufts",
    division: 3,
    org_id: "717",
    mascot: "Jumbos",
    encoded_h: "YzcxNw==",
    encoded_c: "YzcxN19j",
    encoded_q: "YzcxN19x",
    encoded_p: "YzcxN19w",
  },
  {
    school_code: "401",
    name: "UMass Boston",
    division: 3,
    org_id: "401",
    mascot: "Beacons",
    encoded_h: "YzQwMQ==",
    encoded_c: "YzQwMV9j",
    encoded_q: "YzQwMV9x",
    encoded_p: "YzQwMV9w",
  },
  {
    school_code: "656",
    name: "UMass Dartmouth",
    division: 3,
    org_id: "656",
    mascot: "Corsairs",
    encoded_h: "YzY1Ng==",
    encoded_c: "YzY1Nl9j",
    encoded_q: "YzY1Nl9x",
    encoded_p: "YzY1Nl9w",
  },
  {
    school_code: "727",
    name: "Union (NY)",
    division: 3,
    org_id: "727",
    mascot: "Dutchmen",
    encoded_h: "YzcyNw==",
    encoded_c: "YzcyN19j",
    encoded_q: "YzcyN19x",
    encoded_p: "YzcyN19w",
  },
  {
    school_code: "730",
    name: "Ursinus",
    division: 3,
    org_id: "730",
    mascot: "Bears",
    encoded_h: "YzczMA==",
    encoded_c: "YzczMF9j",
    encoded_q: "YzczMF9x",
    encoded_p: "YzczMF9w",
  },
  {
    school_code: "733",
    name: "Utica",
    division: 3,
    org_id: "733",
    mascot: "Pioneers",
    encoded_h: "YzczMw==",
    encoded_c: "YzczM19j",
    encoded_q: "YzczM19x",
    encoded_p: "YzczM19w",
  },
  {
    school_code: "745",
    name: "Va. Wesleyan",
    division: 3,
    org_id: "745",
    mascot: "Marlins",
    encoded_h: "Yzc0NQ==",
    encoded_c: "Yzc0NV9j",
    encoded_q: "Yzc0NV9x",
    encoded_p: "Yzc0NV9w",
  },
  {
    school_code: "30166",
    name: "Valley Forge",
    division: 3,
    org_id: "30166",
    mascot: "The Patriots",
    encoded_h: "YzMwMTY2",
    encoded_c: "YzMwMTY2X2M=",
    encoded_q: "YzMwMTY2X3E=",
    encoded_p: "YzMwMTY2X3A=",
  },
  {
    school_code: "737",
    name: "Vassar",
    division: 3,
    org_id: "737",
    mascot: "Brewers",
    encoded_h: "YzczNw==",
    encoded_c: "YzczN19j",
    encoded_q: "YzczN19x",
    encoded_p: "YzczN19w",
  },
  {
    school_code: "808",
    name: "WPI",
    division: 3,
    org_id: "808",
    mascot: "Engineers",
    encoded_h: "YzgwOA==",
    encoded_c: "YzgwOF9j",
    encoded_q: "YzgwOF9x",
    encoded_p: "YzgwOF9w",
  },
  {
    school_code: "747",
    name: "Wabash",
    division: 3,
    org_id: "747",
    mascot: "Little Giants",
    encoded_h: "Yzc0Nw==",
    encoded_c: "Yzc0N19j",
    encoded_q: "Yzc0N19x",
    encoded_p: "Yzc0N19w",
  },
  {
    school_code: "750",
    name: "Wartburg",
    division: 3,
    org_id: "750",
    mascot: "Knights",
    encoded_h: "Yzc1MA==",
    encoded_c: "Yzc1MF9j",
    encoded_q: "Yzc1MF9x",
    encoded_p: "Yzc1MF9w",
  },
  {
    school_code: "751",
    name: "Wash. & Jeff.",
    division: 3,
    org_id: "751",
    mascot: "Presidents",
    encoded_h: "Yzc1MQ==",
    encoded_c: "Yzc1MV9j",
    encoded_q: "Yzc1MV9x",
    encoded_p: "Yzc1MV9w",
  },
  {
    school_code: "752",
    name: "Wash. & Lee",
    division: 3,
    org_id: "752",
    mascot: "Generals",
    encoded_h: "Yzc1Mg==",
    encoded_c: "Yzc1Ml9j",
    encoded_q: "Yzc1Ml9x",
    encoded_p: "Yzc1Ml9w",
  },
  {
    school_code: "753",
    name: "Washington Col.",
    division: 3,
    org_id: "753",
    mascot: "Shoremen",
    encoded_h: "Yzc1Mw==",
    encoded_c: "Yzc1M19j",
    encoded_q: "Yzc1M19x",
    encoded_p: "Yzc1M19w",
  },
  {
    school_code: "755",
    name: "Washington-St. Louis",
    division: 3,
    org_id: "755",
    mascot: "Bears",
    encoded_h: "Yzc1NQ==",
    encoded_c: "Yzc1NV9j",
    encoded_q: "Yzc1NV9x",
    encoded_p: "Yzc1NV9w",
  },
  {
    school_code: "1434",
    name: "Waynesburg",
    division: 3,
    org_id: "1434",
    mascot: "Yellow Jackets",
    encoded_h: "YzE0MzQ=",
    encoded_c: "YzE0MzRfYw==",
    encoded_q: "YzE0MzRfcQ==",
    encoded_p: "YzE0MzRfcA==",
  },
  {
    school_code: "759",
    name: "Webster",
    division: 3,
    org_id: "759",
    mascot: "Gorloks",
    encoded_h: "Yzc1OQ==",
    encoded_c: "Yzc1OV9j",
    encoded_q: "Yzc1OV9x",
    encoded_p: "Yzc1OV9w",
  },
  {
    school_code: "761",
    name: "Wells",
    division: 3,
    org_id: "761",
    mascot: "Express",
    encoded_h: "Yzc2MQ==",
    encoded_c: "Yzc2MV9j",
    encoded_q: "Yzc2MV9x",
    encoded_p: "Yzc2MV9w",
  },
  {
    school_code: "762",
    name: "Wentworth Inst.",
    division: 3,
    org_id: "762",
    mascot: "Leopards",
    encoded_h: "Yzc2Mg==",
    encoded_c: "Yzc2Ml9j",
    encoded_q: "Yzc2Ml9x",
    encoded_p: "Yzc2Ml9w",
  },
  {
    school_code: "763",
    name: "Wesley",
    division: 3,
    org_id: "763",
    mascot: "Wolverines",
    encoded_h: "Yzc2Mw==",
    encoded_c: "Yzc2M19j",
    encoded_q: "Yzc2M19x",
    encoded_p: "Yzc2M19w",
  },
  {
    school_code: "764",
    name: "Wesleyan (CT)",
    division: 3,
    org_id: "764",
    mascot: "Cardinals",
    encoded_h: "Yzc2NA==",
    encoded_c: "Yzc2NF9j",
    encoded_q: "Yzc2NF9x",
    encoded_p: "Yzc2NF9w",
  },
  {
    school_code: "770",
    name: "Western Conn. St.",
    division: 3,
    org_id: "770",
    mascot: "Colonials",
    encoded_h: "Yzc3MA==",
    encoded_c: "Yzc3MF9j",
    encoded_q: "Yzc3MF9x",
    encoded_p: "Yzc3MF9w",
  },
  {
    school_code: "775",
    name: "Western New Eng.",
    division: 3,
    org_id: "775",
    mascot: "Golden Bears",
    encoded_h: "Yzc3NQ==",
    encoded_c: "Yzc3NV9j",
    encoded_q: "Yzc3NV9x",
    encoded_p: "Yzc3NV9w",
  },
  {
    school_code: "777",
    name: "Westfield St.",
    division: 3,
    org_id: "777",
    mascot: "Owls",
    encoded_h: "Yzc3Nw==",
    encoded_c: "Yzc3N19j",
    encoded_q: "Yzc3N19x",
    encoded_p: "Yzc3N19w",
  },
  {
    school_code: "1450",
    name: "Westminster (MO)",
    division: 3,
    org_id: "1450",
    mascot: "Blue Jays",
    encoded_h: "YzE0NTA=",
    encoded_c: "YzE0NTBfYw==",
    encoded_q: "YzE0NTBfcQ==",
    encoded_p: "YzE0NTBfcA==",
  },
  {
    school_code: "1451",
    name: "Westminster (PA)",
    division: 3,
    org_id: "1451",
    mascot: "Titans",
    encoded_h: "YzE0NTE=",
    encoded_c: "YzE0NTFfYw==",
    encoded_q: "YzE0NTFfcQ==",
    encoded_p: "YzE0NTFfcA==",
  },
  {
    school_code: "778",
    name: "Wheaton (IL)",
    division: 3,
    org_id: "778",
    mascot: "Thunder",
    encoded_h: "Yzc3OA==",
    encoded_c: "Yzc3OF9j",
    encoded_q: "Yzc3OF9x",
    encoded_p: "Yzc3OF9w",
  },
  {
    school_code: "779",
    name: "Wheaton (MA)",
    division: 3,
    org_id: "779",
    mascot: "Lyons",
    encoded_h: "Yzc3OQ==",
    encoded_c: "Yzc3OV9j",
    encoded_q: "Yzc3OV9x",
    encoded_p: "Yzc3OV9w",
  },
  {
    school_code: "780",
    name: "Whitman",
    division: 3,
    org_id: "780",
    mascot: "Missionaries",
    encoded_h: "Yzc4MA==",
    encoded_c: "Yzc4MF9j",
    encoded_q: "Yzc4MF9x",
    encoded_p: "Yzc4MF9w",
  },
  {
    school_code: "781",
    name: "Whittier",
    division: 3,
    org_id: "781",
    mascot: "Poets",
    encoded_h: "Yzc4MQ==",
    encoded_c: "Yzc4MV9j",
    encoded_q: "Yzc4MV9x",
    encoded_p: "Yzc4MV9w",
  },
  {
    school_code: "1454",
    name: "Whitworth",
    division: 3,
    org_id: "1454",
    mascot: "Pirates",
    encoded_h: "YzE0NTQ=",
    encoded_c: "YzE0NTRfYw==",
    encoded_q: "YzE0NTRfcQ==",
    encoded_p: "YzE0NTRfcA==",
  },
  {
    school_code: "783",
    name: "Widener",
    division: 3,
    org_id: "783",
    mascot: "Pride",
    encoded_h: "Yzc4Mw==",
    encoded_c: "Yzc4M19j",
    encoded_q: "Yzc4M19x",
    encoded_p: "Yzc4M19w",
  },
  {
    school_code: "784",
    name: "Wilkes",
    division: 3,
    org_id: "784",
    mascot: "Colonels",
    encoded_h: "Yzc4NA==",
    encoded_c: "Yzc4NF9j",
    encoded_q: "Yzc4NF9x",
    encoded_p: "Yzc4NF9w",
  },
  {
    school_code: "785",
    name: "Willamette",
    division: 3,
    org_id: "785",
    mascot: "Bearcats",
    encoded_h: "Yzc4NQ==",
    encoded_c: "Yzc4NV9j",
    encoded_q: "Yzc4NV9x",
    encoded_p: "Yzc4NV9w",
  },
  {
    school_code: "789",
    name: "Williams",
    division: 3,
    org_id: "789",
    mascot: "Ephs",
    encoded_h: "Yzc4OQ==",
    encoded_c: "Yzc4OV9j",
    encoded_q: "Yzc4OV9x",
    encoded_p: "Yzc4OV9w",
  },
  {
    school_code: "1461",
    name: "Wilmington (OH)",
    division: 3,
    org_id: "1461",
    mascot: "Fightin' Quakers",
    encoded_h: "YzE0NjE=",
    encoded_c: "YzE0NjFfYw==",
    encoded_q: "YzE0NjFfcQ==",
    encoded_p: "YzE0NjFfcA==",
  },
  {
    school_code: "12973",
    name: "Wilson",
    division: 3,
    org_id: "12973",
    mascot: "Phoenix",
    encoded_h: "YzEyOTcz",
    encoded_c: "YzEyOTczX2M=",
    encoded_q: "YzEyOTczX3E=",
    encoded_p: "YzEyOTczX3A=",
  },
  {
    school_code: "8911",
    name: "Wis. Lutheran",
    division: 3,
    org_id: "8911",
    mascot: "Warriors",
    encoded_h: "Yzg5MTE=",
    encoded_c: "Yzg5MTFfYw==",
    encoded_q: "Yzg5MTFfcQ==",
    encoded_p: "Yzg5MTFfcA==",
  },
  {
    school_code: "793",
    name: "Wis.-Eau Claire",
    division: 3,
    org_id: "793",
    encoded_h: "Yzc5Mw==",
    encoded_c: "Yzc5M19j",
    encoded_q: "Yzc5M19x",
    encoded_p: "Yzc5M19w",
  },
  {
    school_code: "795",
    name: "Wis.-La Crosse",
    division: 3,
    org_id: "795",
    mascot: "Eagles",
    encoded_h: "Yzc5NQ==",
    encoded_c: "Yzc5NV9j",
    encoded_q: "Yzc5NV9x",
    encoded_p: "Yzc5NV9w",
  },
  {
    school_code: "798",
    name: "Wis.-Oshkosh",
    division: 3,
    org_id: "798",
    mascot: "Titans",
    encoded_h: "Yzc5OA==",
    encoded_c: "Yzc5OF9j",
    encoded_q: "Yzc5OF9x",
    encoded_p: "Yzc5OF9w",
  },
  {
    school_code: "800",
    name: "Wis.-Platteville",
    division: 3,
    org_id: "800",
    mascot: "Pioneers",
    encoded_h: "YzgwMA==",
    encoded_c: "YzgwMF9j",
    encoded_q: "YzgwMF9x",
    encoded_p: "YzgwMF9w",
  },
  {
    school_code: "801",
    name: "Wis.-River Falls",
    division: 3,
    org_id: "801",
    mascot: "Falcons",
    encoded_h: "YzgwMQ==",
    encoded_c: "YzgwMV9j",
    encoded_q: "YzgwMV9x",
    encoded_p: "YzgwMV9w",
  },
  {
    school_code: "802",
    name: "Wis.-Stevens Point",
    division: 3,
    org_id: "802",
    mascot: "Pointers",
    encoded_h: "YzgwMg==",
    encoded_c: "YzgwMl9j",
    encoded_q: "YzgwMl9x",
    encoded_p: "YzgwMl9w",
  },
  {
    school_code: "803",
    name: "Wis.-Stout",
    division: 3,
    org_id: "803",
    mascot: "Blue Devils",
    encoded_h: "YzgwMw==",
    encoded_c: "YzgwM19j",
    encoded_q: "YzgwM19x",
    encoded_p: "YzgwM19w",
  },
  {
    school_code: "804",
    name: "Wis.-Superior",
    division: 3,
    org_id: "804",
    mascot: "Yellowjackets",
    encoded_h: "YzgwNA==",
    encoded_c: "YzgwNF9j",
    encoded_q: "YzgwNF9x",
    encoded_p: "YzgwNF9w",
  },
  {
    school_code: "805",
    name: "Wis.-Whitewater",
    division: 3,
    org_id: "805",
    mascot: "Warhawks",
    encoded_h: "YzgwNQ==",
    encoded_c: "YzgwNV9j",
    encoded_q: "YzgwNV9x",
    encoded_p: "YzgwNV9w",
  },
  {
    school_code: "806",
    name: "Wittenberg",
    division: 3,
    org_id: "806",
    mascot: "Tigers",
    encoded_h: "YzgwNg==",
    encoded_c: "YzgwNl9j",
    encoded_q: "YzgwNl9x",
    encoded_p: "YzgwNl9w",
  },
  {
    school_code: "787",
    name: "Wm. Paterson",
    division: 3,
    org_id: "787",
    mascot: "Pioneers",
    encoded_h: "Yzc4Nw==",
    encoded_c: "Yzc4N19j",
    encoded_q: "Yzc4N19x",
    encoded_p: "Yzc4N19w",
  },
  {
    school_code: "807",
    name: "Wooster",
    division: 3,
    org_id: "807",
    mascot: "Fighting Scots",
    encoded_h: "YzgwNw==",
    encoded_c: "YzgwN19j",
    encoded_q: "YzgwN19x",
    encoded_p: "YzgwN19w",
  },
  {
    school_code: "809",
    name: "Worcester St.",
    division: 3,
    org_id: "809",
    mascot: "Lancers",
    encoded_h: "YzgwOQ==",
    encoded_c: "YzgwOV9j",
    encoded_q: "YzgwOV9x",
    encoded_p: "YzgwOV9w",
  },
  {
    school_code: "814",
    name: "Yeshiva",
    division: 3,
    org_id: "814",
    mascot: "Maccabees",
    encoded_h: "YzgxNA==",
    encoded_c: "YzgxNF9j",
    encoded_q: "YzgxNF9x",
    encoded_p: "YzgxNF9w",
  },
  {
    school_code: "815",
    name: "York (PA)",
    division: 3,
    org_id: "815",
    mascot: "Spartans",
    encoded_h: "YzgxNQ==",
    encoded_c: "YzgxNV9j",
    encoded_q: "YzgxNV9x",
    encoded_p: "YzgxNV9w",
  },
  {
    school_code: "tent",
    name: "Tennessee Tech",
    division: 1,
    org_id: "692",
    mascot: "Golden Eagles",
    encoded_h: "Y3RlbnQ=",
    encoded_c: "Y3RlbnRfYw==",
    encoded_q: "Y3RlbnRfcQ==",
    encoded_p: "Y3RlbnRfcA==",
  },
  {
    school_code: "30177",
    name: "Lubbock Christian",
    division: 2,
    org_id: "30177",
    mascot: "Chaparrals",
    encoded_h: "YzMwMTc3",
    encoded_c: "YzMwMTc3X2M=",
    encoded_q: "YzMwMTc3X3E=",
    encoded_p: "YzMwMTc3X3A=",
  },
  {
    school_code: "30178",
    name: "Northwestern Oklahoma",
    division: 2,
    org_id: "30178",
    mascot: "Rangers",
    encoded_h: "YzMwMTc4",
    encoded_c: "YzMwMTc4X2M=",
    encoded_q: "YzMwMTc4X3E=",
    encoded_p: "YzMwMTc4X3A=",
  },
  {
    school_code: "30175",
    name: "Holy Names",
    division: 2,
    org_id: "30175",
    mascot: "Hawks",
    encoded_h: "YzMwMTc1",
    encoded_c: "YzMwMTc1X2M=",
    encoded_q: "YzMwMTc1X3E=",
    encoded_p: "YzMwMTc1X3A=",
  },
  {
    school_code: "30013",
    name: "Lee",
    division: 2,
    org_id: "30013",
    mascot: "Flames",
    encoded_h: "YzMwMDEz",
    encoded_c: "YzMwMDEzX2M=",
    encoded_q: "YzMwMDEzX3E=",
    encoded_p: "YzMwMDEzX3A=",
  },
  {
    school_code: "30193",
    name: "Emmanuel (GA)",
    division: 2,
    org_id: "30193",
    mascot: "Lions",
    encoded_h: "YzMwMTkz",
    encoded_c: "YzMwMTkzX2M=",
    encoded_q: "YzMwMTkzX3E=",
    encoded_p: "YzMwMTkzX3A=",
  },
  {
    school_code: "30202",
    name: "Okla. Baptist",
    division: 2,
    org_id: "30202",
    mascot: "Bison",
    encoded_h: "YzMwMjAy",
    encoded_c: "YzMwMjAyX2M=",
    encoded_q: "YzMwMjAyX3E=",
    encoded_p: "YzMwMjAyX3A=",
  },
  {
    school_code: "30179",
    name: "Oklahoma Christian",
    division: 2,
    org_id: "30179",
    mascot: "Eagles",
    encoded_h: "YzMwMTc5",
    encoded_c: "YzMwMTc5X2M=",
    encoded_q: "YzMwMTc5X3E=",
    encoded_p: "YzMwMTc5X3A=",
  },
  {
    school_code: "30180",
    name: "Rogers St.",
    division: 2,
    org_id: "30180",
    mascot: "Hillcats",
    encoded_h: "YzMwMTgw",
    encoded_c: "YzMwMTgwX2M=",
    encoded_q: "YzMwMTgwX3E=",
    encoded_p: "YzMwMTgwX3A=",
  },
  {
    school_code: "30181",
    name: "UVa-Wise",
    division: 2,
    org_id: "30181",
    mascot: "Highland Cavaliers",
    encoded_h: "YzMwMTgx",
    encoded_c: "YzMwMTgxX2M=",
    encoded_q: "YzMwMTgxX3E=",
    encoded_p: "YzMwMTgxX3A=",
  },
  {
    school_code: "17094",
    name: "William Peace",
    division: 3,
    org_id: "17094",
    mascot: "Pacers",
    encoded_h: "YzE3MDk0",
    encoded_c: "YzE3MDk0X2M=",
    encoded_q: "YzE3MDk0X3E=",
    encoded_p: "YzE3MDk0X3A=",
  },
];

export const SCHOOLS_SOFTBALL: ISchool[] = [
  {
    school_code: "sb_2",
    name: "Abilene Christian",
    division: 1,
    org_id: "sb_2",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzI=",
    encoded_c: "Y3NiXzJfYw==",
    encoded_q: "Y3NiXzJfcQ==",
    encoded_p: "Y3NiXzJfcA==",
  },
  {
    school_code: "sb_5",
    name: "Akron",
    division: 1,
    org_id: "sb_5",
    mascot: "Zips",
    encoded_h: "Y3NiXzU=",
    encoded_c: "Y3NiXzVfYw==",
    encoded_q: "Y3NiXzVfcQ==",
    encoded_p: "Y3NiXzVfcA==",
  },
  {
    school_code: "sb_8",
    name: "Alabama",
    division: 1,
    org_id: "sb_8",
    mascot: "Crimson Tide",
    encoded_h: "Y3NiXzg=",
    encoded_c: "Y3NiXzhfYw==",
    encoded_q: "Y3NiXzhfcQ==",
    encoded_p: "Y3NiXzhfcA==",
  },
  {
    school_code: "sb_6",
    name: "Alabama A&M",
    division: 1,
    org_id: "sb_6",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzY=",
    encoded_c: "Y3NiXzZfYw==",
    encoded_q: "Y3NiXzZfcQ==",
    encoded_p: "Y3NiXzZfcA==",
  },
  {
    school_code: "sb_7",
    name: "Alabama St.",
    division: 1,
    org_id: "sb_7",
    mascot: "Lady Hornets",
    encoded_h: "Y3NiXzc=",
    encoded_c: "Y3NiXzdfYw==",
    encoded_q: "Y3NiXzdfcQ==",
    encoded_p: "Y3NiXzdfcA==",
  },
  {
    school_code: "sb_14",
    name: "Albany (NY)",
    division: 1,
    org_id: "sb_14",
    mascot: "Great Danes",
    encoded_h: "Y3NiXzE0",
    encoded_c: "Y3NiXzE0X2M=",
    encoded_q: "Y3NiXzE0X3E=",
    encoded_p: "Y3NiXzE0X3A=",
  },
  {
    school_code: "sb_17",
    name: "Alcorn",
    division: 1,
    org_id: "sb_17",
    mascot: "Lady Braves",
    encoded_h: "Y3NiXzE3",
    encoded_c: "Y3NiXzE3X2M=",
    encoded_q: "Y3NiXzE3X3E=",
    encoded_p: "Y3NiXzE3X3A=",
  },
  {
    school_code: "sb_27",
    name: "Appalachian St.",
    division: 1,
    org_id: "sb_27",
    mascot: "Mountaineers",
    encoded_h: "Y3NiXzI3",
    encoded_c: "Y3NiXzI3X2M=",
    encoded_q: "Y3NiXzI3X3E=",
    encoded_p: "Y3NiXzI3X3A=",
  },
  {
    school_code: "sb_29",
    name: "Arizona",
    division: 1,
    org_id: "sb_29",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzI5",
    encoded_c: "Y3NiXzI5X2M=",
    encoded_q: "Y3NiXzI5X3E=",
    encoded_p: "Y3NiXzI5X3A=",
  },
  {
    school_code: "sb_28",
    name: "Arizona St.",
    division: 1,
    org_id: "sb_28",
    mascot: "Sun Devils",
    encoded_h: "Y3NiXzI4",
    encoded_c: "Y3NiXzI4X2M=",
    encoded_q: "Y3NiXzI4X3E=",
    encoded_p: "Y3NiXzI4X3A=",
  },
  {
    school_code: "sb_2678",
    name: "Ark.-Pine Bluff",
    division: 1,
    org_id: "sb_2678",
    mascot: "Lady Lions",
    encoded_h: "Y3NiXzI2Nzg=",
    encoded_c: "Y3NiXzI2NzhfYw==",
    encoded_q: "Y3NiXzI2NzhfcQ==",
    encoded_p: "Y3NiXzI2NzhfcA==",
  },
  {
    school_code: "sb_31",
    name: "Arkansas",
    division: 1,
    org_id: "sb_31",
    mascot: "Razorbacks",
    encoded_h: "Y3NiXzMx",
    encoded_c: "Y3NiXzMxX2M=",
    encoded_q: "Y3NiXzMxX3E=",
    encoded_p: "Y3NiXzMxX3A=",
  },
  {
    school_code: "sb_725",
    name: "Army",
    division: 1,
    org_id: "sb_725",
    mascot: "Black Knights",
    encoded_h: "Y3NiXzcyNQ==",
    encoded_c: "Y3NiXzcyNV9j",
    encoded_q: "Y3NiXzcyNV9x",
    encoded_p: "Y3NiXzcyNV9w",
  },
  {
    school_code: "sb_37",
    name: "Auburn",
    division: 1,
    org_id: "sb_37",
    mascot: "Tigers",
    encoded_h: "Y3NiXzM3",
    encoded_c: "Y3NiXzM3X2M=",
    encoded_q: "Y3NiXzM3X3E=",
    encoded_p: "Y3NiXzM3X3A=",
  },
  {
    school_code: "sb_43",
    name: "Austin Peay",
    division: 1,
    org_id: "sb_43",
    mascot: "Lady Govs",
    encoded_h: "Y3NiXzQz",
    encoded_c: "Y3NiXzQzX2M=",
    encoded_q: "Y3NiXzQzX3E=",
    encoded_p: "Y3NiXzQzX3A=",
  },
  {
    school_code: "sb_77",
    name: "BYU",
    division: 1,
    org_id: "sb_77",
    mascot: "Cougars",
    encoded_h: "Y3NiXzc3",
    encoded_c: "Y3NiXzc3X2M=",
    encoded_q: "Y3NiXzc3X3E=",
    encoded_p: "Y3NiXzc3X3A=",
  },
  {
    school_code: "sb_94",
    name: "Bakersfield",
    division: 1,
    org_id: "sb_94",
    mascot: "Roadrunners",
    encoded_h: "Y3NiXzk0",
    encoded_c: "Y3NiXzk0X2M=",
    encoded_q: "Y3NiXzk0X3E=",
    encoded_p: "Y3NiXzk0X3A=",
  },
  {
    school_code: "sb_47",
    name: "Ball St.",
    division: 1,
    org_id: "sb_47",
    mascot: "Cardinals",
    encoded_h: "Y3NiXzQ3",
    encoded_c: "Y3NiXzQ3X2M=",
    encoded_q: "Y3NiXzQ3X3E=",
    encoded_p: "Y3NiXzQ3X3A=",
  },
  {
    school_code: "sb_51",
    name: "Baylor",
    division: 1,
    org_id: "sb_51",
    mascot: "Lady Bears",
    encoded_h: "Y3NiXzUx",
    encoded_c: "Y3NiXzUxX2M=",
    encoded_q: "Y3NiXzUxX3E=",
    encoded_p: "Y3NiXzUxX3A=",
  },
  {
    school_code: "sb_14927",
    name: "Belmont",
    division: 1,
    org_id: "sb_14927",
    mascot: "Bruins",
    encoded_h: "Y3NiXzE0OTI3",
    encoded_c: "Y3NiXzE0OTI3X2M=",
    encoded_q: "Y3NiXzE0OTI3X3E=",
    encoded_p: "Y3NiXzE0OTI3X3A=",
  },
  {
    school_code: "sb_61",
    name: "Bethune-Cookman",
    division: 1,
    org_id: "sb_61",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzYx",
    encoded_c: "Y3NiXzYxX2M=",
    encoded_q: "Y3NiXzYxX3E=",
    encoded_p: "Y3NiXzYxX3A=",
  },
  {
    school_code: "sb_62",
    name: "Binghamton",
    division: 1,
    org_id: "sb_62",
    mascot: "Bearcats",
    encoded_h: "Y3NiXzYy",
    encoded_c: "Y3NiXzYyX2M=",
    encoded_q: "Y3NiXzYyX3E=",
    encoded_p: "Y3NiXzYyX3A=",
  },
  {
    school_code: "sb_66",
    name: "Boise St.",
    division: 1,
    org_id: "sb_66",
    mascot: "Broncos",
    encoded_h: "Y3NiXzY2",
    encoded_c: "Y3NiXzY2X2M=",
    encoded_q: "Y3NiXzY2X3E=",
    encoded_p: "Y3NiXzY2X3A=",
  },
  {
    school_code: "sb_67",
    name: "Boston College",
    division: 1,
    org_id: "sb_67",
    mascot: "Eagles",
    encoded_h: "Y3NiXzY3",
    encoded_c: "Y3NiXzY3X2M=",
    encoded_q: "Y3NiXzY3X3E=",
    encoded_p: "Y3NiXzY3X3A=",
  },
  {
    school_code: "sb_68",
    name: "Boston U.",
    division: 1,
    org_id: "sb_68",
    mascot: "Terriers",
    encoded_h: "Y3NiXzY4",
    encoded_c: "Y3NiXzY4X2M=",
    encoded_q: "Y3NiXzY4X3E=",
    encoded_p: "Y3NiXzY4X3A=",
  },
  {
    school_code: "sb_71",
    name: "Bowling Green",
    division: 1,
    org_id: "sb_71",
    mascot: "Falcons",
    encoded_h: "Y3NiXzcx",
    encoded_c: "Y3NiXzcxX2M=",
    encoded_q: "Y3NiXzcxX3E=",
    encoded_p: "Y3NiXzcxX3A=",
  },
  {
    school_code: "sb_72",
    name: "Bradley",
    division: 1,
    org_id: "sb_72",
    mascot: "Braves",
    encoded_h: "Y3NiXzcy",
    encoded_c: "Y3NiXzcyX2M=",
    encoded_q: "Y3NiXzcyX3E=",
    encoded_p: "Y3NiXzcyX3A=",
  },
  {
    school_code: "sb_80",
    name: "Brown",
    division: 1,
    org_id: "sb_80",
    mascot: "Bears",
    encoded_h: "Y3NiXzgw",
    encoded_c: "Y3NiXzgwX2M=",
    encoded_q: "Y3NiXzgwX3E=",
    encoded_p: "Y3NiXzgwX3A=",
  },
  {
    school_code: "sb_81",
    name: "Bryant",
    division: 1,
    org_id: "sb_81",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzgx",
    encoded_c: "Y3NiXzgxX2M=",
    encoded_q: "Y3NiXzgxX3E=",
    encoded_p: "Y3NiXzgxX3A=",
  },
  {
    school_code: "sb_83",
    name: "Bucknell",
    division: 1,
    org_id: "sb_83",
    mascot: "Bison",
    encoded_h: "Y3NiXzgz",
    encoded_c: "Y3NiXzgzX2M=",
    encoded_q: "Y3NiXzgzX3E=",
    encoded_p: "Y3NiXzgzX3A=",
  },
  {
    school_code: "sb_86",
    name: "Buffalo",
    division: 1,
    org_id: "sb_86",
    mascot: "Bulls",
    encoded_h: "Y3NiXzg2",
    encoded_c: "Y3NiXzg2X2M=",
    encoded_q: "Y3NiXzg2X3E=",
    encoded_p: "Y3NiXzg2X3A=",
  },
  {
    school_code: "sb_87",
    name: "Butler",
    division: 1,
    org_id: "sb_87",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzg3",
    encoded_c: "Y3NiXzg3X2M=",
    encoded_q: "Y3NiXzg3X3E=",
    encoded_p: "Y3NiXzg3X3A=",
  },
  {
    school_code: "sb_101",
    name: "CSUN",
    division: 1,
    org_id: "sb_101",
    mascot: "Matadors",
    encoded_h: "Y3NiXzEwMQ==",
    encoded_c: "Y3NiXzEwMV9j",
    encoded_q: "Y3NiXzEwMV9x",
    encoded_p: "Y3NiXzEwMV9w",
  },
  {
    school_code: "sb_90",
    name: "Cal Poly",
    division: 1,
    org_id: "sb_90",
    mascot: "Mustangs",
    encoded_h: "Y3NiXzkw",
    encoded_c: "Y3NiXzkwX2M=",
    encoded_q: "Y3NiXzkwX3E=",
    encoded_p: "Y3NiXzkwX3A=",
  },
  {
    school_code: "sb_97",
    name: "Cal St. Fullerton",
    division: 1,
    org_id: "sb_97",
    mascot: "Titans",
    encoded_h: "Y3NiXzk3",
    encoded_c: "Y3NiXzk3X2M=",
    encoded_q: "Y3NiXzk3X3E=",
    encoded_p: "Y3NiXzk3X3A=",
  },
  {
    school_code: "sb_107",
    name: "California",
    division: 1,
    org_id: "sb_107",
    mascot: "Golden Bears",
    encoded_h: "Y3NiXzEwNw==",
    encoded_c: "Y3NiXzEwN19j",
    encoded_q: "Y3NiXzEwN19x",
    encoded_p: "Y3NiXzEwN19w",
  },
  {
    school_code: "sb_115",
    name: "Campbell",
    division: 1,
    org_id: "sb_115",
    mascot: "Camels",
    encoded_h: "Y3NiXzExNQ==",
    encoded_c: "Y3NiXzExNV9j",
    encoded_q: "Y3NiXzExNV9x",
    encoded_p: "Y3NiXzExNV9w",
  },
  {
    school_code: "sb_116",
    name: "Canisius",
    division: 1,
    org_id: "sb_116",
    mascot: "Golden Griffins",
    encoded_h: "Y3NiXzExNg==",
    encoded_c: "Y3NiXzExNl9j",
    encoded_q: "Y3NiXzExNl9x",
    encoded_p: "Y3NiXzExNl9w",
  },
  {
    school_code: "sb_1004",
    name: "Central Ark.",
    division: 1,
    org_id: "sb_1004",
    mascot: "Bears",
    encoded_h: "Y3NiXzEwMDQ=",
    encoded_c: "Y3NiXzEwMDRfYw==",
    encoded_q: "Y3NiXzEwMDRfcQ==",
    encoded_p: "Y3NiXzEwMDRfcA==",
  },
  {
    school_code: "sb_127",
    name: "Central Conn. St.",
    division: 1,
    org_id: "sb_127",
    mascot: "Blue Devils",
    encoded_h: "Y3NiXzEyNw==",
    encoded_c: "Y3NiXzEyN19j",
    encoded_q: "Y3NiXzEyN19x",
    encoded_p: "Y3NiXzEyN19w",
  },
  {
    school_code: "sb_129",
    name: "Central Mich.",
    division: 1,
    org_id: "sb_129",
    mascot: "Chippewas",
    encoded_h: "Y3NiXzEyOQ==",
    encoded_c: "Y3NiXzEyOV9j",
    encoded_q: "Y3NiXzEyOV9x",
    encoded_p: "Y3NiXzEyOV9w",
  },
  {
    school_code: "sb_48",
    name: "Charleston So.",
    division: 1,
    org_id: "sb_48",
    mascot: "Buccaneers",
    encoded_h: "Y3NiXzQ4",
    encoded_c: "Y3NiXzQ4X2M=",
    encoded_q: "Y3NiXzQ4X3E=",
    encoded_p: "Y3NiXzQ4X3A=",
  },
  {
    school_code: "sb_458",
    name: "Charlotte",
    division: 1,
    org_id: "sb_458",
    mascot: "49ers",
    encoded_h: "Y3NiXzQ1OA==",
    encoded_c: "Y3NiXzQ1OF9j",
    encoded_q: "Y3NiXzQ1OF9x",
    encoded_p: "Y3NiXzQ1OF9w",
  },
  {
    school_code: "sb_693",
    name: "Chattanooga",
    division: 1,
    org_id: "sb_693",
    mascot: "Mocs",
    encoded_h: "Y3NiXzY5Mw==",
    encoded_c: "Y3NiXzY5M19j",
    encoded_q: "Y3NiXzY5M19x",
    encoded_p: "Y3NiXzY5M19w",
  },
  {
    school_code: "sb_147",
    name: "Clemson",
    division: 1,
    org_id: "sb_147",
    mascot: "Tigers",
    encoded_h: "Y3NiXzE0Nw==",
    encoded_c: "Y3NiXzE0N19j",
    encoded_q: "Y3NiXzE0N19x",
    encoded_p: "Y3NiXzE0N19w",
  },
  {
    school_code: "sb_148",
    name: "Cleveland St.",
    division: 1,
    org_id: "sb_148",
    mascot: "Vikings",
    encoded_h: "Y3NiXzE0OA==",
    encoded_c: "Y3NiXzE0OF9j",
    encoded_q: "Y3NiXzE0OF9x",
    encoded_p: "Y3NiXzE0OF9w",
  },
  {
    school_code: "sb_149",
    name: "Coastal Caro.",
    division: 1,
    org_id: "sb_149",
    mascot: "Chanticleers",
    encoded_h: "Y3NiXzE0OQ==",
    encoded_c: "Y3NiXzE0OV9j",
    encoded_q: "Y3NiXzE0OV9x",
    encoded_p: "Y3NiXzE0OV9w",
  },
  {
    school_code: "sb_1014",
    name: "Col. of Charleston",
    division: 1,
    org_id: "sb_1014",
    mascot: "Cougars",
    encoded_h: "Y3NiXzEwMTQ=",
    encoded_c: "Y3NiXzEwMTRfYw==",
    encoded_q: "Y3NiXzEwMTRfcQ==",
    encoded_p: "Y3NiXzEwMTRfcA==",
  },
  {
    school_code: "sb_153",
    name: "Colgate",
    division: 1,
    org_id: "sb_153",
    mascot: "Raiders",
    encoded_h: "Y3NiXzE1Mw==",
    encoded_c: "Y3NiXzE1M19j",
    encoded_q: "Y3NiXzE1M19x",
    encoded_p: "Y3NiXzE1M19w",
  },
  {
    school_code: "sb_156",
    name: "Colorado St.",
    division: 1,
    org_id: "sb_156",
    mascot: "Rams",
    encoded_h: "Y3NiXzE1Ng==",
    encoded_c: "Y3NiXzE1Nl9j",
    encoded_q: "Y3NiXzE1Nl9x",
    encoded_p: "Y3NiXzE1Nl9w",
  },
  {
    school_code: "sb_158",
    name: "Columbia",
    division: 1,
    org_id: "sb_158",
    mascot: "Lions",
    encoded_h: "Y3NiXzE1OA==",
    encoded_c: "Y3NiXzE1OF9j",
    encoded_q: "Y3NiXzE1OF9x",
    encoded_p: "Y3NiXzE1OF9w",
  },
  {
    school_code: "sb_165",
    name: "Coppin St.",
    division: 1,
    org_id: "sb_165",
    mascot: "Eagles",
    encoded_h: "Y3NiXzE2NQ==",
    encoded_c: "Y3NiXzE2NV9j",
    encoded_q: "Y3NiXzE2NV9x",
    encoded_p: "Y3NiXzE2NV9w",
  },
  {
    school_code: "sb_167",
    name: "Cornell",
    division: 1,
    org_id: "sb_167",
    mascot: "Big Red",
    encoded_h: "Y3NiXzE2Nw==",
    encoded_c: "Y3NiXzE2N19j",
    encoded_q: "Y3NiXzE2N19x",
    encoded_p: "Y3NiXzE2N19w",
  },
  {
    school_code: "sb_169",
    name: "Creighton",
    division: 1,
    org_id: "sb_169",
    mascot: "Bluejays",
    encoded_h: "Y3NiXzE2OQ==",
    encoded_c: "Y3NiXzE2OV9j",
    encoded_q: "Y3NiXzE2OV9x",
    encoded_p: "Y3NiXzE2OV9w",
  },
  {
    school_code: "sb_172",
    name: "Dartmouth",
    division: 1,
    org_id: "sb_172",
    mascot: "Big Green",
    encoded_h: "Y3NiXzE3Mg==",
    encoded_c: "Y3NiXzE3Ml9j",
    encoded_q: "Y3NiXzE3Ml9x",
    encoded_p: "Y3NiXzE3Ml9w",
  },
  {
    school_code: "sb_175",
    name: "Dayton",
    division: 1,
    org_id: "sb_175",
    mascot: "Flyers",
    encoded_h: "Y3NiXzE3NQ==",
    encoded_c: "Y3NiXzE3NV9j",
    encoded_q: "Y3NiXzE3NV9x",
    encoded_p: "Y3NiXzE3NV9w",
  },
  {
    school_code: "sb_176",
    name: "DePaul",
    division: 1,
    org_id: "sb_176",
    mascot: "Blue Demons",
    encoded_h: "Y3NiXzE3Ng==",
    encoded_c: "Y3NiXzE3Nl9j",
    encoded_q: "Y3NiXzE3Nl9x",
    encoded_p: "Y3NiXzE3Nl9w",
  },
  {
    school_code: "sb_180",
    name: "Delaware",
    division: 1,
    org_id: "sb_180",
    mascot: "Blue Hens",
    encoded_h: "Y3NiXzE4MA==",
    encoded_c: "Y3NiXzE4MF9j",
    encoded_q: "Y3NiXzE4MF9x",
    encoded_p: "Y3NiXzE4MF9w",
  },
  {
    school_code: "sb_178",
    name: "Delaware St.",
    division: 1,
    org_id: "sb_178",
    mascot: "Hornets",
    encoded_h: "Y3NiXzE3OA==",
    encoded_c: "Y3NiXzE3OF9j",
    encoded_q: "Y3NiXzE3OF9x",
    encoded_p: "Y3NiXzE3OF9w",
  },
  {
    school_code: "sb_184",
    name: "Detroit",
    division: 1,
    org_id: "sb_184",
    mascot: "Titans",
    encoded_h: "Y3NiXzE4NA==",
    encoded_c: "Y3NiXzE4NF9j",
    encoded_q: "Y3NiXzE4NF9x",
    encoded_p: "Y3NiXzE4NF9w",
  },
  {
    school_code: "sb_189",
    name: "Drake",
    division: 1,
    org_id: "sb_189",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzE4OQ==",
    encoded_c: "Y3NiXzE4OV9j",
    encoded_q: "Y3NiXzE4OV9x",
    encoded_p: "Y3NiXzE4OV9w",
  },
  {
    school_code: "sb_191",
    name: "Drexel",
    division: 1,
    org_id: "sb_191",
    mascot: "Dragons",
    encoded_h: "Y3NiXzE5MQ==",
    encoded_c: "Y3NiXzE5MV9j",
    encoded_q: "Y3NiXzE5MV9x",
    encoded_p: "Y3NiXzE5MV9w",
  },
  {
    school_code: "sb_193",
    name: "Duke",
    division: 1,
    org_id: "sb_193",
    mascot: "Blue Devils",
    encoded_h: "Y3NiXzE5Mw==",
    encoded_c: "Y3NiXzE5M19j",
    encoded_q: "Y3NiXzE5M19x",
    encoded_p: "Y3NiXzE5M19w",
  },
  {
    school_code: "sb_196",
    name: "East Carolina",
    division: 1,
    org_id: "sb_196",
    mascot: "Pirates",
    encoded_h: "Y3NiXzE5Ng==",
    encoded_c: "Y3NiXzE5Nl9j",
    encoded_q: "Y3NiXzE5Nl9x",
    encoded_p: "Y3NiXzE5Nl9w",
  },
  {
    school_code: "sb_198",
    name: "East Tenn. St.",
    division: 1,
    org_id: "sb_198",
    mascot: "Buccaneers",
    encoded_h: "Y3NiXzE5OA==",
    encoded_c: "Y3NiXzE5OF9j",
    encoded_q: "Y3NiXzE5OF9x",
    encoded_p: "Y3NiXzE5OF9w",
  },
  {
    school_code: "sb_201",
    name: "Eastern Ill.",
    division: 1,
    org_id: "sb_201",
    mascot: "Panthers",
    encoded_h: "Y3NiXzIwMQ==",
    encoded_c: "Y3NiXzIwMV9j",
    encoded_q: "Y3NiXzIwMV9x",
    encoded_p: "Y3NiXzIwMV9w",
  },
  {
    school_code: "sb_202",
    name: "Eastern Ky.",
    division: 1,
    org_id: "sb_202",
    mascot: "Colonels",
    encoded_h: "Y3NiXzIwMg==",
    encoded_c: "Y3NiXzIwMl9j",
    encoded_q: "Y3NiXzIwMl9x",
    encoded_p: "Y3NiXzIwMl9w",
  },
  {
    school_code: "sb_204",
    name: "Eastern Mich.",
    division: 1,
    org_id: "sb_204",
    mascot: "Eagles",
    encoded_h: "Y3NiXzIwNA==",
    encoded_c: "Y3NiXzIwNF9j",
    encoded_q: "Y3NiXzIwNF9x",
    encoded_p: "Y3NiXzIwNF9w",
  },
  {
    school_code: "sb_1068",
    name: "Elon",
    division: 1,
    org_id: "sb_1068",
    mascot: "Phoenix",
    encoded_h: "Y3NiXzEwNjg=",
    encoded_c: "Y3NiXzEwNjhfYw==",
    encoded_q: "Y3NiXzEwNjhfcQ==",
    encoded_p: "Y3NiXzEwNjhfcA==",
  },
  {
    school_code: "sb_219",
    name: "Evansville",
    division: 1,
    org_id: "sb_219",
    mascot: "Purple Aces",
    encoded_h: "Y3NiXzIxOQ==",
    encoded_c: "Y3NiXzIxOV9j",
    encoded_q: "Y3NiXzIxOV9x",
    encoded_p: "Y3NiXzIxOV9w",
  },
  {
    school_code: "sb_28755",
    name: "FGCU",
    division: 1,
    org_id: "sb_28755",
    mascot: "Eagles",
    encoded_h: "Y3NiXzI4NzU1",
    encoded_c: "Y3NiXzI4NzU1X2M=",
    encoded_q: "Y3NiXzI4NzU1X3E=",
    encoded_p: "Y3NiXzI4NzU1X3A=",
  },
  {
    school_code: "sb_231",
    name: "FIU",
    division: 1,
    org_id: "sb_231",
    mascot: "Panthers",
    encoded_h: "Y3NiXzIzMQ==",
    encoded_c: "Y3NiXzIzMV9j",
    encoded_q: "Y3NiXzIzMV9x",
    encoded_p: "Y3NiXzIzMV9w",
  },
  {
    school_code: "sb_220",
    name: "Fairfield",
    division: 1,
    org_id: "sb_220",
    mascot: "Stags",
    encoded_h: "Y3NiXzIyMA==",
    encoded_c: "Y3NiXzIyMF9j",
    encoded_q: "Y3NiXzIyMF9x",
    encoded_p: "Y3NiXzIyMF9w",
  },
  {
    school_code: "sb_222",
    name: "Fairleigh Dickinson",
    division: 1,
    org_id: "sb_222",
    mascot: "Knights",
    encoded_h: "Y3NiXzIyMg==",
    encoded_c: "Y3NiXzIyMl9j",
    encoded_q: "Y3NiXzIyMl9x",
    encoded_p: "Y3NiXzIyMl9w",
  },
  {
    school_code: "sb_229",
    name: "Fla. Atlantic",
    division: 1,
    org_id: "sb_229",
    mascot: "Owls",
    encoded_h: "Y3NiXzIyOQ==",
    encoded_c: "Y3NiXzIyOV9j",
    encoded_q: "Y3NiXzIyOV9x",
    encoded_p: "Y3NiXzIyOV9w",
  },
  {
    school_code: "sb_235",
    name: "Florida",
    division: 1,
    org_id: "sb_235",
    mascot: "Gators",
    encoded_h: "Y3NiXzIzNQ==",
    encoded_c: "Y3NiXzIzNV9j",
    encoded_q: "Y3NiXzIzNV9x",
    encoded_p: "Y3NiXzIzNV9w",
  },
  {
    school_code: "sb_228",
    name: "Florida A&M",
    division: 1,
    org_id: "sb_228",
    mascot: "Lady Rattlers",
    encoded_h: "Y3NiXzIyOA==",
    encoded_c: "Y3NiXzIyOF9j",
    encoded_q: "Y3NiXzIyOF9x",
    encoded_p: "Y3NiXzIyOF9w",
  },
  {
    school_code: "sb_234",
    name: "Florida St.",
    division: 1,
    org_id: "sb_234",
    mascot: "Seminoles",
    encoded_h: "Y3NiXzIzNA==",
    encoded_c: "Y3NiXzIzNF9j",
    encoded_q: "Y3NiXzIzNF9x",
    encoded_p: "Y3NiXzIzNF9w",
  },
  {
    school_code: "sb_236",
    name: "Fordham",
    division: 1,
    org_id: "sb_236",
    mascot: "Rams",
    encoded_h: "Y3NiXzIzNg==",
    encoded_c: "Y3NiXzIzNl9j",
    encoded_q: "Y3NiXzIzNl9x",
    encoded_p: "Y3NiXzIzNl9w",
  },
  {
    school_code: "sb_96",
    name: "Fresno St.",
    division: 1,
    org_id: "sb_96",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzk2",
    encoded_c: "Y3NiXzk2X2M=",
    encoded_q: "Y3NiXzk2X3E=",
    encoded_p: "Y3NiXzk2X3A=",
  },
  {
    school_code: "sb_244",
    name: "Furman",
    division: 1,
    org_id: "sb_244",
    mascot: "Paladins",
    encoded_h: "Y3NiXzI0NA==",
    encoded_c: "Y3NiXzI0NF9j",
    encoded_q: "Y3NiXzI0NF9x",
    encoded_p: "Y3NiXzI0NF9w",
  },
  {
    school_code: "sb_253",
    name: "Ga. Southern",
    division: 1,
    org_id: "sb_253",
    mascot: "Eagles",
    encoded_h: "Y3NiXzI1Mw==",
    encoded_c: "Y3NiXzI1M19j",
    encoded_q: "Y3NiXzI1M19x",
    encoded_p: "Y3NiXzI1M19w",
  },
  {
    school_code: "sb_1092",
    name: "Gardner-Webb",
    division: 1,
    org_id: "sb_1092",
    mascot: "Runnin' Bulldogs",
    encoded_h: "Y3NiXzEwOTI=",
    encoded_c: "Y3NiXzEwOTJfYw==",
    encoded_q: "Y3NiXzEwOTJfcQ==",
    encoded_p: "Y3NiXzEwOTJfcA==",
  },
  {
    school_code: "sb_248",
    name: "George Mason",
    division: 1,
    org_id: "sb_248",
    mascot: "Patriots",
    encoded_h: "Y3NiXzI0OA==",
    encoded_c: "Y3NiXzI0OF9j",
    encoded_q: "Y3NiXzI0OF9x",
    encoded_p: "Y3NiXzI0OF9w",
  },
  {
    school_code: "sb_249",
    name: "George Washington",
    division: 1,
    org_id: "sb_249",
    mascot: "Colonials",
    encoded_h: "Y3NiXzI0OQ==",
    encoded_c: "Y3NiXzI0OV9j",
    encoded_q: "Y3NiXzI0OV9x",
    encoded_p: "Y3NiXzI0OV9w",
  },
  {
    school_code: "sb_251",
    name: "Georgetown",
    division: 1,
    org_id: "sb_251",
    mascot: "Hoyas",
    encoded_h: "Y3NiXzI1MQ==",
    encoded_c: "Y3NiXzI1MV9j",
    encoded_q: "Y3NiXzI1MV9x",
    encoded_p: "Y3NiXzI1MV9w",
  },
  {
    school_code: "sb_257",
    name: "Georgia",
    division: 1,
    org_id: "sb_257",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzI1Nw==",
    encoded_c: "Y3NiXzI1N19j",
    encoded_q: "Y3NiXzI1N19x",
    encoded_p: "Y3NiXzI1N19w",
  },
  {
    school_code: "sb_254",
    name: "Georgia St.",
    division: 1,
    org_id: "sb_254",
    mascot: "Panthers",
    encoded_h: "Y3NiXzI1NA==",
    encoded_c: "Y3NiXzI1NF9j",
    encoded_q: "Y3NiXzI1NF9x",
    encoded_p: "Y3NiXzI1NF9w",
  },
  {
    school_code: "sb_255",
    name: "Georgia Tech",
    division: 1,
    org_id: "sb_255",
    mascot: "Yellow Jackets",
    encoded_h: "Y3NiXzI1NQ==",
    encoded_c: "Y3NiXzI1NV9j",
    encoded_q: "Y3NiXzI1NV9x",
    encoded_p: "Y3NiXzI1NV9w",
  },
  {
    school_code: "sb_261",
    name: "Grambling",
    division: 1,
    org_id: "sb_261",
    mascot: "Lady Tigers",
    encoded_h: "Y3NiXzI2MQ==",
    encoded_c: "Y3NiXzI2MV9j",
    encoded_q: "Y3NiXzI2MV9x",
    encoded_p: "Y3NiXzI2MV9w",
  },
  {
    school_code: "sb_1104",
    name: "Grand Canyon",
    division: 1,
    org_id: "sb_1104",
    mascot: "Antelopes",
    encoded_h: "Y3NiXzExMDQ=",
    encoded_c: "Y3NiXzExMDRfYw==",
    encoded_q: "Y3NiXzExMDRfcQ==",
    encoded_p: "Y3NiXzExMDRfcA==",
  },
  {
    school_code: "sb_794",
    name: "Green Bay",
    division: 1,
    org_id: "sb_794",
    mascot: "Phoenix",
    encoded_h: "Y3NiXzc5NA==",
    encoded_c: "Y3NiXzc5NF9j",
    encoded_q: "Y3NiXzc5NF9x",
    encoded_p: "Y3NiXzc5NF9w",
  },
  {
    school_code: "sb_270",
    name: "Hampton",
    division: 1,
    org_id: "sb_270",
    mascot: "Lady Pirates",
    encoded_h: "Y3NiXzI3MA==",
    encoded_c: "Y3NiXzI3MF9j",
    encoded_q: "Y3NiXzI3MF9x",
    encoded_p: "Y3NiXzI3MF9w",
  },
  {
    school_code: "sb_272",
    name: "Hartford",
    division: 1,
    org_id: "sb_272",
    mascot: "Hawks",
    encoded_h: "Y3NiXzI3Mg==",
    encoded_c: "Y3NiXzI3Ml9j",
    encoded_q: "Y3NiXzI3Ml9x",
    encoded_p: "Y3NiXzI3Ml9w",
  },
  {
    school_code: "sb_275",
    name: "Harvard",
    division: 1,
    org_id: "sb_275",
    mascot: "Crimson",
    encoded_h: "Y3NiXzI3NQ==",
    encoded_c: "Y3NiXzI3NV9j",
    encoded_q: "Y3NiXzI3NV9x",
    encoded_p: "Y3NiXzI3NV9w",
  },
  {
    school_code: "sb_277",
    name: "Hawaii",
    division: 1,
    org_id: "sb_277",
    mascot: "Rainbow Wahine",
    encoded_h: "Y3NiXzI3Nw==",
    encoded_c: "Y3NiXzI3N19j",
    encoded_q: "Y3NiXzI3N19x",
    encoded_p: "Y3NiXzI3N19w",
  },
  {
    school_code: "sb_283",
    name: "Hofstra",
    division: 1,
    org_id: "sb_283",
    mascot: "Pride",
    encoded_h: "Y3NiXzI4Mw==",
    encoded_c: "Y3NiXzI4M19j",
    encoded_q: "Y3NiXzI4M19x",
    encoded_p: "Y3NiXzI4M19w",
  },
  {
    school_code: "sb_285",
    name: "Holy Cross",
    division: 1,
    org_id: "sb_285",
    mascot: "Crusaders",
    encoded_h: "Y3NiXzI4NQ==",
    encoded_c: "Y3NiXzI4NV9j",
    encoded_q: "Y3NiXzI4NV9x",
    encoded_p: "Y3NiXzI4NV9w",
  },
  {
    school_code: "sb_288",
    name: "Houston",
    division: 1,
    org_id: "sb_288",
    mascot: "Cougars",
    encoded_h: "Y3NiXzI4OA==",
    encoded_c: "Y3NiXzI4OF9j",
    encoded_q: "Y3NiXzI4OF9x",
    encoded_p: "Y3NiXzI4OF9w",
  },
  {
    school_code: "sb_287",
    name: "Houston Baptist",
    division: 1,
    org_id: "sb_287",
    mascot: "Huskies",
    encoded_h: "Y3NiXzI4Nw==",
    encoded_c: "Y3NiXzI4N19j",
    encoded_q: "Y3NiXzI4N19x",
    encoded_p: "Y3NiXzI4N19w",
  },
  {
    school_code: "sb_290",
    name: "Howard",
    division: 1,
    org_id: "sb_290",
    mascot: "Lady Bison",
    encoded_h: "Y3NiXzI5MA==",
    encoded_c: "Y3NiXzI5MF9j",
    encoded_q: "Y3NiXzI5MF9x",
    encoded_p: "Y3NiXzI5MF9w",
  },
  {
    school_code: "sb_308",
    name: "IPFW",
    division: 1,
    org_id: "sb_308",
    mascot: "Mastodons",
    encoded_h: "Y3NiXzMwOA==",
    encoded_c: "Y3NiXzMwOF9j",
    encoded_q: "Y3NiXzMwOF9x",
    encoded_p: "Y3NiXzMwOF9w",
  },
  {
    school_code: "sb_2699",
    name: "IUPUI",
    division: 1,
    org_id: "sb_2699",
    mascot: "Jaguars",
    encoded_h: "Y3NiXzI2OTk=",
    encoded_c: "Y3NiXzI2OTlfYw==",
    encoded_q: "Y3NiXzI2OTlfcQ==",
    encoded_p: "Y3NiXzI2OTlfcA==",
  },
  {
    school_code: "sb_294",
    name: "Idaho St.",
    division: 1,
    org_id: "sb_294",
    mascot: "Bengals",
    encoded_h: "Y3NiXzI5NA==",
    encoded_c: "Y3NiXzI5NF9j",
    encoded_q: "Y3NiXzI5NF9x",
    encoded_p: "Y3NiXzI5NF9w",
  },
  {
    school_code: "sb_302",
    name: "Ill.-Chicago",
    division: 1,
    org_id: "sb_302",
    mascot: "Flames",
    encoded_h: "Y3NiXzMwMg==",
    encoded_c: "Y3NiXzMwMl9j",
    encoded_q: "Y3NiXzMwMl9x",
    encoded_p: "Y3NiXzMwMl9w",
  },
  {
    school_code: "sb_301",
    name: "Illinois",
    division: 1,
    org_id: "sb_301",
    mascot: "Fighting Illini",
    encoded_h: "Y3NiXzMwMQ==",
    encoded_c: "Y3NiXzMwMV9j",
    encoded_q: "Y3NiXzMwMV9x",
    encoded_p: "Y3NiXzMwMV9w",
  },
  {
    school_code: "sb_299",
    name: "Illinois St.",
    division: 1,
    org_id: "sb_299",
    mascot: "Redbirds",
    encoded_h: "Y3NiXzI5OQ==",
    encoded_c: "Y3NiXzI5OV9j",
    encoded_q: "Y3NiXzI5OV9x",
    encoded_p: "Y3NiXzI5OV9w",
  },
  {
    school_code: "sb_2743",
    name: "Incarnate Word",
    division: 1,
    org_id: "sb_2743",
    mascot: "Cardinals",
    encoded_h: "Y3NiXzI3NDM=",
    encoded_c: "Y3NiXzI3NDNfYw==",
    encoded_q: "Y3NiXzI3NDNfcQ==",
    encoded_p: "Y3NiXzI3NDNfcA==",
  },
  {
    school_code: "sb_306",
    name: "Indiana",
    division: 1,
    org_id: "sb_306",
    mascot: "Hoosiers",
    encoded_h: "Y3NiXzMwNg==",
    encoded_c: "Y3NiXzMwNl9j",
    encoded_q: "Y3NiXzMwNl9x",
    encoded_p: "Y3NiXzMwNl9w",
  },
  {
    school_code: "sb_305",
    name: "Indiana St.",
    division: 1,
    org_id: "sb_305",
    mascot: "Sycamores",
    encoded_h: "Y3NiXzMwNQ==",
    encoded_c: "Y3NiXzMwNV9j",
    encoded_q: "Y3NiXzMwNV9x",
    encoded_p: "Y3NiXzMwNV9w",
  },
  {
    school_code: "sb_310",
    name: "Iona",
    division: 1,
    org_id: "sb_310",
    mascot: "Gaels",
    encoded_h: "Y3NiXzMxMA==",
    encoded_c: "Y3NiXzMxMF9j",
    encoded_q: "Y3NiXzMxMF9x",
    encoded_p: "Y3NiXzMxMF9w",
  },
  {
    school_code: "sb_312",
    name: "Iowa",
    division: 1,
    org_id: "sb_312",
    mascot: "Hawkeyes",
    encoded_h: "Y3NiXzMxMg==",
    encoded_c: "Y3NiXzMxMl9j",
    encoded_q: "Y3NiXzMxMl9x",
    encoded_p: "Y3NiXzMxMl9w",
  },
  {
    school_code: "sb_311",
    name: "Iowa St.",
    division: 1,
    org_id: "sb_311",
    mascot: "Cyclones",
    encoded_h: "Y3NiXzMxMQ==",
    encoded_c: "Y3NiXzMxMV9j",
    encoded_q: "Y3NiXzMxMV9x",
    encoded_p: "Y3NiXzMxMV9w",
  },
  {
    school_code: "sb_314",
    name: "Jackson St.",
    division: 1,
    org_id: "sb_314",
    mascot: "Lady Tigers",
    encoded_h: "Y3NiXzMxNA==",
    encoded_c: "Y3NiXzMxNF9j",
    encoded_q: "Y3NiXzMxNF9x",
    encoded_p: "Y3NiXzMxNF9w",
  },
  {
    school_code: "sb_316",
    name: "Jacksonville",
    division: 1,
    org_id: "sb_316",
    mascot: "Dolphins",
    encoded_h: "Y3NiXzMxNg==",
    encoded_c: "Y3NiXzMxNl9j",
    encoded_q: "Y3NiXzMxNl9x",
    encoded_p: "Y3NiXzMxNl9w",
  },
  {
    school_code: "sb_315",
    name: "Jacksonville St.",
    division: 1,
    org_id: "sb_315",
    mascot: "Gamecocks",
    encoded_h: "Y3NiXzMxNQ==",
    encoded_c: "Y3NiXzMxNV9j",
    encoded_q: "Y3NiXzMxNV9x",
    encoded_p: "Y3NiXzMxNV9w",
  },
  {
    school_code: "sb_317",
    name: "James Madison",
    division: 1,
    org_id: "sb_317",
    mascot: "Dukes",
    encoded_h: "Y3NiXzMxNw==",
    encoded_c: "Y3NiXzMxN19j",
    encoded_q: "Y3NiXzMxN19x",
    encoded_p: "Y3NiXzMxN19w",
  },
  {
    school_code: "sb_328",
    name: "Kansas",
    division: 1,
    org_id: "sb_328",
    mascot: "Jayhawks",
    encoded_h: "Y3NiXzMyOA==",
    encoded_c: "Y3NiXzMyOF9j",
    encoded_q: "Y3NiXzMyOF9x",
    encoded_p: "Y3NiXzMyOF9w",
  },
  {
    school_code: "sb_1157",
    name: "Kennesaw St.",
    division: 1,
    org_id: "sb_1157",
    mascot: "Owls",
    encoded_h: "Y3NiXzExNTc=",
    encoded_c: "Y3NiXzExNTdfYw==",
    encoded_q: "Y3NiXzExNTdfcQ==",
    encoded_p: "Y3NiXzExNTdfcA==",
  },
  {
    school_code: "sb_331",
    name: "Kent St.",
    division: 1,
    org_id: "sb_331",
    mascot: "Golden Flashes",
    encoded_h: "Y3NiXzMzMQ==",
    encoded_c: "Y3NiXzMzMV9j",
    encoded_q: "Y3NiXzMzMV9x",
    encoded_p: "Y3NiXzMzMV9w",
  },
  {
    school_code: "sb_334",
    name: "Kentucky",
    division: 1,
    org_id: "sb_334",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzMzNA==",
    encoded_c: "Y3NiXzMzNF9j",
    encoded_q: "Y3NiXzMzNF9x",
    encoded_p: "Y3NiXzMzNF9w",
  },
  {
    school_code: "sb_361",
    name: "LIU Brooklyn",
    division: 1,
    org_id: "sb_361",
    mascot: "Blackbirds",
    encoded_h: "Y3NiXzM2MQ==",
    encoded_c: "Y3NiXzM2MV9j",
    encoded_q: "Y3NiXzM2MV9x",
    encoded_p: "Y3NiXzM2MV9w",
  },
  {
    school_code: "sb_365",
    name: "LSU",
    division: 1,
    org_id: "sb_365",
    mascot: "Tigers",
    encoded_h: "Y3NiXzM2NQ==",
    encoded_c: "Y3NiXzM2NV9j",
    encoded_q: "Y3NiXzM2NV9x",
    encoded_p: "Y3NiXzM2NV9w",
  },
  {
    school_code: "sb_340",
    name: "La Salle",
    division: 1,
    org_id: "sb_340",
    mascot: "Explorers",
    encoded_h: "Y3NiXzM0MA==",
    encoded_c: "Y3NiXzM0MF9j",
    encoded_q: "Y3NiXzM0MF9x",
    encoded_p: "Y3NiXzM0MF9w",
  },
  {
    school_code: "sb_671",
    name: "La.-Lafayette",
    division: 1,
    org_id: "sb_671",
    mascot: "Ragin' Cajuns",
    encoded_h: "Y3NiXzY3MQ==",
    encoded_c: "Y3NiXzY3MV9j",
    encoded_q: "Y3NiXzY3MV9x",
    encoded_p: "Y3NiXzY3MV9w",
  },
  {
    school_code: "sb_498",
    name: "La.-Monroe",
    division: 1,
    org_id: "sb_498",
    mascot: "Warhawks",
    encoded_h: "Y3NiXzQ5OA==",
    encoded_c: "Y3NiXzQ5OF9j",
    encoded_q: "Y3NiXzQ5OF9x",
    encoded_p: "Y3NiXzQ5OF9w",
  },
  {
    school_code: "sb_342",
    name: "Lafayette",
    division: 1,
    org_id: "sb_342",
    mascot: "Leopards",
    encoded_h: "Y3NiXzM0Mg==",
    encoded_c: "Y3NiXzM0Ml9j",
    encoded_q: "Y3NiXzM0Ml9x",
    encoded_p: "Y3NiXzM0Ml9w",
  },
  {
    school_code: "sb_346",
    name: "Lamar",
    division: 1,
    org_id: "sb_346",
    mascot: "Lady Cardinals",
    encoded_h: "Y3NiXzM0Ng==",
    encoded_c: "Y3NiXzM0Nl9j",
    encoded_q: "Y3NiXzM0Nl9x",
    encoded_p: "Y3NiXzM0Nl9w",
  },
  {
    school_code: "sb_352",
    name: "Lehigh",
    division: 1,
    org_id: "sb_352",
    mascot: "Mountain Hawks",
    encoded_h: "Y3NiXzM1Mg==",
    encoded_c: "Y3NiXzM1Ml9j",
    encoded_q: "Y3NiXzM1Ml9x",
    encoded_p: "Y3NiXzM1Ml9w",
  },
  {
    school_code: "sb_355",
    name: "Liberty",
    division: 1,
    org_id: "sb_355",
    mascot: "Lady Flames",
    encoded_h: "Y3NiXzM1NQ==",
    encoded_c: "Y3NiXzM1NV9j",
    encoded_q: "Y3NiXzM1NV9x",
    encoded_p: "Y3NiXzM1NV9w",
  },
  {
    school_code: "sb_28600",
    name: "Lipscomb",
    division: 1,
    org_id: "sb_28600",
    mascot: "Lady Bisons",
    encoded_h: "Y3NiXzI4NjAw",
    encoded_c: "Y3NiXzI4NjAwX2M=",
    encoded_q: "Y3NiXzI4NjAwX3E=",
    encoded_p: "Y3NiXzI4NjAwX3A=",
  },
  {
    school_code: "sb_99",
    name: "Long Beach St.",
    division: 1,
    org_id: "sb_99",
    mascot: "49ers",
    encoded_h: "Y3NiXzk5",
    encoded_c: "Y3NiXzk5X2M=",
    encoded_q: "Y3NiXzk5X3E=",
    encoded_p: "Y3NiXzk5X3A=",
  },
  {
    school_code: "sb_363",
    name: "Longwood",
    division: 1,
    org_id: "sb_363",
    mascot: "Lancers",
    encoded_h: "Y3NiXzM2Mw==",
    encoded_c: "Y3NiXzM2M19j",
    encoded_q: "Y3NiXzM2M19x",
    encoded_p: "Y3NiXzM2M19w",
  },
  {
    school_code: "sb_366",
    name: "Louisiana Tech",
    division: 1,
    org_id: "sb_366",
    mascot: "Lady Techsters",
    encoded_h: "Y3NiXzM2Ng==",
    encoded_c: "Y3NiXzM2Nl9j",
    encoded_q: "Y3NiXzM2Nl9x",
    encoded_p: "Y3NiXzM2Nl9w",
  },
  {
    school_code: "sb_367",
    name: "Louisville",
    division: 1,
    org_id: "sb_367",
    mascot: "Cardinals",
    encoded_h: "Y3NiXzM2Nw==",
    encoded_c: "Y3NiXzM2N19j",
    encoded_q: "Y3NiXzM2N19x",
    encoded_p: "Y3NiXzM2N19w",
  },
  {
    school_code: "sb_371",
    name: "Loyola Chicago",
    division: 1,
    org_id: "sb_371",
    mascot: "Ramblers",
    encoded_h: "Y3NiXzM3MQ==",
    encoded_c: "Y3NiXzM3MV9j",
    encoded_q: "Y3NiXzM3MV9x",
    encoded_p: "Y3NiXzM3MV9w",
  },
  {
    school_code: "sb_370",
    name: "Loyola Marymount",
    division: 1,
    org_id: "sb_370",
    mascot: "Lions",
    encoded_h: "Y3NiXzM3MA==",
    encoded_c: "Y3NiXzM3MF9j",
    encoded_q: "Y3NiXzM3MF9x",
    encoded_p: "Y3NiXzM3MF9w",
  },
  {
    school_code: "sb_380",
    name: "Maine",
    division: 1,
    org_id: "sb_380",
    mascot: "Black Bears",
    encoded_h: "Y3NiXzM4MA==",
    encoded_c: "Y3NiXzM4MF9j",
    encoded_q: "Y3NiXzM4MF9x",
    encoded_p: "Y3NiXzM4MF9w",
  },
  {
    school_code: "sb_381",
    name: "Manhattan",
    division: 1,
    org_id: "sb_381",
    mascot: "Jaspers",
    encoded_h: "Y3NiXzM4MQ==",
    encoded_c: "Y3NiXzM4MV9j",
    encoded_q: "Y3NiXzM4MV9x",
    encoded_p: "Y3NiXzM4MV9w",
  },
  {
    school_code: "sb_386",
    name: "Marist",
    division: 1,
    org_id: "sb_386",
    mascot: "Red Foxes",
    encoded_h: "Y3NiXzM4Ng==",
    encoded_c: "Y3NiXzM4Nl9j",
    encoded_q: "Y3NiXzM4Nl9x",
    encoded_p: "Y3NiXzM4Nl9w",
  },
  {
    school_code: "sb_388",
    name: "Marshall",
    division: 1,
    org_id: "sb_388",
    mascot: "Thundering Herd",
    encoded_h: "Y3NiXzM4OA==",
    encoded_c: "Y3NiXzM4OF9j",
    encoded_q: "Y3NiXzM4OF9x",
    encoded_p: "Y3NiXzM4OF9w",
  },
  {
    school_code: "sb_392",
    name: "Maryland",
    division: 1,
    org_id: "sb_392",
    mascot: "Terrapins",
    encoded_h: "Y3NiXzM5Mg==",
    encoded_c: "Y3NiXzM5Ml9j",
    encoded_q: "Y3NiXzM5Ml9x",
    encoded_p: "Y3NiXzM5Ml9w",
  },
  {
    school_code: "sb_400",
    name: "Massachusetts",
    division: 1,
    org_id: "sb_400",
    mascot: "Minutewomen",
    encoded_h: "Y3NiXzQwMA==",
    encoded_c: "Y3NiXzQwMF9j",
    encoded_q: "Y3NiXzQwMF9x",
    encoded_p: "Y3NiXzQwMF9w",
  },
  {
    school_code: "sb_402",
    name: "McNeese St.",
    division: 1,
    org_id: "sb_402",
    mascot: "Cowgirls",
    encoded_h: "Y3NiXzQwMg==",
    encoded_c: "Y3NiXzQwMl9j",
    encoded_q: "Y3NiXzQwMl9x",
    encoded_p: "Y3NiXzQwMl9w",
  },
  {
    school_code: "sb_404",
    name: "Memphis",
    division: 1,
    org_id: "sb_404",
    mascot: "Tigers",
    encoded_h: "Y3NiXzQwNA==",
    encoded_c: "Y3NiXzQwNF9j",
    encoded_q: "Y3NiXzQwNF9x",
    encoded_p: "Y3NiXzQwNF9w",
  },
  {
    school_code: "sb_406",
    name: "Mercer",
    division: 1,
    org_id: "sb_406",
    mascot: "Bears",
    encoded_h: "Y3NiXzQwNg==",
    encoded_c: "Y3NiXzQwNl9j",
    encoded_q: "Y3NiXzQwNl9x",
    encoded_p: "Y3NiXzQwNl9w",
  },
  {
    school_code: "sb_414",
    name: "Miami (OH)",
    division: 1,
    org_id: "sb_414",
    mascot: "RedHawks",
    encoded_h: "Y3NiXzQxNA==",
    encoded_c: "Y3NiXzQxNF9j",
    encoded_q: "Y3NiXzQxNF9x",
    encoded_p: "Y3NiXzQxNF9w",
  },
  {
    school_code: "sb_418",
    name: "Michigan",
    division: 1,
    org_id: "sb_418",
    mascot: "Wolverines",
    encoded_h: "Y3NiXzQxOA==",
    encoded_c: "Y3NiXzQxOF9j",
    encoded_q: "Y3NiXzQxOF9x",
    encoded_p: "Y3NiXzQxOF9w",
  },
  {
    school_code: "sb_416",
    name: "Michigan St.",
    division: 1,
    org_id: "sb_416",
    mascot: "Spartans",
    encoded_h: "Y3NiXzQxNg==",
    encoded_c: "Y3NiXzQxNl9j",
    encoded_q: "Y3NiXzQxNl9x",
    encoded_p: "Y3NiXzQxNl9w",
  },
  {
    school_code: "sb_419",
    name: "Middle Tenn.",
    division: 1,
    org_id: "sb_419",
    mascot: "Blue Raiders",
    encoded_h: "Y3NiXzQxOQ==",
    encoded_c: "Y3NiXzQxOV9j",
    encoded_q: "Y3NiXzQxOV9x",
    encoded_p: "Y3NiXzQxOV9w",
  },
  {
    school_code: "sb_428",
    name: "Minnesota",
    division: 1,
    org_id: "sb_428",
    mascot: "Golden Gophers",
    encoded_h: "Y3NiXzQyOA==",
    encoded_c: "Y3NiXzQyOF9j",
    encoded_q: "Y3NiXzQyOF9x",
    encoded_p: "Y3NiXzQyOF9w",
  },
  {
    school_code: "sb_430",
    name: "Mississippi St.",
    division: 1,
    org_id: "sb_430",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzQzMA==",
    encoded_c: "Y3NiXzQzMF9j",
    encoded_q: "Y3NiXzQzMF9x",
    encoded_p: "Y3NiXzQzMF9w",
  },
  {
    school_code: "sb_432",
    name: "Mississippi Val.",
    division: 1,
    org_id: "sb_432",
    mascot: "Devilettes",
    encoded_h: "Y3NiXzQzMg==",
    encoded_c: "Y3NiXzQzMl9j",
    encoded_q: "Y3NiXzQzMl9x",
    encoded_p: "Y3NiXzQzMl9w",
  },
  {
    school_code: "sb_434",
    name: "Missouri",
    division: 1,
    org_id: "sb_434",
    mascot: "Tigers",
    encoded_h: "Y3NiXzQzNA==",
    encoded_c: "Y3NiXzQzNF9j",
    encoded_q: "Y3NiXzQzNF9x",
    encoded_p: "Y3NiXzQzNF9w",
  },
  {
    school_code: "sb_669",
    name: "Missouri St.",
    division: 1,
    org_id: "sb_669",
    mascot: "Bears",
    encoded_h: "Y3NiXzY2OQ==",
    encoded_c: "Y3NiXzY2OV9j",
    encoded_q: "Y3NiXzY2OV9x",
    encoded_p: "Y3NiXzY2OV9w",
  },
  {
    school_code: "sb_439",
    name: "Monmouth",
    division: 1,
    org_id: "sb_439",
    mascot: "Hawks",
    encoded_h: "Y3NiXzQzOQ==",
    encoded_c: "Y3NiXzQzOV9j",
    encoded_q: "Y3NiXzQzOV9x",
    encoded_p: "Y3NiXzQzOV9w",
  },
  {
    school_code: "sb_444",
    name: "Morehead St.",
    division: 1,
    org_id: "sb_444",
    mascot: "Eagles",
    encoded_h: "Y3NiXzQ0NA==",
    encoded_c: "Y3NiXzQ0NF9j",
    encoded_q: "Y3NiXzQ0NF9x",
    encoded_p: "Y3NiXzQ0NF9w",
  },
  {
    school_code: "sb_446",
    name: "Morgan St.",
    division: 1,
    org_id: "sb_446",
    mascot: "Lady Bears",
    encoded_h: "Y3NiXzQ0Ng==",
    encoded_c: "Y3NiXzQ0Nl9j",
    encoded_q: "Y3NiXzQ0Nl9x",
    encoded_p: "Y3NiXzQ0Nl9w",
  },
  {
    school_code: "sb_450",
    name: "Mt. St. Mary's",
    division: 1,
    org_id: "sb_450",
    mascot: "Mountaineers",
    encoded_h: "Y3NiXzQ1MA==",
    encoded_c: "Y3NiXzQ1MF9j",
    encoded_q: "Y3NiXzQ1MF9x",
    encoded_p: "Y3NiXzQ1MF9w",
  },
  {
    school_code: "sb_454",
    name: "Murray St.",
    division: 1,
    org_id: "sb_454",
    mascot: "Racers",
    encoded_h: "Y3NiXzQ1NA==",
    encoded_c: "Y3NiXzQ1NF9j",
    encoded_q: "Y3NiXzQ1NF9x",
    encoded_p: "Y3NiXzQ1NF9w",
  },
  {
    school_code: "sb_488",
    name: "N.C. A&T",
    division: 1,
    org_id: "sb_488",
    mascot: "Aggies",
    encoded_h: "Y3NiXzQ4OA==",
    encoded_c: "Y3NiXzQ4OF9j",
    encoded_q: "Y3NiXzQ4OF9x",
    encoded_p: "Y3NiXzQ4OF9w",
  },
  {
    school_code: "sb_489",
    name: "N.C. Central",
    division: 1,
    org_id: "sb_489",
    mascot: "Eagles",
    encoded_h: "Y3NiXzQ4OQ==",
    encoded_c: "Y3NiXzQ4OV9j",
    encoded_q: "Y3NiXzQ4OV9x",
    encoded_p: "Y3NiXzQ4OV9w",
  },
  {
    school_code: "sb_463",
    name: "Nebraska",
    division: 1,
    org_id: "sb_463",
    mascot: "Cornhuskers",
    encoded_h: "Y3NiXzQ2Mw==",
    encoded_c: "Y3NiXzQ2M19j",
    encoded_q: "Y3NiXzQ2M19x",
    encoded_p: "Y3NiXzQ2M19w",
  },
  {
    school_code: "sb_466",
    name: "Nevada",
    division: 1,
    org_id: "sb_466",
    mascot: "Wolf Pack",
    encoded_h: "Y3NiXzQ2Ng==",
    encoded_c: "Y3NiXzQ2Nl9j",
    encoded_q: "Y3NiXzQ2Nl9x",
    encoded_p: "Y3NiXzQ2Nl9w",
  },
  {
    school_code: "sb_473",
    name: "New Mexico",
    division: 1,
    org_id: "sb_473",
    mascot: "Lobos",
    encoded_h: "Y3NiXzQ3Mw==",
    encoded_c: "Y3NiXzQ3M19j",
    encoded_q: "Y3NiXzQ3M19x",
    encoded_p: "Y3NiXzQ3M19w",
  },
  {
    school_code: "sb_472",
    name: "New Mexico St.",
    division: 1,
    org_id: "sb_472",
    mascot: "Aggies",
    encoded_h: "Y3NiXzQ3Mg==",
    encoded_c: "Y3NiXzQ3Ml9j",
    encoded_q: "Y3NiXzQ3Ml9x",
    encoded_p: "Y3NiXzQ3Ml9w",
  },
  {
    school_code: "sb_482",
    name: "Niagara",
    division: 1,
    org_id: "sb_482",
    mascot: "Purple Eagles",
    encoded_h: "Y3NiXzQ4Mg==",
    encoded_c: "Y3NiXzQ4Ml9j",
    encoded_q: "Y3NiXzQ4Ml9x",
    encoded_p: "Y3NiXzQ4Ml9w",
  },
  {
    school_code: "sb_483",
    name: "Nicholls St.",
    division: 1,
    org_id: "sb_483",
    mascot: "Colonels",
    encoded_h: "Y3NiXzQ4Mw==",
    encoded_c: "Y3NiXzQ4M19j",
    encoded_q: "Y3NiXzQ4M19x",
    encoded_p: "Y3NiXzQ4M19w",
  },
  {
    school_code: "sb_485",
    name: "Norfolk St.",
    division: 1,
    org_id: "sb_485",
    mascot: "Spartans",
    encoded_h: "Y3NiXzQ4NQ==",
    encoded_c: "Y3NiXzQ4NV9j",
    encoded_q: "Y3NiXzQ4NV9x",
    encoded_p: "Y3NiXzQ4NV9w",
  },
  {
    school_code: "sb_457",
    name: "North Carolina",
    division: 1,
    org_id: "sb_457",
    mascot: "Tar Heels",
    encoded_h: "Y3NiXzQ1Nw==",
    encoded_c: "Y3NiXzQ1N19j",
    encoded_q: "Y3NiXzQ1N19x",
    encoded_p: "Y3NiXzQ1N19w",
  },
  {
    school_code: "sb_490",
    name: "North Carolina St.",
    division: 1,
    org_id: "sb_490",
    mascot: "Wolfpack",
    encoded_h: "Y3NiXzQ5MA==",
    encoded_c: "Y3NiXzQ5MF9j",
    encoded_q: "Y3NiXzQ5MF9x",
    encoded_p: "Y3NiXzQ5MF9w",
  },
  {
    school_code: "sb_494",
    name: "North Dakota",
    division: 1,
    org_id: "sb_494",
    mascot: "",
    encoded_h: "Y3NiXzQ5NA==",
    encoded_c: "Y3NiXzQ5NF9j",
    encoded_q: "Y3NiXzQ5NF9x",
    encoded_p: "Y3NiXzQ5NF9w",
  },
  {
    school_code: "sb_493",
    name: "North Dakota St.",
    division: 1,
    org_id: "sb_493",
    mascot: "Bison",
    encoded_h: "Y3NiXzQ5Mw==",
    encoded_c: "Y3NiXzQ5M19j",
    encoded_q: "Y3NiXzQ5M19x",
    encoded_p: "Y3NiXzQ5M19w",
  },
  {
    school_code: "sb_2711",
    name: "North Florida",
    division: 1,
    org_id: "sb_2711",
    mascot: "Ospreys",
    encoded_h: "Y3NiXzI3MTE=",
    encoded_c: "Y3NiXzI3MTFfYw==",
    encoded_q: "Y3NiXzI3MTFfcQ==",
    encoded_p: "Y3NiXzI3MTFfcA==",
  },
  {
    school_code: "sb_497",
    name: "North Texas",
    division: 1,
    org_id: "sb_497",
    mascot: "Mean Green",
    encoded_h: "Y3NiXzQ5Nw==",
    encoded_c: "Y3NiXzQ5N19j",
    encoded_q: "Y3NiXzQ5N19x",
    encoded_p: "Y3NiXzQ5N19w",
  },
  {
    school_code: "sb_502",
    name: "Northern Colo.",
    division: 1,
    org_id: "sb_502",
    mascot: "Bears",
    encoded_h: "Y3NiXzUwMg==",
    encoded_c: "Y3NiXzUwMl9j",
    encoded_q: "Y3NiXzUwMl9x",
    encoded_p: "Y3NiXzUwMl9w",
  },
  {
    school_code: "sb_503",
    name: "Northern Ill.",
    division: 1,
    org_id: "sb_503",
    mascot: "Huskies",
    encoded_h: "Y3NiXzUwMw==",
    encoded_c: "Y3NiXzUwM19j",
    encoded_q: "Y3NiXzUwM19x",
    encoded_p: "Y3NiXzUwM19w",
  },
  {
    school_code: "sb_505",
    name: "Northern Ky.",
    division: 1,
    org_id: "sb_505",
    mascot: "Norse",
    encoded_h: "Y3NiXzUwNQ==",
    encoded_c: "Y3NiXzUwNV9j",
    encoded_q: "Y3NiXzUwNV9x",
    encoded_p: "Y3NiXzUwNV9w",
  },
  {
    school_code: "sb_509",
    name: "Northwestern",
    division: 1,
    org_id: "sb_509",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzUwOQ==",
    encoded_c: "Y3NiXzUwOV9j",
    encoded_q: "Y3NiXzUwOV9x",
    encoded_p: "Y3NiXzUwOV9w",
  },
  {
    school_code: "sb_508",
    name: "Northwestern St.",
    division: 1,
    org_id: "sb_508",
    mascot: "Lady Demons",
    encoded_h: "Y3NiXzUwOA==",
    encoded_c: "Y3NiXzUwOF9j",
    encoded_q: "Y3NiXzUwOF9x",
    encoded_p: "Y3NiXzUwOF9w",
  },
  {
    school_code: "sb_513",
    name: "Notre Dame",
    division: 1,
    org_id: "sb_513",
    mascot: "Fighting Irish",
    encoded_h: "Y3NiXzUxMw==",
    encoded_c: "Y3NiXzUxM19j",
    encoded_q: "Y3NiXzUxM19x",
    encoded_p: "Y3NiXzUxM19w",
  },
  {
    school_code: "sb_514",
    name: "Oakland",
    division: 1,
    org_id: "sb_514",
    mascot: "Golden Grizzlies",
    encoded_h: "Y3NiXzUxNA==",
    encoded_c: "Y3NiXzUxNF9j",
    encoded_q: "Y3NiXzUxNF9x",
    encoded_p: "Y3NiXzUxNF9w",
  },
  {
    school_code: "sb_519",
    name: "Ohio",
    division: 1,
    org_id: "sb_519",
    mascot: "Bobcats",
    encoded_h: "Y3NiXzUxOQ==",
    encoded_c: "Y3NiXzUxOV9j",
    encoded_q: "Y3NiXzUxOV9x",
    encoded_p: "Y3NiXzUxOV9w",
  },
  {
    school_code: "sb_518",
    name: "Ohio St.",
    division: 1,
    org_id: "sb_518",
    mascot: "Buckeyes",
    encoded_h: "Y3NiXzUxOA==",
    encoded_c: "Y3NiXzUxOF9j",
    encoded_q: "Y3NiXzUxOF9x",
    encoded_p: "Y3NiXzUxOF9w",
  },
  {
    school_code: "sb_522",
    name: "Oklahoma",
    division: 1,
    org_id: "sb_522",
    mascot: "Sooners",
    encoded_h: "Y3NiXzUyMg==",
    encoded_c: "Y3NiXzUyMl9j",
    encoded_q: "Y3NiXzUyMl9x",
    encoded_p: "Y3NiXzUyMl9w",
  },
  {
    school_code: "sb_521",
    name: "Oklahoma St.",
    division: 1,
    org_id: "sb_521",
    mascot: "Cowgirls",
    encoded_h: "Y3NiXzUyMQ==",
    encoded_c: "Y3NiXzUyMV9j",
    encoded_q: "Y3NiXzUyMV9x",
    encoded_p: "Y3NiXzUyMV9w",
  },
  {
    school_code: "sb_433",
    name: "Ole Miss",
    division: 1,
    org_id: "sb_433",
    mascot: "Rebels",
    encoded_h: "Y3NiXzQzMw==",
    encoded_c: "Y3NiXzQzM19j",
    encoded_q: "Y3NiXzQzM19x",
    encoded_p: "Y3NiXzQzM19w",
  },
  {
    school_code: "sb_464",
    name: "Omaha",
    division: 1,
    org_id: "sb_464",
    mascot: "Mavericks",
    encoded_h: "Y3NiXzQ2NA==",
    encoded_c: "Y3NiXzQ2NF9j",
    encoded_q: "Y3NiXzQ2NF9x",
    encoded_p: "Y3NiXzQ2NF9w",
  },
  {
    school_code: "sb_529",
    name: "Oregon",
    division: 1,
    org_id: "sb_529",
    mascot: "Ducks",
    encoded_h: "Y3NiXzUyOQ==",
    encoded_c: "Y3NiXzUyOV9j",
    encoded_q: "Y3NiXzUyOV9x",
    encoded_p: "Y3NiXzUyOV9w",
  },
  {
    school_code: "sb_528",
    name: "Oregon St.",
    division: 1,
    org_id: "sb_528",
    mascot: "Beavers",
    encoded_h: "Y3NiXzUyOA==",
    encoded_c: "Y3NiXzUyOF9j",
    encoded_q: "Y3NiXzUyOF9x",
    encoded_p: "Y3NiXzUyOF9w",
  },
  {
    school_code: "sb_534",
    name: "Pacific",
    division: 1,
    org_id: "sb_534",
    mascot: "Tigers",
    encoded_h: "Y3NiXzUzNA==",
    encoded_c: "Y3NiXzUzNF9j",
    encoded_q: "Y3NiXzUzNF9x",
    encoded_p: "Y3NiXzUzNF9w",
  },
  {
    school_code: "sb_540",
    name: "Penn",
    division: 1,
    org_id: "sb_540",
    mascot: "Quakers",
    encoded_h: "Y3NiXzU0MA==",
    encoded_c: "Y3NiXzU0MF9j",
    encoded_q: "Y3NiXzU0MF9x",
    encoded_p: "Y3NiXzU0MF9w",
  },
  {
    school_code: "sb_539",
    name: "Penn St.",
    division: 1,
    org_id: "sb_539",
    mascot: "Nittany Lions",
    encoded_h: "Y3NiXzUzOQ==",
    encoded_c: "Y3NiXzUzOV9j",
    encoded_q: "Y3NiXzUzOV9x",
    encoded_p: "Y3NiXzUzOV9w",
  },
  {
    school_code: "sb_545",
    name: "Pittsburgh",
    division: 1,
    org_id: "sb_545",
    mascot: "Panthers",
    encoded_h: "Y3NiXzU0NQ==",
    encoded_c: "Y3NiXzU0NV9j",
    encoded_q: "Y3NiXzU0NV9x",
    encoded_p: "Y3NiXzU0NV9w",
  },
  {
    school_code: "sb_550",
    name: "Portland St.",
    division: 1,
    org_id: "sb_550",
    mascot: "Vikings",
    encoded_h: "Y3NiXzU1MA==",
    encoded_c: "Y3NiXzU1MF9j",
    encoded_q: "Y3NiXzU1MF9x",
    encoded_p: "Y3NiXzU1MF9w",
  },
  {
    school_code: "sb_553",
    name: "Prairie View",
    division: 1,
    org_id: "sb_553",
    mascot: "Lady Panthers",
    encoded_h: "Y3NiXzU1Mw==",
    encoded_c: "Y3NiXzU1M19j",
    encoded_q: "Y3NiXzU1M19x",
    encoded_p: "Y3NiXzU1M19w",
  },
  {
    school_code: "sb_1320",
    name: "Presbyterian",
    division: 1,
    org_id: "sb_1320",
    mascot: "Blue Hose",
    encoded_h: "Y3NiXzEzMjA=",
    encoded_c: "Y3NiXzEzMjBfYw==",
    encoded_q: "Y3NiXzEzMjBfcQ==",
    encoded_p: "Y3NiXzEzMjBfcA==",
  },
  {
    school_code: "sb_554",
    name: "Princeton",
    division: 1,
    org_id: "sb_554",
    mascot: "Tigers",
    encoded_h: "Y3NiXzU1NA==",
    encoded_c: "Y3NiXzU1NF9j",
    encoded_q: "Y3NiXzU1NF9x",
    encoded_p: "Y3NiXzU1NF9w",
  },
  {
    school_code: "sb_556",
    name: "Providence",
    division: 1,
    org_id: "sb_556",
    mascot: "Friars",
    encoded_h: "Y3NiXzU1Ng==",
    encoded_c: "Y3NiXzU1Nl9j",
    encoded_q: "Y3NiXzU1Nl9x",
    encoded_p: "Y3NiXzU1Nl9w",
  },
  {
    school_code: "sb_559",
    name: "Purdue",
    division: 1,
    org_id: "sb_559",
    mascot: "Boilermakers",
    encoded_h: "Y3NiXzU1OQ==",
    encoded_c: "Y3NiXzU1OV9j",
    encoded_q: "Y3NiXzU1OV9x",
    encoded_p: "Y3NiXzU1OV9w",
  },
  {
    school_code: "sb_562",
    name: "Quinnipiac",
    division: 1,
    org_id: "sb_562",
    mascot: "Bobcats",
    encoded_h: "Y3NiXzU2Mg==",
    encoded_c: "Y3NiXzU2Ml9j",
    encoded_q: "Y3NiXzU2Ml9x",
    encoded_p: "Y3NiXzU2Ml9w",
  },
  {
    school_code: "sb_563",
    name: "Radford",
    division: 1,
    org_id: "sb_563",
    mascot: "Highlanders",
    encoded_h: "Y3NiXzU2Mw==",
    encoded_c: "Y3NiXzU2M19j",
    encoded_q: "Y3NiXzU2M19x",
    encoded_p: "Y3NiXzU2M19w",
  },
  {
    school_code: "sb_572",
    name: "Rhode Island",
    division: 1,
    org_id: "sb_572",
    mascot: "Rams",
    encoded_h: "Y3NiXzU3Mg==",
    encoded_c: "Y3NiXzU3Ml9j",
    encoded_q: "Y3NiXzU3Ml9x",
    encoded_p: "Y3NiXzU3Ml9w",
  },
  {
    school_code: "sb_576",
    name: "Rider",
    division: 1,
    org_id: "sb_576",
    mascot: "Broncs",
    encoded_h: "Y3NiXzU3Ng==",
    encoded_c: "Y3NiXzU3Nl9j",
    encoded_q: "Y3NiXzU3Nl9x",
    encoded_p: "Y3NiXzU3Nl9w",
  },
  {
    school_code: "sb_579",
    name: "Robert Morris",
    division: 1,
    org_id: "sb_579",
    mascot: "Colonials",
    encoded_h: "Y3NiXzU3OQ==",
    encoded_c: "Y3NiXzU3OV9j",
    encoded_q: "Y3NiXzU3OV9x",
    encoded_p: "Y3NiXzU3OV9w",
  },
  {
    school_code: "sb_587",
    name: "Rutgers",
    division: 1,
    org_id: "sb_587",
    mascot: "Scarlet Knights",
    encoded_h: "Y3NiXzU4Nw==",
    encoded_c: "Y3NiXzU4N19j",
    encoded_q: "Y3NiXzU4N19x",
    encoded_p: "Y3NiXzU4N19w",
  },
  {
    school_code: "sb_660",
    name: "SIUE",
    division: 1,
    org_id: "sb_660",
    mascot: "Cougars",
    encoded_h: "Y3NiXzY2MA==",
    encoded_c: "Y3NiXzY2MF9j",
    encoded_q: "Y3NiXzY2MF9x",
    encoded_p: "Y3NiXzY2MF9w",
  },
  {
    school_code: "sb_102",
    name: "Sacramento St.",
    division: 1,
    org_id: "sb_102",
    mascot: "Hornets",
    encoded_h: "Y3NiXzEwMg==",
    encoded_c: "Y3NiXzEwMl9j",
    encoded_q: "Y3NiXzEwMl9x",
    encoded_p: "Y3NiXzEwMl9w",
  },
  {
    school_code: "sb_590",
    name: "Sacred Heart",
    division: 1,
    org_id: "sb_590",
    mascot: "Pioneers",
    encoded_h: "Y3NiXzU5MA==",
    encoded_c: "Y3NiXzU5MF9j",
    encoded_q: "Y3NiXzU5MF9x",
    encoded_p: "Y3NiXzU5MF9w",
  },
  {
    school_code: "sb_600",
    name: "Saint Francis (PA)",
    division: 1,
    org_id: "sb_600",
    mascot: "The Red Flash",
    encoded_h: "Y3NiXzYwMA==",
    encoded_c: "Y3NiXzYwMF9j",
    encoded_q: "Y3NiXzYwMF9x",
    encoded_p: "Y3NiXzYwMF9w",
  },
  {
    school_code: "sb_606",
    name: "Saint Joseph's",
    division: 1,
    org_id: "sb_606",
    mascot: "Hawks",
    encoded_h: "Y3NiXzYwNg==",
    encoded_c: "Y3NiXzYwNl9j",
    encoded_q: "Y3NiXzYwNl9x",
    encoded_p: "Y3NiXzYwNl9w",
  },
  {
    school_code: "sb_609",
    name: "Saint Louis",
    division: 1,
    org_id: "sb_609",
    mascot: "Billikens",
    encoded_h: "Y3NiXzYwOQ==",
    encoded_c: "Y3NiXzYwOV9j",
    encoded_q: "Y3NiXzYwOV9x",
    encoded_p: "Y3NiXzYwOV9w",
  },
  {
    school_code: "sb_617",
    name: "Saint Peter's",
    division: 1,
    org_id: "sb_617",
    mascot: "Peahens",
    encoded_h: "Y3NiXzYxNw==",
    encoded_c: "Y3NiXzYxN19j",
    encoded_q: "Y3NiXzYxN19x",
    encoded_p: "Y3NiXzYxN19w",
  },
  {
    school_code: "sb_624",
    name: "Sam Houston St.",
    division: 1,
    org_id: "sb_624",
    mascot: "Bearkats",
    encoded_h: "Y3NiXzYyNA==",
    encoded_c: "Y3NiXzYyNF9j",
    encoded_q: "Y3NiXzYyNF9x",
    encoded_p: "Y3NiXzYyNF9w",
  },
  {
    school_code: "sb_625",
    name: "Samford",
    division: 1,
    org_id: "sb_625",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzYyNQ==",
    encoded_c: "Y3NiXzYyNV9j",
    encoded_q: "Y3NiXzYyNV9x",
    encoded_p: "Y3NiXzYyNV9w",
  },
  {
    school_code: "sb_627",
    name: "San Diego",
    division: 1,
    org_id: "sb_627",
    mascot: "Toreros",
    encoded_h: "Y3NiXzYyNw==",
    encoded_c: "Y3NiXzYyN19j",
    encoded_q: "Y3NiXzYyN19x",
    encoded_p: "Y3NiXzYyN19w",
  },
  {
    school_code: "sb_626",
    name: "San Diego St.",
    division: 1,
    org_id: "sb_626",
    mascot: "Aztecs",
    encoded_h: "Y3NiXzYyNg==",
    encoded_c: "Y3NiXzYyNl9j",
    encoded_q: "Y3NiXzYyNl9x",
    encoded_p: "Y3NiXzYyNl9w",
  },
  {
    school_code: "sb_630",
    name: "San Jose St.",
    division: 1,
    org_id: "sb_630",
    mascot: "Spartans",
    encoded_h: "Y3NiXzYzMA==",
    encoded_c: "Y3NiXzYzMF9j",
    encoded_q: "Y3NiXzYzMF9x",
    encoded_p: "Y3NiXzYzMF9w",
  },
  {
    school_code: "sb_631",
    name: "Santa Clara",
    division: 1,
    org_id: "sb_631",
    mascot: "Broncos",
    encoded_h: "Y3NiXzYzMQ==",
    encoded_c: "Y3NiXzYzMV9j",
    encoded_q: "Y3NiXzYzMV9x",
    encoded_p: "Y3NiXzYzMV9w",
  },
  {
    school_code: "sb_632",
    name: "Savannah St.",
    division: 1,
    org_id: "sb_632",
    mascot: "Lady Tigers",
    encoded_h: "Y3NiXzYzMg==",
    encoded_c: "Y3NiXzYzMl9j",
    encoded_q: "Y3NiXzYzMl9x",
    encoded_p: "Y3NiXzYzMl9w",
  },
  {
    school_code: "sb_1356",
    name: "Seattle",
    division: 1,
    org_id: "sb_1356",
    mascot: "Redhawks",
    encoded_h: "Y3NiXzEzNTY=",
    encoded_c: "Y3NiXzEzNTZfYw==",
    encoded_q: "Y3NiXzEzNTZfcQ==",
    encoded_p: "Y3NiXzEzNTZfcA==",
  },
  {
    school_code: "sb_635",
    name: "Seton Hall",
    division: 1,
    org_id: "sb_635",
    mascot: "Pirates",
    encoded_h: "Y3NiXzYzNQ==",
    encoded_c: "Y3NiXzYzNV9j",
    encoded_q: "Y3NiXzYzNV9x",
    encoded_p: "Y3NiXzYzNV9w",
  },
  {
    school_code: "sb_639",
    name: "Siena",
    division: 1,
    org_id: "sb_639",
    mascot: "Saints",
    encoded_h: "Y3NiXzYzOQ==",
    encoded_c: "Y3NiXzYzOV9j",
    encoded_q: "Y3NiXzYzOV9x",
    encoded_p: "Y3NiXzYzOV9w",
  },
  {
    school_code: "sb_646",
    name: "South Ala.",
    division: 1,
    org_id: "sb_646",
    mascot: "Jaguars",
    encoded_h: "Y3NiXzY0Ng==",
    encoded_c: "Y3NiXzY0Nl9j",
    encoded_q: "Y3NiXzY0Nl9x",
    encoded_p: "Y3NiXzY0Nl9w",
  },
  {
    school_code: "sb_648",
    name: "South Carolina",
    division: 1,
    org_id: "sb_648",
    mascot: "Gamecocks",
    encoded_h: "Y3NiXzY0OA==",
    encoded_c: "Y3NiXzY0OF9j",
    encoded_q: "Y3NiXzY0OF9x",
    encoded_p: "Y3NiXzY0OF9w",
  },
  {
    school_code: "sb_647",
    name: "South Carolina St.",
    division: 1,
    org_id: "sb_647",
    mascot: "Lady Bulldogs",
    encoded_h: "Y3NiXzY0Nw==",
    encoded_c: "Y3NiXzY0N19j",
    encoded_q: "Y3NiXzY0N19x",
    encoded_p: "Y3NiXzY0N19w",
  },
  {
    school_code: "sb_650",
    name: "South Dakota",
    division: 1,
    org_id: "sb_650",
    mascot: "Coyotes",
    encoded_h: "Y3NiXzY1MA==",
    encoded_c: "Y3NiXzY1MF9j",
    encoded_q: "Y3NiXzY1MF9x",
    encoded_p: "Y3NiXzY1MF9w",
  },
  {
    school_code: "sb_649",
    name: "South Dakota St.",
    division: 1,
    org_id: "sb_649",
    mascot: "Jackrabbits",
    encoded_h: "Y3NiXzY0OQ==",
    encoded_c: "Y3NiXzY0OV9j",
    encoded_q: "Y3NiXzY0OV9x",
    encoded_p: "Y3NiXzY0OV9w",
  },
  {
    school_code: "sb_651",
    name: "South Fla.",
    division: 1,
    org_id: "sb_651",
    mascot: "Bulls",
    encoded_h: "Y3NiXzY1MQ==",
    encoded_c: "Y3NiXzY1MV9j",
    encoded_q: "Y3NiXzY1MV9x",
    encoded_p: "Y3NiXzY1MV9w",
  },
  {
    school_code: "sb_654",
    name: "Southeast Mo. St.",
    division: 1,
    org_id: "sb_654",
    mascot: "Redhawks",
    encoded_h: "Y3NiXzY1NA==",
    encoded_c: "Y3NiXzY1NF9j",
    encoded_q: "Y3NiXzY1NF9x",
    encoded_p: "Y3NiXzY1NF9w",
  },
  {
    school_code: "sb_655",
    name: "Southeastern La.",
    division: 1,
    org_id: "sb_655",
    mascot: "Lady Lions",
    encoded_h: "Y3NiXzY1NQ==",
    encoded_c: "Y3NiXzY1NV9j",
    encoded_q: "Y3NiXzY1NV9x",
    encoded_p: "Y3NiXzY1NV9w",
  },
  {
    school_code: "sb_659",
    name: "Southern Ill.",
    division: 1,
    org_id: "sb_659",
    mascot: "Salukis",
    encoded_h: "Y3NiXzY1OQ==",
    encoded_c: "Y3NiXzY1OV9j",
    encoded_q: "Y3NiXzY1OV9x",
    encoded_p: "Y3NiXzY1OV9w",
  },
  {
    school_code: "sb_664",
    name: "Southern Miss.",
    division: 1,
    org_id: "sb_664",
    mascot: "Golden Eagles",
    encoded_h: "Y3NiXzY2NA==",
    encoded_c: "Y3NiXzY2NF9j",
    encoded_q: "Y3NiXzY2NF9x",
    encoded_p: "Y3NiXzY2NF9w",
  },
  {
    school_code: "sb_665",
    name: "Southern U.",
    division: 1,
    org_id: "sb_665",
    mascot: "Lady Jaguars",
    encoded_h: "Y3NiXzY2NQ==",
    encoded_c: "Y3NiXzY2NV9j",
    encoded_q: "Y3NiXzY2NV9x",
    encoded_p: "Y3NiXzY2NV9w",
  },
  {
    school_code: "sb_667",
    name: "Southern Utah",
    division: 1,
    org_id: "sb_667",
    mascot: "Thunderbirds",
    encoded_h: "Y3NiXzY2Nw==",
    encoded_c: "Y3NiXzY2N19j",
    encoded_q: "Y3NiXzY2N19x",
    encoded_p: "Y3NiXzY2N19w",
  },
  {
    school_code: "sb_596",
    name: "St. Bonaventure",
    division: 1,
    org_id: "sb_596",
    mascot: "Bonnies",
    encoded_h: "Y3NiXzU5Ng==",
    encoded_c: "Y3NiXzU5Nl9j",
    encoded_q: "Y3NiXzU5Nl9x",
    encoded_p: "Y3NiXzU5Nl9w",
  },
  {
    school_code: "sb_603",
    name: "St. John's (NY)",
    division: 1,
    org_id: "sb_603",
    mascot: "Red Storm",
    encoded_h: "Y3NiXzYwMw==",
    encoded_c: "Y3NiXzYwM19j",
    encoded_q: "Y3NiXzYwM19x",
    encoded_p: "Y3NiXzYwM19w",
  },
  {
    school_code: "sb_610",
    name: "St. Mary's (CA)",
    division: 1,
    org_id: "sb_610",
    mascot: "Gaels",
    encoded_h: "Y3NiXzYxMA==",
    encoded_c: "Y3NiXzYxMF9j",
    encoded_q: "Y3NiXzYxMF9x",
    encoded_p: "Y3NiXzYxMF9w",
  },
  {
    school_code: "sb_674",
    name: "Stanford",
    division: 1,
    org_id: "sb_674",
    mascot: "Cardinal",
    encoded_h: "Y3NiXzY3NA==",
    encoded_c: "Y3NiXzY3NF9j",
    encoded_q: "Y3NiXzY3NF9x",
    encoded_p: "Y3NiXzY3NF9w",
  },
  {
    school_code: "sb_676",
    name: "Stephen F. Austin",
    division: 1,
    org_id: "sb_676",
    mascot: "Ladyjacks",
    encoded_h: "Y3NiXzY3Ng==",
    encoded_c: "Y3NiXzY3Nl9j",
    encoded_q: "Y3NiXzY3Nl9x",
    encoded_p: "Y3NiXzY3Nl9w",
  },
  {
    school_code: "sb_678",
    name: "Stetson",
    division: 1,
    org_id: "sb_678",
    mascot: "Hatters",
    encoded_h: "Y3NiXzY3OA==",
    encoded_c: "Y3NiXzY3OF9j",
    encoded_q: "Y3NiXzY3OF9x",
    encoded_p: "Y3NiXzY3OF9w",
  },
  {
    school_code: "sb_683",
    name: "Stony Brook",
    division: 1,
    org_id: "sb_683",
    mascot: "Seawolves",
    encoded_h: "Y3NiXzY4Mw==",
    encoded_c: "Y3NiXzY4M19j",
    encoded_q: "Y3NiXzY4M19x",
    encoded_p: "Y3NiXzY4M19w",
  },
  {
    school_code: "sb_688",
    name: "Syracuse",
    division: 1,
    org_id: "sb_688",
    mascot: "Orange",
    encoded_h: "Y3NiXzY4OA==",
    encoded_c: "Y3NiXzY4OF9j",
    encoded_q: "Y3NiXzY4OF9x",
    encoded_p: "Y3NiXzY4OF9w",
  },
  {
    school_code: "sb_690",
    name: "Temple",
    division: 1,
    org_id: "sb_690",
    mascot: "Owls",
    encoded_h: "Y3NiXzY5MA==",
    encoded_c: "Y3NiXzY5MF9j",
    encoded_q: "Y3NiXzY5MF9x",
    encoded_p: "Y3NiXzY5MF9w",
  },
  {
    school_code: "sb_694",
    name: "Tennessee",
    division: 1,
    org_id: "sb_694",
    mascot: "Lady Volunteers",
    encoded_h: "Y3NiXzY5NA==",
    encoded_c: "Y3NiXzY5NF9j",
    encoded_q: "Y3NiXzY5NF9x",
    encoded_p: "Y3NiXzY5NF9w",
  },
  {
    school_code: "sb_691",
    name: "Tennessee St.",
    division: 1,
    org_id: "sb_691",
    mascot: "Tigers",
    encoded_h: "Y3NiXzY5MQ==",
    encoded_c: "Y3NiXzY5MV9j",
    encoded_q: "Y3NiXzY5MV9x",
    encoded_p: "Y3NiXzY5MV9w",
  },
  {
    school_code: "sb_692",
    name: "Tennessee Tech",
    division: 1,
    org_id: "sb_692",
    mascot: "Golden Eagles",
    encoded_h: "Y3NiXzY5Mg==",
    encoded_c: "Y3NiXzY5Ml9j",
    encoded_q: "Y3NiXzY5Ml9x",
    encoded_p: "Y3NiXzY5Ml9w",
  },
  {
    school_code: "sb_703",
    name: "Texas",
    division: 1,
    org_id: "sb_703",
    mascot: "Longhorns",
    encoded_h: "Y3NiXzcwMw==",
    encoded_c: "Y3NiXzcwM19j",
    encoded_q: "Y3NiXzcwM19x",
    encoded_p: "Y3NiXzcwM19w",
  },
  {
    school_code: "sb_697",
    name: "Texas A&M",
    division: 1,
    org_id: "sb_697",
    mascot: "Aggies",
    encoded_h: "Y3NiXzY5Nw==",
    encoded_c: "Y3NiXzY5N19j",
    encoded_q: "Y3NiXzY5N19x",
    encoded_p: "Y3NiXzY5N19w",
  },
  {
    school_code: "sb_26172",
    name: "Texas A&M-Corpus Christi",
    division: 1,
    org_id: "sb_26172",
    mascot: "Islanders",
    encoded_h: "Y3NiXzI2MTcy",
    encoded_c: "Y3NiXzI2MTcyX2M=",
    encoded_q: "Y3NiXzI2MTcyX3E=",
    encoded_p: "Y3NiXzI2MTcyX3A=",
  },
  {
    school_code: "sb_699",
    name: "Texas Southern",
    division: 1,
    org_id: "sb_699",
    mascot: "Lady Tigers",
    encoded_h: "Y3NiXzY5OQ==",
    encoded_c: "Y3NiXzY5OV9j",
    encoded_q: "Y3NiXzY5OV9x",
    encoded_p: "Y3NiXzY5OV9w",
  },
  {
    school_code: "sb_670",
    name: "Texas St.",
    division: 1,
    org_id: "sb_670",
    mascot: "Bobcats",
    encoded_h: "Y3NiXzY3MA==",
    encoded_c: "Y3NiXzY3MF9j",
    encoded_q: "Y3NiXzY3MF9x",
    encoded_p: "Y3NiXzY3MF9w",
  },
  {
    school_code: "sb_700",
    name: "Texas Tech",
    division: 1,
    org_id: "sb_700",
    mascot: "Red Raiders",
    encoded_h: "Y3NiXzcwMA==",
    encoded_c: "Y3NiXzcwMF9j",
    encoded_q: "Y3NiXzcwMF9x",
    encoded_p: "Y3NiXzcwMF9w",
  },
  {
    school_code: "sb_702",
    name: "Texas-Arlington",
    division: 1,
    org_id: "sb_702",
    mascot: "Mavericks",
    encoded_h: "Y3NiXzcwMg==",
    encoded_c: "Y3NiXzcwMl9j",
    encoded_q: "Y3NiXzcwMl9x",
    encoded_p: "Y3NiXzcwMl9w",
  },
  {
    school_code: "sb_709",
    name: "Toledo",
    division: 1,
    org_id: "sb_709",
    mascot: "Rockets",
    encoded_h: "Y3NiXzcwOQ==",
    encoded_c: "Y3NiXzcwOV9j",
    encoded_q: "Y3NiXzcwOV9x",
    encoded_p: "Y3NiXzcwOV9w",
  },
  {
    school_code: "sb_711",
    name: "Towson",
    division: 1,
    org_id: "sb_711",
    mascot: "Tigers",
    encoded_h: "Y3NiXzcxMQ==",
    encoded_c: "Y3NiXzcxMV9j",
    encoded_q: "Y3NiXzcxMV9x",
    encoded_p: "Y3NiXzcxMV9w",
  },
  {
    school_code: "sb_716",
    name: "Troy",
    division: 1,
    org_id: "sb_716",
    mascot: "Trojans",
    encoded_h: "Y3NiXzcxNg==",
    encoded_c: "Y3NiXzcxNl9j",
    encoded_q: "Y3NiXzcxNl9x",
    encoded_p: "Y3NiXzcxNl9w",
  },
  {
    school_code: "sb_719",
    name: "Tulsa",
    division: 1,
    org_id: "sb_719",
    mascot: "Golden Hurricane",
    encoded_h: "Y3NiXzcxOQ==",
    encoded_c: "Y3NiXzcxOV9j",
    encoded_q: "Y3NiXzcxOV9x",
    encoded_p: "Y3NiXzcxOV9w",
  },
  {
    school_code: "sb_9",
    name: "UAB",
    division: 1,
    org_id: "sb_9",
    mascot: "Blazers",
    encoded_h: "Y3NiXzk=",
    encoded_c: "Y3NiXzlfYw==",
    encoded_q: "Y3NiXzlfcQ==",
    encoded_p: "Y3NiXzlfcA==",
  },
  {
    school_code: "sb_108",
    name: "UC Davis",
    division: 1,
    org_id: "sb_108",
    mascot: "Aggies",
    encoded_h: "Y3NiXzEwOA==",
    encoded_c: "Y3NiXzEwOF9j",
    encoded_q: "Y3NiXzEwOF9x",
    encoded_p: "Y3NiXzEwOF9w",
  },
  {
    school_code: "sb_111",
    name: "UC Riverside",
    division: 1,
    org_id: "sb_111",
    mascot: "Highlanders",
    encoded_h: "Y3NiXzExMQ==",
    encoded_c: "Y3NiXzExMV9j",
    encoded_q: "Y3NiXzExMV9x",
    encoded_p: "Y3NiXzExMV9w",
  },
  {
    school_code: "sb_104",
    name: "UC Santa Barbara",
    division: 1,
    org_id: "sb_104",
    mascot: "Gauchos",
    encoded_h: "Y3NiXzEwNA==",
    encoded_c: "Y3NiXzEwNF9j",
    encoded_q: "Y3NiXzEwNF9x",
    encoded_p: "Y3NiXzEwNF9w",
  },
  {
    school_code: "sb_128",
    name: "UCF",
    division: 1,
    org_id: "sb_128",
    mascot: "Knights",
    encoded_h: "Y3NiXzEyOA==",
    encoded_c: "Y3NiXzEyOF9j",
    encoded_q: "Y3NiXzEyOF9x",
    encoded_p: "Y3NiXzEyOF9w",
  },
  {
    school_code: "sb_110",
    name: "UCLA",
    division: 1,
    org_id: "sb_110",
    mascot: "Bruins",
    encoded_h: "Y3NiXzExMA==",
    encoded_c: "Y3NiXzExMF9j",
    encoded_q: "Y3NiXzExMF9x",
    encoded_p: "Y3NiXzExMF9w",
  },
  {
    school_code: "sb_164",
    name: "UConn",
    division: 1,
    org_id: "sb_164",
    mascot: "Huskies",
    encoded_h: "Y3NiXzE2NA==",
    encoded_c: "Y3NiXzE2NF9j",
    encoded_q: "Y3NiXzE2NF9x",
    encoded_p: "Y3NiXzE2NF9w",
  },
  {
    school_code: "sb_391",
    name: "UMBC",
    division: 1,
    org_id: "sb_391",
    mascot: "Retrievers",
    encoded_h: "Y3NiXzM5MQ==",
    encoded_c: "Y3NiXzM5MV9j",
    encoded_q: "Y3NiXzM5MV9x",
    encoded_p: "Y3NiXzM5MV9w",
  },
  {
    school_code: "sb_393",
    name: "UMES",
    division: 1,
    org_id: "sb_393",
    mascot: "Hawks",
    encoded_h: "Y3NiXzM5Mw==",
    encoded_c: "Y3NiXzM5M19j",
    encoded_q: "Y3NiXzM5M19x",
    encoded_p: "Y3NiXzM5M19w",
  },
  {
    school_code: "sb_2707",
    name: "UMKC",
    division: 1,
    org_id: "sb_2707",
    mascot: "Kangaroos",
    encoded_h: "Y3NiXzI3MDc=",
    encoded_c: "Y3NiXzI3MDdfYw==",
    encoded_q: "Y3NiXzI3MDdfcQ==",
    encoded_p: "Y3NiXzI3MDdfcA==",
  },
  {
    school_code: "sb_368",
    name: "UMass Lowell",
    division: 1,
    org_id: "sb_368",
    mascot: "River Hawks",
    encoded_h: "Y3NiXzM2OA==",
    encoded_c: "Y3NiXzM2OF9j",
    encoded_q: "Y3NiXzM2OF9x",
    encoded_p: "Y3NiXzM2OF9w",
  },
  {
    school_code: "sb_459",
    name: "UNC Greensboro",
    division: 1,
    org_id: "sb_459",
    mascot: "Spartans",
    encoded_h: "Y3NiXzQ1OQ==",
    encoded_c: "Y3NiXzQ1OV9j",
    encoded_q: "Y3NiXzQ1OV9x",
    encoded_p: "Y3NiXzQ1OV9w",
  },
  {
    school_code: "sb_460",
    name: "UNCW",
    division: 1,
    org_id: "sb_460",
    mascot: "Seahawks",
    encoded_h: "Y3NiXzQ2MA==",
    encoded_c: "Y3NiXzQ2MF9j",
    encoded_q: "Y3NiXzQ2MF9x",
    encoded_p: "Y3NiXzQ2MF9w",
  },
  {
    school_code: "sb_504",
    name: "UNI",
    division: 1,
    org_id: "sb_504",
    mascot: "Panthers",
    encoded_h: "Y3NiXzUwNA==",
    encoded_c: "Y3NiXzUwNF9j",
    encoded_q: "Y3NiXzUwNF9x",
    encoded_p: "Y3NiXzUwNF9w",
  },
  {
    school_code: "sb_465",
    name: "UNLV",
    division: 1,
    org_id: "sb_465",
    mascot: "Rebels",
    encoded_h: "Y3NiXzQ2NQ==",
    encoded_c: "Y3NiXzQ2NV9j",
    encoded_q: "Y3NiXzQ2NV9x",
    encoded_p: "Y3NiXzQ2NV9w",
  },
  {
    school_code: "sb_10411",
    name: "USC Upstate",
    division: 1,
    org_id: "sb_10411",
    mascot: "Spartans",
    encoded_h: "Y3NiXzEwNDEx",
    encoded_c: "Y3NiXzEwNDExX2M=",
    encoded_q: "Y3NiXzEwNDExX3E=",
    encoded_p: "Y3NiXzEwNDExX3A=",
  },
  {
    school_code: "sb_695",
    name: "UT Martin",
    division: 1,
    org_id: "sb_695",
    mascot: "Skyhawks",
    encoded_h: "Y3NiXzY5NQ==",
    encoded_c: "Y3NiXzY5NV9j",
    encoded_q: "Y3NiXzY5NV9x",
    encoded_p: "Y3NiXzY5NV9w",
  },
  {
    school_code: "sb_704",
    name: "UTEP",
    division: 1,
    org_id: "sb_704",
    mascot: "Miners",
    encoded_h: "Y3NiXzcwNA==",
    encoded_c: "Y3NiXzcwNF9j",
    encoded_q: "Y3NiXzcwNF9x",
    encoded_p: "Y3NiXzcwNF9w",
  },
  {
    school_code: "sb_706",
    name: "UTSA",
    division: 1,
    org_id: "sb_706",
    mascot: "Roadrunners",
    encoded_h: "Y3NiXzcwNg==",
    encoded_c: "Y3NiXzcwNl9j",
    encoded_q: "Y3NiXzcwNl9x",
    encoded_p: "Y3NiXzcwNl9w",
  },
  {
    school_code: "sb_732",
    name: "Utah",
    division: 1,
    org_id: "sb_732",
    mascot: "Utes",
    encoded_h: "Y3NiXzczMg==",
    encoded_c: "Y3NiXzczMl9j",
    encoded_q: "Y3NiXzczMl9x",
    encoded_p: "Y3NiXzczMl9w",
  },
  {
    school_code: "sb_731",
    name: "Utah St.",
    division: 1,
    org_id: "sb_731",
    mascot: "Aggies",
    encoded_h: "Y3NiXzczMQ==",
    encoded_c: "Y3NiXzczMV9j",
    encoded_q: "Y3NiXzczMV9x",
    encoded_p: "Y3NiXzczMV9w",
  },
  {
    school_code: "sb_30024",
    name: "Utah Valley",
    division: 1,
    org_id: "sb_30024",
    mascot: "Wolverines",
    encoded_h: "Y3NiXzMwMDI0",
    encoded_c: "Y3NiXzMwMDI0X2M=",
    encoded_q: "Y3NiXzMwMDI0X3E=",
    encoded_p: "Y3NiXzMwMDI0X3A=",
  },
  {
    school_code: "sb_735",
    name: "Valparaiso",
    division: 1,
    org_id: "sb_735",
    mascot: "Crusaders",
    encoded_h: "Y3NiXzczNQ==",
    encoded_c: "Y3NiXzczNV9j",
    encoded_q: "Y3NiXzczNV9x",
    encoded_p: "Y3NiXzczNV9w",
  },
  {
    school_code: "sb_739",
    name: "Villanova",
    division: 1,
    org_id: "sb_739",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzczOQ==",
    encoded_c: "Y3NiXzczOV9j",
    encoded_q: "Y3NiXzczOV9x",
    encoded_p: "Y3NiXzczOV9w",
  },
  {
    school_code: "sb_746",
    name: "Virginia",
    division: 1,
    org_id: "sb_746",
    mascot: "Cavaliers",
    encoded_h: "Y3NiXzc0Ng==",
    encoded_c: "Y3NiXzc0Nl9j",
    encoded_q: "Y3NiXzc0Nl9x",
    encoded_p: "Y3NiXzc0Nl9w",
  },
  {
    school_code: "sb_742",
    name: "Virginia Tech",
    division: 1,
    org_id: "sb_742",
    mascot: "Hokies",
    encoded_h: "Y3NiXzc0Mg==",
    encoded_c: "Y3NiXzc0Ml9j",
    encoded_q: "Y3NiXzc0Ml9x",
    encoded_p: "Y3NiXzc0Ml9w",
  },
  {
    school_code: "sb_748",
    name: "Wagner",
    division: 1,
    org_id: "sb_748",
    mascot: "Seahawks",
    encoded_h: "Y3NiXzc0OA==",
    encoded_c: "Y3NiXzc0OF9j",
    encoded_q: "Y3NiXzc0OF9x",
    encoded_p: "Y3NiXzc0OF9w",
  },
  {
    school_code: "sb_756",
    name: "Washington",
    division: 1,
    org_id: "sb_756",
    mascot: "Huskies",
    encoded_h: "Y3NiXzc1Ng==",
    encoded_c: "Y3NiXzc1Nl9j",
    encoded_q: "Y3NiXzc1Nl9x",
    encoded_p: "Y3NiXzc1Nl9w",
  },
  {
    school_code: "sb_758",
    name: "Weber St.",
    division: 1,
    org_id: "sb_758",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzc1OA==",
    encoded_c: "Y3NiXzc1OF9j",
    encoded_q: "Y3NiXzc1OF9x",
    encoded_p: "Y3NiXzc1OF9w",
  },
  {
    school_code: "sb_769",
    name: "Western Caro.",
    division: 1,
    org_id: "sb_769",
    mascot: "Catamounts",
    encoded_h: "Y3NiXzc2OQ==",
    encoded_c: "Y3NiXzc2OV9j",
    encoded_q: "Y3NiXzc2OV9x",
    encoded_p: "Y3NiXzc2OV9w",
  },
  {
    school_code: "sb_771",
    name: "Western Ill.",
    division: 1,
    org_id: "sb_771",
    mascot: "Leathernecks",
    encoded_h: "Y3NiXzc3MQ==",
    encoded_c: "Y3NiXzc3MV9j",
    encoded_q: "Y3NiXzc3MV9x",
    encoded_p: "Y3NiXzc3MV9w",
  },
  {
    school_code: "sb_772",
    name: "Western Ky.",
    division: 1,
    org_id: "sb_772",
    mascot: "Lady Toppers",
    encoded_h: "Y3NiXzc3Mg==",
    encoded_c: "Y3NiXzc3Ml9j",
    encoded_q: "Y3NiXzc3Ml9x",
    encoded_p: "Y3NiXzc3Ml9w",
  },
  {
    school_code: "sb_774",
    name: "Western Mich.",
    division: 1,
    org_id: "sb_774",
    mascot: "Broncos",
    encoded_h: "Y3NiXzc3NA==",
    encoded_c: "Y3NiXzc3NF9j",
    encoded_q: "Y3NiXzc3NF9x",
    encoded_p: "Y3NiXzc3NF9w",
  },
  {
    school_code: "sb_782",
    name: "Wichita St.",
    division: 1,
    org_id: "sb_782",
    mascot: "Shockers",
    encoded_h: "Y3NiXzc4Mg==",
    encoded_c: "Y3NiXzc4Ml9j",
    encoded_q: "Y3NiXzc4Ml9x",
    encoded_p: "Y3NiXzc4Ml9w",
  },
  {
    school_code: "sb_792",
    name: "Winthrop",
    division: 1,
    org_id: "sb_792",
    mascot: "Eagles",
    encoded_h: "Y3NiXzc5Mg==",
    encoded_c: "Y3NiXzc5Ml9j",
    encoded_q: "Y3NiXzc5Ml9x",
    encoded_p: "Y3NiXzc5Ml9w",
  },
  {
    school_code: "sb_796",
    name: "Wisconsin",
    division: 1,
    org_id: "sb_796",
    mascot: "Badgers",
    encoded_h: "Y3NiXzc5Ng==",
    encoded_c: "Y3NiXzc5Nl9j",
    encoded_q: "Y3NiXzc5Nl9x",
    encoded_p: "Y3NiXzc5Nl9w",
  },
  {
    school_code: "sb_810",
    name: "Wright St.",
    division: 1,
    org_id: "sb_810",
    mascot: "Raiders",
    encoded_h: "Y3NiXzgxMA==",
    encoded_c: "Y3NiXzgxMF9j",
    encoded_q: "Y3NiXzgxMF9x",
    encoded_p: "Y3NiXzgxMF9w",
  },
  {
    school_code: "sb_813",
    name: "Yale",
    division: 1,
    org_id: "sb_813",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzgxMw==",
    encoded_c: "Y3NiXzgxM19j",
    encoded_q: "Y3NiXzgxM19x",
    encoded_p: "Y3NiXzgxM19w",
  },
  {
    school_code: "sb_817",
    name: "Youngstown St.",
    division: 1,
    org_id: "sb_817",
    mascot: "Penguins",
    encoded_h: "Y3NiXzgxNw==",
    encoded_c: "Y3NiXzgxN19j",
    encoded_q: "Y3NiXzgxN19x",
    encoded_p: "Y3NiXzgxN19w",
  },
  {
    school_code: "sb_30123",
    name: "Academy of Art",
    division: 2,
    org_id: "sb_30123",
    mascot: "Urban Knights",
    encoded_h: "Y3NiXzMwMTIz",
    encoded_c: "Y3NiXzMwMTIzX2M=",
    encoded_q: "Y3NiXzMwMTIzX3E=",
    encoded_p: "Y3NiXzMwMTIzX3A=",
  },
  {
    school_code: "sb_929",
    name: "Adams St.",
    division: 2,
    org_id: "sb_929",
    mascot: "Grizzlies",
    encoded_h: "Y3NiXzkyOQ==",
    encoded_c: "Y3NiXzkyOV9j",
    encoded_q: "Y3NiXzkyOV9x",
    encoded_p: "Y3NiXzkyOV9w",
  },
  {
    school_code: "sb_3",
    name: "Adelphi",
    division: 2,
    org_id: "sb_3",
    mascot: "Panthers",
    encoded_h: "Y3NiXzM=",
    encoded_c: "Y3NiXzNfYw==",
    encoded_q: "Y3NiXzNfcQ==",
    encoded_p: "Y3NiXzNfcA==",
  },
  {
    school_code: "sb_10",
    name: "Ala.-Huntsville",
    division: 2,
    org_id: "sb_10",
    mascot: "Chargers",
    encoded_h: "Y3NiXzEw",
    encoded_c: "Y3NiXzEwX2M=",
    encoded_q: "Y3NiXzEwX3E=",
    encoded_p: "Y3NiXzEwX3A=",
  },
  {
    school_code: "sb_13",
    name: "Albany St. (GA)",
    division: 2,
    org_id: "sb_13",
    mascot: "Lady Rams",
    encoded_h: "Y3NiXzEz",
    encoded_c: "Y3NiXzEzX2M=",
    encoded_q: "Y3NiXzEzX3E=",
    encoded_p: "Y3NiXzEzX3A=",
  },
  {
    school_code: "sb_934",
    name: "Alderson Broaddus",
    division: 2,
    org_id: "sb_934",
    mascot: "Battlers",
    encoded_h: "Y3NiXzkzNA==",
    encoded_c: "Y3NiXzkzNF9j",
    encoded_q: "Y3NiXzkzNF9x",
    encoded_p: "Y3NiXzkzNF9w",
  },
  {
    school_code: "sb_22",
    name: "American Int'l",
    division: 2,
    org_id: "sb_22",
    mascot: "Yellow Jackets",
    encoded_h: "Y3NiXzIy",
    encoded_c: "Y3NiXzIyX2M=",
    encoded_q: "Y3NiXzIyX3E=",
    encoded_p: "Y3NiXzIyX3A=",
  },
  {
    school_code: "sb_13028",
    name: "Anderson (SC)",
    division: 2,
    org_id: "sb_13028",
    mascot: "Trojans",
    encoded_h: "Y3NiXzEzMDI4",
    encoded_c: "Y3NiXzEzMDI4X2M=",
    encoded_q: "Y3NiXzEzMDI4X3E=",
    encoded_p: "Y3NiXzEzMDI4X3A=",
  },
  {
    school_code: "sb_25",
    name: "Angelo St.",
    division: 2,
    org_id: "sb_25",
    mascot: "Belles",
    encoded_h: "Y3NiXzI1",
    encoded_c: "Y3NiXzI1X2M=",
    encoded_q: "Y3NiXzI1X3E=",
    encoded_p: "Y3NiXzI1X3A=",
  },
  {
    school_code: "sb_8411",
    name: "Ark.-Monticello",
    division: 2,
    org_id: "sb_8411",
    mascot: "Cotton Blossoms",
    encoded_h: "Y3NiXzg0MTE=",
    encoded_c: "Y3NiXzg0MTFfYw==",
    encoded_q: "Y3NiXzg0MTFfcQ==",
    encoded_p: "Y3NiXzg0MTFfcA==",
  },
  {
    school_code: "sb_947",
    name: "Arkansas Tech",
    division: 2,
    org_id: "sb_947",
    mascot: "Golden Suns",
    encoded_h: "Y3NiXzk0Nw==",
    encoded_c: "Y3NiXzk0N19j",
    encoded_q: "Y3NiXzk0N19x",
    encoded_p: "Y3NiXzk0N19w",
  },
  {
    school_code: "sb_33",
    name: "Armstrong",
    division: 2,
    org_id: "sb_33",
    mascot: "Pirates",
    encoded_h: "Y3NiXzMz",
    encoded_c: "Y3NiXzMzX2M=",
    encoded_q: "Y3NiXzMzX3E=",
    encoded_p: "Y3NiXzMzX3A=",
  },
  {
    school_code: "sb_35",
    name: "Ashland",
    division: 2,
    org_id: "sb_35",
    mascot: "Eagles",
    encoded_h: "Y3NiXzM1",
    encoded_c: "Y3NiXzM1X2M=",
    encoded_q: "Y3NiXzM1X3E=",
    encoded_p: "Y3NiXzM1X3A=",
  },
  {
    school_code: "sb_36",
    name: "Assumption",
    division: 2,
    org_id: "sb_36",
    mascot: "Greyhounds",
    encoded_h: "Y3NiXzM2",
    encoded_c: "Y3NiXzM2X2M=",
    encoded_q: "Y3NiXzM2X3E=",
    encoded_p: "Y3NiXzM2X3A=",
  },
  {
    school_code: "sb_41",
    name: "Augustana (SD)",
    division: 2,
    org_id: "sb_41",
    mascot: "Vikings",
    encoded_h: "Y3NiXzQx",
    encoded_c: "Y3NiXzQxX2M=",
    encoded_q: "Y3NiXzQxX3E=",
    encoded_p: "Y3NiXzQxX3A=",
  },
  {
    school_code: "sb_30146",
    name: "Azusa Pacific",
    division: 2,
    org_id: "sb_30146",
    mascot: "Cougars",
    encoded_h: "Y3NiXzMwMTQ2",
    encoded_c: "Y3NiXzMwMTQ2X2M=",
    encoded_q: "Y3NiXzMwMTQ2X3E=",
    encoded_p: "Y3NiXzMwMTQ2X3A=",
  },
  {
    school_code: "sb_30093",
    name: "AUM",
    division: 2,
    org_id: "sb_30093",
    mascot: "Warhawks",
    encoded_h: "Y3NiXzMwMDkz",
    encoded_c: "Y3NiXzMwMDkzX2M=",
    encoded_q: "Y3NiXzMwMDkzX3E=",
    encoded_p: "Y3NiXzMwMDkzX3A=",
  },
  {
    school_code: "sb_2685",
    name: "BYU-Hawaii",
    division: 2,
    org_id: "sb_2685",
    mascot: "Seasiders",
    encoded_h: "Y3NiXzI2ODU=",
    encoded_c: "Y3NiXzI2ODVfYw==",
    encoded_q: "Y3NiXzI2ODVfcQ==",
    encoded_p: "Y3NiXzI2ODVfcA==",
  },
  {
    school_code: "sb_49",
    name: "Barry",
    division: 2,
    org_id: "sb_49",
    mascot: "Buccaneers",
    encoded_h: "Y3NiXzQ5",
    encoded_c: "Y3NiXzQ5X2M=",
    encoded_q: "Y3NiXzQ5X3E=",
    encoded_p: "Y3NiXzQ5X3A=",
  },
  {
    school_code: "sb_15646",
    name: "Barton",
    division: 2,
    org_id: "sb_15646",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzE1NjQ2",
    encoded_c: "Y3NiXzE1NjQ2X2M=",
    encoded_q: "Y3NiXzE1NjQ2X3E=",
    encoded_p: "Y3NiXzE1NjQ2X3A=",
  },
  {
    school_code: "sb_52",
    name: "Bellarmine",
    division: 2,
    org_id: "sb_52",
    mascot: "Knights",
    encoded_h: "Y3NiXzUy",
    encoded_c: "Y3NiXzUyX2M=",
    encoded_q: "Y3NiXzUyX3E=",
    encoded_p: "Y3NiXzUyX3A=",
  },
  {
    school_code: "sb_2683",
    name: "Belmont Abbey",
    division: 2,
    org_id: "sb_2683",
    mascot: "Crusaders",
    encoded_h: "Y3NiXzI2ODM=",
    encoded_c: "Y3NiXzI2ODNfYw==",
    encoded_q: "Y3NiXzI2ODNfcQ==",
    encoded_p: "Y3NiXzI2ODNfcA==",
  },
  {
    school_code: "sb_54",
    name: "Bemidji St.",
    division: 2,
    org_id: "sb_54",
    mascot: "Beavers",
    encoded_h: "Y3NiXzU0",
    encoded_c: "Y3NiXzU0X2M=",
    encoded_q: "Y3NiXzU0X3E=",
    encoded_p: "Y3NiXzU0X3A=",
  },
  {
    school_code: "sb_55",
    name: "Benedict",
    division: 2,
    org_id: "sb_55",
    mascot: "Lady Tigers",
    encoded_h: "Y3NiXzU1",
    encoded_c: "Y3NiXzU1X2M=",
    encoded_q: "Y3NiXzU1X3E=",
    encoded_p: "Y3NiXzU1X3A=",
  },
  {
    school_code: "sb_56",
    name: "Bentley",
    division: 2,
    org_id: "sb_56",
    mascot: "Falcons",
    encoded_h: "Y3NiXzU2",
    encoded_c: "Y3NiXzU2X2M=",
    encoded_q: "Y3NiXzU2X3E=",
    encoded_p: "Y3NiXzU2X3A=",
  },
  {
    school_code: "sb_30220",
    name: "Biola",
    division: 2,
    org_id: "sb_30220",
    mascot: "Eagles",
    encoded_h: "Y3NiXzMwMjIw",
    encoded_c: "Y3NiXzMwMjIwX2M=",
    encoded_q: "Y3NiXzMwMjIwX3E=",
    encoded_p: "Y3NiXzMwMjIwX3A=",
  },
  {
    school_code: "sb_30134",
    name: "Black Hills St.",
    division: 2,
    org_id: "sb_30134",
    mascot: "Yellow Jackets",
    encoded_h: "Y3NiXzMwMTM0",
    encoded_c: "Y3NiXzMwMTM0X2M=",
    encoded_q: "Y3NiXzMwMTM0X3E=",
    encoded_p: "Y3NiXzMwMTM0X3A=",
  },
  {
    school_code: "sb_984",
    name: "Bloomfield",
    division: 2,
    org_id: "sb_984",
    mascot: "Bears",
    encoded_h: "Y3NiXzk4NA==",
    encoded_c: "Y3NiXzk4NF9j",
    encoded_q: "Y3NiXzk4NF9x",
    encoded_p: "Y3NiXzk4NF9w",
  },
  {
    school_code: "sb_65",
    name: "Bloomsburg",
    division: 2,
    org_id: "sb_65",
    mascot: "Huskies",
    encoded_h: "Y3NiXzY1",
    encoded_c: "Y3NiXzY1X2M=",
    encoded_q: "Y3NiXzY1X3E=",
    encoded_p: "Y3NiXzY1X3A=",
  },
  {
    school_code: "sb_985",
    name: "Bluefield St.",
    division: 2,
    org_id: "sb_985",
    mascot: "Lady Blues",
    encoded_h: "Y3NiXzk4NQ==",
    encoded_c: "Y3NiXzk4NV9j",
    encoded_q: "Y3NiXzk4NV9x",
    encoded_p: "Y3NiXzk4NV9w",
  },
  {
    school_code: "sb_70",
    name: "Bowie St.",
    division: 2,
    org_id: "sb_70",
    mascot: "Lady Bulldogs",
    encoded_h: "Y3NiXzcw",
    encoded_c: "Y3NiXzcwX2M=",
    encoded_q: "Y3NiXzcwX3E=",
    encoded_p: "Y3NiXzcwX3A=",
  },
  {
    school_code: "sb_30094",
    name: "Brevard",
    division: 2,
    org_id: "sb_30094",
    mascot: "Tornados",
    encoded_h: "Y3NiXzMwMDk0",
    encoded_c: "Y3NiXzMwMDk0X2M=",
    encoded_q: "Y3NiXzMwMDk0X3E=",
    encoded_p: "Y3NiXzMwMDk0X3A=",
  },
  {
    school_code: "sb_74",
    name: "Bridgeport",
    division: 2,
    org_id: "sb_74",
    mascot: "Purple Knights",
    encoded_h: "Y3NiXzc0",
    encoded_c: "Y3NiXzc0X2M=",
    encoded_q: "Y3NiXzc0X3E=",
    encoded_p: "Y3NiXzc0X3A=",
  },
  {
    school_code: "sb_92",
    name: "Cal St. Dom. Hills",
    division: 2,
    org_id: "sb_92",
    mascot: "Toros",
    encoded_h: "Y3NiXzky",
    encoded_c: "Y3NiXzkyX2M=",
    encoded_q: "Y3NiXzkyX3E=",
    encoded_p: "Y3NiXzkyX3A=",
  },
  {
    school_code: "sb_98",
    name: "Cal St. East Bay",
    division: 2,
    org_id: "sb_98",
    mascot: "Pioneers",
    encoded_h: "Y3NiXzk4",
    encoded_c: "Y3NiXzk4X2M=",
    encoded_q: "Y3NiXzk4X3E=",
    encoded_p: "Y3NiXzk4X3A=",
  },
  {
    school_code: "sb_30055",
    name: "Cal St. Monterey Bay",
    division: 2,
    org_id: "sb_30055",
    mascot: "Otters",
    encoded_h: "Y3NiXzMwMDU1",
    encoded_c: "Y3NiXzMwMDU1X2M=",
    encoded_q: "Y3NiXzMwMDU1X3E=",
    encoded_p: "Y3NiXzMwMDU1X3A=",
  },
  {
    school_code: "sb_93",
    name: "Cal St. San B'dino",
    division: 2,
    org_id: "sb_93",
    mascot: "Coyotes",
    encoded_h: "Y3NiXzkz",
    encoded_c: "Y3NiXzkzX2M=",
    encoded_q: "Y3NiXzkzX3E=",
    encoded_p: "Y3NiXzkzX3A=",
  },
  {
    school_code: "sb_103",
    name: "Cal St. Stanislaus",
    division: 2,
    org_id: "sb_103",
    mascot: "Warriors",
    encoded_h: "Y3NiXzEwMw==",
    encoded_c: "Y3NiXzEwM19j",
    encoded_q: "Y3NiXzEwM19x",
    encoded_p: "Y3NiXzEwM19w",
  },
  {
    school_code: "sb_30198",
    name: "Cal St. San Marcos",
    division: 2,
    org_id: "sb_30198",
    mascot: "Cougars",
    encoded_h: "Y3NiXzMwMTk4",
    encoded_c: "Y3NiXzMwMTk4X2M=",
    encoded_q: "Y3NiXzMwMTk4X3E=",
    encoded_p: "Y3NiXzMwMTk4X3A=",
  },
  {
    school_code: "sb_995",
    name: "Caldwell",
    division: 2,
    org_id: "sb_995",
    mascot: "Cougars",
    encoded_h: "Y3NiXzk5NQ==",
    encoded_c: "Y3NiXzk5NV9j",
    encoded_q: "Y3NiXzk5NV9x",
    encoded_p: "Y3NiXzk5NV9w",
  },
  {
    school_code: "sb_106",
    name: "California (PA)",
    division: 2,
    org_id: "sb_106",
    mascot: "Vulcans",
    encoded_h: "Y3NiXzEwNg==",
    encoded_c: "Y3NiXzEwNl9j",
    encoded_q: "Y3NiXzEwNl9x",
    encoded_p: "Y3NiXzEwNl9w",
  },
  {
    school_code: "sb_30135",
    name: "California Baptist",
    division: 2,
    org_id: "sb_30135",
    mascot: "Lancers",
    encoded_h: "Y3NiXzMwMTM1",
    encoded_c: "Y3NiXzMwMTM1X2M=",
    encoded_q: "Y3NiXzMwMTM1X3E=",
    encoded_p: "Y3NiXzMwMTM1X3A=",
  },
  {
    school_code: "sb_996",
    name: "Cameron",
    division: 2,
    org_id: "sb_996",
    mascot: "Aggies",
    encoded_h: "Y3NiXzk5Ng==",
    encoded_c: "Y3NiXzk5Nl9j",
    encoded_q: "Y3NiXzk5Nl9x",
    encoded_p: "Y3NiXzk5Nl9w",
  },
  {
    school_code: "sb_119",
    name: "Carnegie Mellon",
    division: 2,
    org_id: "sb_119",
    mascot: "Tartans",
    encoded_h: "Y3NiXzExOQ==",
    encoded_c: "Y3NiXzExOV9j",
    encoded_q: "Y3NiXzExOV9x",
    encoded_p: "Y3NiXzExOV9w",
  },
  {
    school_code: "sb_1000",
    name: "Carson-Newman",
    division: 2,
    org_id: "sb_1000",
    mascot: "Lady Eagles",
    encoded_h: "Y3NiXzEwMDA=",
    encoded_c: "Y3NiXzEwMDBfYw==",
    encoded_q: "Y3NiXzEwMDBfcQ==",
    encoded_p: "Y3NiXzEwMDBfcA==",
  },
  {
    school_code: "sb_1001",
    name: "Catawba",
    division: 2,
    org_id: "sb_1001",
    mascot: "Lady Indians",
    encoded_h: "Y3NiXzEwMDE=",
    encoded_c: "Y3NiXzEwMDFfYw==",
    encoded_q: "Y3NiXzEwMDFfcQ==",
    encoded_p: "Y3NiXzEwMDFfcA==",
  },
  {
    school_code: "sb_30124",
    name: "Cedarville",
    division: 2,
    org_id: "sb_30124",
    mascot: "Yellow Jackets",
    encoded_h: "Y3NiXzMwMTI0",
    encoded_c: "Y3NiXzMwMTI0X2M=",
    encoded_q: "Y3NiXzMwMTI0X3E=",
    encoded_p: "Y3NiXzMwMTI0X3A=",
  },
  {
    school_code: "sb_130",
    name: "Central Mo.",
    division: 2,
    org_id: "sb_130",
    mascot: "Jennies",
    encoded_h: "Y3NiXzEzMA==",
    encoded_c: "Y3NiXzEzMF9j",
    encoded_q: "Y3NiXzEzMF9x",
    encoded_p: "Y3NiXzEzMF9w",
  },
  {
    school_code: "sb_1009",
    name: "Central Okla.",
    division: 2,
    org_id: "sb_1009",
    mascot: "Bronchos",
    encoded_h: "Y3NiXzEwMDk=",
    encoded_c: "Y3NiXzEwMDlfYw==",
    encoded_q: "Y3NiXzEwMDlfcQ==",
    encoded_p: "Y3NiXzEwMDlfcA==",
  },
  {
    school_code: "sb_1010",
    name: "Central Wash.",
    division: 2,
    org_id: "sb_1010",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzEwMTA=",
    encoded_c: "Y3NiXzEwMTBfYw==",
    encoded_q: "Y3NiXzEwMTBfcQ==",
    encoded_p: "Y3NiXzEwMTBfcA==",
  },
  {
    school_code: "sb_1012",
    name: "Chadron St.",
    division: 2,
    org_id: "sb_1012",
    mascot: "Eagles",
    encoded_h: "Y3NiXzEwMTI=",
    encoded_c: "Y3NiXzEwMTJfYw==",
    encoded_q: "Y3NiXzEwMTJfcQ==",
    encoded_p: "Y3NiXzEwMTJfcA==",
  },
  {
    school_code: "sb_133",
    name: "Chaminade",
    division: 2,
    org_id: "sb_133",
    mascot: "Lady Silverswords",
    encoded_h: "Y3NiXzEzMw==",
    encoded_c: "Y3NiXzEzM19j",
    encoded_q: "Y3NiXzEzM19x",
    encoded_p: "Y3NiXzEzM19w",
  },
  {
    school_code: "sb_1013",
    name: "Charleston (WV)",
    division: 2,
    org_id: "sb_1013",
    mascot: "Golden Eagles",
    encoded_h: "Y3NiXzEwMTM=",
    encoded_c: "Y3NiXzEwMTNfYw==",
    encoded_q: "Y3NiXzEwMTNfcQ==",
    encoded_p: "Y3NiXzEwMTNfcA==",
  },
  {
    school_code: "sb_21323",
    name: "Chestnut Hill",
    division: 2,
    org_id: "sb_21323",
    mascot: "Griffins",
    encoded_h: "Y3NiXzIxMzIz",
    encoded_c: "Y3NiXzIxMzIzX2M=",
    encoded_q: "Y3NiXzIxMzIzX3E=",
    encoded_p: "Y3NiXzIxMzIzX3A=",
  },
  {
    school_code: "sb_95",
    name: "Chico St.",
    division: 2,
    org_id: "sb_95",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzk1",
    encoded_c: "Y3NiXzk1X2M=",
    encoded_q: "Y3NiXzk1X3E=",
    encoded_p: "Y3NiXzk1X3A=",
  },
  {
    school_code: "sb_8875",
    name: "Chowan",
    division: 2,
    org_id: "sb_8875",
    mascot: "Hawks",
    encoded_h: "Y3NiXzg4NzU=",
    encoded_c: "Y3NiXzg4NzVfYw==",
    encoded_q: "Y3NiXzg4NzVfcQ==",
    encoded_p: "Y3NiXzg4NzVfcA==",
  },
  {
    school_code: "sb_1015",
    name: "Christian Bros.",
    division: 2,
    org_id: "sb_1015",
    mascot: "Lady Buccaneers",
    encoded_h: "Y3NiXzEwMTU=",
    encoded_c: "Y3NiXzEwMTVfYw==",
    encoded_q: "Y3NiXzEwMTVfcQ==",
    encoded_p: "Y3NiXzEwMTVfcA==",
  },
  {
    school_code: "sb_30058",
    name: "Claflin",
    division: 2,
    org_id: "sb_30058",
    mascot: "Lady Panthers",
    encoded_h: "Y3NiXzMwMDU4",
    encoded_c: "Y3NiXzMwMDU4X2M=",
    encoded_q: "Y3NiXzMwMDU4X3E=",
    encoded_p: "Y3NiXzMwMDU4X3A=",
  },
  {
    school_code: "sb_143",
    name: "Clarion",
    division: 2,
    org_id: "sb_143",
    mascot: "Golden Eagles",
    encoded_h: "Y3NiXzE0Mw==",
    encoded_c: "Y3NiXzE0M19j",
    encoded_q: "Y3NiXzE0M19x",
    encoded_p: "Y3NiXzE0M19w",
  },
  {
    school_code: "sb_144",
    name: "Clark Atlanta",
    division: 2,
    org_id: "sb_144",
    mascot: "Lady Panthers",
    encoded_h: "Y3NiXzE0NA==",
    encoded_c: "Y3NiXzE0NF9j",
    encoded_q: "Y3NiXzE0NF9x",
    encoded_p: "Y3NiXzE0NF9w",
  },
  {
    school_code: "sb_1023",
    name: "Coker",
    division: 2,
    org_id: "sb_1023",
    mascot: "Cobras",
    encoded_h: "Y3NiXzEwMjM=",
    encoded_c: "Y3NiXzEwMjNfYw==",
    encoded_q: "Y3NiXzEwMjNfcQ==",
    encoded_p: "Y3NiXzEwMjNfcA==",
  },
  {
    school_code: "sb_11403",
    name: "Colo. Christian",
    division: 2,
    org_id: "sb_11403",
    mascot: "Cougars",
    encoded_h: "Y3NiXzExNDAz",
    encoded_c: "Y3NiXzExNDAzX2M=",
    encoded_q: "Y3NiXzExNDAzX3E=",
    encoded_p: "Y3NiXzExNDAzX3A=",
  },
  {
    school_code: "sb_11416",
    name: "Colorado Mesa",
    division: 2,
    org_id: "sb_11416",
    mascot: "Mavericks",
    encoded_h: "Y3NiXzExNDE2",
    encoded_c: "Y3NiXzExNDE2X2M=",
    encoded_q: "Y3NiXzExNDE2X3E=",
    encoded_p: "Y3NiXzExNDE2X3A=",
  },
  {
    school_code: "sb_155",
    name: "Colorado Mines",
    division: 2,
    org_id: "sb_155",
    mascot: "Orediggers",
    encoded_h: "Y3NiXzE1NQ==",
    encoded_c: "Y3NiXzE1NV9j",
    encoded_q: "Y3NiXzE1NV9x",
    encoded_p: "Y3NiXzE1NV9w",
  },
  {
    school_code: "sb_2720",
    name: "Colorado St.-Pueblo",
    division: 2,
    org_id: "sb_2720",
    mascot: "ThunderWolves",
    encoded_h: "Y3NiXzI3MjA=",
    encoded_c: "Y3NiXzI3MjBfYw==",
    encoded_q: "Y3NiXzI3MjBfcQ==",
    encoded_p: "Y3NiXzI3MjBfcA==",
  },
  {
    school_code: "sb_159",
    name: "Columbus St.",
    division: 2,
    org_id: "sb_159",
    mascot: "Lady Cougars",
    encoded_h: "Y3NiXzE1OQ==",
    encoded_c: "Y3NiXzE1OV9j",
    encoded_q: "Y3NiXzE1OV9x",
    encoded_p: "Y3NiXzE1OV9w",
  },
  {
    school_code: "sb_1028",
    name: "Concord",
    division: 2,
    org_id: "sb_1028",
    mascot: "Mountain Lions",
    encoded_h: "Y3NiXzEwMjg=",
    encoded_c: "Y3NiXzEwMjhfYw==",
    encoded_q: "Y3NiXzEwMjhfcQ==",
    encoded_p: "Y3NiXzEwMjhfcA==",
  },
  {
    school_code: "sb_30199",
    name: "Concordia (CA)",
    division: 2,
    org_id: "sb_30199",
    mascot: "Eagles",
    encoded_h: "Y3NiXzMwMTk5",
    encoded_c: "Y3NiXzMwMTk5X2M=",
    encoded_q: "Y3NiXzMwMTk5X3E=",
    encoded_p: "Y3NiXzMwMTk5X3A=",
  },
  {
    school_code: "sb_162",
    name: "Concordia (NY)",
    division: 2,
    org_id: "sb_162",
    mascot: "Clippers",
    encoded_h: "Y3NiXzE2Mg==",
    encoded_c: "Y3NiXzE2Ml9j",
    encoded_q: "Y3NiXzE2Ml9x",
    encoded_p: "Y3NiXzE2Ml9w",
  },
  {
    school_code: "sb_30200",
    name: "Concordia Portland",
    division: 2,
    org_id: "sb_30200",
    mascot: "Cavaliers",
    encoded_h: "Y3NiXzMwMjAw",
    encoded_c: "Y3NiXzMwMjAwX2M=",
    encoded_q: "Y3NiXzMwMjAwX3E=",
    encoded_p: "Y3NiXzMwMjAwX3A=",
  },
  {
    school_code: "sb_9081",
    name: "Concordia-St. Paul",
    division: 2,
    org_id: "sb_9081",
    mascot: "Golden Bears",
    encoded_h: "Y3NiXzkwODE=",
    encoded_c: "Y3NiXzkwODFfYw==",
    encoded_q: "Y3NiXzkwODFfcQ==",
    encoded_p: "Y3NiXzkwODFfcA==",
  },
  {
    school_code: "sb_8935",
    name: "Converse",
    division: 2,
    org_id: "sb_8935",
    mascot: "Valkyries",
    encoded_h: "Y3NiXzg5MzU=",
    encoded_c: "Y3NiXzg5MzVfYw==",
    encoded_q: "Y3NiXzg5MzVfcQ==",
    encoded_p: "Y3NiXzg5MzVfcA==",
  },
  {
    school_code: "sb_174",
    name: "Davis & Elkins",
    division: 2,
    org_id: "sb_174",
    mascot: "Senators",
    encoded_h: "Y3NiXzE3NA==",
    encoded_c: "Y3NiXzE3NF9j",
    encoded_q: "Y3NiXzE3NF9x",
    encoded_p: "Y3NiXzE3NF9w",
  },
  {
    school_code: "sb_181",
    name: "Delta St.",
    division: 2,
    org_id: "sb_181",
    mascot: "Lady Statesmen",
    encoded_h: "Y3NiXzE4MQ==",
    encoded_c: "Y3NiXzE4MV9j",
    encoded_q: "Y3NiXzE4MV9x",
    encoded_p: "Y3NiXzE4MV9w",
  },
  {
    school_code: "sb_30095",
    name: "Utah Tech",
    division: 2,
    org_id: "sb_30095",
    mascot: "Trailblazer",
    encoded_h: "Y3NiXzMwMDk1",
    encoded_c: "Y3NiXzMwMDk1X2M=",
    encoded_q: "Y3NiXzMwMDk1X3E=",
    encoded_p: "Y3NiXzMwMDk1X3A=",
  },
  {
    school_code: "sb_30118",
    name: "Dominican (CA)",
    division: 2,
    org_id: "sb_30118",
    mascot: "Penguins",
    encoded_h: "Y3NiXzMwMTE4",
    encoded_c: "Y3NiXzMwMTE4X2M=",
    encoded_q: "Y3NiXzMwMTE4X3E=",
    encoded_p: "Y3NiXzMwMTE4X3A=",
  },
  {
    school_code: "sb_8956",
    name: "Dominican (NY)",
    division: 2,
    org_id: "sb_8956",
    mascot: "Lady Chargers",
    encoded_h: "Y3NiXzg5NTY=",
    encoded_c: "Y3NiXzg5NTZfYw==",
    encoded_q: "Y3NiXzg5NTZfcQ==",
    encoded_p: "Y3NiXzg5NTZfcA==",
  },
  {
    school_code: "sb_2692",
    name: "Dowling",
    division: 2,
    org_id: "sb_2692",
    mascot: "Golden Lions",
    encoded_h: "Y3NiXzI2OTI=",
    encoded_c: "Y3NiXzI2OTJfYw==",
    encoded_q: "Y3NiXzI2OTJfcQ==",
    encoded_p: "Y3NiXzI2OTJfcA==",
  },
  {
    school_code: "sb_1057",
    name: "Drury",
    division: 2,
    org_id: "sb_1057",
    mascot: "Panthers",
    encoded_h: "Y3NiXzEwNTc=",
    encoded_c: "Y3NiXzEwNTdfYw==",
    encoded_q: "Y3NiXzEwNTdfcQ==",
    encoded_p: "Y3NiXzEwNTdfcA==",
  },
  {
    school_code: "sb_8965",
    name: "East Central",
    division: 2,
    org_id: "sb_8965",
    mascot: "Tigers",
    encoded_h: "Y3NiXzg5NjU=",
    encoded_c: "Y3NiXzg5NjVfYw==",
    encoded_q: "Y3NiXzg5NjVfcQ==",
    encoded_p: "Y3NiXzg5NjVfcA==",
  },
  {
    school_code: "sb_197",
    name: "East Stroudsburg",
    division: 2,
    org_id: "sb_197",
    mascot: "Warriors",
    encoded_h: "Y3NiXzE5Nw==",
    encoded_c: "Y3NiXzE5N19j",
    encoded_q: "Y3NiXzE5N19x",
    encoded_p: "Y3NiXzE5N19w",
  },
  {
    school_code: "sb_206",
    name: "Eastern N.M.",
    division: 2,
    org_id: "sb_206",
    mascot: "Zias",
    encoded_h: "Y3NiXzIwNg==",
    encoded_c: "Y3NiXzIwNl9j",
    encoded_q: "Y3NiXzIwNl9x",
    encoded_p: "Y3NiXzIwNl9w",
  },
  {
    school_code: "sb_208",
    name: "Eckerd",
    division: 2,
    org_id: "sb_208",
    mascot: "Tritons",
    encoded_h: "Y3NiXzIwOA==",
    encoded_c: "Y3NiXzIwOF9j",
    encoded_q: "Y3NiXzIwOF9x",
    encoded_p: "Y3NiXzIwOF9w",
  },
  {
    school_code: "sb_209",
    name: "Edinboro",
    division: 2,
    org_id: "sb_209",
    mascot: "Fighting Scots",
    encoded_h: "Y3NiXzIwOQ==",
    encoded_c: "Y3NiXzIwOV9j",
    encoded_q: "Y3NiXzIwOV9x",
    encoded_p: "Y3NiXzIwOV9w",
  },
  {
    school_code: "sb_210",
    name: "Elizabeth City St.",
    division: 2,
    org_id: "sb_210",
    mascot: "Lady Vikings",
    encoded_h: "Y3NiXzIxMA==",
    encoded_c: "Y3NiXzIxMF9j",
    encoded_q: "Y3NiXzIxMF9x",
    encoded_p: "Y3NiXzIxMF9w",
  },
  {
    school_code: "sb_30193",
    name: "Emmanuel (GA)",
    division: 2,
    org_id: "sb_30193",
    mascot: "Lions",
    encoded_h: "Y3NiXzMwMTkz",
    encoded_c: "Y3NiXzMwMTkzX2M=",
    encoded_q: "Y3NiXzMwMTkzX3E=",
    encoded_p: "Y3NiXzMwMTkzX3A=",
  },
  {
    school_code: "sb_9680",
    name: "Embry-Riddle (FL)",
    division: 2,
    org_id: "sb_9680",
    mascot: "Eagles",
    encoded_h: "Y3NiXzk2ODA=",
    encoded_c: "Y3NiXzk2ODBfYw==",
    encoded_q: "Y3NiXzk2ODBfcQ==",
    encoded_p: "Y3NiXzk2ODBfcA==",
  },
  {
    school_code: "sb_1071",
    name: "Emporia St.",
    division: 2,
    org_id: "sb_1071",
    mascot: "Lady Hornets",
    encoded_h: "Y3NiXzEwNzE=",
    encoded_c: "Y3NiXzEwNzFfYw==",
    encoded_q: "Y3NiXzEwNzFfcQ==",
    encoded_p: "Y3NiXzEwNzFfcA==",
  },
  {
    school_code: "sb_1072",
    name: "Erskine",
    division: 2,
    org_id: "sb_1072",
    mascot: "Flying Fleet",
    encoded_h: "Y3NiXzEwNzI=",
    encoded_c: "Y3NiXzEwNzJfYw==",
    encoded_q: "Y3NiXzEwNzJfcQ==",
    encoded_p: "Y3NiXzEwNzJfcA==",
  },
  {
    school_code: "sb_1076",
    name: "Fairmont St.",
    division: 2,
    org_id: "sb_1076",
    mascot: "Falcons",
    encoded_h: "Y3NiXzEwNzY=",
    encoded_c: "Y3NiXzEwNzZfYw==",
    encoded_q: "Y3NiXzEwNzZfcQ==",
    encoded_p: "Y3NiXzEwNzZfcA==",
  },
  {
    school_code: "sb_223",
    name: "Fayetteville St.",
    division: 2,
    org_id: "sb_223",
    mascot: "Lady Broncos",
    encoded_h: "Y3NiXzIyMw==",
    encoded_c: "Y3NiXzIyM19j",
    encoded_q: "Y3NiXzIyM19x",
    encoded_p: "Y3NiXzIyM19w",
  },
  {
    school_code: "sb_23157",
    name: "Felician",
    division: 2,
    org_id: "sb_23157",
    mascot: "Golden Falcons",
    encoded_h: "Y3NiXzIzMTU3",
    encoded_c: "Y3NiXzIzMTU3X2M=",
    encoded_q: "Y3NiXzIzMTU3X3E=",
    encoded_p: "Y3NiXzIzMTU3X3A=",
  },
  {
    school_code: "sb_224",
    name: "Ferris St.",
    division: 2,
    org_id: "sb_224",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzIyNA==",
    encoded_c: "Y3NiXzIyNF9j",
    encoded_q: "Y3NiXzIyNF9x",
    encoded_p: "Y3NiXzIyNF9w",
  },
  {
    school_code: "sb_1079",
    name: "Findlay",
    division: 2,
    org_id: "sb_1079",
    mascot: "Oilers",
    encoded_h: "Y3NiXzEwNzk=",
    encoded_c: "Y3NiXzEwNzlfYw==",
    encoded_q: "Y3NiXzEwNzlfcQ==",
    encoded_p: "Y3NiXzEwNzlfcA==",
  },
  {
    school_code: "sb_233",
    name: "Fla. Southern",
    division: 2,
    org_id: "sb_233",
    mascot: "Moccasins",
    encoded_h: "Y3NiXzIzMw==",
    encoded_c: "Y3NiXzIzM19j",
    encoded_q: "Y3NiXzIzM19x",
    encoded_p: "Y3NiXzIzM19w",
  },
  {
    school_code: "sb_30092",
    name: "Flagler",
    division: 2,
    org_id: "sb_30092",
    mascot: "Saints",
    encoded_h: "Y3NiXzMwMDky",
    encoded_c: "Y3NiXzMwMDkyX2M=",
    encoded_q: "Y3NiXzMwMDkyX3E=",
    encoded_p: "Y3NiXzMwMDkyX3A=",
  },
  {
    school_code: "sb_230",
    name: "Florida Tech",
    division: 2,
    org_id: "sb_230",
    mascot: "Panthers",
    encoded_h: "Y3NiXzIzMA==",
    encoded_c: "Y3NiXzIzMF9j",
    encoded_q: "Y3NiXzIzMF9x",
    encoded_p: "Y3NiXzIzMF9w",
  },
  {
    school_code: "sb_9011",
    name: "Fort Hays St.",
    division: 2,
    org_id: "sb_9011",
    mascot: "Tigers",
    encoded_h: "Y3NiXzkwMTE=",
    encoded_c: "Y3NiXzkwMTFfYw==",
    encoded_q: "Y3NiXzkwMTFfcQ==",
    encoded_p: "Y3NiXzkwMTFfcA==",
  },
  {
    school_code: "sb_1083",
    name: "Fort Lewis",
    division: 2,
    org_id: "sb_1083",
    mascot: "Skyhawks",
    encoded_h: "Y3NiXzEwODM=",
    encoded_c: "Y3NiXzEwODNfYw==",
    encoded_q: "Y3NiXzEwODNfcQ==",
    encoded_p: "Y3NiXzEwODNfcA==",
  },
  {
    school_code: "sb_237",
    name: "Fort Valley St",
    division: 2,
    org_id: "sb_237",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzIzNw==",
    encoded_c: "Y3NiXzIzN19j",
    encoded_q: "Y3NiXzIzN19x",
    encoded_p: "Y3NiXzIzN19w",
  },
  {
    school_code: "sb_1085",
    name: "Francis Marion",
    division: 2,
    org_id: "sb_1085",
    mascot: "Patriots",
    encoded_h: "Y3NiXzEwODU=",
    encoded_c: "Y3NiXzEwODVfYw==",
    encoded_q: "Y3NiXzEwODVfcQ==",
    encoded_p: "Y3NiXzEwODVfcA==",
  },
  {
    school_code: "sb_241",
    name: "Franklin Pierce",
    division: 2,
    org_id: "sb_241",
    mascot: "Ravens",
    encoded_h: "Y3NiXzI0MQ==",
    encoded_c: "Y3NiXzI0MV9j",
    encoded_q: "Y3NiXzI0MV9x",
    encoded_p: "Y3NiXzI0MV9w",
  },
  {
    school_code: "sb_30147",
    name: "Fresno Pacific",
    division: 2,
    org_id: "sb_30147",
    mascot: "Sunbirds",
    encoded_h: "Y3NiXzMwMTQ3",
    encoded_c: "Y3NiXzMwMTQ3X2M=",
    encoded_q: "Y3NiXzMwMTQ3X3E=",
    encoded_p: "Y3NiXzMwMTQ3X3A=",
  },
  {
    school_code: "sb_39",
    name: "Augusta",
    division: 2,
    org_id: "sb_39",
    mascot: "Jaguars",
    encoded_h: "Y3NiXzM5",
    encoded_c: "Y3NiXzM5X2M=",
    encoded_q: "Y3NiXzM5X3E=",
    encoded_p: "Y3NiXzM5X3A=",
  },
  {
    school_code: "sb_1097",
    name: "Ga. Southwestern",
    division: 2,
    org_id: "sb_1097",
    mascot: "Lady Hurricanes",
    encoded_h: "Y3NiXzEwOTc=",
    encoded_c: "Y3NiXzEwOTdfYw==",
    encoded_q: "Y3NiXzEwOTdfcQ==",
    encoded_p: "Y3NiXzEwOTdfcA==",
  },
  {
    school_code: "sb_246",
    name: "Gannon",
    division: 2,
    org_id: "sb_246",
    mascot: "Lady Knights",
    encoded_h: "Y3NiXzI0Ng==",
    encoded_c: "Y3NiXzI0Nl9j",
    encoded_q: "Y3NiXzI0Nl9x",
    encoded_p: "Y3NiXzI0Nl9w",
  },
  {
    school_code: "sb_1096",
    name: "Georgia College",
    division: 2,
    org_id: "sb_1096",
    mascot: "Bobcats",
    encoded_h: "Y3NiXzEwOTY=",
    encoded_c: "Y3NiXzEwOTZfYw==",
    encoded_q: "Y3NiXzEwOTZfcQ==",
    encoded_p: "Y3NiXzEwOTZfcA==",
  },
  {
    school_code: "sb_9004",
    name: "Georgian Court",
    division: 2,
    org_id: "sb_9004",
    mascot: "Lions",
    encoded_h: "Y3NiXzkwMDQ=",
    encoded_c: "Y3NiXzkwMDRfYw==",
    encoded_q: "Y3NiXzkwMDRfcQ==",
    encoded_p: "Y3NiXzkwMDRfcA==",
  },
  {
    school_code: "sb_1098",
    name: "Glenville St.",
    division: 2,
    org_id: "sb_1098",
    mascot: "Lady Pioneers",
    encoded_h: "Y3NiXzEwOTg=",
    encoded_c: "Y3NiXzEwOThfYw==",
    encoded_q: "Y3NiXzEwOThfcQ==",
    encoded_p: "Y3NiXzEwOThfcA==",
  },
  {
    school_code: "sb_8366",
    name: "Goldey-Beacom",
    division: 2,
    org_id: "sb_8366",
    mascot: "Lightning",
    encoded_h: "Y3NiXzgzNjY=",
    encoded_c: "Y3NiXzgzNjZfYw==",
    encoded_q: "Y3NiXzgzNjZfcQ==",
    encoded_p: "Y3NiXzgzNjZfcA==",
  },
  {
    school_code: "sb_262",
    name: "Grand Valley St.",
    division: 2,
    org_id: "sb_262",
    mascot: "Lakers",
    encoded_h: "Y3NiXzI2Mg==",
    encoded_c: "Y3NiXzI2Ml9j",
    encoded_q: "Y3NiXzI2Ml9x",
    encoded_p: "Y3NiXzI2Ml9w",
  },
  {
    school_code: "sb_1116",
    name: "Harding",
    division: 2,
    org_id: "sb_1116",
    mascot: "Lady Bisons",
    encoded_h: "Y3NiXzExMTY=",
    encoded_c: "Y3NiXzExMTZfYw==",
    encoded_q: "Y3NiXzExMTZfcQ==",
    encoded_p: "Y3NiXzExMTZfcA==",
  },
  {
    school_code: "sb_2697",
    name: "Hawaii Hilo",
    division: 2,
    org_id: "sb_2697",
    mascot: "Vulcans",
    encoded_h: "Y3NiXzI2OTc=",
    encoded_c: "Y3NiXzI2OTdfYw==",
    encoded_q: "Y3NiXzI2OTdfcQ==",
    encoded_p: "Y3NiXzI2OTdfcA==",
  },
  {
    school_code: "sb_2696",
    name: "Hawaii Pacific",
    division: 2,
    org_id: "sb_2696",
    mascot: "Sharks",
    encoded_h: "Y3NiXzI2OTY=",
    encoded_c: "Y3NiXzI2OTZfYw==",
    encoded_q: "Y3NiXzI2OTZfcQ==",
    encoded_p: "Y3NiXzI2OTZfcA==",
  },
  {
    school_code: "sb_1123",
    name: "Henderson St.",
    division: 2,
    org_id: "sb_1123",
    mascot: "Lady Reddies",
    encoded_h: "Y3NiXzExMjM=",
    encoded_c: "Y3NiXzExMjNfYw==",
    encoded_q: "Y3NiXzExMjNfcQ==",
    encoded_p: "Y3NiXzExMjNfcA==",
  },
  {
    school_code: "sb_280",
    name: "Hillsdale",
    division: 2,
    org_id: "sb_280",
    mascot: "Chargers",
    encoded_h: "Y3NiXzI4MA==",
    encoded_c: "Y3NiXzI4MF9j",
    encoded_q: "Y3NiXzI4MF9x",
    encoded_p: "Y3NiXzI4MF9w",
  },
  {
    school_code: "sb_1126",
    name: "Holy Family",
    division: 2,
    org_id: "sb_1126",
    mascot: "Tigers",
    encoded_h: "Y3NiXzExMjY=",
    encoded_c: "Y3NiXzExMjZfYw==",
    encoded_q: "Y3NiXzExMjZfcQ==",
    encoded_p: "Y3NiXzExMjZfcA==",
  },
  {
    school_code: "sb_30175",
    name: "Holy Names",
    division: 2,
    org_id: "sb_30175",
    mascot: "Hawks",
    encoded_h: "Y3NiXzMwMTc1",
    encoded_c: "Y3NiXzMwMTc1X2M=",
    encoded_q: "Y3NiXzMwMTc1X3E=",
    encoded_p: "Y3NiXzMwMTc1X3A=",
  },
  {
    school_code: "sb_291",
    name: "Humboldt St.",
    division: 2,
    org_id: "sb_291",
    mascot: "Lumberjacks",
    encoded_h: "Y3NiXzI5MQ==",
    encoded_c: "Y3NiXzI5MV9j",
    encoded_q: "Y3NiXzI5MV9x",
    encoded_p: "Y3NiXzI5MV9w",
  },
  {
    school_code: "sb_30050",
    name: "Ill.-Springfield",
    division: 2,
    org_id: "sb_30050",
    mascot: "Prairie Stars",
    encoded_h: "Y3NiXzMwMDUw",
    encoded_c: "Y3NiXzMwMDUwX2M=",
    encoded_q: "Y3NiXzMwMDUwX3E=",
    encoded_p: "Y3NiXzMwMDUwX3A=",
  },
  {
    school_code: "sb_307",
    name: "Indiana (PA)",
    division: 2,
    org_id: "sb_307",
    mascot: "Crimson Hawks",
    encoded_h: "Y3NiXzMwNw==",
    encoded_c: "Y3NiXzMwN19j",
    encoded_q: "Y3NiXzMwN19x",
    encoded_p: "Y3NiXzMwN19w",
  },
  {
    school_code: "sb_309",
    name: "Indianapolis",
    division: 2,
    org_id: "sb_309",
    mascot: "Greyhounds",
    encoded_h: "Y3NiXzMwOQ==",
    encoded_c: "Y3NiXzMwOV9j",
    encoded_q: "Y3NiXzMwOV9x",
    encoded_p: "Y3NiXzMwOV9w",
  },
  {
    school_code: "sb_323",
    name: "Johnson C. Smith",
    division: 2,
    org_id: "sb_323",
    mascot: "Golden Bulls",
    encoded_h: "Y3NiXzMyMw==",
    encoded_c: "Y3NiXzMyM19j",
    encoded_q: "Y3NiXzMyM19x",
    encoded_p: "Y3NiXzMyM19w",
  },
  {
    school_code: "sb_332",
    name: "Kentucky St.",
    division: 2,
    org_id: "sb_332",
    mascot: "Thorobrettes",
    encoded_h: "Y3NiXzMzMg==",
    encoded_c: "Y3NiXzMzMl9j",
    encoded_q: "Y3NiXzMzMl9x",
    encoded_p: "Y3NiXzMzMl9w",
  },
  {
    school_code: "sb_30051",
    name: "King (TN)",
    division: 2,
    org_id: "sb_30051",
    mascot: "Tornado",
    encoded_h: "Y3NiXzMwMDUx",
    encoded_c: "Y3NiXzMwMDUxX2M=",
    encoded_q: "Y3NiXzMwMDUxX3E=",
    encoded_p: "Y3NiXzMwMDUxX3A=",
  },
  {
    school_code: "sb_339",
    name: "Kutztown",
    division: 2,
    org_id: "sb_339",
    mascot: "Golden Bears",
    encoded_h: "Y3NiXzMzOQ==",
    encoded_c: "Y3NiXzMzOV9j",
    encoded_q: "Y3NiXzMzOV9x",
    encoded_p: "Y3NiXzMzOV9w",
  },
  {
    school_code: "sb_333",
    name: "Ky. Wesleyan",
    division: 2,
    org_id: "sb_333",
    mascot: "Panthers",
    encoded_h: "Y3NiXzMzMw==",
    encoded_c: "Y3NiXzMzM19j",
    encoded_q: "Y3NiXzMzM19x",
    encoded_p: "Y3NiXzMzM19w",
  },
  {
    school_code: "sb_362",
    name: "LIU Post",
    division: 2,
    org_id: "sb_362",
    mascot: "Pioneers",
    encoded_h: "Y3NiXzM2Mg==",
    encoded_c: "Y3NiXzM2Ml9j",
    encoded_q: "Y3NiXzM2Ml9x",
    encoded_p: "Y3NiXzM2Ml9w",
  },
  {
    school_code: "sb_2746",
    name: "Lake Erie",
    division: 2,
    org_id: "sb_2746",
    mascot: "Storm",
    encoded_h: "Y3NiXzI3NDY=",
    encoded_c: "Y3NiXzI3NDZfYw==",
    encoded_q: "Y3NiXzI3NDZfcQ==",
    encoded_p: "Y3NiXzI3NDZfcA==",
  },
  {
    school_code: "sb_345",
    name: "Lake Superior St.",
    division: 2,
    org_id: "sb_345",
    mascot: "Lakers",
    encoded_h: "Y3NiXzM0NQ==",
    encoded_c: "Y3NiXzM0NV9j",
    encoded_q: "Y3NiXzM0NV9x",
    encoded_p: "Y3NiXzM0NV9w",
  },
  {
    school_code: "sb_1167",
    name: "Lander",
    division: 2,
    org_id: "sb_1167",
    mascot: "Bearcats",
    encoded_h: "Y3NiXzExNjc=",
    encoded_c: "Y3NiXzExNjdfYw==",
    encoded_q: "Y3NiXzExNjdfcQ==",
    encoded_p: "Y3NiXzExNjdfcA==",
  },
  {
    school_code: "sb_347",
    name: "Lane",
    division: 2,
    org_id: "sb_347",
    mascot: "Dragons",
    encoded_h: "Y3NiXzM0Nw==",
    encoded_c: "Y3NiXzM0N19j",
    encoded_q: "Y3NiXzM0N19x",
    encoded_p: "Y3NiXzM0N19w",
  },
  {
    school_code: "sb_349",
    name: "Le Moyne",
    division: 2,
    org_id: "sb_349",
    mascot: "Dolphins",
    encoded_h: "Y3NiXzM0OQ==",
    encoded_c: "Y3NiXzM0OV9j",
    encoded_q: "Y3NiXzM0OV9x",
    encoded_p: "Y3NiXzM0OV9w",
  },
  {
    school_code: "sb_350",
    name: "LeMoyne-Owen",
    division: 2,
    org_id: "sb_350",
    mascot: "Lady Magicians",
    encoded_h: "Y3NiXzM1MA==",
    encoded_c: "Y3NiXzM1MF9j",
    encoded_q: "Y3NiXzM1MF9x",
    encoded_p: "Y3NiXzM1MF9w",
  },
  {
    school_code: "sb_30013",
    name: "Lee",
    division: 2,
    org_id: "sb_30013",
    mascot: "Lady Flames",
    encoded_h: "Y3NiXzMwMDEz",
    encoded_c: "Y3NiXzMwMDEzX2M=",
    encoded_q: "Y3NiXzMwMDEzX3E=",
    encoded_p: "Y3NiXzMwMDEzX3A=",
  },
  {
    school_code: "sb_13302",
    name: "Lees-McRae",
    division: 2,
    org_id: "sb_13302",
    mascot: "Bobcats",
    encoded_h: "Y3NiXzEzMzAy",
    encoded_c: "Y3NiXzEzMzAyX2M=",
    encoded_q: "Y3NiXzEzMzAyX3E=",
    encoded_p: "Y3NiXzEzMzAyX3A=",
  },
  {
    school_code: "sb_1170",
    name: "Lenoir-Rhyne",
    division: 2,
    org_id: "sb_1170",
    mascot: "Bears",
    encoded_h: "Y3NiXzExNzA=",
    encoded_c: "Y3NiXzExNzBfYw==",
    encoded_q: "Y3NiXzExNzBfcQ==",
    encoded_p: "Y3NiXzExNzBfcA==",
  },
  {
    school_code: "sb_354",
    name: "Lewis",
    division: 2,
    org_id: "sb_354",
    mascot: "Flyers",
    encoded_h: "Y3NiXzM1NA==",
    encoded_c: "Y3NiXzM1NF9j",
    encoded_q: "Y3NiXzM1NF9x",
    encoded_p: "Y3NiXzM1NF9w",
  },
  {
    school_code: "sb_1174",
    name: "Limestone",
    division: 2,
    org_id: "sb_1174",
    mascot: "Saints",
    encoded_h: "Y3NiXzExNzQ=",
    encoded_c: "Y3NiXzExNzRfYw==",
    encoded_q: "Y3NiXzExNzRfcQ==",
    encoded_p: "Y3NiXzExNzRfcA==",
  },
  {
    school_code: "sb_356",
    name: "Lincoln (MO)",
    division: 2,
    org_id: "sb_356",
    mascot: "Blue Tigers",
    encoded_h: "Y3NiXzM1Ng==",
    encoded_c: "Y3NiXzM1Nl9j",
    encoded_q: "Y3NiXzM1Nl9x",
    encoded_p: "Y3NiXzM1Nl9w",
  },
  {
    school_code: "sb_357",
    name: "Lincoln (PA)",
    division: 2,
    org_id: "sb_357",
    mascot: "Lady Lions",
    encoded_h: "Y3NiXzM1Nw==",
    encoded_c: "Y3NiXzM1N19j",
    encoded_q: "Y3NiXzM1N19x",
    encoded_p: "Y3NiXzM1N19w",
  },
  {
    school_code: "sb_1176",
    name: "Lincoln Memorial",
    division: 2,
    org_id: "sb_1176",
    mascot: "Lady Railsplitters",
    encoded_h: "Y3NiXzExNzY=",
    encoded_c: "Y3NiXzExNzZfYw==",
    encoded_q: "Y3NiXzExNzZfcQ==",
    encoded_p: "Y3NiXzExNzZfcA==",
  },
  {
    school_code: "sb_30136",
    name: "Lindenwood (MO)",
    division: 2,
    org_id: "sb_30136",
    mascot: "Lady Lions",
    encoded_h: "Y3NiXzMwMTM2",
    encoded_c: "Y3NiXzMwMTM2X2M=",
    encoded_q: "Y3NiXzMwMTM2X3E=",
    encoded_p: "Y3NiXzMwMTM2X3A=",
  },
  {
    school_code: "sb_359",
    name: "Livingstone",
    division: 2,
    org_id: "sb_359",
    mascot: "Blue Bears",
    encoded_h: "Y3NiXzM1OQ==",
    encoded_c: "Y3NiXzM1OV9j",
    encoded_q: "Y3NiXzM1OV9x",
    encoded_p: "Y3NiXzM1OV9w",
  },
  {
    school_code: "sb_360",
    name: "Lock Haven",
    division: 2,
    org_id: "sb_360",
    mascot: "Lady Eagles",
    encoded_h: "Y3NiXzM2MA==",
    encoded_c: "Y3NiXzM2MF9j",
    encoded_q: "Y3NiXzM2MF9x",
    encoded_p: "Y3NiXzM2MF9w",
  },
  {
    school_code: "sb_30177",
    name: "Lubbock Christian",
    division: 2,
    org_id: "sb_30177",
    mascot: "Chaparrals",
    encoded_h: "Y3NiXzMwMTc3",
    encoded_c: "Y3NiXzMwMTc3X2M=",
    encoded_q: "Y3NiXzMwMTc3X3E=",
    encoded_p: "Y3NiXzMwMTc3X3A=",
  },
  {
    school_code: "sb_20794",
    name: "Lynn",
    division: 2,
    org_id: "sb_20794",
    mascot: "Fighting Knights",
    encoded_h: "Y3NiXzIwNzk0",
    encoded_c: "Y3NiXzIwNzk0X2M=",
    encoded_q: "Y3NiXzIwNzk0X3E=",
    encoded_p: "Y3NiXzIwNzk0X3A=",
  },
  {
    school_code: "sb_30137",
    name: "Malone",
    division: 2,
    org_id: "sb_30137",
    mascot: "Pioneers",
    encoded_h: "Y3NiXzMwMTM3",
    encoded_c: "Y3NiXzMwMTM3X2M=",
    encoded_q: "Y3NiXzMwMTM3X3E=",
    encoded_p: "Y3NiXzMwMTM3X3A=",
  },
  {
    school_code: "sb_384",
    name: "Mansfield",
    division: 2,
    org_id: "sb_384",
    mascot: "Mountaineers",
    encoded_h: "Y3NiXzM4NA==",
    encoded_c: "Y3NiXzM4NF9j",
    encoded_q: "Y3NiXzM4NF9x",
    encoded_p: "Y3NiXzM4NF9w",
  },
  {
    school_code: "sb_1199",
    name: "Mars Hill",
    division: 2,
    org_id: "sb_1199",
    mascot: "Lions",
    encoded_h: "Y3NiXzExOTk=",
    encoded_c: "Y3NiXzExOTlfYw==",
    encoded_q: "Y3NiXzExOTlfcQ==",
    encoded_p: "Y3NiXzExOTlfcA==",
  },
  {
    school_code: "sb_30075",
    name: "Mary",
    division: 2,
    org_id: "sb_30075",
    mascot: "Marauders",
    encoded_h: "Y3NiXzMwMDc1",
    encoded_c: "Y3NiXzMwMDc1X2M=",
    encoded_q: "Y3NiXzMwMDc1X3E=",
    encoded_p: "Y3NiXzMwMDc1X3A=",
  },
  {
    school_code: "sb_395",
    name: "Maryville (MO)",
    division: 2,
    org_id: "sb_395",
    mascot: "Saints",
    encoded_h: "Y3NiXzM5NQ==",
    encoded_c: "Y3NiXzM5NV9j",
    encoded_q: "Y3NiXzM5NV9x",
    encoded_p: "Y3NiXzM5NV9w",
  },
  {
    school_code: "sb_30138",
    name: "McKendree",
    division: 2,
    org_id: "sb_30138",
    mascot: "Bearcats",
    encoded_h: "Y3NiXzMwMTM4",
    encoded_c: "Y3NiXzMwMTM4X2M=",
    encoded_q: "Y3NiXzMwMTM4X3E=",
    encoded_p: "Y3NiXzMwMTM4X3A=",
  },
  {
    school_code: "sb_8530",
    name: "McMurry",
    division: 2,
    org_id: "sb_8530",
    mascot: "War Hawks",
    encoded_h: "Y3NiXzg1MzA=",
    encoded_c: "Y3NiXzg1MzBfYw==",
    encoded_q: "Y3NiXzg1MzBfcQ==",
    encoded_p: "Y3NiXzg1MzBfcA==",
  },
  {
    school_code: "sb_407",
    name: "Mercy",
    division: 2,
    org_id: "sb_407",
    mascot: "Mavericks",
    encoded_h: "Y3NiXzQwNw==",
    encoded_c: "Y3NiXzQwN19j",
    encoded_q: "Y3NiXzQwN19x",
    encoded_p: "Y3NiXzQwN19w",
  },
  {
    school_code: "sb_408",
    name: "Mercyhurst",
    division: 2,
    org_id: "sb_408",
    mascot: "Lakers",
    encoded_h: "Y3NiXzQwOA==",
    encoded_c: "Y3NiXzQwOF9j",
    encoded_q: "Y3NiXzQwOF9x",
    encoded_p: "Y3NiXzQwOF9w",
  },
  {
    school_code: "sb_410",
    name: "Merrimack",
    division: 2,
    org_id: "sb_410",
    mascot: "Warriors",
    encoded_h: "Y3NiXzQxMA==",
    encoded_c: "Y3NiXzQxMF9j",
    encoded_q: "Y3NiXzQxMF9x",
    encoded_p: "Y3NiXzQxMF9w",
  },
  {
    school_code: "sb_413",
    name: "Metro St.",
    division: 2,
    org_id: "sb_413",
    mascot: "Roadrunners",
    encoded_h: "Y3NiXzQxMw==",
    encoded_c: "Y3NiXzQxM19j",
    encoded_q: "Y3NiXzQxM19x",
    encoded_p: "Y3NiXzQxM19w",
  },
  {
    school_code: "sb_1222",
    name: "Midwestern St.",
    division: 2,
    org_id: "sb_1222",
    mascot: "Mustangs",
    encoded_h: "Y3NiXzEyMjI=",
    encoded_c: "Y3NiXzEyMjJfYw==",
    encoded_q: "Y3NiXzEyMjJfcQ==",
    encoded_p: "Y3NiXzEyMjJfcA==",
  },
  {
    school_code: "sb_422",
    name: "Miles",
    division: 1,
    org_id: "sb_422",
    mascot: "Lady Bears",
    encoded_h: "Y3NiXzQyMg==",
    encoded_c: "Y3NiXzQyMl9j",
    encoded_q: "Y3NiXzQyMl9x",
    encoded_p: "Y3NiXzQyMl9w",
  },
  {
    school_code: "sb_423",
    name: "Millersville",
    division: 2,
    org_id: "sb_423",
    mascot: "Marauders",
    encoded_h: "Y3NiXzQyMw==",
    encoded_c: "Y3NiXzQyM19j",
    encoded_q: "Y3NiXzQyM19x",
    encoded_p: "Y3NiXzQyM19w",
  },
  {
    school_code: "sb_427",
    name: "Minn. Duluth",
    division: 2,
    org_id: "sb_427",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzQyNw==",
    encoded_c: "Y3NiXzQyN19j",
    encoded_q: "Y3NiXzQyN19x",
    encoded_p: "Y3NiXzQyN19w",
  },
  {
    school_code: "sb_383",
    name: "Minn. St. Mankato",
    division: 2,
    org_id: "sb_383",
    mascot: "Mavericks",
    encoded_h: "Y3NiXzM4Mw==",
    encoded_c: "Y3NiXzM4M19j",
    encoded_q: "Y3NiXzM4M19x",
    encoded_p: "Y3NiXzM4M19w",
  },
  {
    school_code: "sb_1240",
    name: "Minn. St. Moorhead",
    division: 2,
    org_id: "sb_1240",
    mascot: "Dragons",
    encoded_h: "Y3NiXzEyNDA=",
    encoded_c: "Y3NiXzEyNDBfYw==",
    encoded_q: "Y3NiXzEyNDBfcQ==",
    encoded_p: "Y3NiXzEyNDBfcA==",
  },
  {
    school_code: "sb_27854",
    name: "Minn.-Crookston",
    division: 2,
    org_id: "sb_27854",
    mascot: "Golden Eagles",
    encoded_h: "Y3NiXzI3ODU0",
    encoded_c: "Y3NiXzI3ODU0X2M=",
    encoded_q: "Y3NiXzI3ODU0X3E=",
    encoded_p: "Y3NiXzI3ODU0X3A=",
  },
  {
    school_code: "sb_30125",
    name: "Minot St.",
    division: 2,
    org_id: "sb_30125",
    mascot: "Beavers",
    encoded_h: "Y3NiXzMwMTI1",
    encoded_c: "Y3NiXzMwMTI1X2M=",
    encoded_q: "Y3NiXzMwMTI1X3E=",
    encoded_p: "Y3NiXzMwMTI1X3A=",
  },
  {
    school_code: "sb_435",
    name: "Missouri S&T",
    division: 2,
    org_id: "sb_435",
    mascot: "Lady Miners",
    encoded_h: "Y3NiXzQzNQ==",
    encoded_c: "Y3NiXzQzNV9j",
    encoded_q: "Y3NiXzQzNV9x",
    encoded_p: "Y3NiXzQzNV9w",
  },
  {
    school_code: "sb_9012",
    name: "Mo. Southern St.",
    division: 2,
    org_id: "sb_9012",
    mascot: "Lions",
    encoded_h: "Y3NiXzkwMTI=",
    encoded_c: "Y3NiXzkwMTJfYw==",
    encoded_q: "Y3NiXzkwMTJfcQ==",
    encoded_p: "Y3NiXzkwMTJfcA==",
  },
  {
    school_code: "sb_9013",
    name: "Mo. Western St.",
    division: 2,
    org_id: "sb_9013",
    mascot: "Griffons",
    encoded_h: "Y3NiXzkwMTM=",
    encoded_c: "Y3NiXzkwMTNfYw==",
    encoded_q: "Y3NiXzkwMTNfcQ==",
    encoded_p: "Y3NiXzkwMTNfcA==",
  },
  {
    school_code: "sb_436",
    name: "Mo.-St. Louis",
    division: 2,
    org_id: "sb_436",
    mascot: "Tritons",
    encoded_h: "Y3NiXzQzNg==",
    encoded_c: "Y3NiXzQzNl9j",
    encoded_q: "Y3NiXzQzNl9x",
    encoded_p: "Y3NiXzQzNl9w",
  },
  {
    school_code: "sb_437",
    name: "Molloy",
    division: 2,
    org_id: "sb_437",
    mascot: "Lions",
    encoded_h: "Y3NiXzQzNw==",
    encoded_c: "Y3NiXzQzN19j",
    encoded_q: "Y3NiXzQzN19x",
    encoded_p: "Y3NiXzQzN19w",
  },
  {
    school_code: "sb_205",
    name: "Mont. St. Billings",
    division: 2,
    org_id: "sb_205",
    mascot: "Yellowjackets",
    encoded_h: "Y3NiXzIwNQ==",
    encoded_c: "Y3NiXzIwNV9j",
    encoded_q: "Y3NiXzIwNV9x",
    encoded_p: "Y3NiXzIwNV9w",
  },
  {
    school_code: "sb_1238",
    name: "Montevallo",
    division: 2,
    org_id: "sb_1238",
    mascot: "Falcons",
    encoded_h: "Y3NiXzEyMzg=",
    encoded_c: "Y3NiXzEyMzhfYw==",
    encoded_q: "Y3NiXzEyMzhfcQ==",
    encoded_p: "Y3NiXzEyMzhfcA==",
  },
  {
    school_code: "sb_1245",
    name: "Mount Olive",
    division: 2,
    org_id: "sb_1245",
    mascot: "Trojans",
    encoded_h: "Y3NiXzEyNDU=",
    encoded_c: "Y3NiXzEyNDVfYw==",
    encoded_q: "Y3NiXzEyNDVfcQ==",
    encoded_p: "Y3NiXzEyNDVfcA==",
  },
  {
    school_code: "sb_8577",
    name: "N.M. Highlands",
    division: 2,
    org_id: "sb_8577",
    mascot: "Cowgirls",
    encoded_h: "Y3NiXzg1Nzc=",
    encoded_c: "Y3NiXzg1NzdfYw==",
    encoded_q: "Y3NiXzg1NzdfcQ==",
    encoded_p: "Y3NiXzg1NzdfcA==",
  },
  {
    school_code: "sb_477",
    name: "NYIT",
    division: 2,
    org_id: "sb_477",
    mascot: "Bears",
    encoded_h: "Y3NiXzQ3Nw==",
    encoded_c: "Y3NiXzQ3N19j",
    encoded_q: "Y3NiXzQ3N19x",
    encoded_p: "Y3NiXzQ3N19w",
  },
  {
    school_code: "sb_479",
    name: "NYU",
    division: 3,
    org_id: "sb_479",
    mascot: "Violets",
    encoded_h: "Y3NiXzQ3OQ==",
    encoded_c: "Y3NiXzQ3OV9j",
    encoded_q: "Y3NiXzQ3OV9x",
    encoded_p: "Y3NiXzQ3OV9w",
  },
  {
    school_code: "sb_1156",
    name: "Neb.-Kearney",
    division: 2,
    org_id: "sb_1156",
    mascot: "Antelopes",
    encoded_h: "Y3NiXzExNTY=",
    encoded_c: "Y3NiXzExNTZfYw==",
    encoded_q: "Y3NiXzExNTZfcQ==",
    encoded_p: "Y3NiXzExNTZfcA==",
  },
  {
    school_code: "sb_470",
    name: "New Haven",
    division: 2,
    org_id: "sb_470",
    mascot: "Chargers",
    encoded_h: "Y3NiXzQ3MA==",
    encoded_c: "Y3NiXzQ3MF9j",
    encoded_q: "Y3NiXzQ3MF9x",
    encoded_p: "Y3NiXzQ3MF9w",
  },
  {
    school_code: "sb_1257",
    name: "Newberry",
    division: 2,
    org_id: "sb_1257",
    mascot: "Wolves",
    encoded_h: "Y3NiXzEyNTc=",
    encoded_c: "Y3NiXzEyNTdfYw==",
    encoded_q: "Y3NiXzEyNTdfcQ==",
    encoded_p: "Y3NiXzEyNTdfcA==",
  },
  {
    school_code: "sb_30054",
    name: "Newman",
    division: 2,
    org_id: "sb_30054",
    mascot: "Jets",
    encoded_h: "Y3NiXzMwMDU0",
    encoded_c: "Y3NiXzMwMDU0X2M=",
    encoded_q: "Y3NiXzMwMDU0X3E=",
    encoded_p: "Y3NiXzMwMDU0X3A=",
  },
  {
    school_code: "sb_487",
    name: "North Ala.",
    division: 2,
    org_id: "sb_487",
    mascot: "Lions",
    encoded_h: "Y3NiXzQ4Nw==",
    encoded_c: "Y3NiXzQ4N19j",
    encoded_q: "Y3NiXzQ4N19x",
    encoded_p: "Y3NiXzQ4N19w",
  },
  {
    school_code: "sb_30052",
    name: "North Georgia",
    division: 2,
    org_id: "sb_30052",
    mascot: "Nighthawks",
    encoded_h: "Y3NiXzMwMDUy",
    encoded_c: "Y3NiXzMwMDUyX2M=",
    encoded_q: "Y3NiXzMwMDUyX3E=",
    encoded_p: "Y3NiXzMwMDUyX3A=",
  },
  {
    school_code: "sb_9223",
    name: "North Greenville",
    division: 2,
    org_id: "sb_9223",
    mascot: "Crusaders",
    encoded_h: "Y3NiXzkyMjM=",
    encoded_c: "Y3NiXzkyMjNfYw==",
    encoded_q: "Y3NiXzkyMjNfcQ==",
    encoded_p: "Y3NiXzkyMjNfcA==",
  },
  {
    school_code: "sb_12810",
    name: "Northeastern St.",
    division: 2,
    org_id: "sb_12810",
    mascot: "RiverHawks",
    encoded_h: "Y3NiXzEyODEw",
    encoded_c: "Y3NiXzEyODEwX2M=",
    encoded_q: "Y3NiXzEyODEwX3E=",
    encoded_p: "Y3NiXzEyODEwX3A=",
  },
  {
    school_code: "sb_1266",
    name: "Northern St.",
    division: 2,
    org_id: "sb_1266",
    mascot: "Wolves",
    encoded_h: "Y3NiXzEyNjY=",
    encoded_c: "Y3NiXzEyNjZfYw==",
    encoded_q: "Y3NiXzEyNjZfcQ==",
    encoded_p: "Y3NiXzEyNjZfcA==",
  },
  {
    school_code: "sb_507",
    name: "Northwest Mo. St.",
    division: 2,
    org_id: "sb_507",
    mascot: "Bearcats",
    encoded_h: "Y3NiXzUwNw==",
    encoded_c: "Y3NiXzUwN19j",
    encoded_q: "Y3NiXzUwN19x",
    encoded_p: "Y3NiXzUwN19w",
  },
  {
    school_code: "sb_1274",
    name: "Northwest Nazarene",
    division: 2,
    org_id: "sb_1274",
    mascot: "Crusaders",
    encoded_h: "Y3NiXzEyNzQ=",
    encoded_c: "Y3NiXzEyNzRfYw==",
    encoded_q: "Y3NiXzEyNzRfcQ==",
    encoded_p: "Y3NiXzEyNzRfcA==",
  },
  {
    school_code: "sb_30178",
    name: "Northwestern Okla.",
    division: 2,
    org_id: "sb_30178",
    mascot: "Rangers",
    encoded_h: "Y3NiXzMwMTc4",
    encoded_c: "Y3NiXzMwMTc4X2M=",
    encoded_q: "Y3NiXzMwMTc4X3E=",
    encoded_p: "Y3NiXzMwMTc4X3A=",
  },
  {
    school_code: "sb_510",
    name: "Northwood (MI)",
    division: 2,
    org_id: "sb_510",
    mascot: "Timberwolves",
    encoded_h: "Y3NiXzUxMA==",
    encoded_c: "Y3NiXzUxMF9j",
    encoded_q: "Y3NiXzUxMF9x",
    encoded_p: "Y3NiXzUxMF9w",
  },
  {
    school_code: "sb_30126",
    name: "Notre Dame (OH)",
    division: 2,
    org_id: "sb_30126",
    mascot: "Falcons",
    encoded_h: "Y3NiXzMwMTI2",
    encoded_c: "Y3NiXzMwMTI2X2M=",
    encoded_q: "Y3NiXzMwMTI2X3E=",
    encoded_p: "Y3NiXzMwMTI2X3A=",
  },
  {
    school_code: "sb_30056",
    name: "Notre Dame de Namur",
    division: 2,
    org_id: "sb_30056",
    mascot: "Argonauts",
    encoded_h: "Y3NiXzMwMDU2",
    encoded_c: "Y3NiXzMwMDU2X2M=",
    encoded_q: "Y3NiXzMwMDU2X3E=",
    encoded_p: "Y3NiXzMwMDU2X3A=",
  },
  {
    school_code: "sb_24639",
    name: "Nova Southeastern",
    division: 2,
    org_id: "sb_24639",
    mascot: "Sharks",
    encoded_h: "Y3NiXzI0NjM5",
    encoded_c: "Y3NiXzI0NjM5X2M=",
    encoded_q: "Y3NiXzI0NjM5X3E=",
    encoded_p: "Y3NiXzI0NjM5X3A=",
  },
  {
    school_code: "sb_1277",
    name: "Nyack",
    division: 2,
    org_id: "sb_1277",
    mascot: "Lady Warriors",
    encoded_h: "Y3NiXzEyNzc=",
    encoded_c: "Y3NiXzEyNzdfYw==",
    encoded_q: "Y3NiXzEyNzdfcQ==",
    encoded_p: "Y3NiXzEyNzdfcA==",
  },
  {
    school_code: "sb_1278",
    name: "Oakland City",
    division: 2,
    org_id: "sb_1278",
    mascot: "Lady Oaks",
    encoded_h: "Y3NiXzEyNzg=",
    encoded_c: "Y3NiXzEyNzhfYw==",
    encoded_q: "Y3NiXzEyNzhfcQ==",
    encoded_p: "Y3NiXzEyNzhfcA==",
  },
  {
    school_code: "sb_30119",
    name: "Ohio Dominican",
    division: 2,
    org_id: "sb_30119",
    mascot: "Panthers",
    encoded_h: "Y3NiXzMwMTE5",
    encoded_c: "Y3NiXzMwMTE5X2M=",
    encoded_q: "Y3NiXzMwMTE5X3E=",
    encoded_p: "Y3NiXzMwMTE5X3A=",
  },
  {
    school_code: "sb_15207",
    name: "Ohio Valley",
    division: 2,
    org_id: "sb_15207",
    mascot: "Fighting Scots",
    encoded_h: "Y3NiXzE1MjA3",
    encoded_c: "Y3NiXzE1MjA3X2M=",
    encoded_q: "Y3NiXzE1MjA3X3E=",
    encoded_p: "Y3NiXzE1MjA3X3A=",
  },
  {
    school_code: "sb_30202",
    name: "Okla. Baptist",
    division: 2,
    org_id: "sb_30202",
    mascot: "Lady Bison",
    encoded_h: "Y3NiXzMwMjAy",
    encoded_c: "Y3NiXzMwMjAyX2M=",
    encoded_q: "Y3NiXzMwMjAyX3E=",
    encoded_p: "Y3NiXzMwMjAyX3A=",
  },
  {
    school_code: "sb_30179",
    name: "Okla. Christian",
    division: 2,
    org_id: "sb_30179",
    mascot: "Eagles",
    encoded_h: "Y3NiXzMwMTc5",
    encoded_c: "Y3NiXzMwMTc5X2M=",
    encoded_q: "Y3NiXzMwMTc5X3E=",
    encoded_p: "Y3NiXzMwMTc5X3A=",
  },
  {
    school_code: "sb_24342",
    name: "Okla. Panhandle",
    division: 2,
    org_id: "sb_24342",
    mascot: "Aggies",
    encoded_h: "Y3NiXzI0MzQy",
    encoded_c: "Y3NiXzI0MzQyX2M=",
    encoded_q: "Y3NiXzI0MzQyX3E=",
    encoded_p: "Y3NiXzI0MzQyX3A=",
  },
  {
    school_code: "sb_1289",
    name: "Ouachita Baptist",
    division: 2,
    org_id: "sb_1289",
    mascot: "Lady Tigers",
    encoded_h: "Y3NiXzEyODk=",
    encoded_c: "Y3NiXzEyODlfYw==",
    encoded_q: "Y3NiXzEyODlfcQ==",
    encoded_p: "Y3NiXzEyODlfcA==",
  },
  {
    school_code: "sb_24361",
    name: "P.R.-Mayaguez",
    division: 2,
    org_id: "sb_24361",
    mascot: "Janes",
    encoded_h: "Y3NiXzI0MzYx",
    encoded_c: "Y3NiXzI0MzYxX2M=",
    encoded_q: "Y3NiXzI0MzYxX3E=",
    encoded_p: "Y3NiXzI0MzYxX3A=",
  },
  {
    school_code: "sb_533",
    name: "Pace",
    division: 2,
    org_id: "sb_533",
    mascot: "Setters",
    encoded_h: "Y3NiXzUzMw==",
    encoded_c: "Y3NiXzUzM19j",
    encoded_q: "Y3NiXzUzM19x",
    encoded_p: "Y3NiXzUzM19w",
  },
  {
    school_code: "sb_535",
    name: "Paine",
    division: 2,
    org_id: "sb_535",
    mascot: "Lady Lions",
    encoded_h: "Y3NiXzUzNQ==",
    encoded_c: "Y3NiXzUzNV9j",
    encoded_q: "Y3NiXzUzNV9x",
    encoded_p: "Y3NiXzUzNV9w",
  },
  {
    school_code: "sb_30045",
    name: "Palm Beach Atl.",
    division: 2,
    org_id: "sb_30045",
    mascot: "Sailfish",
    encoded_h: "Y3NiXzMwMDQ1",
    encoded_c: "Y3NiXzMwMDQ1X2M=",
    encoded_q: "Y3NiXzMwMDQ1X3E=",
    encoded_p: "Y3NiXzMwMDQ1X3A=",
  },
  {
    school_code: "sb_542",
    name: "Pfeiffer",
    division: 2,
    org_id: "sb_542",
    mascot: "Falcons",
    encoded_h: "Y3NiXzU0Mg==",
    encoded_c: "Y3NiXzU0Ml9j",
    encoded_q: "Y3NiXzU0Ml9x",
    encoded_p: "Y3NiXzU0Ml9w",
  },
  {
    school_code: "sb_543",
    name: "Philadelphia U.",
    division: 2,
    org_id: "sb_543",
    mascot: "Rams",
    encoded_h: "Y3NiXzU0Mw==",
    encoded_c: "Y3NiXzU0M19j",
    encoded_q: "Y3NiXzU0M19x",
    encoded_p: "Y3NiXzU0M19w",
  },
  {
    school_code: "sb_546",
    name: "Pitt.-Johnstown",
    division: 2,
    org_id: "sb_546",
    mascot: "Lady Cats",
    encoded_h: "Y3NiXzU0Ng==",
    encoded_c: "Y3NiXzU0Nl9j",
    encoded_q: "Y3NiXzU0Nl9x",
    encoded_p: "Y3NiXzU0Nl9w",
  },
  {
    school_code: "sb_1314",
    name: "Pittsburg St.",
    division: 2,
    org_id: "sb_1314",
    mascot: "Gorillas",
    encoded_h: "Y3NiXzEzMTQ=",
    encoded_c: "Y3NiXzEzMTRfYw==",
    encoded_q: "Y3NiXzEzMTRfcQ==",
    encoded_p: "Y3NiXzEzMTRfcA==",
  },
  {
    school_code: "sb_1318",
    name: "Post",
    division: 2,
    org_id: "sb_1318",
    mascot: "Eagles",
    encoded_h: "Y3NiXzEzMTg=",
    encoded_c: "Y3NiXzEzMThfYw==",
    encoded_q: "Y3NiXzEzMThfcQ==",
    encoded_p: "Y3NiXzEzMThfcA==",
  },
  {
    school_code: "sb_11504",
    name: "Queens (NC)",
    division: 2,
    org_id: "sb_11504",
    mascot: "Royals",
    encoded_h: "Y3NiXzExNTA0",
    encoded_c: "Y3NiXzExNTA0X2M=",
    encoded_q: "Y3NiXzExNTA0X3E=",
    encoded_p: "Y3NiXzExNTA0X3A=",
  },
  {
    school_code: "sb_560",
    name: "Queens (NY)",
    division: 2,
    org_id: "sb_560",
    mascot: "Knights",
    encoded_h: "Y3NiXzU2MA==",
    encoded_c: "Y3NiXzU2MF9j",
    encoded_q: "Y3NiXzU2MF9x",
    encoded_p: "Y3NiXzU2MF9w",
  },
  {
    school_code: "sb_561",
    name: "Quincy",
    division: 2,
    org_id: "sb_561",
    mascot: "Hawks",
    encoded_h: "Y3NiXzU2MQ==",
    encoded_c: "Y3NiXzU2MV9j",
    encoded_q: "Y3NiXzU2MV9x",
    encoded_p: "Y3NiXzU2MV9w",
  },
  {
    school_code: "sb_568",
    name: "Regis (CO)",
    division: 2,
    org_id: "sb_568",
    mascot: "Rangers",
    encoded_h: "Y3NiXzU2OA==",
    encoded_c: "Y3NiXzU2OF9j",
    encoded_q: "Y3NiXzU2OF9x",
    encoded_p: "Y3NiXzU2OF9w",
  },
  {
    school_code: "sb_1328",
    name: "Rockhurst",
    division: 2,
    org_id: "sb_1328",
    mascot: "Hawks",
    encoded_h: "Y3NiXzEzMjg=",
    encoded_c: "Y3NiXzEzMjhfYw==",
    encoded_q: "Y3NiXzEzMjhfcQ==",
    encoded_p: "Y3NiXzEzMjhfcA==",
  },
  {
    school_code: "sb_30180",
    name: "Rogers St.",
    division: 2,
    org_id: "sb_30180",
    mascot: "Hillcats",
    encoded_h: "Y3NiXzMwMTgw",
    encoded_c: "Y3NiXzMwMTgwX2M=",
    encoded_q: "Y3NiXzMwMTgwX3E=",
    encoded_p: "Y3NiXzMwMTgwX3A=",
  },
  {
    school_code: "sb_584",
    name: "Rollins",
    division: 2,
    org_id: "sb_584",
    mascot: "Tars",
    encoded_h: "Y3NiXzU4NA==",
    encoded_c: "Y3NiXzU4NF9j",
    encoded_q: "Y3NiXzU4NF9x",
    encoded_p: "Y3NiXzU4NF9w",
  },
  {
    school_code: "sb_11276",
    name: "S.C. Aiken",
    division: 2,
    org_id: "sb_11276",
    mascot: "Pacers",
    encoded_h: "Y3NiXzExMjc2",
    encoded_c: "Y3NiXzExMjc2X2M=",
    encoded_q: "Y3NiXzExMjc2X3E=",
    encoded_p: "Y3NiXzExMjc2X3A=",
  },
  {
    school_code: "sb_591",
    name: "Saginaw Valley",
    division: 2,
    org_id: "sb_591",
    mascot: "Cardinals",
    encoded_h: "Y3NiXzU5MQ==",
    encoded_c: "Y3NiXzU5MV9j",
    encoded_q: "Y3NiXzU5MV9x",
    encoded_p: "Y3NiXzU5MV9w",
  },
  {
    school_code: "sb_594",
    name: "Saint Augustine's",
    division: 2,
    org_id: "sb_594",
    mascot: "Lady Falcons",
    encoded_h: "Y3NiXzU5NA==",
    encoded_c: "Y3NiXzU5NF9j",
    encoded_q: "Y3NiXzU5NF9x",
    encoded_p: "Y3NiXzU5NF9w",
  },
  {
    school_code: "sb_608",
    name: "Saint Leo",
    division: 2,
    org_id: "sb_608",
    mascot: "Lions",
    encoded_h: "Y3NiXzYwOA==",
    encoded_c: "Y3NiXzYwOF9j",
    encoded_q: "Y3NiXzYwOF9x",
    encoded_p: "Y3NiXzYwOF9w",
  },
  {
    school_code: "sb_1343",
    name: "Saint Martin's",
    division: 2,
    org_id: "sb_1343",
    mascot: "Saints",
    encoded_h: "Y3NiXzEzNDM=",
    encoded_c: "Y3NiXzEzNDNfYw==",
    encoded_q: "Y3NiXzEzNDNfcQ==",
    encoded_p: "Y3NiXzEzNDNfcA==",
  },
  {
    school_code: "sb_613",
    name: "Saint Michael's",
    division: 2,
    org_id: "sb_613",
    mascot: "Purple Knights",
    encoded_h: "Y3NiXzYxMw==",
    encoded_c: "Y3NiXzYxM19j",
    encoded_q: "Y3NiXzYxM19x",
    encoded_p: "Y3NiXzYxM19w",
  },
  {
    school_code: "sb_1347",
    name: "Saint Rose",
    division: 2,
    org_id: "sb_1347",
    mascot: "Golden Knights",
    encoded_h: "Y3NiXzEzNDc=",
    encoded_c: "Y3NiXzEzNDdfYw==",
    encoded_q: "Y3NiXzEzNDdfcQ==",
    encoded_p: "Y3NiXzEzNDdfcA==",
  },
  {
    school_code: "sb_19117",
    name: "Salem Int'l",
    division: 2,
    org_id: "sb_19117",
    mascot: "Tigers",
    encoded_h: "Y3NiXzE5MTE3",
    encoded_c: "Y3NiXzE5MTE3X2M=",
    encoded_q: "Y3NiXzE5MTE3X3E=",
    encoded_p: "Y3NiXzE5MTE3X3A=",
  },
  {
    school_code: "sb_628",
    name: "San Fran. St.",
    division: 2,
    org_id: "sb_628",
    mascot: "Gators",
    encoded_h: "Y3NiXzYyOA==",
    encoded_c: "Y3NiXzYyOF9j",
    encoded_q: "Y3NiXzYyOF9x",
    encoded_p: "Y3NiXzYyOF9w",
  },
  {
    school_code: "sb_30063",
    name: "Seton Hill",
    division: 2,
    org_id: "sb_30063",
    mascot: "Griffins",
    encoded_h: "Y3NiXzMwMDYz",
    encoded_c: "Y3NiXzMwMDYzX2M=",
    encoded_q: "Y3NiXzMwMDYzX3E=",
    encoded_p: "Y3NiXzMwMDYzX3A=",
  },
  {
    school_code: "sb_636",
    name: "Shaw",
    division: 2,
    org_id: "sb_636",
    mascot: "Lady Bears",
    encoded_h: "Y3NiXzYzNg==",
    encoded_c: "Y3NiXzYzNl9j",
    encoded_q: "Y3NiXzYzNl9x",
    encoded_p: "Y3NiXzYzNl9w",
  },
  {
    school_code: "sb_10870",
    name: "Shepherd",
    division: 2,
    org_id: "sb_10870",
    mascot: "Rams",
    encoded_h: "Y3NiXzEwODcw",
    encoded_c: "Y3NiXzEwODcwX2M=",
    encoded_q: "Y3NiXzEwODcwX3E=",
    encoded_p: "Y3NiXzEwODcwX3A=",
  },
  {
    school_code: "sb_638",
    name: "Shippensburg",
    division: 2,
    org_id: "sb_638",
    mascot: "Raiders",
    encoded_h: "Y3NiXzYzOA==",
    encoded_c: "Y3NiXzYzOF9j",
    encoded_q: "Y3NiXzYzOF9x",
    encoded_p: "Y3NiXzYzOF9w",
  },
  {
    school_code: "sb_30151",
    name: "Shorter",
    division: 2,
    org_id: "sb_30151",
    mascot: "Lady Hawks",
    encoded_h: "Y3NiXzMwMTUx",
    encoded_c: "Y3NiXzMwMTUxX2M=",
    encoded_q: "Y3NiXzMwMTUxX3E=",
    encoded_p: "Y3NiXzMwMTUxX3A=",
  },
  {
    school_code: "sb_30127",
    name: "Simon Fraser",
    division: 2,
    org_id: "sb_30127",
    mascot: "Clan",
    encoded_h: "Y3NiXzMwMTI3",
    encoded_c: "Y3NiXzMwMTI3X2M=",
    encoded_q: "Y3NiXzMwMTI3X3E=",
    encoded_p: "Y3NiXzMwMTI3X3A=",
  },
  {
    school_code: "sb_30128",
    name: "Sioux Falls",
    division: 2,
    org_id: "sb_30128",
    mascot: "Cougars",
    encoded_h: "Y3NiXzMwMTI4",
    encoded_c: "Y3NiXzMwMTI4X2M=",
    encoded_q: "Y3NiXzMwMTI4X3E=",
    encoded_p: "Y3NiXzMwMTI4X3A=",
  },
  {
    school_code: "sb_643",
    name: "Slippery Rock",
    division: 2,
    org_id: "sb_643",
    mascot: "Rocks",
    encoded_h: "Y3NiXzY0Mw==",
    encoded_c: "Y3NiXzY0M19j",
    encoded_q: "Y3NiXzY0M19x",
    encoded_p: "Y3NiXzY0M19w",
  },
  {
    school_code: "sb_645",
    name: "Sonoma St.",
    division: 2,
    org_id: "sb_645",
    mascot: "Seawolves",
    encoded_h: "Y3NiXzY0NQ==",
    encoded_c: "Y3NiXzY0NV9j",
    encoded_q: "Y3NiXzY0NV9x",
    encoded_p: "Y3NiXzY0NV9w",
  },
  {
    school_code: "sb_1371",
    name: "Southeastern Okla.",
    division: 2,
    org_id: "sb_1371",
    mascot: "Savage Storm",
    encoded_h: "Y3NiXzEzNzE=",
    encoded_c: "Y3NiXzEzNzFfYw==",
    encoded_q: "Y3NiXzEzNzFfcQ==",
    encoded_p: "Y3NiXzEzNzFfcA==",
  },
  {
    school_code: "sb_1376",
    name: "Southern Ark.",
    division: 2,
    org_id: "sb_1376",
    mascot: "Lady Muleriders",
    encoded_h: "Y3NiXzEzNzY=",
    encoded_c: "Y3NiXzEzNzZfYw==",
    encoded_q: "Y3NiXzEzNzZfcQ==",
    encoded_p: "Y3NiXzEzNzZfcA==",
  },
  {
    school_code: "sb_658",
    name: "Southern Conn. St.",
    division: 2,
    org_id: "sb_658",
    mascot: "Owls",
    encoded_h: "Y3NiXzY1OA==",
    encoded_c: "Y3NiXzY1OF9j",
    encoded_q: "Y3NiXzY1OF9x",
    encoded_p: "Y3NiXzY1OF9w",
  },
  {
    school_code: "sb_661",
    name: "Southern Ind.",
    division: 2,
    org_id: "sb_661",
    mascot: "Screaming Eagles",
    encoded_h: "Y3NiXzY2MQ==",
    encoded_c: "Y3NiXzY2MV9j",
    encoded_q: "Y3NiXzY2MV9x",
    encoded_p: "Y3NiXzY2MV9w",
  },
  {
    school_code: "sb_468",
    name: "Southern N.H.",
    division: 2,
    org_id: "sb_468",
    mascot: "Penmen",
    encoded_h: "Y3NiXzQ2OA==",
    encoded_c: "Y3NiXzQ2OF9j",
    encoded_q: "Y3NiXzQ2OF9x",
    encoded_p: "Y3NiXzQ2OF9w",
  },
  {
    school_code: "sb_30152",
    name: "Southern Nazarene",
    division: 2,
    org_id: "sb_30152",
    mascot: "Crimson Storm",
    encoded_h: "Y3NiXzMwMTUy",
    encoded_c: "Y3NiXzMwMTUyX2M=",
    encoded_q: "Y3NiXzMwMTUyX3E=",
    encoded_p: "Y3NiXzMwMTUyX3A=",
  },
  {
    school_code: "sb_30192",
    name: "Southern Wesleyan",
    division: 2,
    org_id: "sb_30192",
    mascot: "Warriors",
    encoded_h: "Y3NiXzMwMTky",
    encoded_c: "Y3NiXzMwMTkyX2M=",
    encoded_q: "Y3NiXzMwMTkyX3E=",
    encoded_p: "Y3NiXzMwMTkyX3A=",
  },
  {
    school_code: "sb_2755",
    name: "Southwest Baptist",
    division: 2,
    org_id: "sb_2755",
    mascot: "Bearcats",
    encoded_h: "Y3NiXzI3NTU=",
    encoded_c: "Y3NiXzI3NTVfYw==",
    encoded_q: "Y3NiXzI3NTVfcQ==",
    encoded_p: "Y3NiXzI3NTVfcA==",
  },
  {
    school_code: "sb_8736",
    name: "Southwest Minn. St.",
    division: 2,
    org_id: "sb_8736",
    mascot: "Mustangs",
    encoded_h: "Y3NiXzg3MzY=",
    encoded_c: "Y3NiXzg3MzZfYw==",
    encoded_q: "Y3NiXzg3MzZfcQ==",
    encoded_p: "Y3NiXzg3MzZfcA==",
  },
  {
    school_code: "sb_8744",
    name: "Southwestern Okla.",
    division: 2,
    org_id: "sb_8744",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzg3NDQ=",
    encoded_c: "Y3NiXzg3NDRfYw==",
    encoded_q: "Y3NiXzg3NDRfcQ==",
    encoded_p: "Y3NiXzg3NDRfcA==",
  },
  {
    school_code: "sb_593",
    name: "St. Anselm",
    division: 2,
    org_id: "sb_593",
    mascot: "Hawks",
    encoded_h: "Y3NiXzU5Mw==",
    encoded_c: "Y3NiXzU5M19j",
    encoded_q: "Y3NiXzU5M19x",
    encoded_p: "Y3NiXzU5M19w",
  },
  {
    school_code: "sb_598",
    name: "St. Cloud St.",
    division: 2,
    org_id: "sb_598",
    mascot: "Huskies",
    encoded_h: "Y3NiXzU5OA==",
    encoded_c: "Y3NiXzU5OF9j",
    encoded_q: "Y3NiXzU5OF9x",
    encoded_p: "Y3NiXzU5OF9w",
  },
  {
    school_code: "sb_1334",
    name: "St. Edward's",
    division: 2,
    org_id: "sb_1334",
    mascot: "Hilltoppers",
    encoded_h: "Y3NiXzEzMzQ=",
    encoded_c: "Y3NiXzEzMzRfYw==",
    encoded_q: "Y3NiXzEzMzRfcQ==",
    encoded_p: "Y3NiXzEzMzRfcA==",
  },
  {
    school_code: "sb_604",
    name: "St. Joseph's (IN)",
    division: 2,
    org_id: "sb_604",
    mascot: "Pumas",
    encoded_h: "Y3NiXzYwNA==",
    encoded_c: "Y3NiXzYwNF9j",
    encoded_q: "Y3NiXzYwNF9x",
    encoded_p: "Y3NiXzYwNF9w",
  },
  {
    school_code: "sb_1346",
    name: "St. Mary's (TX)",
    division: 2,
    org_id: "sb_1346",
    mascot: "Rattlers",
    encoded_h: "Y3NiXzEzNDY=",
    encoded_c: "Y3NiXzEzNDZfYw==",
    encoded_q: "Y3NiXzEzNDZfcQ==",
    encoded_p: "Y3NiXzEzNDZfcA==",
  },
  {
    school_code: "sb_1348",
    name: "St. Thomas Aquinas",
    division: 2,
    org_id: "sb_1348",
    mascot: "Spartans",
    encoded_h: "Y3NiXzEzNDg=",
    encoded_c: "Y3NiXzEzNDhfYw==",
    encoded_q: "Y3NiXzEzNDhfcQ==",
    encoded_p: "Y3NiXzEzNDhfcA==",
  },
  {
    school_code: "sb_680",
    name: "Stillman",
    division: 2,
    org_id: "sb_680",
    mascot: "Lady Tigers",
    encoded_h: "Y3NiXzY4MA==",
    encoded_c: "Y3NiXzY4MF9j",
    encoded_q: "Y3NiXzY4MF9x",
    encoded_p: "Y3NiXzY4MF9w",
  },
  {
    school_code: "sb_682",
    name: "Stonehill",
    division: 2,
    org_id: "sb_682",
    mascot: "Skyhawks",
    encoded_h: "Y3NiXzY4Mg==",
    encoded_c: "Y3NiXzY4Ml9j",
    encoded_q: "Y3NiXzY4Ml9x",
    encoded_p: "Y3NiXzY4Ml9w",
  },
  {
    school_code: "sb_689",
    name: "Tampa",
    division: 2,
    org_id: "sb_689",
    mascot: "Spartans",
    encoded_h: "Y3NiXzY4OQ==",
    encoded_c: "Y3NiXzY4OV9j",
    encoded_q: "Y3NiXzY4OV9x",
    encoded_p: "Y3NiXzY4OV9w",
  },
  {
    school_code: "sb_1395",
    name: "Tarleton St.",
    division: 2,
    org_id: "sb_1395",
    mascot: "TexAnns",
    encoded_h: "Y3NiXzEzOTU=",
    encoded_c: "Y3NiXzEzOTVfYw==",
    encoded_q: "Y3NiXzEzOTVfcQ==",
    encoded_p: "Y3NiXzEzOTVfcA==",
  },
  {
    school_code: "sb_199",
    name: "Tex. A&M-Commerce",
    division: 2,
    org_id: "sb_199",
    mascot: "Lions",
    encoded_h: "Y3NiXzE5OQ==",
    encoded_c: "Y3NiXzE5OV9j",
    encoded_q: "Y3NiXzE5OV9x",
    encoded_p: "Y3NiXzE5OV9w",
  },
  {
    school_code: "sb_30090",
    name: "Tex. A&M Int'l",
    division: 2,
    org_id: "sb_30090",
    mascot: "Dustdevils",
    encoded_h: "Y3NiXzMwMDkw",
    encoded_c: "Y3NiXzMwMDkwX2M=",
    encoded_q: "Y3NiXzMwMDkwX3E=",
    encoded_p: "Y3NiXzMwMDkwX3A=",
  },
  {
    school_code: "sb_696",
    name: "Tex. A&M-Kingsville",
    division: 2,
    org_id: "sb_696",
    mascot: "Javelinas",
    encoded_h: "Y3NiXzY5Ng==",
    encoded_c: "Y3NiXzY5Nl9j",
    encoded_q: "Y3NiXzY5Nl9x",
    encoded_p: "Y3NiXzY5Nl9w",
  },
  {
    school_code: "sb_30088",
    name: "Tex. Permian Basin",
    division: 2,
    org_id: "sb_30088",
    mascot: "Falcons",
    encoded_h: "Y3NiXzMwMDg4",
    encoded_c: "Y3NiXzMwMDg4X2M=",
    encoded_q: "Y3NiXzMwMDg4X3E=",
    encoded_p: "Y3NiXzMwMDg4X3A=",
  },
  {
    school_code: "sb_701",
    name: "Texas Woman's",
    division: 2,
    org_id: "sb_701",
    mascot: "Pioneers",
    encoded_h: "Y3NiXzcwMQ==",
    encoded_c: "Y3NiXzcwMV9j",
    encoded_q: "Y3NiXzcwMV9x",
    encoded_p: "Y3NiXzcwMV9w",
  },
  {
    school_code: "sb_1403",
    name: "Tiffin",
    division: 2,
    org_id: "sb_1403",
    mascot: "Dragons",
    encoded_h: "Y3NiXzE0MDM=",
    encoded_c: "Y3NiXzE0MDNfYw==",
    encoded_q: "Y3NiXzE0MDNfcQ==",
    encoded_p: "Y3NiXzE0MDNfcA==",
  },
  {
    school_code: "sb_30002",
    name: "Trevecca Nazarene",
    division: 2,
    org_id: "sb_30002",
    mascot: "Trojans",
    encoded_h: "Y3NiXzMwMDAy",
    encoded_c: "Y3NiXzMwMDAyX2M=",
    encoded_q: "Y3NiXzMwMDAyX3E=",
    encoded_p: "Y3NiXzMwMDAyX3A=",
  },
  {
    school_code: "sb_499",
    name: "Truman",
    division: 2,
    org_id: "sb_499",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzQ5OQ==",
    encoded_c: "Y3NiXzQ5OV9j",
    encoded_q: "Y3NiXzQ5OV9x",
    encoded_p: "Y3NiXzQ5OV9w",
  },
  {
    school_code: "sb_12830",
    name: "Tusculum",
    division: 2,
    org_id: "sb_12830",
    mascot: "Pioneers",
    encoded_h: "Y3NiXzEyODMw",
    encoded_c: "Y3NiXzEyODMwX2M=",
    encoded_q: "Y3NiXzEyODMwX3E=",
    encoded_p: "Y3NiXzEyODMwX3A=",
  },
  {
    school_code: "sb_720",
    name: "Tuskegee",
    division: 2,
    org_id: "sb_720",
    mascot: "Tigerettes",
    encoded_h: "Y3NiXzcyMA==",
    encoded_c: "Y3NiXzcyMF9j",
    encoded_q: "Y3NiXzcyMF9x",
    encoded_p: "Y3NiXzcyMF9w",
  },
  {
    school_code: "sb_112",
    name: "UC San Diego",
    division: 2,
    org_id: "sb_112",
    mascot: "Tritons",
    encoded_h: "Y3NiXzExMg==",
    encoded_c: "Y3NiXzExMl9j",
    encoded_q: "Y3NiXzExMl9x",
    encoded_p: "Y3NiXzExMl9w",
  },
  {
    school_code: "sb_9754",
    name: "UC-Colo. Springs",
    division: 2,
    org_id: "sb_9754",
    mascot: "Mountain Lions",
    encoded_h: "Y3NiXzk3NTQ=",
    encoded_c: "Y3NiXzk3NTRfYw==",
    encoded_q: "Y3NiXzk3NTRfcQ==",
    encoded_p: "Y3NiXzk3NTRfcA==",
  },
  {
    school_code: "sb_537",
    name: "UNC Pembroke",
    division: 2,
    org_id: "sb_537",
    mascot: "Braves",
    encoded_h: "Y3NiXzUzNw==",
    encoded_c: "Y3NiXzUzN19j",
    encoded_q: "Y3NiXzUzN19x",
    encoded_p: "Y3NiXzUzN19w",
  },
  {
    school_code: "sb_28594",
    name: "USciences",
    division: 2,
    org_id: "sb_28594",
    mascot: "Devils",
    encoded_h: "Y3NiXzI4NTk0",
    encoded_c: "Y3NiXzI4NTk0X2M=",
    encoded_q: "Y3NiXzI4NTk0X3E=",
    encoded_p: "Y3NiXzI4NTk0X3A=",
  },
  {
    school_code: "sb_30259",
    name: "USC Beaufort",
    division: 2,
    org_id: "sb_30259",
    mascot: "Sand Sharks",
    encoded_h: "Y3NiXzMwMjU5",
    encoded_c: "Y3NiXzMwMjU5X2M=",
    encoded_q: "Y3NiXzMwMjU5X3E=",
    encoded_p: "Y3NiXzMwMjU5X3A=",
  },
  {
    school_code: "sb_30181",
    name: "UVa-Wise",
    division: 2,
    org_id: "sb_30181",
    mascot: "Highland Cavaliers",
    encoded_h: "Y3NiXzMwMTgx",
    encoded_c: "Y3NiXzMwMTgxX2M=",
    encoded_q: "Y3NiXzMwMTgxX3E=",
    encoded_p: "Y3NiXzMwMTgxX3A=",
  },
  {
    school_code: "sb_30153",
    name: "Union (TN)",
    division: 2,
    org_id: "sb_30153",
    mascot: "Lady Bulldogs",
    encoded_h: "Y3NiXzMwMTUz",
    encoded_c: "Y3NiXzMwMTUzX2M=",
    encoded_q: "Y3NiXzMwMTUzX3E=",
    encoded_p: "Y3NiXzMwMTUzX3A=",
  },
  {
    school_code: "sb_728",
    name: "Upper Iowa",
    division: 2,
    org_id: "sb_728",
    mascot: "Peacocks",
    encoded_h: "Y3NiXzcyOA==",
    encoded_c: "Y3NiXzcyOF9j",
    encoded_q: "Y3NiXzcyOF9x",
    encoded_p: "Y3NiXzcyOF9w",
  },
  {
    school_code: "sb_30104",
    name: "Urbana",
    division: 2,
    org_id: "sb_30104",
    mascot: "Blue Knights",
    encoded_h: "Y3NiXzMwMTA0",
    encoded_c: "Y3NiXzMwMTA0X2M=",
    encoded_q: "Y3NiXzMwMTA0X3E=",
    encoded_p: "Y3NiXzMwMTA0X3A=",
  },
  {
    school_code: "sb_30140",
    name: "Ursuline",
    division: 2,
    org_id: "sb_30140",
    mascot: "Arrows",
    encoded_h: "Y3NiXzMwMTQw",
    encoded_c: "Y3NiXzMwMTQwX2M=",
    encoded_q: "Y3NiXzMwMTQwX3E=",
    encoded_p: "Y3NiXzMwMTQwX3A=",
  },
  {
    school_code: "sb_734",
    name: "Valdosta St.",
    division: 2,
    org_id: "sb_734",
    mascot: "Blazers",
    encoded_h: "Y3NiXzczNA==",
    encoded_c: "Y3NiXzczNF9j",
    encoded_q: "Y3NiXzczNF9x",
    encoded_p: "Y3NiXzczNF9w",
  },
  {
    school_code: "sb_743",
    name: "Virginia St.",
    division: 2,
    org_id: "sb_743",
    mascot: "Trojans",
    encoded_h: "Y3NiXzc0Mw==",
    encoded_c: "Y3NiXzc0M19j",
    encoded_q: "Y3NiXzc0M19x",
    encoded_p: "Y3NiXzc0M19w",
  },
  {
    school_code: "sb_744",
    name: "Virginia Union",
    division: 2,
    org_id: "sb_744",
    mascot: "Lady Panthers",
    encoded_h: "Y3NiXzc0NA==",
    encoded_c: "Y3NiXzc0NF9j",
    encoded_q: "Y3NiXzc0NF9x",
    encoded_p: "Y3NiXzc0NF9w",
  },
  {
    school_code: "sb_30141",
    name: "Walsh",
    division: 2,
    org_id: "sb_30141",
    mascot: "Cavaliers",
    encoded_h: "Y3NiXzMwMTQx",
    encoded_c: "Y3NiXzMwMTQxX2M=",
    encoded_q: "Y3NiXzMwMTQxX3E=",
    encoded_p: "Y3NiXzMwMTQxX3A=",
  },
  {
    school_code: "sb_2814",
    name: "Washburn",
    division: 2,
    org_id: "sb_2814",
    mascot: "Ichabods",
    encoded_h: "Y3NiXzI4MTQ=",
    encoded_c: "Y3NiXzI4MTRfYw==",
    encoded_q: "Y3NiXzI4MTRfcQ==",
    encoded_p: "Y3NiXzI4MTRfcA==",
  },
  {
    school_code: "sb_757",
    name: "Wayne St. (MI)",
    division: 2,
    org_id: "sb_757",
    mascot: "Warriors",
    encoded_h: "Y3NiXzc1Nw==",
    encoded_c: "Y3NiXzc1N19j",
    encoded_q: "Y3NiXzc1N19x",
    encoded_p: "Y3NiXzc1N19w",
  },
  {
    school_code: "sb_1433",
    name: "Wayne St. (NE)",
    division: 2,
    org_id: "sb_1433",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzE0MzM=",
    encoded_c: "Y3NiXzE0MzNfYw==",
    encoded_q: "Y3NiXzE0MzNfcQ==",
    encoded_p: "Y3NiXzE0MzNfcA==",
  },
  {
    school_code: "sb_358",
    name: "West Ala.",
    division: 2,
    org_id: "sb_358",
    mascot: "Tigers",
    encoded_h: "Y3NiXzM1OA==",
    encoded_c: "Y3NiXzM1OF9j",
    encoded_q: "Y3NiXzM1OF9x",
    encoded_p: "Y3NiXzM1OF9w",
  },
  {
    school_code: "sb_765",
    name: "West Chester",
    division: 2,
    org_id: "sb_765",
    mascot: "Golden Rams",
    encoded_h: "Y3NiXzc2NQ==",
    encoded_c: "Y3NiXzc2NV9j",
    encoded_q: "Y3NiXzc2NV9x",
    encoded_p: "Y3NiXzc2NV9w",
  },
  {
    school_code: "sb_11740",
    name: "West Florida",
    division: 2,
    org_id: "sb_11740",
    mascot: "Argonauts",
    encoded_h: "Y3NiXzExNzQw",
    encoded_c: "Y3NiXzExNzQwX2M=",
    encoded_q: "Y3NiXzExNzQwX3E=",
    encoded_p: "Y3NiXzExNzQwX3A=",
  },
  {
    school_code: "sb_766",
    name: "West Ga.",
    division: 2,
    org_id: "sb_766",
    mascot: "Wolves",
    encoded_h: "Y3NiXzc2Ng==",
    encoded_c: "Y3NiXzc2Nl9j",
    encoded_q: "Y3NiXzc2Nl9x",
    encoded_p: "Y3NiXzc2Nl9w",
  },
  {
    school_code: "sb_1438",
    name: "West Liberty",
    division: 2,
    org_id: "sb_1438",
    mascot: "Lady Hilltoppers",
    encoded_h: "Y3NiXzE0Mzg=",
    encoded_c: "Y3NiXzE0MzhfYw==",
    encoded_q: "Y3NiXzE0MzhfcQ==",
    encoded_p: "Y3NiXzE0MzhfcA==",
  },
  {
    school_code: "sb_767",
    name: "West Tex. A&M",
    division: 2,
    org_id: "sb_767",
    mascot: "Lady Buffs",
    encoded_h: "Y3NiXzc2Nw==",
    encoded_c: "Y3NiXzc2N19j",
    encoded_q: "Y3NiXzc2N19x",
    encoded_p: "Y3NiXzc2N19w",
  },
  {
    school_code: "sb_9630",
    name: "West Va. Wesleyan",
    division: 2,
    org_id: "sb_9630",
    mascot: "Lady Bobcats",
    encoded_h: "Y3NiXzk2MzA=",
    encoded_c: "Y3NiXzk2MzBfYw==",
    encoded_q: "Y3NiXzk2MzBfcQ==",
    encoded_p: "Y3NiXzk2MzBfcA==",
  },
  {
    school_code: "sb_1439",
    name: "West Virginia St.",
    division: 2,
    org_id: "sb_1439",
    mascot: "Yellow Jackets",
    encoded_h: "Y3NiXzE0Mzk=",
    encoded_c: "Y3NiXzE0MzlfYw==",
    encoded_q: "Y3NiXzE0MzlfcQ==",
    encoded_p: "Y3NiXzE0MzlfcA==",
  },
  {
    school_code: "sb_1445",
    name: "Western N.M.",
    division: 2,
    org_id: "sb_1445",
    mascot: "Mustangs",
    encoded_h: "Y3NiXzE0NDU=",
    encoded_c: "Y3NiXzE0NDVfYw==",
    encoded_q: "Y3NiXzE0NDVfcQ==",
    encoded_p: "Y3NiXzE0NDVfcA==",
  },
  {
    school_code: "sb_1446",
    name: "Western Ore.",
    division: 2,
    org_id: "sb_1446",
    mascot: "Wolves",
    encoded_h: "Y3NiXzE0NDY=",
    encoded_c: "Y3NiXzE0NDZfYw==",
    encoded_q: "Y3NiXzE0NDZfcQ==",
    encoded_p: "Y3NiXzE0NDZfcA==",
  },
  {
    school_code: "sb_1448",
    name: "Western Wash.",
    division: 2,
    org_id: "sb_1448",
    mascot: "Vikings",
    encoded_h: "Y3NiXzE0NDg=",
    encoded_c: "Y3NiXzE0NDhfYw==",
    encoded_q: "Y3NiXzE0NDhfcQ==",
    encoded_p: "Y3NiXzE0NDhfcA==",
  },
  {
    school_code: "sb_12799",
    name: "Wheeling Jesuit",
    division: 2,
    org_id: "sb_12799",
    mascot: "Lady Cardinals",
    encoded_h: "Y3NiXzEyNzk5",
    encoded_c: "Y3NiXzEyNzk5X2M=",
    encoded_q: "Y3NiXzEyNzk5X3E=",
    encoded_p: "Y3NiXzEyNzk5X3A=",
  },
  {
    school_code: "sb_30120",
    name: "William Jewell",
    division: 2,
    org_id: "sb_30120",
    mascot: "Cardinals",
    encoded_h: "Y3NiXzMwMTIw",
    encoded_c: "Y3NiXzMwMTIwX2M=",
    encoded_q: "Y3NiXzMwMTIwX3E=",
    encoded_p: "Y3NiXzMwMTIwX3A=",
  },
  {
    school_code: "sb_1460",
    name: "Wilmington (DE)",
    division: 2,
    org_id: "sb_1460",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzE0NjA=",
    encoded_c: "Y3NiXzE0NjBfYw==",
    encoded_q: "Y3NiXzE0NjBfcQ==",
    encoded_p: "Y3NiXzE0NjBfcA==",
  },
  {
    school_code: "sb_1462",
    name: "Wingate",
    division: 2,
    org_id: "sb_1462",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzE0NjI=",
    encoded_c: "Y3NiXzE0NjJfYw==",
    encoded_q: "Y3NiXzE0NjJfcQ==",
    encoded_p: "Y3NiXzE0NjJfcA==",
  },
  {
    school_code: "sb_790",
    name: "Winona St.",
    division: 2,
    org_id: "sb_790",
    mascot: "Warriors",
    encoded_h: "Y3NiXzc5MA==",
    encoded_c: "Y3NiXzc5MF9j",
    encoded_q: "Y3NiXzc5MF9x",
    encoded_p: "Y3NiXzc5MF9w",
  },
  {
    school_code: "sb_791",
    name: "Winston-Salem",
    division: 2,
    org_id: "sb_791",
    mascot: "Lady Rams",
    encoded_h: "Y3NiXzc5MQ==",
    encoded_c: "Y3NiXzc5MV9j",
    encoded_q: "Y3NiXzc5MV9x",
    encoded_p: "Y3NiXzc5MV9w",
  },
  {
    school_code: "sb_799",
    name: "Wis.-Parkside",
    division: 2,
    org_id: "sb_799",
    mascot: "Rangers",
    encoded_h: "Y3NiXzc5OQ==",
    encoded_c: "Y3NiXzc5OV9j",
    encoded_q: "Y3NiXzc5OV9x",
    encoded_p: "Y3NiXzc5OV9w",
  },
  {
    school_code: "sb_30154",
    name: "Young Harris",
    division: 2,
    org_id: "sb_30154",
    mascot: "Mountain Lions",
    encoded_h: "Y3NiXzMwMTU0",
    encoded_c: "Y3NiXzMwMTU0X2M=",
    encoded_q: "Y3NiXzMwMTU0X3E=",
    encoded_p: "Y3NiXzMwMTU0X3A=",
  },
  {
    school_code: "sb_4",
    name: "Adrian",
    division: 3,
    org_id: "sb_4",
    mascot: "Bulldogs",
    encoded_h: "Y3NiXzQ=",
    encoded_c: "Y3NiXzRfYw==",
    encoded_q: "Y3NiXzRfcQ==",
    encoded_p: "Y3NiXzRfcA==",
  },
  {
    school_code: "sb_11324",
    name: "Agnes Scott",
    division: 3,
    org_id: "sb_11324",
    encoded_h: "Y3NiXzExMzI0",
    encoded_c: "Y3NiXzExMzI0X2M=",
    encoded_q: "Y3NiXzExMzI0X3E=",
    encoded_p: "Y3NiXzExMzI0X3A=",
  },
  {
    school_code: "sb_2798",
    name: "Albertus Magnus",
    division: 3,
    org_id: "sb_2798",
    encoded_h: "Y3NiXzI3OTg=",
    encoded_c: "Y3NiXzI3OThfYw==",
    encoded_q: "Y3NiXzI3OThfcQ==",
    encoded_p: "Y3NiXzI3OThfcA==",
  },
  {
    school_code: "sb_15",
    name: "Albion",
    division: 3,
    org_id: "sb_15",
    encoded_h: "Y3NiXzE1",
    encoded_c: "Y3NiXzE1X2M=",
    encoded_q: "Y3NiXzE1X3E=",
    encoded_p: "Y3NiXzE1X3A=",
  },
  {
    school_code: "sb_16",
    name: "Albright",
    division: 3,
    org_id: "sb_16",
    encoded_h: "Y3NiXzE2",
    encoded_c: "Y3NiXzE2X2M=",
    encoded_q: "Y3NiXzE2X3E=",
    encoded_p: "Y3NiXzE2X3A=",
  },
  {
    school_code: "sb_18",
    name: "Alfred",
    division: 3,
    org_id: "sb_18",
    encoded_h: "Y3NiXzE4",
    encoded_c: "Y3NiXzE4X2M=",
    encoded_q: "Y3NiXzE4X3E=",
    encoded_p: "Y3NiXzE4X3A=",
  },
  {
    school_code: "sb_30170",
    name: "Alfred St",
    division: 3,
    org_id: "sb_30170",
    mascot: "Pioneers",
    encoded_h: "Y3NiXzMwMTcw",
    encoded_c: "Y3NiXzMwMTcwX2M=",
    encoded_q: "Y3NiXzMwMTcwX3E=",
    encoded_p: "Y3NiXzMwMTcwX3A=",
  },
  {
    school_code: "sb_19",
    name: "Allegheny",
    division: 3,
    org_id: "sb_19",
    encoded_h: "Y3NiXzE5",
    encoded_c: "Y3NiXzE5X2M=",
    encoded_q: "Y3NiXzE5X3E=",
    encoded_p: "Y3NiXzE5X3A=",
  },
  {
    school_code: "sb_21",
    name: "Alma",
    division: 3,
    org_id: "sb_21",
    encoded_h: "Y3NiXzIx",
    encoded_c: "Y3NiXzIxX2M=",
    encoded_q: "Y3NiXzIxX3E=",
    encoded_p: "Y3NiXzIxX3A=",
  },
  {
    school_code: "sb_938",
    name: "Alvernia",
    division: 3,
    org_id: "sb_938",
    encoded_h: "Y3NiXzkzOA==",
    encoded_c: "Y3NiXzkzOF9j",
    encoded_q: "Y3NiXzkzOF9x",
    encoded_p: "Y3NiXzkzOF9w",
  },
  {
    school_code: "sb_28746",
    name: "Alverno",
    division: 3,
    org_id: "sb_28746",
    encoded_h: "Y3NiXzI4NzQ2",
    encoded_c: "Y3NiXzI4NzQ2X2M=",
    encoded_q: "Y3NiXzI4NzQ2X3E=",
    encoded_p: "Y3NiXzI4NzQ2X3A=",
  },
  {
    school_code: "sb_24",
    name: "Amherst",
    division: 3,
    org_id: "sb_24",
    encoded_h: "Y3NiXzI0",
    encoded_c: "Y3NiXzI0X2M=",
    encoded_q: "Y3NiXzI0X3E=",
    encoded_p: "Y3NiXzI0X3A=",
  },
  {
    school_code: "sb_939",
    name: "Anderson (IN)",
    division: 3,
    org_id: "sb_939",
    encoded_h: "Y3NiXzkzOQ==",
    encoded_c: "Y3NiXzkzOV9j",
    encoded_q: "Y3NiXzkzOV9x",
    encoded_p: "Y3NiXzkzOV9w",
  },
  {
    school_code: "sb_26",
    name: "Anna Maria",
    division: 3,
    org_id: "sb_26",
    encoded_h: "Y3NiXzI2",
    encoded_c: "Y3NiXzI2X2M=",
    encoded_q: "Y3NiXzI2X3E=",
    encoded_p: "Y3NiXzI2X3A=",
  },
  {
    school_code: "sb_966",
    name: "Arcadia",
    division: 3,
    org_id: "sb_966",
    encoded_h: "Y3NiXzk2Ng==",
    encoded_c: "Y3NiXzk2Nl9j",
    encoded_q: "Y3NiXzk2Nl9x",
    encoded_p: "Y3NiXzk2Nl9w",
  },
  {
    school_code: "sb_30243",
    name: "Asbury",
    division: 3,
    org_id: "sb_30243",
    mascot: "Eagles",
    encoded_h: "Y3NiXzMwMjQz",
    encoded_c: "Y3NiXzMwMjQzX2M=",
    encoded_q: "Y3NiXzMwMjQzX3E=",
    encoded_p: "Y3NiXzMwMjQzX3A=",
  },
  {
    school_code: "sb_38",
    name: "Augsburg",
    division: 3,
    org_id: "sb_38",
    encoded_h: "Y3NiXzM4",
    encoded_c: "Y3NiXzM4X2M=",
    encoded_q: "Y3NiXzM4X3E=",
    encoded_p: "Y3NiXzM4X3A=",
  },
  {
    school_code: "sb_40",
    name: "Augustana (IL)",
    division: 3,
    org_id: "sb_40",
    encoded_h: "Y3NiXzQw",
    encoded_c: "Y3NiXzQwX2M=",
    encoded_q: "Y3NiXzQwX3E=",
    encoded_p: "Y3NiXzQwX3A=",
  },
  {
    school_code: "sb_42",
    name: "Aurora",
    division: 3,
    org_id: "sb_42",
    encoded_h: "Y3NiXzQy",
    encoded_c: "Y3NiXzQyX2M=",
    encoded_q: "Y3NiXzQyX3E=",
    encoded_p: "Y3NiXzQyX3A=",
  },
  {
    school_code: "sb_953",
    name: "Austin",
    division: 3,
    org_id: "sb_953",
    encoded_h: "Y3NiXzk1Mw==",
    encoded_c: "Y3NiXzk1M19j",
    encoded_q: "Y3NiXzk1M19x",
    encoded_p: "Y3NiXzk1M19w",
  },
  {
    school_code: "sb_44",
    name: "Averett",
    division: 3,
    org_id: "sb_44",
    encoded_h: "Y3NiXzQ0",
    encoded_c: "Y3NiXzQ0X2M=",
    encoded_q: "Y3NiXzQ0X3E=",
    encoded_p: "Y3NiXzQ0X3A=",
  },
  {
    school_code: "sb_45",
    name: "Babson",
    division: 3,
    org_id: "sb_45",
    encoded_h: "Y3NiXzQ1",
    encoded_c: "Y3NiXzQ1X2M=",
    encoded_q: "Y3NiXzQ1X3E=",
    encoded_p: "Y3NiXzQ1X3A=",
  },
  {
    school_code: "sb_46",
    name: "Baldwin Wallace",
    division: 3,
    org_id: "sb_46",
    encoded_h: "Y3NiXzQ2",
    encoded_c: "Y3NiXzQ2X2M=",
    encoded_q: "Y3NiXzQ2X3E=",
    encoded_p: "Y3NiXzQ2X3A=",
  },
  {
    school_code: "sb_958",
    name: "Baptist Bible (PA)",
    division: 3,
    org_id: "sb_958",
    encoded_h: "Y3NiXzk1OA==",
    encoded_c: "Y3NiXzk1OF9j",
    encoded_q: "Y3NiXzk1OF9x",
    encoded_p: "Y3NiXzk1OF9w",
  },
  {
    school_code: "sb_58",
    name: "Baruch",
    division: 3,
    org_id: "sb_58",
    encoded_h: "Y3NiXzU4",
    encoded_c: "Y3NiXzU4X2M=",
    encoded_q: "Y3NiXzU4X3E=",
    encoded_p: "Y3NiXzU4X3A=",
  },
  {
    school_code: "sb_50",
    name: "Bates",
    division: 3,
    org_id: "sb_50",
    encoded_h: "Y3NiXzUw",
    encoded_c: "Y3NiXzUwX2M=",
    encoded_q: "Y3NiXzUwX3E=",
    encoded_p: "Y3NiXzUwX3A=",
  },
  {
    school_code: "sb_26520",
    name: "Bay Path",
    division: 3,
    org_id: "sb_26520",
    encoded_h: "Y3NiXzI2NTIw",
    encoded_c: "Y3NiXzI2NTIwX2M=",
    encoded_q: "Y3NiXzI2NTIwX3E=",
    encoded_p: "Y3NiXzI2NTIwX3A=",
  },
  {
    school_code: "sb_26107",
    name: "Becker",
    division: 3,
    org_id: "sb_26107",
    encoded_h: "Y3NiXzI2MTA3",
    encoded_c: "Y3NiXzI2MTA3X2M=",
    encoded_q: "Y3NiXzI2MTA3X3E=",
    encoded_p: "Y3NiXzI2MTA3X3A=",
  },
  {
    school_code: "sb_30197",
    name: "Belhaven",
    division: 3,
    org_id: "sb_30197",
    mascot: "Blazers",
    encoded_h: "Y3NiXzMwMTk3",
    encoded_c: "Y3NiXzMwMTk3X2M=",
    encoded_q: "Y3NiXzMwMTk3X3E=",
    encoded_p: "Y3NiXzMwMTk3X3A=",
  },
  {
    school_code: "sb_53",
    name: "Beloit",
    division: 3,
    org_id: "sb_53",
    encoded_h: "Y3NiXzUz",
    encoded_c: "Y3NiXzUzX2M=",
    encoded_q: "Y3NiXzUzX3E=",
    encoded_p: "Y3NiXzUzX3A=",
  },
  {
    school_code: "sb_296",
    name: "Benedictine (IL)",
    division: 3,
    org_id: "sb_296",
    encoded_h: "Y3NiXzI5Ng==",
    encoded_c: "Y3NiXzI5Nl9j",
    encoded_q: "Y3NiXzI5Nl9x",
    encoded_p: "Y3NiXzI5Nl9w",
  },
  {
    school_code: "sb_30171",
    name: "Berea",
    division: 3,
    org_id: "sb_30171",
    mascot: "Mountaineers",
    encoded_h: "Y3NiXzMwMTcx",
    encoded_c: "Y3NiXzMwMTcxX2M=",
    encoded_q: "Y3NiXzMwMTcxX3E=",
    encoded_p: "Y3NiXzMwMTcxX3A=",
  },
  {
    school_code: "sb_30173",
    name: "Iowa Wesleyan",
    division: 3,
    org_id: "sb_30173",
    mascot: "Tigers",
    encoded_h: "Y3NiXzMwMTcz",
    encoded_c: "Y3NiXzMwMTczX2M=",
    encoded_q: "Y3NiXzMwMTczX3E=",
    encoded_p: "Y3NiXzMwMTczX3A=",
  },
  {
    school_code: "sb_973",
    name: "Berry",
    division: 3,
    org_id: "sb_973",
    encoded_h: "Y3NiXzk3Mw==",
    encoded_c: "Y3NiXzk3M19j",
    encoded_q: "Y3NiXzk3M19x",
    encoded_p: "Y3NiXzk3M19w",
  },
  {
    school_code: "sb_59",
    name: "Bethany (WV)",
    division: 3,
    org_id: "sb_59",
    encoded_h: "Y3NiXzU5",
    encoded_c: "Y3NiXzU5X2M=",
    encoded_q: "Y3NiXzU5X3E=",
    encoded_p: "Y3NiXzU5X3A=",
  },
  {
    school_code: "sb_30038",
    name: "Bethany Lutheran",
    division: 3,
    org_id: "sb_30038",
    encoded_h: "Y3NiXzMwMDM4",
    encoded_c: "Y3NiXzMwMDM4X2M=",
    encoded_q: "Y3NiXzMwMDM4X3E=",
    encoded_p: "Y3NiXzMwMDM4X3A=",
  },
  {
    school_code: "sb_60",
    name: "Bethel (MN)",
    division: 3,
    org_id: "sb_60",
    encoded_h: "Y3NiXzYw",
    encoded_c: "Y3NiXzYwX2M=",
    encoded_q: "Y3NiXzYwX3E=",
    encoded_p: "Y3NiXzYwX3A=",
  },
  {
    school_code: "sb_28593",
    name: "Birmingham-So.",
    division: 3,
    org_id: "sb_28593",
    encoded_h: "Y3NiXzI4NTkz",
    encoded_c: "Y3NiXzI4NTkzX2M=",
    encoded_q: "Y3NiXzI4NTkzX3E=",
    encoded_p: "Y3NiXzI4NTkzX3A=",
  },
  {
    school_code: "sb_64",
    name: "Blackburn",
    division: 3,
    org_id: "sb_64",
    encoded_h: "Y3NiXzY0",
    encoded_c: "Y3NiXzY0X2M=",
    encoded_q: "Y3NiXzY0X3E=",
    encoded_p: "Y3NiXzY0X3A=",
  },
  {
    school_code: "sb_987",
    name: "Bluffton",
    division: 3,
    org_id: "sb_987",
    encoded_h: "Y3NiXzk4Nw==",
    encoded_c: "Y3NiXzk4N19j",
    encoded_q: "Y3NiXzk4N19x",
    encoded_p: "Y3NiXzk4N19w",
  },
  {
    school_code: "sb_69",
    name: "Bowdoin",
    division: 3,
    org_id: "sb_69",
    encoded_h: "Y3NiXzY5",
    encoded_c: "Y3NiXzY5X2M=",
    encoded_q: "Y3NiXzY5X3E=",
    encoded_p: "Y3NiXzY5X3A=",
  },
  {
    school_code: "sb_73",
    name: "Brandeis",
    division: 3,
    org_id: "sb_73",
    encoded_h: "Y3NiXzcz",
    encoded_c: "Y3NiXzczX2M=",
    encoded_q: "Y3NiXzczX3E=",
    encoded_p: "Y3NiXzczX3A=",
  },
  {
    school_code: "sb_75",
    name: "Bridgewater (VA)",
    division: 3,
    org_id: "sb_75",
    encoded_h: "Y3NiXzc1",
    encoded_c: "Y3NiXzc1X2M=",
    encoded_q: "Y3NiXzc1X3E=",
    encoded_p: "Y3NiXzc1X3A=",
  },
  {
    school_code: "sb_76",
    name: "Bridgewater St.",
    division: 3,
    org_id: "sb_76",
    encoded_h: "Y3NiXzc2",
    encoded_c: "Y3NiXzc2X2M=",
    encoded_q: "Y3NiXzc2X3E=",
    encoded_p: "Y3NiXzc2X3A=",
  },
  {
    school_code: "sb_78",
    name: "Brockport",
    division: 3,
    org_id: "sb_78",
    encoded_h: "Y3NiXzc4",
    encoded_c: "Y3NiXzc4X2M=",
    encoded_q: "Y3NiXzc4X3E=",
    encoded_p: "Y3NiXzc4X3A=",
  },
  {
    school_code: "sb_79",
    name: "Brooklyn",
    division: 3,
    org_id: "sb_79",
    encoded_h: "Y3NiXzc5",
    encoded_c: "Y3NiXzc5X2M=",
    encoded_q: "Y3NiXzc5X3E=",
    encoded_p: "Y3NiXzc5X3A=",
  },
  {
    school_code: "sb_84",
    name: "Buena Vista",
    division: 3,
    org_id: "sb_84",
    encoded_h: "Y3NiXzg0",
    encoded_c: "Y3NiXzg0X2M=",
    encoded_q: "Y3NiXzg0X3E=",
    encoded_p: "Y3NiXzg0X3A=",
  },
  {
    school_code: "sb_85",
    name: "Buffalo St.",
    division: 3,
    org_id: "sb_85",
    encoded_h: "Y3NiXzg1",
    encoded_c: "Y3NiXzg1X2M=",
    encoded_q: "Y3NiXzg1X3E=",
    encoded_p: "Y3NiXzg1X3A=",
  },
  {
    school_code: "sb_88",
    name: "Cabrini",
    division: 3,
    org_id: "sb_88",
    encoded_h: "Y3NiXzg4",
    encoded_c: "Y3NiXzg4X2M=",
    encoded_q: "Y3NiXzg4X3E=",
    encoded_p: "Y3NiXzg4X3A=",
  },
  {
    school_code: "sb_1305",
    name: "Cairn",
    division: 3,
    org_id: "sb_1305",
    encoded_h: "Y3NiXzEzMDU=",
    encoded_c: "Y3NiXzEzMDVfYw==",
    encoded_q: "Y3NiXzEzMDVfcQ==",
    encoded_p: "Y3NiXzEzMDVfcA==",
  },
  {
    school_code: "sb_105",
    name: "Cal Lutheran",
    division: 3,
    org_id: "sb_105",
    encoded_h: "Y3NiXzEwNQ==",
    encoded_c: "Y3NiXzEwNV9j",
    encoded_q: "Y3NiXzEwNV9x",
    encoded_p: "Y3NiXzEwNV9w",
  },
  {
    school_code: "sb_114",
    name: "Calvin",
    division: 3,
    org_id: "sb_114",
    encoded_h: "Y3NiXzExNA==",
    encoded_c: "Y3NiXzExNF9j",
    encoded_q: "Y3NiXzExNF9x",
    encoded_p: "Y3NiXzExNF9w",
  },
  {
    school_code: "sb_117",
    name: "Capital",
    division: 3,
    org_id: "sb_117",
    encoded_h: "Y3NiXzExNw==",
    encoded_c: "Y3NiXzExN19j",
    encoded_q: "Y3NiXzExN19x",
    encoded_p: "Y3NiXzExN19w",
  },
  {
    school_code: "sb_118",
    name: "Carleton",
    division: 3,
    org_id: "sb_118",
    encoded_h: "Y3NiXzExOA==",
    encoded_c: "Y3NiXzExOF9j",
    encoded_q: "Y3NiXzExOF9x",
    encoded_p: "Y3NiXzExOF9w",
  },
  {
    school_code: "sb_120",
    name: "Carroll (WI)",
    division: 3,
    org_id: "sb_120",
    encoded_h: "Y3NiXzEyMA==",
    encoded_c: "Y3NiXzEyMF9j",
    encoded_q: "Y3NiXzEyMF9x",
    encoded_p: "Y3NiXzEyMF9w",
  },
  {
    school_code: "sb_121",
    name: "Carthage",
    division: 3,
    org_id: "sb_121",
    encoded_h: "Y3NiXzEyMQ==",
    encoded_c: "Y3NiXzEyMV9j",
    encoded_q: "Y3NiXzEyMV9x",
    encoded_p: "Y3NiXzEyMV9w",
  },
  {
    school_code: "sb_122",
    name: "Case",
    division: 3,
    org_id: "sb_122",
    encoded_h: "Y3NiXzEyMg==",
    encoded_c: "Y3NiXzEyMl9j",
    encoded_q: "Y3NiXzEyMl9x",
    encoded_p: "Y3NiXzEyMl9w",
  },
  {
    school_code: "sb_123",
    name: "Castleton",
    division: 3,
    org_id: "sb_123",
    encoded_h: "Y3NiXzEyMw==",
    encoded_c: "Y3NiXzEyM19j",
    encoded_q: "Y3NiXzEyM19x",
    encoded_p: "Y3NiXzEyM19w",
  },
  {
    school_code: "sb_124",
    name: "Catholic",
    division: 3,
    org_id: "sb_124",
    encoded_h: "Y3NiXzEyNA==",
    encoded_c: "Y3NiXzEyNF9j",
    encoded_q: "Y3NiXzEyNF9x",
    encoded_p: "Y3NiXzEyNF9w",
  },
  {
    school_code: "sb_23624",
    name: "Cazenovia",
    division: 3,
    org_id: "sb_23624",
    encoded_h: "Y3NiXzIzNjI0",
    encoded_c: "Y3NiXzIzNjI0X2M=",
    encoded_q: "Y3NiXzIzNjI0X3E=",
    encoded_p: "Y3NiXzIzNjI0X3A=",
  },
  {
    school_code: "sb_14795",
    name: "Cedar Crest",
    division: 3,
    org_id: "sb_14795",
    encoded_h: "Y3NiXzE0Nzk1",
    encoded_c: "Y3NiXzE0Nzk1X2M=",
    encoded_q: "Y3NiXzE0Nzk1X3E=",
    encoded_p: "Y3NiXzE0Nzk1X3A=",
  },
  {
    school_code: "sb_125",
    name: "Centenary (LA)",
    division: 3,
    org_id: "sb_125",
    encoded_h: "Y3NiXzEyNQ==",
    encoded_c: "Y3NiXzEyNV9j",
    encoded_q: "Y3NiXzEyNV9x",
    encoded_p: "Y3NiXzEyNV9w",
  },
  {
    school_code: "sb_12884",
    name: "Centenary (NJ)",
    division: 3,
    org_id: "sb_12884",
    encoded_h: "Y3NiXzEyODg0",
    encoded_c: "Y3NiXzEyODg0X2M=",
    encoded_q: "Y3NiXzEyODg0X3E=",
    encoded_p: "Y3NiXzEyODg0X3A=",
  },
  {
    school_code: "sb_126",
    name: "Central (IA)",
    division: 3,
    org_id: "sb_126",
    encoded_h: "Y3NiXzEyNg==",
    encoded_c: "Y3NiXzEyNl9j",
    encoded_q: "Y3NiXzEyNl9x",
    encoded_p: "Y3NiXzEyNl9w",
  },
  {
    school_code: "sb_132",
    name: "Centre",
    division: 3,
    org_id: "sb_132",
    encoded_h: "Y3NiXzEzMg==",
    encoded_c: "Y3NiXzEzMl9j",
    encoded_q: "Y3NiXzEzMl9x",
    encoded_p: "Y3NiXzEzMl9w",
  },
  {
    school_code: "sb_134",
    name: "Chapman",
    division: 3,
    org_id: "sb_134",
    encoded_h: "Y3NiXzEzNA==",
    encoded_c: "Y3NiXzEzNF9j",
    encoded_q: "Y3NiXzEzNF9x",
    encoded_p: "Y3NiXzEzNF9w",
  },
  {
    school_code: "sb_10972",
    name: "Chatham",
    division: 3,
    org_id: "sb_10972",
    encoded_h: "Y3NiXzEwOTcy",
    encoded_c: "Y3NiXzEwOTcyX2M=",
    encoded_q: "Y3NiXzEwOTcyX3E=",
    encoded_p: "Y3NiXzEwOTcyX3A=",
  },
  {
    school_code: "sb_137",
    name: "Chicago",
    division: 3,
    org_id: "sb_137",
    encoded_h: "Y3NiXzEzNw==",
    encoded_c: "Y3NiXzEzN19j",
    encoded_q: "Y3NiXzEzN19x",
    encoded_p: "Y3NiXzEzN19w",
  },
  {
    school_code: "sb_139",
    name: "Chris. Newport",
    division: 3,
    org_id: "sb_139",
    encoded_h: "Y3NiXzEzOQ==",
    encoded_c: "Y3NiXzEzOV9j",
    encoded_q: "Y3NiXzEzOV9x",
    encoded_p: "Y3NiXzEzOV9w",
  },
  {
    school_code: "sb_142",
    name: "Claremont-M-S",
    division: 3,
    org_id: "sb_142",
    encoded_h: "Y3NiXzE0Mg==",
    encoded_c: "Y3NiXzE0Ml9j",
    encoded_q: "Y3NiXzE0Ml9x",
    encoded_p: "Y3NiXzE0Ml9w",
  },
  {
    school_code: "sb_145",
    name: "Clark (MA)",
    division: 3,
    org_id: "sb_145",
    encoded_h: "Y3NiXzE0NQ==",
    encoded_c: "Y3NiXzE0NV9j",
    encoded_q: "Y3NiXzE0NV9x",
    encoded_p: "Y3NiXzE0NV9w",
  },
  {
    school_code: "sb_146",
    name: "Clarkson",
    division: 3,
    org_id: "sb_146",
    encoded_h: "Y3NiXzE0Ng==",
    encoded_c: "Y3NiXzE0Nl9j",
    encoded_q: "Y3NiXzE0Nl9x",
    encoded_p: "Y3NiXzE0Nl9w",
  },
  {
    school_code: "sb_722",
    name: "Coast Guard",
    division: 3,
    org_id: "sb_722",
    encoded_h: "Y3NiXzcyMg==",
    encoded_c: "Y3NiXzcyMl9j",
    encoded_q: "Y3NiXzcyMl9x",
    encoded_p: "Y3NiXzcyMl9w",
  },
  {
    school_code: "sb_30083",
    name: "Cobleskill St.",
    division: 3,
    org_id: "sb_30083",
    encoded_h: "Y3NiXzMwMDgz",
    encoded_c: "Y3NiXzMwMDgzX2M=",
    encoded_q: "Y3NiXzMwMDgzX3E=",
    encoded_p: "Y3NiXzMwMDgzX3A=",
  },
  {
    school_code: "sb_150",
    name: "Coe",
    division: 3,
    org_id: "sb_150",
    encoded_h: "Y3NiXzE1MA==",
    encoded_c: "Y3NiXzE1MF9j",
    encoded_q: "Y3NiXzE1MF9x",
    encoded_p: "Y3NiXzE1MF9w",
  },
  {
    school_code: "sb_151",
    name: "Colby",
    division: 3,
    org_id: "sb_151",
    encoded_h: "Y3NiXzE1MQ==",
    encoded_c: "Y3NiXzE1MV9j",
    encoded_q: "Y3NiXzE1MV9x",
    encoded_p: "Y3NiXzE1MV9w",
  },
  {
    school_code: "sb_154",
    name: "Colorado Col.",
    division: 3,
    org_id: "sb_154",
    encoded_h: "Y3NiXzE1NA==",
    encoded_c: "Y3NiXzE1NF9j",
    encoded_q: "Y3NiXzE1NF9x",
    encoded_p: "Y3NiXzE1NF9w",
  },
  {
    school_code: "sb_24400",
    name: "Concordia (TX)",
    division: 3,
    org_id: "sb_24400",
    encoded_h: "Y3NiXzI0NDAw",
    encoded_c: "Y3NiXzI0NDAwX2M=",
    encoded_q: "Y3NiXzI0NDAwX3E=",
    encoded_p: "Y3NiXzI0NDAwX3A=",
  },
  {
    school_code: "sb_160",
    name: "Concordia Chicago",
    division: 3,
    org_id: "sb_160",
    encoded_h: "Y3NiXzE2MA==",
    encoded_c: "Y3NiXzE2MF9j",
    encoded_q: "Y3NiXzE2MF9x",
    encoded_p: "Y3NiXzE2MF9w",
  },
  {
    school_code: "sb_1036",
    name: "Concordia Wisconsin",
    division: 3,
    org_id: "sb_1036",
    encoded_h: "Y3NiXzEwMzY=",
    encoded_c: "Y3NiXzEwMzZfYw==",
    encoded_q: "Y3NiXzEwMzZfcQ==",
    encoded_p: "Y3NiXzEwMzZfcA==",
  },
  {
    school_code: "sb_161",
    name: "Concordia-M'head",
    division: 3,
    org_id: "sb_161",
    encoded_h: "Y3NiXzE2MQ==",
    encoded_c: "Y3NiXzE2MV9j",
    encoded_q: "Y3NiXzE2MV9x",
    encoded_p: "Y3NiXzE2MV9w",
  },
  {
    school_code: "sb_166",
    name: "Cornell College",
    division: 3,
    org_id: "sb_166",
    encoded_h: "Y3NiXzE2Ng==",
    encoded_c: "Y3NiXzE2Nl9j",
    encoded_q: "Y3NiXzE2Nl9x",
    encoded_p: "Y3NiXzE2Nl9w",
  },
  {
    school_code: "sb_30111",
    name: "Covenant",
    division: 3,
    org_id: "sb_30111",
    encoded_h: "Y3NiXzMwMTEx",
    encoded_c: "Y3NiXzMwMTExX2M=",
    encoded_q: "Y3NiXzMwMTExX3E=",
    encoded_p: "Y3NiXzMwMTExX3A=",
  },
  {
    school_code: "sb_30035",
    name: "Crown (MN)",
    division: 3,
    org_id: "sb_30035",
    encoded_h: "Y3NiXzMwMDM1",
    encoded_c: "Y3NiXzMwMDM1X2M=",
    encoded_q: "Y3NiXzMwMDM1X3E=",
    encoded_p: "Y3NiXzMwMDM1X3A=",
  },
  {
    school_code: "sb_170",
    name: "Curry",
    division: 3,
    org_id: "sb_170",
    encoded_h: "Y3NiXzE3MA==",
    encoded_c: "Y3NiXzE3MF9j",
    encoded_q: "Y3NiXzE3MF9x",
    encoded_p: "Y3NiXzE3MF9w",
  },
  {
    school_code: "sb_1059",
    name: "D'Youville",
    division: 3,
    org_id: "sb_1059",
    encoded_h: "Y3NiXzEwNTk=",
    encoded_c: "Y3NiXzEwNTlfYw==",
    encoded_q: "Y3NiXzEwNTlfcQ==",
    encoded_p: "Y3NiXzEwNTlfcA==",
  },
  {
    school_code: "sb_1044",
    name: "Dallas",
    division: 3,
    org_id: "sb_1044",
    encoded_h: "Y3NiXzEwNDQ=",
    encoded_c: "Y3NiXzEwNDRfYw==",
    encoded_q: "Y3NiXzEwNDRfcQ==",
    encoded_p: "Y3NiXzEwNDRfcA==",
  },
  {
    school_code: "sb_2691",
    name: "Daniel Webster",
    division: 3,
    org_id: "sb_2691",
    encoded_h: "Y3NiXzI2OTE=",
    encoded_c: "Y3NiXzI2OTFfYw==",
    encoded_q: "Y3NiXzI2OTFfcQ==",
    encoded_p: "Y3NiXzI2OTFfcA==",
  },
  {
    school_code: "sb_30221",
    name: "Davenport",
    division: 2,
    org_id: "sb_30221",
    mascot: "Panthers",
    encoded_h: "Y3NiXzMwMjIx",
    encoded_c: "Y3NiXzMwMjIxX2M=",
    encoded_q: "Y3NiXzMwMjIxX3E=",
    encoded_p: "Y3NiXzMwMjIxX3A=",
  },
  {
    school_code: "sb_177",
    name: "DePauw",
    division: 3,
    org_id: "sb_177",
    encoded_h: "Y3NiXzE3Nw==",
    encoded_c: "Y3NiXzE3N19j",
    encoded_q: "Y3NiXzE3N19x",
    encoded_p: "Y3NiXzE3N19w",
  },
  {
    school_code: "sb_20",
    name: "DeSales",
    division: 3,
    org_id: "sb_20",
    encoded_h: "Y3NiXzIw",
    encoded_c: "Y3NiXzIwX2M=",
    encoded_q: "Y3NiXzIwX3E=",
    encoded_p: "Y3NiXzIwX3A=",
  },
  {
    school_code: "sb_1050",
    name: "Defiance",
    division: 3,
    org_id: "sb_1050",
    encoded_h: "Y3NiXzEwNTA=",
    encoded_c: "Y3NiXzEwNTBfYw==",
    encoded_q: "Y3NiXzEwNTBfcQ==",
    encoded_p: "Y3NiXzEwNTBfcA==",
  },
  {
    school_code: "sb_179",
    name: "Delaware Valley",
    division: 3,
    org_id: "sb_179",
    encoded_h: "Y3NiXzE3OQ==",
    encoded_c: "Y3NiXzE3OV9j",
    encoded_q: "Y3NiXzE3OV9x",
    encoded_p: "Y3NiXzE3OV9w",
  },
  {
    school_code: "sb_182",
    name: "Denison",
    division: 3,
    org_id: "sb_182",
    encoded_h: "Y3NiXzE4Mg==",
    encoded_c: "Y3NiXzE4Ml9j",
    encoded_q: "Y3NiXzE4Ml9x",
    encoded_p: "Y3NiXzE4Ml9w",
  },
  {
    school_code: "sb_185",
    name: "Dickinson",
    division: 3,
    org_id: "sb_185",
    encoded_h: "Y3NiXzE4NQ==",
    encoded_c: "Y3NiXzE4NV9j",
    encoded_q: "Y3NiXzE4NV9x",
    encoded_p: "Y3NiXzE4NV9w",
  },
  {
    school_code: "sb_1332",
    name: "Dominican (IL)",
    division: 3,
    org_id: "sb_1332",
    encoded_h: "Y3NiXzEzMzI=",
    encoded_c: "Y3NiXzEzMzJfYw==",
    encoded_q: "Y3NiXzEzMzJfcQ==",
    encoded_p: "Y3NiXzEzMzJfcA==",
  },
  {
    school_code: "sb_190",
    name: "Drew",
    division: 3,
    org_id: "sb_190",
    encoded_h: "Y3NiXzE5MA==",
    encoded_c: "Y3NiXzE5MF9j",
    encoded_q: "Y3NiXzE5MF9x",
    encoded_p: "Y3NiXzE5MF9w",
  },
  {
    school_code: "sb_192",
    name: "Dubuque",
    division: 3,
    org_id: "sb_192",
    encoded_h: "Y3NiXzE5Mg==",
    encoded_c: "Y3NiXzE5Ml9j",
    encoded_q: "Y3NiXzE5Ml9x",
    encoded_p: "Y3NiXzE5Ml9w",
  },
  {
    school_code: "sb_195",
    name: "Earlham",
    division: 3,
    org_id: "sb_195",
    mascot: "Quakers",
    encoded_h: "Y3NiXzE5NQ==",
    encoded_c: "Y3NiXzE5NV9j",
    encoded_q: "Y3NiXzE5NV9x",
    encoded_p: "Y3NiXzE5NV9w",
  },
  {
    school_code: "sb_1062",
    name: "East Tex. Baptist",
    division: 3,
    org_id: "sb_1062",
    encoded_h: "Y3NiXzEwNjI=",
    encoded_c: "Y3NiXzEwNjJfYw==",
    encoded_q: "Y3NiXzEwNjJfcQ==",
    encoded_p: "Y3NiXzEwNjJfcA==",
  },
  {
    school_code: "sb_203",
    name: "East. Mennonite",
    division: 3,
    org_id: "sb_203",
    encoded_h: "Y3NiXzIwMw==",
    encoded_c: "Y3NiXzIwM19j",
    encoded_q: "Y3NiXzIwM19x",
    encoded_p: "Y3NiXzIwM19w",
  },
  {
    school_code: "sb_8968",
    name: "Eastern",
    division: 3,
    org_id: "sb_8968",
    encoded_h: "Y3NiXzg5Njg=",
    encoded_c: "Y3NiXzg5NjhfYw==",
    encoded_q: "Y3NiXzg5NjhfcQ==",
    encoded_p: "Y3NiXzg5NjhfcA==",
  },
  {
    school_code: "sb_200",
    name: "Eastern Conn. St.",
    division: 3,
    org_id: "sb_200",
    encoded_h: "Y3NiXzIwMA==",
    encoded_c: "Y3NiXzIwMF9j",
    encoded_q: "Y3NiXzIwMF9x",
    encoded_p: "Y3NiXzIwMF9w",
  },
  {
    school_code: "sb_1064",
    name: "Eastern Nazarene",
    division: 3,
    org_id: "sb_1064",
    encoded_h: "Y3NiXzEwNjQ=",
    encoded_c: "Y3NiXzEwNjRfYw==",
    encoded_q: "Y3NiXzEwNjRfcQ==",
    encoded_p: "Y3NiXzEwNjRfcA==",
  },
  {
    school_code: "sb_1066",
    name: "Edgewood",
    division: 3,
    org_id: "sb_1066",
    encoded_h: "Y3NiXzEwNjY=",
    encoded_c: "Y3NiXzEwNjZfYw==",
    encoded_q: "Y3NiXzEwNjZfcQ==",
    encoded_p: "Y3NiXzEwNjZfcA==",
  },
  {
    school_code: "sb_211",
    name: "Elizabethtown",
    division: 3,
    org_id: "sb_211",
    encoded_h: "Y3NiXzIxMQ==",
    encoded_c: "Y3NiXzIxMV9j",
    encoded_q: "Y3NiXzIxMV9x",
    encoded_p: "Y3NiXzIxMV9w",
  },
  {
    school_code: "sb_212",
    name: "Elmhurst",
    division: 3,
    org_id: "sb_212",
    encoded_h: "Y3NiXzIxMg==",
    encoded_c: "Y3NiXzIxMl9j",
    encoded_q: "Y3NiXzIxMl9x",
    encoded_p: "Y3NiXzIxMl9w",
  },
  {
    school_code: "sb_213",
    name: "Elmira",
    division: 3,
    org_id: "sb_213",
    encoded_h: "Y3NiXzIxMw==",
    encoded_c: "Y3NiXzIxM19j",
    encoded_q: "Y3NiXzIxM19x",
    encoded_p: "Y3NiXzIxM19w",
  },
  {
    school_code: "sb_532",
    name: "Elms",
    division: 3,
    org_id: "sb_532",
    encoded_h: "Y3NiXzUzMg==",
    encoded_c: "Y3NiXzUzMl9j",
    encoded_q: "Y3NiXzUzMl9x",
    encoded_p: "Y3NiXzUzMl9w",
  },
  {
    school_code: "sb_214",
    name: "Emerson",
    division: 3,
    org_id: "sb_214",
    encoded_h: "Y3NiXzIxNA==",
    encoded_c: "Y3NiXzIxNF9j",
    encoded_q: "Y3NiXzIxNF9x",
    encoded_p: "Y3NiXzIxNF9w",
  },
  {
    school_code: "sb_215",
    name: "Emmanuel (MA)",
    division: 3,
    org_id: "sb_215",
    encoded_h: "Y3NiXzIxNQ==",
    encoded_c: "Y3NiXzIxNV9j",
    encoded_q: "Y3NiXzIxNV9x",
    encoded_p: "Y3NiXzIxNV9w",
  },
  {
    school_code: "sb_217",
    name: "Emory",
    division: 3,
    org_id: "sb_217",
    encoded_h: "Y3NiXzIxNw==",
    encoded_c: "Y3NiXzIxN19j",
    encoded_q: "Y3NiXzIxN19x",
    encoded_p: "Y3NiXzIxN19w",
  },
  {
    school_code: "sb_216",
    name: "Emory & Henry",
    division: 3,
    org_id: "sb_216",
    encoded_h: "Y3NiXzIxNg==",
    encoded_c: "Y3NiXzIxNl9j",
    encoded_q: "Y3NiXzIxNl9x",
    encoded_p: "Y3NiXzIxNl9w",
  },
  {
    school_code: "sb_8981",
    name: "Endicott",
    division: 3,
    org_id: "sb_8981",
    encoded_h: "Y3NiXzg5ODE=",
    encoded_c: "Y3NiXzg5ODFfYw==",
    encoded_q: "Y3NiXzg5ODFfcQ==",
    encoded_p: "Y3NiXzg5ODFfcA==",
  },
  {
    school_code: "sb_218",
    name: "Eureka",
    division: 3,
    org_id: "sb_218",
    encoded_h: "Y3NiXzIxOA==",
    encoded_c: "Y3NiXzIxOF9j",
    encoded_q: "Y3NiXzIxOF9x",
    encoded_p: "Y3NiXzIxOF9w",
  },
  {
    school_code: "sb_221",
    name: "FDU-Florham",
    division: 3,
    org_id: "sb_221",
    encoded_h: "Y3NiXzIyMQ==",
    encoded_c: "Y3NiXzIyMV9j",
    encoded_q: "Y3NiXzIyMV9x",
    encoded_p: "Y3NiXzIyMV9w",
  },
  {
    school_code: "sb_27892",
    name: "Farmingdale St.",
    division: 3,
    org_id: "sb_27892",
    encoded_h: "Y3NiXzI3ODky",
    encoded_c: "Y3NiXzI3ODkyX2M=",
    encoded_q: "Y3NiXzI3ODkyX3E=",
    encoded_p: "Y3NiXzI3ODkyX3A=",
  },
  {
    school_code: "sb_225",
    name: "Ferrum",
    division: 3,
    org_id: "sb_225",
    encoded_h: "Y3NiXzIyNQ==",
    encoded_c: "Y3NiXzIyNV9j",
    encoded_q: "Y3NiXzIyNV9x",
    encoded_p: "Y3NiXzIyNV9w",
  },
  {
    school_code: "sb_30032",
    name: "Finlandia",
    division: 3,
    org_id: "sb_30032",
    encoded_h: "Y3NiXzMwMDMy",
    encoded_c: "Y3NiXzMwMDMyX2M=",
    encoded_q: "Y3NiXzMwMDMyX3E=",
    encoded_p: "Y3NiXzMwMDMyX3A=",
  },
  {
    school_code: "sb_227",
    name: "Fitchburg St.",
    division: 3,
    org_id: "sb_227",
    encoded_h: "Y3NiXzIyNw==",
    encoded_c: "Y3NiXzIyN19j",
    encoded_q: "Y3NiXzIyN19x",
    encoded_p: "Y3NiXzIyN19w",
  },
  {
    school_code: "sb_11538",
    name: "Fontbonne",
    division: 3,
    org_id: "sb_11538",
    encoded_h: "Y3NiXzExNTM4",
    encoded_c: "Y3NiXzExNTM4X2M=",
    encoded_q: "Y3NiXzExNTM4X3E=",
    encoded_p: "Y3NiXzExNTM4X3A=",
  },
  {
    school_code: "sb_238",
    name: "Framingham St.",
    division: 3,
    org_id: "sb_238",
    encoded_h: "Y3NiXzIzOA==",
    encoded_c: "Y3NiXzIzOF9j",
    encoded_q: "Y3NiXzIzOF9x",
    encoded_p: "Y3NiXzIzOF9w",
  },
  {
    school_code: "sb_30069",
    name: "Franciscan",
    division: 3,
    org_id: "sb_30069",
    encoded_h: "Y3NiXzMwMDY5",
    encoded_c: "Y3NiXzMwMDY5X2M=",
    encoded_q: "Y3NiXzMwMDY5X3E=",
    encoded_p: "Y3NiXzMwMDY5X3A=",
  },
  {
    school_code: "sb_239",
    name: "Frank. & Marsh.",
    division: 3,
    org_id: "sb_239",
    encoded_h: "Y3NiXzIzOQ==",
    encoded_c: "Y3NiXzIzOV9j",
    encoded_q: "Y3NiXzIzOV9x",
    encoded_p: "Y3NiXzIzOV9w",
  },
  {
    school_code: "sb_2694",
    name: "Franklin",
    division: 3,
    org_id: "sb_2694",
    encoded_h: "Y3NiXzI2OTQ=",
    encoded_c: "Y3NiXzI2OTRfYw==",
    encoded_q: "Y3NiXzI2OTRfcQ==",
    encoded_p: "Y3NiXzI2OTRfcA==",
  },
  {
    school_code: "sb_242",
    name: "Fredonia",
    division: 3,
    org_id: "sb_242",
    encoded_h: "Y3NiXzI0Mg==",
    encoded_c: "Y3NiXzI0Ml9j",
    encoded_q: "Y3NiXzI0Ml9x",
    encoded_p: "Y3NiXzI0Ml9w",
  },
  {
    school_code: "sb_243",
    name: "Frostburg St.",
    division: 3,
    org_id: "sb_243",
    encoded_h: "Y3NiXzI0Mw==",
    encoded_c: "Y3NiXzI0M19j",
    encoded_q: "Y3NiXzI0M19x",
    encoded_p: "Y3NiXzI0M19w",
  },
  {
    school_code: "sb_245",
    name: "Gallaudet",
    division: 3,
    org_id: "sb_245",
    encoded_h: "Y3NiXzI0NQ==",
    encoded_c: "Y3NiXzI0NV9j",
    encoded_q: "Y3NiXzI0NV9x",
    encoded_p: "Y3NiXzI0NV9w",
  },
  {
    school_code: "sb_30084",
    name: "Geneva",
    division: 3,
    org_id: "sb_30084",
    encoded_h: "Y3NiXzMwMDg0",
    encoded_c: "Y3NiXzMwMDg0X2M=",
    encoded_q: "Y3NiXzMwMDg0X3E=",
    encoded_p: "Y3NiXzMwMDg0X3A=",
  },
  {
    school_code: "sb_1094",
    name: "George Fox",
    division: 3,
    org_id: "sb_1094",
    encoded_h: "Y3NiXzEwOTQ=",
    encoded_c: "Y3NiXzEwOTRfYw==",
    encoded_q: "Y3NiXzEwOTRfcQ==",
    encoded_p: "Y3NiXzEwOTRfcA==",
  },
  {
    school_code: "sb_258",
    name: "Gettysburg",
    division: 3,
    org_id: "sb_258",
    encoded_h: "Y3NiXzI1OA==",
    encoded_c: "Y3NiXzI1OF9j",
    encoded_q: "Y3NiXzI1OF9x",
    encoded_p: "Y3NiXzI1OF9w",
  },
  {
    school_code: "sb_1099",
    name: "Gordon",
    division: 3,
    org_id: "sb_1099",
    encoded_h: "Y3NiXzEwOTk=",
    encoded_c: "Y3NiXzEwOTlfYw==",
    encoded_q: "Y3NiXzEwOTlfcQ==",
    encoded_p: "Y3NiXzEwOTlfcA==",
  },
  {
    school_code: "sb_11062",
    name: "Green Mountain",
    division: 3,
    org_id: "sb_11062",
    encoded_h: "Y3NiXzExMDYy",
    encoded_c: "Y3NiXzExMDYyX2M=",
    encoded_q: "Y3NiXzExMDYyX3E=",
    encoded_p: "Y3NiXzExMDYyX3A=",
  },
  {
    school_code: "sb_263",
    name: "Greensboro",
    division: 3,
    org_id: "sb_263",
    encoded_h: "Y3NiXzI2Mw==",
    encoded_c: "Y3NiXzI2M19j",
    encoded_q: "Y3NiXzI2M19x",
    encoded_p: "Y3NiXzI2M19w",
  },
  {
    school_code: "sb_1111",
    name: "Greenville",
    division: 3,
    org_id: "sb_1111",
    encoded_h: "Y3NiXzExMTE=",
    encoded_c: "Y3NiXzExMTFfYw==",
    encoded_q: "Y3NiXzExMTFfcQ==",
    encoded_p: "Y3NiXzExMTFfcA==",
  },
  {
    school_code: "sb_264",
    name: "Grinnell",
    division: 3,
    org_id: "sb_264",
    encoded_h: "Y3NiXzI2NA==",
    encoded_c: "Y3NiXzI2NF9j",
    encoded_q: "Y3NiXzI2NF9x",
    encoded_p: "Y3NiXzI2NF9w",
  },
  {
    school_code: "sb_265",
    name: "Grove City",
    division: 3,
    org_id: "sb_265",
    encoded_h: "Y3NiXzI2NQ==",
    encoded_c: "Y3NiXzI2NV9j",
    encoded_q: "Y3NiXzI2NV9x",
    encoded_p: "Y3NiXzI2NV9w",
  },
  {
    school_code: "sb_1112",
    name: "Guilford",
    division: 3,
    org_id: "sb_1112",
    encoded_h: "Y3NiXzExMTI=",
    encoded_c: "Y3NiXzExMTJfYw==",
    encoded_q: "Y3NiXzExMTJfcQ==",
    encoded_p: "Y3NiXzExMTJfcA==",
  },
  {
    school_code: "sb_266",
    name: "Gust. Adolphus",
    division: 3,
    org_id: "sb_266",
    encoded_h: "Y3NiXzI2Ng==",
    encoded_c: "Y3NiXzI2Nl9j",
    encoded_q: "Y3NiXzI2Nl9x",
    encoded_p: "Y3NiXzI2Nl9w",
  },
  {
    school_code: "sb_12399",
    name: "Gwynedd Mercy",
    division: 3,
    org_id: "sb_12399",
    encoded_h: "Y3NiXzEyMzk5",
    encoded_c: "Y3NiXzEyMzk5X2M=",
    encoded_q: "Y3NiXzEyMzk5X3E=",
    encoded_p: "Y3NiXzEyMzk5X3A=",
  },
  {
    school_code: "sb_267",
    name: "Hamilton",
    division: 3,
    org_id: "sb_267",
    encoded_h: "Y3NiXzI2Nw==",
    encoded_c: "Y3NiXzI2N19j",
    encoded_q: "Y3NiXzI2N19x",
    encoded_p: "Y3NiXzI2N19w",
  },
  {
    school_code: "sb_268",
    name: "Hamline",
    division: 3,
    org_id: "sb_268",
    encoded_h: "Y3NiXzI2OA==",
    encoded_c: "Y3NiXzI2OF9j",
    encoded_q: "Y3NiXzI2OF9x",
    encoded_p: "Y3NiXzI2OF9w",
  },
  {
    school_code: "sb_1115",
    name: "Hanover",
    division: 3,
    org_id: "sb_1115",
    encoded_h: "Y3NiXzExMTU=",
    encoded_c: "Y3NiXzExMTVfYw==",
    encoded_q: "Y3NiXzExMTVfcQ==",
    encoded_p: "Y3NiXzExMTVfcA==",
  },
  {
    school_code: "sb_271",
    name: "Hardin-Simmons",
    division: 3,
    org_id: "sb_271",
    encoded_h: "Y3NiXzI3MQ==",
    encoded_c: "Y3NiXzI3MV9j",
    encoded_q: "Y3NiXzI3MV9x",
    encoded_p: "Y3NiXzI3MV9w",
  },
  {
    school_code: "sb_273",
    name: "Hartwick",
    division: 3,
    org_id: "sb_273",
    encoded_h: "Y3NiXzI3Mw==",
    encoded_c: "Y3NiXzI3M19j",
    encoded_q: "Y3NiXzI3M19x",
    encoded_p: "Y3NiXzI3M19w",
  },
  {
    school_code: "sb_276",
    name: "Haverford",
    division: 3,
    org_id: "sb_276",
    encoded_h: "Y3NiXzI3Ng==",
    encoded_c: "Y3NiXzI3Nl9j",
    encoded_q: "Y3NiXzI3Nl9x",
    encoded_p: "Y3NiXzI3Nl9w",
  },
  {
    school_code: "sb_278",
    name: "Heidelberg",
    division: 3,
    org_id: "sb_278",
    encoded_h: "Y3NiXzI3OA==",
    encoded_c: "Y3NiXzI3OF9j",
    encoded_q: "Y3NiXzI3OF9x",
    encoded_p: "Y3NiXzI3OF9w",
  },
  {
    school_code: "sb_1124",
    name: "Hendrix",
    division: 3,
    org_id: "sb_1124",
    encoded_h: "Y3NiXzExMjQ=",
    encoded_c: "Y3NiXzExMjRfYw==",
    encoded_q: "Y3NiXzExMjRfcQ==",
    encoded_p: "Y3NiXzExMjRfcA==",
  },
  {
    school_code: "sb_8398",
    name: "Hilbert",
    division: 3,
    org_id: "sb_8398",
    encoded_h: "Y3NiXzgzOTg=",
    encoded_c: "Y3NiXzgzOThfYw==",
    encoded_q: "Y3NiXzgzOThfcQ==",
    encoded_p: "Y3NiXzgzOThfcA==",
  },
  {
    school_code: "sb_281",
    name: "Hiram",
    division: 3,
    org_id: "sb_281",
    encoded_h: "Y3NiXzI4MQ==",
    encoded_c: "Y3NiXzI4MV9j",
    encoded_q: "Y3NiXzI4MV9x",
    encoded_p: "Y3NiXzI4MV9w",
  },
  {
    school_code: "sb_8404",
    name: "Hood",
    division: 3,
    org_id: "sb_8404",
    encoded_h: "Y3NiXzg0MDQ=",
    encoded_c: "Y3NiXzg0MDRfYw==",
    encoded_q: "Y3NiXzg0MDRfcQ==",
    encoded_p: "Y3NiXzg0MDRfcA==",
  },
  {
    school_code: "sb_286",
    name: "Hope",
    division: 3,
    org_id: "sb_286",
    encoded_h: "Y3NiXzI4Ng==",
    encoded_c: "Y3NiXzI4Nl9j",
    encoded_q: "Y3NiXzI4Nl9x",
    encoded_p: "Y3NiXzI4Nl9w",
  },
  {
    school_code: "sb_30160",
    name: "Houghton",
    division: 3,
    org_id: "sb_30160",
    encoded_h: "Y3NiXzMwMTYw",
    encoded_c: "Y3NiXzMwMTYwX2M=",
    encoded_q: "Y3NiXzMwMTYwX3E=",
    encoded_p: "Y3NiXzMwMTYwX3A=",
  },
  {
    school_code: "sb_2741",
    name: "Howard Payne",
    division: 3,
    org_id: "sb_2741",
    encoded_h: "Y3NiXzI3NDE=",
    encoded_c: "Y3NiXzI3NDFfYw==",
    encoded_q: "Y3NiXzI3NDFfcQ==",
    encoded_p: "Y3NiXzI3NDFfcA==",
  },
  {
    school_code: "sb_292",
    name: "Hunter",
    division: 3,
    org_id: "sb_292",
    encoded_h: "Y3NiXzI5Mg==",
    encoded_c: "Y3NiXzI5Ml9j",
    encoded_q: "Y3NiXzI5Ml9x",
    encoded_p: "Y3NiXzI5Ml9w",
  },
  {
    school_code: "sb_1130",
    name: "Huntingdon",
    division: 3,
    org_id: "sb_1130",
    encoded_h: "Y3NiXzExMzA=",
    encoded_c: "Y3NiXzExMzBfYw==",
    encoded_q: "Y3NiXzExMzBfcQ==",
    encoded_p: "Y3NiXzExMzBfcA==",
  },
  {
    school_code: "sb_1133",
    name: "Husson",
    division: 3,
    org_id: "sb_1133",
    encoded_h: "Y3NiXzExMzM=",
    encoded_c: "Y3NiXzExMzNfYw==",
    encoded_q: "Y3NiXzExMzNfcQ==",
    encoded_p: "Y3NiXzExMzNfcA==",
  },
  {
    school_code: "sb_300",
    name: "Ill. Wesleyan",
    division: 3,
    org_id: "sb_300",
    encoded_h: "Y3NiXzMwMA==",
    encoded_c: "Y3NiXzMwMF9j",
    encoded_q: "Y3NiXzMwMF9x",
    encoded_p: "Y3NiXzMwMF9w",
  },
  {
    school_code: "sb_297",
    name: "Illinois Col.",
    division: 3,
    org_id: "sb_297",
    encoded_h: "Y3NiXzI5Nw==",
    encoded_c: "Y3NiXzI5N19j",
    encoded_q: "Y3NiXzI5N19x",
    encoded_p: "Y3NiXzI5N19w",
  },
  {
    school_code: "sb_303",
    name: "Immaculata",
    division: 3,
    org_id: "sb_303",
    encoded_h: "Y3NiXzMwMw==",
    encoded_c: "Y3NiXzMwM19j",
    encoded_q: "Y3NiXzMwM19x",
    encoded_p: "Y3NiXzMwM19w",
  },
  {
    school_code: "sb_313",
    name: "Ithaca",
    division: 3,
    org_id: "sb_313",
    encoded_h: "Y3NiXzMxMw==",
    encoded_c: "Y3NiXzMxM19j",
    encoded_q: "Y3NiXzMxM19x",
    encoded_p: "Y3NiXzMxM19w",
  },
  {
    school_code: "sb_320",
    name: "John Carroll",
    division: 3,
    org_id: "sb_320",
    encoded_h: "Y3NiXzMyMA==",
    encoded_c: "Y3NiXzMyMF9j",
    encoded_q: "Y3NiXzMyMF9x",
    encoded_p: "Y3NiXzMyMF9w",
  },
  {
    school_code: "sb_321",
    name: "John Jay",
    division: 3,
    org_id: "sb_321",
    encoded_h: "Y3NiXzMyMQ==",
    encoded_c: "Y3NiXzMyMV9j",
    encoded_q: "Y3NiXzMyMV9x",
    encoded_p: "Y3NiXzMyMV9w",
  },
  {
    school_code: "sb_22626",
    name: "Johnson & Wales (RI)",
    division: 3,
    org_id: "sb_22626",
    encoded_h: "Y3NiXzIyNjI2",
    encoded_c: "Y3NiXzIyNjI2X2M=",
    encoded_q: "Y3NiXzIyNjI2X3E=",
    encoded_p: "Y3NiXzIyNjI2X3A=",
  },
  {
    school_code: "sb_324",
    name: "Johnson St.",
    division: 3,
    org_id: "sb_324",
    encoded_h: "Y3NiXzMyNA==",
    encoded_c: "Y3NiXzMyNF9j",
    encoded_q: "Y3NiXzMyNF9x",
    encoded_p: "Y3NiXzMyNF9w",
  },
  {
    school_code: "sb_325",
    name: "Juniata",
    division: 3,
    org_id: "sb_325",
    encoded_h: "Y3NiXzMyNQ==",
    encoded_c: "Y3NiXzMyNV9j",
    encoded_q: "Y3NiXzMyNV9x",
    encoded_p: "Y3NiXzMyNV9w",
  },
  {
    school_code: "sb_326",
    name: "Kalamazoo",
    division: 3,
    org_id: "sb_326",
    encoded_h: "Y3NiXzMyNg==",
    encoded_c: "Y3NiXzMyNl9j",
    encoded_q: "Y3NiXzMyNl9x",
    encoded_p: "Y3NiXzMyNl9w",
  },
  {
    school_code: "sb_329",
    name: "Kean",
    division: 3,
    org_id: "sb_329",
    encoded_h: "Y3NiXzMyOQ==",
    encoded_c: "Y3NiXzMyOV9j",
    encoded_q: "Y3NiXzMyOV9x",
    encoded_p: "Y3NiXzMyOV9w",
  },
  {
    school_code: "sb_330",
    name: "Keene St.",
    division: 3,
    org_id: "sb_330",
    encoded_h: "Y3NiXzMzMA==",
    encoded_c: "Y3NiXzMzMF9j",
    encoded_q: "Y3NiXzMzMF9x",
    encoded_p: "Y3NiXzMzMF9w",
  },
  {
    school_code: "sb_335",
    name: "Kenyon",
    division: 3,
    org_id: "sb_335",
    encoded_h: "Y3NiXzMzNQ==",
    encoded_c: "Y3NiXzMzNV9j",
    encoded_q: "Y3NiXzMzNV9x",
    encoded_p: "Y3NiXzMzNV9w",
  },
  {
    school_code: "sb_1159",
    name: "Keuka",
    division: 3,
    org_id: "sb_1159",
    encoded_h: "Y3NiXzExNTk=",
    encoded_c: "Y3NiXzExNTlfYw==",
    encoded_q: "Y3NiXzExNTlfcQ==",
    encoded_p: "Y3NiXzExNTlfcA==",
  },
  {
    school_code: "sb_30047",
    name: "Keystone",
    division: 3,
    org_id: "sb_30047",
    encoded_h: "Y3NiXzMwMDQ3",
    encoded_c: "Y3NiXzMwMDQ3X2M=",
    encoded_q: "Y3NiXzMwMDQ3X3E=",
    encoded_p: "Y3NiXzMwMDQ3X3A=",
  },
  {
    school_code: "sb_336",
    name: "King's (PA)",
    division: 3,
    org_id: "sb_336",
    encoded_h: "Y3NiXzMzNg==",
    encoded_c: "Y3NiXzMzNl9j",
    encoded_q: "Y3NiXzMzNl9x",
    encoded_p: "Y3NiXzMzNl9w",
  },
  {
    school_code: "sb_337",
    name: "Knox",
    division: 3,
    org_id: "sb_337",
    encoded_h: "Y3NiXzMzNw==",
    encoded_c: "Y3NiXzMzN19j",
    encoded_q: "Y3NiXzMzN19x",
    encoded_p: "Y3NiXzMzN19w",
  },
  {
    school_code: "sb_1163",
    name: "La Roche",
    division: 3,
    org_id: "sb_1163",
    encoded_h: "Y3NiXzExNjM=",
    encoded_c: "Y3NiXzExNjNfYw==",
    encoded_q: "Y3NiXzExNjNfcQ==",
    encoded_p: "Y3NiXzExNjNfcA==",
  },
  {
    school_code: "sb_341",
    name: "La Verne",
    division: 3,
    org_id: "sb_341",
    encoded_h: "Y3NiXzM0MQ==",
    encoded_c: "Y3NiXzM0MV9j",
    encoded_q: "Y3NiXzM0MV9x",
    encoded_p: "Y3NiXzM0MV9w",
  },
  {
    school_code: "sb_1162",
    name: "LaGrange",
    division: 3,
    org_id: "sb_1162",
    encoded_h: "Y3NiXzExNjI=",
    encoded_c: "Y3NiXzExNjJfYw==",
    encoded_q: "Y3NiXzExNjJfcQ==",
    encoded_p: "Y3NiXzExNjJfcA==",
  },
  {
    school_code: "sb_344",
    name: "Lake Forest",
    division: 3,
    org_id: "sb_344",
    encoded_h: "Y3NiXzM0NA==",
    encoded_c: "Y3NiXzM0NF9j",
    encoded_q: "Y3NiXzM0NF9x",
    encoded_p: "Y3NiXzM0NF9w",
  },
  {
    school_code: "sb_1164",
    name: "Lakeland",
    division: 3,
    org_id: "sb_1164",
    encoded_h: "Y3NiXzExNjQ=",
    encoded_c: "Y3NiXzExNjRfYw==",
    encoded_q: "Y3NiXzExNjRfcQ==",
    encoded_p: "Y3NiXzExNjRfcA==",
  },
  {
    school_code: "sb_30085",
    name: "Lancaster Bible",
    division: 3,
    org_id: "sb_30085",
    encoded_h: "Y3NiXzMwMDg1",
    encoded_c: "Y3NiXzMwMDg1X2M=",
    encoded_q: "Y3NiXzMwMDg1X3E=",
    encoded_p: "Y3NiXzMwMDg1X3A=",
  },
  {
    school_code: "sb_8486",
    name: "Lasell",
    division: 3,
    org_id: "sb_8486",
    encoded_h: "Y3NiXzg0ODY=",
    encoded_c: "Y3NiXzg0ODZfYw==",
    encoded_q: "Y3NiXzg0ODZfcQ==",
    encoded_p: "Y3NiXzg0ODZfcA==",
  },
  {
    school_code: "sb_348",
    name: "Lawrence",
    division: 3,
    org_id: "sb_348",
    encoded_h: "Y3NiXzM0OA==",
    encoded_c: "Y3NiXzM0OF9j",
    encoded_q: "Y3NiXzM0OF9x",
    encoded_p: "Y3NiXzM0OF9w",
  },
  {
    school_code: "sb_8490",
    name: "LeTourneau",
    division: 3,
    org_id: "sb_8490",
    encoded_h: "Y3NiXzg0OTA=",
    encoded_c: "Y3NiXzg0OTBfYw==",
    encoded_q: "Y3NiXzg0OTBfcQ==",
    encoded_p: "Y3NiXzg0OTBfcA==",
  },
  {
    school_code: "sb_351",
    name: "Lebanon Valley",
    division: 3,
    org_id: "sb_351",
    encoded_h: "Y3NiXzM1MQ==",
    encoded_c: "Y3NiXzM1MV9j",
    encoded_q: "Y3NiXzM1MV9x",
    encoded_p: "Y3NiXzM1MV9w",
  },
  {
    school_code: "sb_279",
    name: "Lehman",
    division: 3,
    org_id: "sb_279",
    encoded_h: "Y3NiXzI3OQ==",
    encoded_c: "Y3NiXzI3OV9j",
    encoded_q: "Y3NiXzI3OV9x",
    encoded_p: "Y3NiXzI3OV9w",
  },
  {
    school_code: "sb_24317",
    name: "Lesley",
    division: 3,
    org_id: "sb_24317",
    encoded_h: "Y3NiXzI0MzE3",
    encoded_c: "Y3NiXzI0MzE3X2M=",
    encoded_q: "Y3NiXzI0MzE3X3E=",
    encoded_p: "Y3NiXzI0MzE3X3A=",
  },
  {
    school_code: "sb_22235",
    name: "Lewis & Clark",
    division: 3,
    org_id: "sb_22235",
    encoded_h: "Y3NiXzIyMjM1",
    encoded_c: "Y3NiXzIyMjM1X2M=",
    encoded_q: "Y3NiXzIyMjM1X3E=",
    encoded_p: "Y3NiXzIyMjM1X3A=",
  },
  {
    school_code: "sb_30066",
    name: "Lincoln Christian",
    division: 3,
    org_id: "sb_30066",
    encoded_h: "Y3NiXzMwMDY2",
    encoded_c: "Y3NiXzMwMDY2X2M=",
    encoded_q: "Y3NiXzMwMDY2X3E=",
    encoded_p: "Y3NiXzMwMDY2X3A=",
  },
  {
    school_code: "sb_1179",
    name: "Linfield",
    division: 3,
    org_id: "sb_1179",
    encoded_h: "Y3NiXzExNzk=",
    encoded_c: "Y3NiXzExNzlfYw==",
    encoded_q: "Y3NiXzExNzlfcQ==",
    encoded_p: "Y3NiXzExNzlfcA==",
  },
  {
    school_code: "sb_364",
    name: "Loras",
    division: 3,
    org_id: "sb_364",
    encoded_h: "Y3NiXzM2NA==",
    encoded_c: "Y3NiXzM2NF9j",
    encoded_q: "Y3NiXzM2NF9x",
    encoded_p: "Y3NiXzM2NF9w",
  },
  {
    school_code: "sb_1182",
    name: "Louisiana Col.",
    division: 3,
    org_id: "sb_1182",
    encoded_h: "Y3NiXzExODI=",
    encoded_c: "Y3NiXzExODJfYw==",
    encoded_q: "Y3NiXzExODJfcQ==",
    encoded_p: "Y3NiXzExODJfcA==",
  },
  {
    school_code: "sb_372",
    name: "Luther",
    division: 3,
    org_id: "sb_372",
    encoded_h: "Y3NiXzM3Mg==",
    encoded_c: "Y3NiXzM3Ml9j",
    encoded_q: "Y3NiXzM3Ml9x",
    encoded_p: "Y3NiXzM3Ml9w",
  },
  {
    school_code: "sb_373",
    name: "Lycoming",
    division: 3,
    org_id: "sb_373",
    encoded_h: "Y3NiXzM3Mw==",
    encoded_c: "Y3NiXzM3M19j",
    encoded_q: "Y3NiXzM3M19x",
    encoded_p: "Y3NiXzM3M19w",
  },
  {
    school_code: "sb_374",
    name: "Lynchburg",
    division: 3,
    org_id: "sb_374",
    encoded_h: "Y3NiXzM3NA==",
    encoded_c: "Y3NiXzM3NF9j",
    encoded_q: "Y3NiXzM3NF9x",
    encoded_p: "Y3NiXzM3NF9w",
  },
  {
    school_code: "sb_30065",
    name: "Lyndon St.",
    division: 3,
    org_id: "sb_30065",
    encoded_h: "Y3NiXzMwMDY1",
    encoded_c: "Y3NiXzMwMDY1X2M=",
    encoded_q: "Y3NiXzMwMDY1X3E=",
    encoded_p: "Y3NiXzMwMDY1X3A=",
  },
  {
    school_code: "sb_22989",
    name: "Lyon",
    division: 3,
    org_id: "sb_22989",
    mascot: "Lyon",
    encoded_h: "Y3NiXzIyOTg5",
    encoded_c: "Y3NiXzIyOTg5X2M=",
    encoded_q: "Y3NiXzIyOTg5X3E=",
    encoded_p: "Y3NiXzIyOTg5X3A=",
  },
  {
    school_code: "sb_486",
    name: "MCLA",
    division: 3,
    org_id: "sb_486",
    encoded_h: "Y3NiXzQ4Ng==",
    encoded_c: "Y3NiXzQ4Nl9j",
    encoded_q: "Y3NiXzQ4Nl9x",
    encoded_p: "Y3NiXzQ4Nl9w",
  },
  {
    school_code: "sb_398",
    name: "MIT",
    division: 3,
    org_id: "sb_398",
    encoded_h: "Y3NiXzM5OA==",
    encoded_c: "Y3NiXzM5OF9j",
    encoded_q: "Y3NiXzM5OF9x",
    encoded_p: "Y3NiXzM5OF9w",
  },
  {
    school_code: "sb_20220",
    name: "MSOE",
    division: 3,
    org_id: "sb_20220",
    encoded_h: "Y3NiXzIwMjIw",
    encoded_c: "Y3NiXzIwMjIwX2M=",
    encoded_q: "Y3NiXzIwMjIwX3E=",
    encoded_p: "Y3NiXzIwMjIwX3A=",
  },
  {
    school_code: "sb_377",
    name: "MacMurray",
    division: 3,
    org_id: "sb_377",
    encoded_h: "Y3NiXzM3Nw==",
    encoded_c: "Y3NiXzM3N19j",
    encoded_q: "Y3NiXzM3N19x",
    encoded_p: "Y3NiXzM3N19w",
  },
  {
    school_code: "sb_376",
    name: "Macalester",
    division: 3,
    org_id: "sb_376",
    encoded_h: "Y3NiXzM3Ng==",
    encoded_c: "Y3NiXzM3Nl9j",
    encoded_q: "Y3NiXzM3Nl9x",
    encoded_p: "Y3NiXzM3Nl9w",
  },
  {
    school_code: "sb_378",
    name: "Maine Maritime",
    division: 3,
    org_id: "sb_378",
    encoded_h: "Y3NiXzM3OA==",
    encoded_c: "Y3NiXzM3OF9j",
    encoded_q: "Y3NiXzM3OF9x",
    encoded_p: "Y3NiXzM3OF9w",
  },
  {
    school_code: "sb_1192",
    name: "Manchester",
    division: 3,
    org_id: "sb_1192",
    encoded_h: "Y3NiXzExOTI=",
    encoded_c: "Y3NiXzExOTJfYw==",
    encoded_q: "Y3NiXzExOTJfcQ==",
    encoded_p: "Y3NiXzExOTJfcA==",
  },
  {
    school_code: "sb_382",
    name: "Manhattanville",
    division: 3,
    org_id: "sb_382",
    encoded_h: "Y3NiXzM4Mg==",
    encoded_c: "Y3NiXzM4Ml9j",
    encoded_q: "Y3NiXzM4Ml9x",
    encoded_p: "Y3NiXzM4Ml9w",
  },
  {
    school_code: "sb_1194",
    name: "Maranatha Baptist",
    division: 3,
    org_id: "sb_1194",
    encoded_h: "Y3NiXzExOTQ=",
    encoded_c: "Y3NiXzExOTRfYw==",
    encoded_q: "Y3NiXzExOTRfcQ==",
    encoded_p: "Y3NiXzExOTRfcA==",
  },
  {
    school_code: "sb_1196",
    name: "Marian (WI)",
    division: 3,
    org_id: "sb_1196",
    encoded_h: "Y3NiXzExOTY=",
    encoded_c: "Y3NiXzExOTZfYw==",
    encoded_q: "Y3NiXzExOTZfcQ==",
    encoded_p: "Y3NiXzExOTZfcA==",
  },
  {
    school_code: "sb_385",
    name: "Marietta",
    division: 3,
    org_id: "sb_385",
    encoded_h: "Y3NiXzM4NQ==",
    encoded_c: "Y3NiXzM4NV9j",
    encoded_q: "Y3NiXzM4NV9x",
    encoded_p: "Y3NiXzM4NV9w",
  },
  {
    school_code: "sb_8597",
    name: "Martin Luther",
    division: 3,
    org_id: "sb_8597",
    encoded_h: "Y3NiXzg1OTc=",
    encoded_c: "Y3NiXzg1OTdfYw==",
    encoded_q: "Y3NiXzg1OTdfcQ==",
    encoded_p: "Y3NiXzg1OTdfcA==",
  },
  {
    school_code: "389",
    name: "Mary Baldwin",
    division: 3,
    org_id: "389",
    encoded_h: "YzM4OQ==",
    encoded_c: "YzM4OV9j",
    encoded_q: "YzM4OV9x",
    encoded_p: "YzM4OV9w",
  },
  {
    school_code: "sb_389",
    name: "Mary Baldwin",
    division: 3,
    org_id: "sb_389",
    encoded_h: "Y3NiXzM4OQ==",
    encoded_c: "Y3NiXzM4OV9j",
    encoded_q: "Y3NiXzM4OV9x",
    encoded_p: "Y3NiXzM4OV9w",
  },
  {
    school_code: "sb_1203",
    name: "Mary Hardin-Baylor",
    division: 3,
    org_id: "sb_1203",
    encoded_h: "Y3NiXzEyMDM=",
    encoded_c: "Y3NiXzEyMDNfYw==",
    encoded_q: "Y3NiXzEyMDNfcQ==",
    encoded_p: "Y3NiXzEyMDNfcA==",
  },
  {
    school_code: "sb_390",
    name: "Mary Washington",
    division: 3,
    org_id: "sb_390",
    encoded_h: "Y3NiXzM5MA==",
    encoded_c: "Y3NiXzM5MF9j",
    encoded_q: "Y3NiXzM5MF9x",
    encoded_p: "Y3NiXzM5MF9w",
  },
  {
    school_code: "sb_396",
    name: "Maryville (TN)",
    division: 3,
    org_id: "sb_396",
    encoded_h: "Y3NiXzM5Ng==",
    encoded_c: "Y3NiXzM5Nl9j",
    encoded_q: "Y3NiXzM5Nl9x",
    encoded_p: "Y3NiXzM5Nl9w",
  },
  {
    school_code: "sb_397",
    name: "Marywood",
    division: 3,
    org_id: "sb_397",
    encoded_h: "Y3NiXzM5Nw==",
    encoded_c: "Y3NiXzM5N19j",
    encoded_q: "Y3NiXzM5N19x",
    encoded_p: "Y3NiXzM5N19w",
  },
  {
    school_code: "sb_399",
    name: "Mass. Maritime",
    division: 3,
    org_id: "sb_399",
    encoded_h: "Y3NiXzM5OQ==",
    encoded_c: "Y3NiXzM5OV9j",
    encoded_q: "Y3NiXzM5OV9x",
    encoded_p: "Y3NiXzM5OV9w",
  },
  {
    school_code: "sb_773",
    name: "McDaniel",
    division: 3,
    org_id: "sb_773",
    encoded_h: "Y3NiXzc3Mw==",
    encoded_c: "Y3NiXzc3M19j",
    encoded_q: "Y3NiXzc3M19x",
    encoded_p: "Y3NiXzc3M19w",
  },
  {
    school_code: "sb_379",
    name: "Me.-Farmington",
    division: 3,
    org_id: "sb_379",
    encoded_h: "Y3NiXzM3OQ==",
    encoded_c: "Y3NiXzM3OV9j",
    encoded_q: "Y3NiXzM3OV9x",
    encoded_p: "Y3NiXzM3OV9w",
  },
  {
    school_code: "sb_30040",
    name: "Me.-Presque Isle",
    division: 3,
    org_id: "sb_30040",
    encoded_h: "Y3NiXzMwMDQw",
    encoded_c: "Y3NiXzMwMDQwX2M=",
    encoded_q: "Y3NiXzMwMDQwX3E=",
    encoded_p: "Y3NiXzMwMDQwX3A=",
  },
  {
    school_code: "sb_23725",
    name: "Medaille",
    division: 3,
    org_id: "sb_23725",
    encoded_h: "Y3NiXzIzNzI1",
    encoded_c: "Y3NiXzIzNzI1X2M=",
    encoded_q: "Y3NiXzIzNzI1X3E=",
    encoded_p: "Y3NiXzIzNzI1X3A=",
  },
  {
    school_code: "sb_403",
    name: "Medgar Evers",
    division: 3,
    org_id: "sb_403",
    encoded_h: "Y3NiXzQwMw==",
    encoded_c: "Y3NiXzQwM19j",
    encoded_q: "Y3NiXzQwM19x",
    encoded_p: "Y3NiXzQwM19w",
  },
  {
    school_code: "sb_409",
    name: "Meredith",
    division: 3,
    org_id: "sb_409",
    encoded_h: "Y3NiXzQwOQ==",
    encoded_c: "Y3NiXzQwOV9j",
    encoded_q: "Y3NiXzQwOV9x",
    encoded_p: "Y3NiXzQwOV9w",
  },
  {
    school_code: "sb_411",
    name: "Messiah",
    division: 3,
    org_id: "sb_411",
    encoded_h: "Y3NiXzQxMQ==",
    encoded_c: "Y3NiXzQxMV9j",
    encoded_q: "Y3NiXzQxMV9x",
    encoded_p: "Y3NiXzQxMV9w",
  },
  {
    school_code: "sb_412",
    name: "Methodist",
    division: 3,
    org_id: "sb_412",
    encoded_h: "Y3NiXzQxMg==",
    encoded_c: "Y3NiXzQxMl9j",
    encoded_q: "Y3NiXzQxMl9x",
    encoded_p: "Y3NiXzQxMl9w",
  },
  {
    school_code: "sb_420",
    name: "Middlebury",
    division: 3,
    org_id: "sb_420",
    encoded_h: "Y3NiXzQyMA==",
    encoded_c: "Y3NiXzQyMF9j",
    encoded_q: "Y3NiXzQyMF9x",
    encoded_p: "Y3NiXzQyMF9w",
  },
  {
    school_code: "sb_424",
    name: "Millikin",
    division: 3,
    org_id: "sb_424",
    encoded_h: "Y3NiXzQyNA==",
    encoded_c: "Y3NiXzQyNF9j",
    encoded_q: "Y3NiXzQyNF9x",
    encoded_p: "Y3NiXzQyNF9w",
  },
  {
    school_code: "sb_426",
    name: "Millsaps",
    division: 3,
    org_id: "sb_426",
    encoded_h: "Y3NiXzQyNg==",
    encoded_c: "Y3NiXzQyNl9j",
    encoded_q: "Y3NiXzQyNl9x",
    encoded_p: "Y3NiXzQyNl9w",
  },
  {
    school_code: "sb_1226",
    name: "Minn.-Morris",
    division: 3,
    org_id: "sb_1226",
    encoded_h: "Y3NiXzEyMjY=",
    encoded_c: "Y3NiXzEyMjZfYw==",
    encoded_q: "Y3NiXzEyMjZfcQ==",
    encoded_p: "Y3NiXzEyMjZfcA==",
  },
  {
    school_code: "sb_16142",
    name: "Misericordia",
    division: 3,
    org_id: "sb_16142",
    encoded_h: "Y3NiXzE2MTQy",
    encoded_c: "Y3NiXzE2MTQyX2M=",
    encoded_q: "Y3NiXzE2MTQyX3E=",
    encoded_p: "Y3NiXzE2MTQyX3A=",
  },
  {
    school_code: "sb_429",
    name: "Mississippi Col.",
    division: 3,
    org_id: "sb_429",
    encoded_h: "Y3NiXzQyOQ==",
    encoded_c: "Y3NiXzQyOV9j",
    encoded_q: "Y3NiXzQyOV9x",
    encoded_p: "Y3NiXzQyOV9w",
  },
  {
    school_code: "sb_30042",
    name: "Mitchell",
    division: 3,
    org_id: "sb_30042",
    encoded_h: "Y3NiXzMwMDQy",
    encoded_c: "Y3NiXzMwMDQyX2M=",
    encoded_q: "Y3NiXzMwMDQyX3E=",
    encoded_p: "Y3NiXzMwMDQyX3A=",
  },
  {
    school_code: "sb_438",
    name: "Monmouth (IL)",
    division: 3,
    org_id: "sb_438",
    encoded_h: "Y3NiXzQzOA==",
    encoded_c: "Y3NiXzQzOF9j",
    encoded_q: "Y3NiXzQzOF9x",
    encoded_p: "Y3NiXzQzOF9w",
  },
  {
    school_code: "sb_442",
    name: "Montclair St.",
    division: 3,
    org_id: "sb_442",
    encoded_h: "Y3NiXzQ0Mg==",
    encoded_c: "Y3NiXzQ0Ml9j",
    encoded_q: "Y3NiXzQ0Ml9x",
    encoded_p: "Y3NiXzQ0Ml9w",
  },
  {
    school_code: "sb_443",
    name: "Moravian",
    division: 3,
    org_id: "sb_443",
    encoded_h: "Y3NiXzQ0Mw==",
    encoded_c: "Y3NiXzQ0M19j",
    encoded_q: "Y3NiXzQ0M19x",
    encoded_p: "Y3NiXzQ0M19w",
  },
  {
    school_code: "sb_30067",
    name: "Morrisville St.",
    division: 3,
    org_id: "sb_30067",
    encoded_h: "Y3NiXzMwMDY3",
    encoded_c: "Y3NiXzMwMDY3X2M=",
    encoded_q: "Y3NiXzMwMDY3X3E=",
    encoded_p: "Y3NiXzMwMDY3X3A=",
  },
  {
    school_code: "sb_30036",
    name: "Mount Aloysius",
    division: 3,
    org_id: "sb_30036",
    encoded_h: "Y3NiXzMwMDM2",
    encoded_c: "Y3NiXzMwMDM2X2M=",
    encoded_q: "Y3NiXzMwMDM2X3E=",
    encoded_p: "Y3NiXzMwMDM2X3A=",
  },
  {
    school_code: "sb_449",
    name: "Mount Holyoke",
    division: 3,
    org_id: "sb_449",
    encoded_h: "Y3NiXzQ0OQ==",
    encoded_c: "Y3NiXzQ0OV9j",
    encoded_q: "Y3NiXzQ0OV9x",
    encoded_p: "Y3NiXzQ0OV9w",
  },
  {
    school_code: "sb_27901",
    name: "Mount Ida",
    division: 3,
    org_id: "sb_27901",
    encoded_h: "Y3NiXzI3OTAx",
    encoded_c: "Y3NiXzI3OTAxX2M=",
    encoded_q: "Y3NiXzI3OTAxX3E=",
    encoded_p: "Y3NiXzI3OTAxX3A=",
  },
  {
    school_code: "sb_30033",
    name: "Mount Mary",
    division: 3,
    org_id: "sb_30033",
    encoded_h: "Y3NiXzMwMDMz",
    encoded_c: "Y3NiXzMwMDMzX2M=",
    encoded_q: "Y3NiXzMwMDMzX3E=",
    encoded_p: "Y3NiXzMwMDMzX3A=",
  },
  {
    school_code: "sb_452",
    name: "Mount Union",
    division: 3,
    org_id: "sb_452",
    encoded_h: "Y3NiXzQ1Mg==",
    encoded_c: "Y3NiXzQ1Ml9j",
    encoded_q: "Y3NiXzQ1Ml9x",
    encoded_p: "Y3NiXzQ1Ml9w",
  },
  {
    school_code: "sb_8567",
    name: "Mt. St. Joseph",
    division: 3,
    org_id: "sb_8567",
    encoded_h: "Y3NiXzg1Njc=",
    encoded_c: "Y3NiXzg1NjdfYw==",
    encoded_q: "Y3NiXzg1NjdfcQ==",
    encoded_p: "Y3NiXzg1NjdfcA==",
  },
  {
    school_code: "sb_1468",
    name: "Mt. St. Mary (NY)",
    division: 3,
    org_id: "sb_1468",
    encoded_h: "Y3NiXzE0Njg=",
    encoded_c: "Y3NiXzE0NjhfYw==",
    encoded_q: "Y3NiXzE0NjhfcQ==",
    encoded_p: "Y3NiXzE0NjhfcA==",
  },
  {
    school_code: "sb_451",
    name: "Mt. St. Vincent",
    division: 3,
    org_id: "sb_451",
    encoded_h: "Y3NiXzQ1MQ==",
    encoded_c: "Y3NiXzQ1MV9j",
    encoded_q: "Y3NiXzQ1MV9x",
    encoded_p: "Y3NiXzQ1MV9w",
  },
  {
    school_code: "sb_453",
    name: "Muhlenberg",
    division: 3,
    org_id: "sb_453",
    encoded_h: "Y3NiXzQ1Mw==",
    encoded_c: "Y3NiXzQ1M19j",
    encoded_q: "Y3NiXzQ1M19x",
    encoded_p: "Y3NiXzQ1M19w",
  },
  {
    school_code: "sb_455",
    name: "Muskingum",
    division: 3,
    org_id: "sb_455",
    encoded_h: "Y3NiXzQ1NQ==",
    encoded_c: "Y3NiXzQ1NV9j",
    encoded_q: "Y3NiXzQ1NV9x",
    encoded_p: "Y3NiXzQ1NV9w",
  },
  {
    school_code: "sb_431",
    name: "MUW",
    division: 3,
    org_id: "sb_431",
    encoded_h: "Y3NiXzQzMQ==",
    encoded_c: "Y3NiXzQzMV9j",
    encoded_q: "Y3NiXzQzMV9x",
    encoded_p: "Y3NiXzQzMV9w",
  },
  {
    school_code: "sb_491",
    name: "N.C. Wesleyan",
    division: 3,
    org_id: "sb_491",
    encoded_h: "Y3NiXzQ5MQ==",
    encoded_c: "Y3NiXzQ5MV9j",
    encoded_q: "Y3NiXzQ5MV9x",
    encoded_p: "Y3NiXzQ5MV9w",
  },
  {
    school_code: "sb_461",
    name: "Nazareth",
    division: 3,
    org_id: "sb_461",
    encoded_h: "Y3NiXzQ2MQ==",
    encoded_c: "Y3NiXzQ2MV9j",
    encoded_q: "Y3NiXzQ2MV9x",
    encoded_p: "Y3NiXzQ2MV9w",
  },
  {
    school_code: "sb_462",
    name: "Neb. Wesleyan",
    division: 3,
    org_id: "sb_462",
    encoded_h: "Y3NiXzQ2Mg==",
    encoded_c: "Y3NiXzQ2Ml9j",
    encoded_q: "Y3NiXzQ2Ml9x",
    encoded_p: "Y3NiXzQ2Ml9w",
  },
  {
    school_code: "sb_1254",
    name: "Neumann",
    division: 3,
    org_id: "sb_1254",
    encoded_h: "Y3NiXzEyNTQ=",
    encoded_c: "Y3NiXzEyNTRfYw==",
    encoded_q: "Y3NiXzEyNTRfcQ==",
    encoded_p: "Y3NiXzEyNTRfcA==",
  },
  {
    school_code: "sb_467",
    name: "New England Col.",
    division: 3,
    org_id: "sb_467",
    encoded_h: "Y3NiXzQ2Nw==",
    encoded_c: "Y3NiXzQ2N19j",
    encoded_q: "Y3NiXzQ2N19x",
    encoded_p: "Y3NiXzQ2N19w",
  },
  {
    school_code: "sb_319",
    name: "New Jersey City",
    division: 3,
    org_id: "sb_319",
    encoded_h: "Y3NiXzMxOQ==",
    encoded_c: "Y3NiXzMxOV9j",
    encoded_q: "Y3NiXzMxOV9x",
    encoded_p: "Y3NiXzMxOV9w",
  },
  {
    school_code: "sb_476",
    name: "New Rochelle",
    division: 3,
    org_id: "sb_476",
    encoded_h: "Y3NiXzQ3Ng==",
    encoded_c: "Y3NiXzQ3Nl9j",
    encoded_q: "Y3NiXzQ3Nl9x",
    encoded_p: "Y3NiXzQ3Nl9w",
  },
  {
    school_code: "sb_21772",
    name: "Newbury",
    division: 3,
    org_id: "sb_21772",
    encoded_h: "Y3NiXzIxNzcy",
    encoded_c: "Y3NiXzIxNzcyX2M=",
    encoded_q: "Y3NiXzIxNzcyX3E=",
    encoded_p: "Y3NiXzIxNzcyX3A=",
  },
  {
    school_code: "sb_484",
    name: "Nichols",
    division: 3,
    org_id: "sb_484",
    encoded_h: "Y3NiXzQ4NA==",
    encoded_c: "Y3NiXzQ4NF9j",
    encoded_q: "Y3NiXzQ4NF9x",
    encoded_p: "Y3NiXzQ4NF9w",
  },
  {
    school_code: "sb_492",
    name: "North Central (IL)",
    division: 3,
    org_id: "sb_492",
    encoded_h: "Y3NiXzQ5Mg==",
    encoded_c: "Y3NiXzQ5Ml9j",
    encoded_q: "Y3NiXzQ5Ml9x",
    encoded_p: "Y3NiXzQ5Ml9w",
  },
  {
    school_code: "sb_30039",
    name: "North Central (MN)",
    division: 3,
    org_id: "sb_30039",
    encoded_h: "Y3NiXzMwMDM5",
    encoded_c: "Y3NiXzMwMDM5X2M=",
    encoded_q: "Y3NiXzMwMDM5X3E=",
    encoded_p: "Y3NiXzMwMDM5X3A=",
  },
  {
    school_code: "sb_496",
    name: "North Park",
    division: 3,
    org_id: "sb_496",
    encoded_h: "Y3NiXzQ5Ng==",
    encoded_c: "Y3NiXzQ5Nl9j",
    encoded_q: "Y3NiXzQ5Nl9x",
    encoded_p: "Y3NiXzQ5Nl9w",
  },
  {
    school_code: "sb_1267",
    name: "Northland",
    division: 3,
    org_id: "sb_1267",
    encoded_h: "Y3NiXzEyNjc=",
    encoded_c: "Y3NiXzEyNjdfYw==",
    encoded_q: "Y3NiXzEyNjdfcQ==",
    encoded_p: "Y3NiXzEyNjdfcA==",
  },
  {
    school_code: "sb_30031",
    name: "Northwestern-St. Paul",
    division: 3,
    org_id: "sb_30031",
    encoded_h: "Y3NiXzMwMDMx",
    encoded_c: "Y3NiXzMwMDMxX2M=",
    encoded_q: "Y3NiXzMwMDMxX3E=",
    encoded_p: "Y3NiXzMwMDMxX3A=",
  },
  {
    school_code: "sb_511",
    name: "Norwich",
    division: 3,
    org_id: "sb_511",
    encoded_h: "Y3NiXzUxMQ==",
    encoded_c: "Y3NiXzUxMV9j",
    encoded_q: "Y3NiXzUxMV9x",
    encoded_p: "Y3NiXzUxMV9w",
  },
  {
    school_code: "sb_8600",
    name: "Notre Dame (MD)",
    division: 3,
    org_id: "sb_8600",
    encoded_h: "Y3NiXzg2MDA=",
    encoded_c: "Y3NiXzg2MDBfYw==",
    encoded_q: "Y3NiXzg2MDBfcQ==",
    encoded_p: "Y3NiXzg2MDBfcA==",
  },
  {
    school_code: "sb_515",
    name: "Oberlin",
    division: 3,
    org_id: "sb_515",
    encoded_h: "Y3NiXzUxNQ==",
    encoded_c: "Y3NiXzUxNV9j",
    encoded_q: "Y3NiXzUxNV9x",
    encoded_p: "Y3NiXzUxNV9w",
  },
  {
    school_code: "sb_516",
    name: "Occidental",
    division: 3,
    org_id: "sb_516",
    encoded_h: "Y3NiXzUxNg==",
    encoded_c: "Y3NiXzUxNl9j",
    encoded_q: "Y3NiXzUxNl9x",
    encoded_p: "Y3NiXzUxNl9w",
  },
  {
    school_code: "sb_517",
    name: "Ohio Northern",
    division: 3,
    org_id: "sb_517",
    encoded_h: "Y3NiXzUxNw==",
    encoded_c: "Y3NiXzUxN19j",
    encoded_q: "Y3NiXzUxN19x",
    encoded_p: "Y3NiXzUxN19w",
  },
  {
    school_code: "sb_520",
    name: "Ohio Wesleyan",
    division: 3,
    org_id: "sb_520",
    encoded_h: "Y3NiXzUyMA==",
    encoded_c: "Y3NiXzUyMF9j",
    encoded_q: "Y3NiXzUyMF9x",
    encoded_p: "Y3NiXzUyMF9w",
  },
  {
    school_code: "sb_524",
    name: "Old Westbury",
    division: 3,
    org_id: "sb_524",
    encoded_h: "Y3NiXzUyNA==",
    encoded_c: "Y3NiXzUyNF9j",
    encoded_q: "Y3NiXzUyNF9x",
    encoded_p: "Y3NiXzUyNF9w",
  },
  {
    school_code: "sb_525",
    name: "Olivet",
    division: 3,
    org_id: "sb_525",
    encoded_h: "Y3NiXzUyNQ==",
    encoded_c: "Y3NiXzUyNV9j",
    encoded_q: "Y3NiXzUyNV9x",
    encoded_p: "Y3NiXzUyNV9w",
  },
  {
    school_code: "sb_530",
    name: "Oswego St.",
    division: 3,
    org_id: "sb_530",
    encoded_h: "Y3NiXzUzMA==",
    encoded_c: "Y3NiXzUzMF9j",
    encoded_q: "Y3NiXzUzMF9x",
    encoded_p: "Y3NiXzUzMF9w",
  },
  {
    school_code: "sb_531",
    name: "Otterbein",
    division: 3,
    org_id: "sb_531",
    encoded_h: "Y3NiXzUzMQ==",
    encoded_c: "Y3NiXzUzMV9j",
    encoded_q: "Y3NiXzUzMV9x",
    encoded_p: "Y3NiXzUzMV9w",
  },
  {
    school_code: "sb_23008",
    name: "Ozarks (AR)",
    division: 3,
    org_id: "sb_23008",
    encoded_h: "Y3NiXzIzMDA4",
    encoded_c: "Y3NiXzIzMDA4X2M=",
    encoded_q: "Y3NiXzIzMDA4X3E=",
    encoded_p: "Y3NiXzIzMDA4X3A=",
  },
  {
    school_code: "sb_2751",
    name: "Pacific (OR)",
    division: 3,
    org_id: "sb_2751",
    encoded_h: "Y3NiXzI3NTE=",
    encoded_c: "Y3NiXzI3NTFfYw==",
    encoded_q: "Y3NiXzI3NTFfcQ==",
    encoded_p: "Y3NiXzI3NTFfcA==",
  },
  {
    school_code: "sb_1297",
    name: "Pacific Lutheran",
    division: 3,
    org_id: "sb_1297",
    encoded_h: "Y3NiXzEyOTc=",
    encoded_c: "Y3NiXzEyOTdfYw==",
    encoded_q: "Y3NiXzEyOTdfcQ==",
    encoded_p: "Y3NiXzEyOTdfcA==",
  },
  {
    school_code: "sb_30190",
    name: "Penn College",
    division: 3,
    org_id: "sb_30190",
    mascot: "Wildcats",
    encoded_h: "Y3NiXzMwMTkw",
    encoded_c: "Y3NiXzMwMTkwX2M=",
    encoded_q: "Y3NiXzMwMTkwX3E=",
    encoded_p: "Y3NiXzMwMTkwX3A=",
  },
  {
    school_code: "sb_30081",
    name: "Penn St. Harrisburg",
    division: 3,
    org_id: "sb_30081",
    encoded_h: "Y3NiXzMwMDgx",
    encoded_c: "Y3NiXzMwMDgxX2M=",
    encoded_q: "Y3NiXzMwMDgxX3E=",
    encoded_p: "Y3NiXzMwMDgxX3A=",
  },
  {
    school_code: "sb_30114",
    name: "Penn St.-Abington",
    division: 3,
    org_id: "sb_30114",
    encoded_h: "Y3NiXzMwMTE0",
    encoded_c: "Y3NiXzMwMTE0X2M=",
    encoded_q: "Y3NiXzMwMTE0X3E=",
    encoded_p: "Y3NiXzMwMTE0X3A=",
  },
  {
    school_code: "sb_25719",
    name: "Penn St.-Altoona",
    division: 3,
    org_id: "sb_25719",
    encoded_h: "Y3NiXzI1NzE5",
    encoded_c: "Y3NiXzI1NzE5X2M=",
    encoded_q: "Y3NiXzI1NzE5X3E=",
    encoded_p: "Y3NiXzI1NzE5X3A=",
  },
  {
    school_code: "sb_538",
    name: "Penn St.-Behrend",
    division: 3,
    org_id: "sb_538",
    encoded_h: "Y3NiXzUzOA==",
    encoded_c: "Y3NiXzUzOF9j",
    encoded_q: "Y3NiXzUzOF9x",
    encoded_p: "Y3NiXzUzOF9w",
  },
  {
    school_code: "sb_30044",
    name: "Penn St.-Berks",
    division: 3,
    org_id: "sb_30044",
    encoded_h: "Y3NiXzMwMDQ0",
    encoded_c: "Y3NiXzMwMDQ0X2M=",
    encoded_q: "Y3NiXzMwMDQ0X3E=",
    encoded_p: "Y3NiXzMwMDQ0X3A=",
  },
  {
    school_code: "sb_21830",
    name: "Piedmont",
    division: 3,
    org_id: "sb_21830",
    encoded_h: "Y3NiXzIxODMw",
    encoded_c: "Y3NiXzIxODMwX2M=",
    encoded_q: "Y3NiXzIxODMwX3E=",
    encoded_p: "Y3NiXzIxODMwX3A=",
  },
  {
    school_code: "sb_544",
    name: "Pine Manor",
    division: 3,
    org_id: "sb_544",
    encoded_h: "Y3NiXzU0NA==",
    encoded_c: "Y3NiXzU0NF9j",
    encoded_q: "Y3NiXzU0NF9x",
    encoded_p: "Y3NiXzU0NF9w",
  },
  {
    school_code: "sb_1315",
    name: "Pitt.-Bradford",
    division: 3,
    org_id: "sb_1315",
    encoded_h: "Y3NiXzEzMTU=",
    encoded_c: "Y3NiXzEzMTVfYw==",
    encoded_q: "Y3NiXzEzMTVfcQ==",
    encoded_p: "Y3NiXzEzMTVfcA==",
  },
  {
    school_code: "sb_11839",
    name: "Pitt.-Greensburg",
    division: 3,
    org_id: "sb_11839",
    encoded_h: "Y3NiXzExODM5",
    encoded_c: "Y3NiXzExODM5X2M=",
    encoded_q: "Y3NiXzExODM5X3E=",
    encoded_p: "Y3NiXzExODM5X3A=",
  },
  {
    school_code: "sb_547",
    name: "Plattsburgh St.",
    division: 3,
    org_id: "sb_547",
    encoded_h: "Y3NiXzU0Nw==",
    encoded_c: "Y3NiXzU0N19j",
    encoded_q: "Y3NiXzU0N19x",
    encoded_p: "Y3NiXzU0N19w",
  },
  {
    school_code: "sb_548",
    name: "Plymouth St.",
    division: 3,
    org_id: "sb_548",
    encoded_h: "Y3NiXzU0OA==",
    encoded_c: "Y3NiXzU0OF9j",
    encoded_q: "Y3NiXzU0OF9x",
    encoded_p: "Y3NiXzU0OF9w",
  },
  {
    school_code: "sb_481",
    name: "Polytechnic (NY)",
    division: 3,
    org_id: "sb_481",
    encoded_h: "Y3NiXzQ4MQ==",
    encoded_c: "Y3NiXzQ4MV9j",
    encoded_q: "Y3NiXzQ4MV9x",
    encoded_p: "Y3NiXzQ4MV9w",
  },
  {
    school_code: "sb_549",
    name: "Pomona-Pitzer",
    division: 3,
    org_id: "sb_549",
    encoded_h: "Y3NiXzU0OQ==",
    encoded_c: "Y3NiXzU0OV9j",
    encoded_q: "Y3NiXzU0OV9x",
    encoded_p: "Y3NiXzU0OV9w",
  },
  {
    school_code: "sb_30034",
    name: "Presentation",
    division: 3,
    org_id: "sb_30034",
    encoded_h: "Y3NiXzMwMDM0",
    encoded_c: "Y3NiXzMwMDM0X2M=",
    encoded_q: "Y3NiXzMwMDM0X3E=",
    encoded_p: "Y3NiXzMwMDM0X3A=",
  },
  {
    school_code: "sb_555",
    name: "Principia",
    division: 3,
    org_id: "sb_555",
    encoded_h: "Y3NiXzU1NQ==",
    encoded_c: "Y3NiXzU1NV9j",
    encoded_q: "Y3NiXzU1NV9x",
    encoded_p: "Y3NiXzU1NV9w",
  },
  {
    school_code: "sb_557",
    name: "Puget Sound",
    division: 3,
    org_id: "sb_557",
    encoded_h: "Y3NiXzU1Nw==",
    encoded_c: "Y3NiXzU1N19j",
    encoded_q: "Y3NiXzU1N19x",
    encoded_p: "Y3NiXzU1N19w",
  },
  {
    school_code: "sb_30029",
    name: "Purchase",
    division: 3,
    org_id: "sb_30029",
    encoded_h: "Y3NiXzMwMDI5",
    encoded_c: "Y3NiXzMwMDI5X2M=",
    encoded_q: "Y3NiXzMwMDI5X3E=",
    encoded_p: "Y3NiXzMwMDI5X3A=",
  },
  {
    school_code: "sb_30222",
    name: "Purdue Northwest",
    division: 2,
    org_id: "sb_30222",
    mascot: "Pride",
    encoded_h: "Y3NiXzMwMjIy",
    encoded_c: "Y3NiXzMwMjIyX2M=",
    encoded_q: "Y3NiXzMwMjIyX3E=",
    encoded_p: "Y3NiXzMwMjIyX3A=",
  },
  {
    school_code: "sb_564",
    name: "Ramapo",
    division: 3,
    org_id: "sb_564",
    encoded_h: "Y3NiXzU2NA==",
    encoded_c: "Y3NiXzU2NF9j",
    encoded_q: "Y3NiXzU2NF9x",
    encoded_p: "Y3NiXzU2NF9w",
  },
  {
    school_code: "sb_566",
    name: "Randolph",
    division: 3,
    org_id: "sb_566",
    encoded_h: "Y3NiXzU2Ng==",
    encoded_c: "Y3NiXzU2Nl9j",
    encoded_q: "Y3NiXzU2Nl9x",
    encoded_p: "Y3NiXzU2Nl9w",
  },
  {
    school_code: "sb_565",
    name: "Randolph-Macon",
    division: 3,
    org_id: "sb_565",
    encoded_h: "Y3NiXzU2NQ==",
    encoded_c: "Y3NiXzU2NV9j",
    encoded_q: "Y3NiXzU2NV9x",
    encoded_p: "Y3NiXzU2NV9w",
  },
  {
    school_code: "sb_567",
    name: "Redlands",
    division: 3,
    org_id: "sb_567",
    encoded_h: "Y3NiXzU2Nw==",
    encoded_c: "Y3NiXzU2N19j",
    encoded_q: "Y3NiXzU2N19x",
    encoded_p: "Y3NiXzU2N19w",
  },
  {
    school_code: "sb_569",
    name: "Regis (MA)",
    division: 3,
    org_id: "sb_569",
    encoded_h: "Y3NiXzU2OQ==",
    encoded_c: "Y3NiXzU2OV9j",
    encoded_q: "Y3NiXzU2OV9x",
    encoded_p: "Y3NiXzU2OV9w",
  },
  {
    school_code: "sb_570",
    name: "Rensselaer",
    division: 3,
    org_id: "sb_570",
    encoded_h: "Y3NiXzU3MA==",
    encoded_c: "Y3NiXzU3MF9j",
    encoded_q: "Y3NiXzU3MF9x",
    encoded_p: "Y3NiXzU3MF9w",
  },
  {
    school_code: "sb_571",
    name: "Rhode Island Col.",
    division: 3,
    org_id: "sb_571",
    encoded_h: "Y3NiXzU3MQ==",
    encoded_c: "Y3NiXzU3MV9j",
    encoded_q: "Y3NiXzU3MV9x",
    encoded_p: "Y3NiXzU3MV9w",
  },
  {
    school_code: "sb_573",
    name: "Rhodes",
    division: 3,
    org_id: "sb_573",
    encoded_h: "Y3NiXzU3Mw==",
    encoded_c: "Y3NiXzU3M19j",
    encoded_q: "Y3NiXzU3M19x",
    encoded_p: "Y3NiXzU3M19w",
  },
  {
    school_code: "sb_681",
    name: "Richard Stockton",
    division: 3,
    org_id: "sb_681",
    encoded_h: "Y3NiXzY4MQ==",
    encoded_c: "Y3NiXzY4MV9j",
    encoded_q: "Y3NiXzY4MV9x",
    encoded_p: "Y3NiXzY4MV9w",
  },
  {
    school_code: "sb_577",
    name: "Ripon",
    division: 3,
    org_id: "sb_577",
    encoded_h: "Y3NiXzU3Nw==",
    encoded_c: "Y3NiXzU3N19j",
    encoded_q: "Y3NiXzU3N19x",
    encoded_p: "Y3NiXzU3N19w",
  },
  {
    school_code: "sb_18422",
    name: "Rivier",
    division: 3,
    org_id: "sb_18422",
    encoded_h: "Y3NiXzE4NDIy",
    encoded_c: "Y3NiXzE4NDIyX2M=",
    encoded_q: "Y3NiXzE4NDIyX3E=",
    encoded_p: "Y3NiXzE4NDIyX3A=",
  },
  {
    school_code: "sb_578",
    name: "Roanoke",
    division: 3,
    org_id: "sb_578",
    encoded_h: "Y3NiXzU3OA==",
    encoded_c: "Y3NiXzU3OF9j",
    encoded_q: "Y3NiXzU3OF9x",
    encoded_p: "Y3NiXzU3OF9w",
  },
  {
    school_code: "sb_581",
    name: "Rochester (NY)",
    division: 3,
    org_id: "sb_581",
    encoded_h: "Y3NiXzU4MQ==",
    encoded_c: "Y3NiXzU4MV9j",
    encoded_q: "Y3NiXzU4MV9x",
    encoded_p: "Y3NiXzU4MV9w",
  },
  {
    school_code: "sb_580",
    name: "Rochester Inst.",
    division: 3,
    org_id: "sb_580",
    encoded_h: "Y3NiXzU4MA==",
    encoded_c: "Y3NiXzU4MF9j",
    encoded_q: "Y3NiXzU4MF9x",
    encoded_p: "Y3NiXzU4MF9w",
  },
  {
    school_code: "sb_582",
    name: "Rockford",
    division: 3,
    org_id: "sb_582",
    encoded_h: "Y3NiXzU4Mg==",
    encoded_c: "Y3NiXzU4Ml9j",
    encoded_q: "Y3NiXzU4Ml9x",
    encoded_p: "Y3NiXzU4Ml9w",
  },
  {
    school_code: "sb_583",
    name: "Roger Williams",
    division: 3,
    org_id: "sb_583",
    encoded_h: "Y3NiXzU4Mw==",
    encoded_c: "Y3NiXzU4M19j",
    encoded_q: "Y3NiXzU4M19x",
    encoded_p: "Y3NiXzU4M19w",
  },
  {
    school_code: "sb_585",
    name: "Rose-Hulman",
    division: 3,
    org_id: "sb_585",
    encoded_h: "Y3NiXzU4NQ==",
    encoded_c: "Y3NiXzU4NV9j",
    encoded_q: "Y3NiXzU4NV9x",
    encoded_p: "Y3NiXzU4NV9w",
  },
  {
    school_code: "sb_15882",
    name: "Rosemont",
    division: 3,
    org_id: "sb_15882",
    encoded_h: "Y3NiXzE1ODgy",
    encoded_c: "Y3NiXzE1ODgyX2M=",
    encoded_q: "Y3NiXzE1ODgyX3E=",
    encoded_p: "Y3NiXzE1ODgyX3A=",
  },
  {
    school_code: "sb_259",
    name: "Rowan",
    division: 3,
    org_id: "sb_259",
    encoded_h: "Y3NiXzI1OQ==",
    encoded_c: "Y3NiXzI1OV9j",
    encoded_q: "Y3NiXzI1OV9x",
    encoded_p: "Y3NiXzI1OV9w",
  },
  {
    school_code: "sb_586",
    name: "Rust",
    division: 3,
    org_id: "sb_586",
    encoded_h: "Y3NiXzU4Ng==",
    encoded_c: "Y3NiXzU4Nl9j",
    encoded_q: "Y3NiXzU4Nl9x",
    encoded_p: "Y3NiXzU4Nl9w",
  },
  {
    school_code: "sb_588",
    name: "Rutgers-Camden",
    division: 3,
    org_id: "sb_588",
    encoded_h: "Y3NiXzU4OA==",
    encoded_c: "Y3NiXzU4OF9j",
    encoded_q: "Y3NiXzU4OF9x",
    encoded_p: "Y3NiXzU4OF9w",
  },
  {
    school_code: "sb_589",
    name: "Rutgers-Newark",
    division: 3,
    org_id: "sb_589",
    encoded_h: "Y3NiXzU4OQ==",
    encoded_c: "Y3NiXzU4OV9j",
    encoded_q: "Y3NiXzU4OV9x",
    encoded_p: "Y3NiXzU4OV9w",
  },
  {
    school_code: "sb_168",
    name: "SUNY Cortland",
    division: 3,
    org_id: "sb_168",
    encoded_h: "Y3NiXzE2OA==",
    encoded_c: "Y3NiXzE2OF9j",
    encoded_q: "Y3NiXzE2OF9x",
    encoded_p: "Y3NiXzE2OF9w",
  },
  {
    school_code: "sb_247",
    name: "SUNY Geneseo",
    division: 3,
    org_id: "sb_247",
    encoded_h: "Y3NiXzI0Nw==",
    encoded_c: "Y3NiXzI0N19j",
    encoded_q: "Y3NiXzI0N19x",
    encoded_p: "Y3NiXzI0N19w",
  },
  {
    school_code: "sb_478",
    name: "SUNY Maritime",
    division: 3,
    org_id: "sb_478",
    encoded_h: "Y3NiXzQ3OA==",
    encoded_c: "Y3NiXzQ3OF9j",
    encoded_q: "Y3NiXzQ3OF9x",
    encoded_p: "Y3NiXzQ3OF9w",
  },
  {
    school_code: "sb_475",
    name: "SUNY New Paltz",
    division: 3,
    org_id: "sb_475",
    encoded_h: "Y3NiXzQ3NQ==",
    encoded_c: "Y3NiXzQ3NV9j",
    encoded_q: "Y3NiXzQ3NV9x",
    encoded_p: "Y3NiXzQ3NV9w",
  },
  {
    school_code: "sb_526",
    name: "SUNY Oneonta",
    division: 3,
    org_id: "sb_526",
    encoded_h: "Y3NiXzUyNg==",
    encoded_c: "Y3NiXzUyNl9j",
    encoded_q: "Y3NiXzUyNl9x",
    encoded_p: "Y3NiXzUyNl9w",
  },
  {
    school_code: "sb_9500",
    name: "SUNY Poly",
    division: 3,
    org_id: "sb_9500",
    encoded_h: "Y3NiXzk1MDA=",
    encoded_c: "Y3NiXzk1MDBfYw==",
    encoded_q: "Y3NiXzk1MDBfcQ==",
    encoded_p: "Y3NiXzk1MDBfcA==",
  },
  {
    school_code: "sb_552",
    name: "SUNY Potsdam",
    division: 3,
    org_id: "sb_552",
    encoded_h: "Y3NiXzU1Mg==",
    encoded_c: "Y3NiXzU1Ml9j",
    encoded_q: "Y3NiXzU1Ml9x",
    encoded_p: "Y3NiXzU1Ml9w",
  },
  {
    school_code: "sb_11036",
    name: "Sage Colleges",
    division: 3,
    org_id: "sb_11036",
    encoded_h: "Y3NiXzExMDM2",
    encoded_c: "Y3NiXzExMDM2X2M=",
    encoded_q: "Y3NiXzExMDM2X3E=",
    encoded_p: "Y3NiXzExMDM2X3A=",
  },
  {
    school_code: "sb_595",
    name: "Saint Benedict",
    division: 3,
    org_id: "sb_595",
    encoded_h: "Y3NiXzU5NQ==",
    encoded_c: "Y3NiXzU5NV9j",
    encoded_q: "Y3NiXzU5NV9x",
    encoded_p: "Y3NiXzU5NV9w",
  },
  {
    school_code: "sb_612",
    name: "Saint Mary's (MN)",
    division: 3,
    org_id: "sb_612",
    encoded_h: "Y3NiXzYxMg==",
    encoded_c: "Y3NiXzYxMl9j",
    encoded_q: "Y3NiXzYxMl9x",
    encoded_p: "Y3NiXzYxMl9w",
  },
  {
    school_code: "sb_30064",
    name: "Saint Vincent",
    division: 3,
    org_id: "sb_30064",
    encoded_h: "Y3NiXzMwMDY0",
    encoded_c: "Y3NiXzMwMDY0X2M=",
    encoded_q: "Y3NiXzMwMDY0X3E=",
    encoded_p: "Y3NiXzMwMDY0X3A=",
  },
  {
    school_code: "sb_30041",
    name: "Salem",
    division: 3,
    org_id: "sb_30041",
    encoded_h: "Y3NiXzMwMDQx",
    encoded_c: "Y3NiXzMwMDQxX2M=",
    encoded_q: "Y3NiXzMwMDQxX3E=",
    encoded_p: "Y3NiXzMwMDQxX3A=",
  },
  {
    school_code: "sb_621",
    name: "Salem St.",
    division: 3,
    org_id: "sb_621",
    encoded_h: "Y3NiXzYyMQ==",
    encoded_c: "Y3NiXzYyMV9j",
    encoded_q: "Y3NiXzYyMV9x",
    encoded_p: "Y3NiXzYyMV9w",
  },
  {
    school_code: "sb_622",
    name: "Salisbury",
    division: 3,
    org_id: "sb_622",
    encoded_h: "Y3NiXzYyMg==",
    encoded_c: "Y3NiXzYyMl9j",
    encoded_q: "Y3NiXzYyMl9x",
    encoded_p: "Y3NiXzYyMl9w",
  },
  {
    school_code: "sb_623",
    name: "Salve Regina",
    division: 3,
    org_id: "sb_623",
    encoded_h: "Y3NiXzYyMw==",
    encoded_c: "Y3NiXzYyM19j",
    encoded_q: "Y3NiXzYyM19x",
    encoded_p: "Y3NiXzYyM19w",
  },
  {
    school_code: "sb_1355",
    name: "Schreiner",
    division: 3,
    org_id: "sb_1355",
    encoded_h: "Y3NiXzEzNTU=",
    encoded_c: "Y3NiXzEzNTVfYw==",
    encoded_q: "Y3NiXzEzNTVfcQ==",
    encoded_p: "Y3NiXzEzNTVfcA==",
  },
  {
    school_code: "sb_633",
    name: "Scranton",
    division: 3,
    org_id: "sb_633",
    encoded_h: "Y3NiXzYzMw==",
    encoded_c: "Y3NiXzYzM19j",
    encoded_q: "Y3NiXzYzM19x",
    encoded_p: "Y3NiXzYzM19w",
  },
  {
    school_code: "sb_652",
    name: "Sewanee",
    division: 3,
    org_id: "sb_652",
    encoded_h: "Y3NiXzY1Mg==",
    encoded_c: "Y3NiXzY1Ml9j",
    encoded_q: "Y3NiXzY1Ml9x",
    encoded_p: "Y3NiXzY1Ml9w",
  },
  {
    school_code: "sb_637",
    name: "Shenandoah",
    division: 3,
    org_id: "sb_637",
    encoded_h: "Y3NiXzYzNw==",
    encoded_c: "Y3NiXzYzN19j",
    encoded_q: "Y3NiXzYzN19x",
    encoded_p: "Y3NiXzYzN19w",
  },
  {
    school_code: "sb_640",
    name: "Simmons",
    division: 3,
    org_id: "sb_640",
    encoded_h: "Y3NiXzY0MA==",
    encoded_c: "Y3NiXzY0MF9j",
    encoded_q: "Y3NiXzY0MF9x",
    encoded_p: "Y3NiXzY0MF9w",
  },
  {
    school_code: "sb_641",
    name: "Simpson",
    division: 3,
    org_id: "sb_641",
    encoded_h: "Y3NiXzY0MQ==",
    encoded_c: "Y3NiXzY0MV9j",
    encoded_q: "Y3NiXzY0MV9x",
    encoded_p: "Y3NiXzY0MV9w",
  },
  {
    school_code: "sb_642",
    name: "Skidmore",
    division: 3,
    org_id: "sb_642",
    encoded_h: "Y3NiXzY0Mg==",
    encoded_c: "Y3NiXzY0Ml9j",
    encoded_q: "Y3NiXzY0Ml9x",
    encoded_p: "Y3NiXzY0Ml9w",
  },
  {
    school_code: "sb_644",
    name: "Smith",
    division: 3,
    org_id: "sb_644",
    encoded_h: "Y3NiXzY0NA==",
    encoded_c: "Y3NiXzY0NF9j",
    encoded_q: "Y3NiXzY0NF9x",
    encoded_p: "Y3NiXzY0NF9w",
  },
  {
    school_code: "sb_662",
    name: "Southern Me.",
    division: 3,
    org_id: "sb_662",
    encoded_h: "Y3NiXzY2Mg==",
    encoded_c: "Y3NiXzY2Ml9j",
    encoded_q: "Y3NiXzY2Ml9x",
    encoded_p: "Y3NiXzY2Ml9w",
  },
  {
    school_code: "sb_1379",
    name: "Southern Vt.",
    division: 3,
    org_id: "sb_1379",
    encoded_h: "Y3NiXzEzNzk=",
    encoded_c: "Y3NiXzEzNzlfYw==",
    encoded_q: "Y3NiXzEzNzlfcQ==",
    encoded_p: "Y3NiXzEzNzlfcA==",
  },
  {
    school_code: "sb_8746",
    name: "Southwestern (TX)",
    division: 3,
    org_id: "sb_8746",
    encoded_h: "Y3NiXzg3NDY=",
    encoded_c: "Y3NiXzg3NDZfYw==",
    encoded_q: "Y3NiXzg3NDZfcQ==",
    encoded_p: "Y3NiXzg3NDZfcA==",
  },
  {
    school_code: "sb_23260",
    name: "Spalding",
    division: 3,
    org_id: "sb_23260",
    encoded_h: "Y3NiXzIzMjYw",
    encoded_c: "Y3NiXzIzMjYwX2M=",
    encoded_q: "Y3NiXzIzMjYwX3E=",
    encoded_p: "Y3NiXzIzMjYwX3A=",
  },
  {
    school_code: "sb_673",
    name: "Springfield",
    division: 3,
    org_id: "sb_673",
    encoded_h: "Y3NiXzY3Mw==",
    encoded_c: "Y3NiXzY3M19j",
    encoded_q: "Y3NiXzY3M19x",
    encoded_p: "Y3NiXzY3M19w",
  },
  {
    school_code: "sb_30163",
    name: "Sarah Lawrence",
    division: 3,
    org_id: "sb_30163",
    encoded_h: "Y3NiXzMwMTYz",
    encoded_c: "Y3NiXzMwMTYzX2M=",
    encoded_q: "Y3NiXzMwMTYzX3E=",
    encoded_p: "Y3NiXzMwMTYzX3A=",
  },
  {
    school_code: "sb_30191",
    name: "Spring Hill",
    division: 2,
    org_id: "sb_30191",
    encoded_h: "Y3NiXzMwMTkx",
    encoded_c: "Y3NiXzMwMTkxX2M=",
    encoded_q: "Y3NiXzMwMTkxX3E=",
    encoded_p: "Y3NiXzMwMTkxX3A=",
  },
  {
    school_code: "sb_597",
    name: "St. Catherine",
    division: 3,
    org_id: "sb_597",
    encoded_h: "Y3NiXzU5Nw==",
    encoded_c: "Y3NiXzU5N19j",
    encoded_q: "Y3NiXzU5N19x",
    encoded_p: "Y3NiXzU5N19w",
  },
  {
    school_code: "sb_9684",
    name: "St. Elizabeth",
    division: 3,
    org_id: "sb_9684",
    encoded_h: "Y3NiXzk2ODQ=",
    encoded_c: "Y3NiXzk2ODRfYw==",
    encoded_q: "Y3NiXzk2ODRfcQ==",
    encoded_p: "Y3NiXzk2ODRfcA==",
  },
  {
    school_code: "sb_601",
    name: "St. John Fisher",
    division: 3,
    org_id: "sb_601",
    encoded_h: "Y3NiXzYwMQ==",
    encoded_c: "Y3NiXzYwMV9j",
    encoded_q: "Y3NiXzYwMV9x",
    encoded_p: "Y3NiXzYwMV9w",
  },
  {
    school_code: "sb_8687",
    name: "St. Joseph (CT)",
    division: 3,
    org_id: "sb_8687",
    encoded_h: "Y3NiXzg2ODc=",
    encoded_c: "Y3NiXzg2ODdfYw==",
    encoded_q: "Y3NiXzg2ODdfcQ==",
    encoded_p: "Y3NiXzg2ODdfcA==",
  },
  {
    school_code: "sb_30072",
    name: "St. Joseph's (Brkln)",
    division: 3,
    org_id: "sb_30072",
    encoded_h: "Y3NiXzMwMDcy",
    encoded_c: "Y3NiXzMwMDcyX2M=",
    encoded_q: "Y3NiXzMwMDcyX3E=",
    encoded_p: "Y3NiXzMwMDcyX3A=",
  },
  {
    school_code: "sb_1340",
    name: "St. Joseph's (L.I.)",
    division: 3,
    org_id: "sb_1340",
    encoded_h: "Y3NiXzEzNDA=",
    encoded_c: "Y3NiXzEzNDBfYw==",
    encoded_q: "Y3NiXzEzNDBfcQ==",
    encoded_p: "Y3NiXzEzNDBfcA==",
  },
  {
    school_code: "sb_605",
    name: "St. Joseph's (ME)",
    division: 3,
    org_id: "sb_605",
    encoded_h: "Y3NiXzYwNQ==",
    encoded_c: "Y3NiXzYwNV9j",
    encoded_q: "Y3NiXzYwNV9x",
    encoded_p: "Y3NiXzYwNV9w",
  },
  {
    school_code: "sb_607",
    name: "St. Lawrence",
    division: 3,
    org_id: "sb_607",
    encoded_h: "Y3NiXzYwNw==",
    encoded_c: "Y3NiXzYwN19j",
    encoded_q: "Y3NiXzYwN19x",
    encoded_p: "Y3NiXzYwN19w",
  },
  {
    school_code: "sb_8688",
    name: "St. Mary's (IN)",
    division: 3,
    org_id: "sb_8688",
    encoded_h: "Y3NiXzg2ODg=",
    encoded_c: "Y3NiXzg2ODhfYw==",
    encoded_q: "Y3NiXzg2ODhfcQ==",
    encoded_p: "Y3NiXzg2ODhfcA==",
  },
  {
    school_code: "sb_614",
    name: "St. Norbert",
    division: 3,
    org_id: "sb_614",
    encoded_h: "Y3NiXzYxNA==",
    encoded_c: "Y3NiXzYxNF9j",
    encoded_q: "Y3NiXzYxNF9x",
    encoded_p: "Y3NiXzYxNF9w",
  },
  {
    school_code: "sb_615",
    name: "St. Olaf",
    division: 3,
    org_id: "sb_615",
    encoded_h: "Y3NiXzYxNQ==",
    encoded_c: "Y3NiXzYxNV9j",
    encoded_q: "Y3NiXzYxNV9x",
    encoded_p: "Y3NiXzYxNV9w",
  },
  {
    school_code: "sb_618",
    name: "St. Scholastica",
    division: 3,
    org_id: "sb_618",
    encoded_h: "Y3NiXzYxOA==",
    encoded_c: "Y3NiXzYxOF9j",
    encoded_q: "Y3NiXzYxOF9x",
    encoded_p: "Y3NiXzYxOF9w",
  },
  {
    school_code: "sb_620",
    name: "St. Thomas (MN)",
    division: 3,
    org_id: "sb_620",
    encoded_h: "Y3NiXzYyMA==",
    encoded_c: "Y3NiXzYyMF9j",
    encoded_q: "Y3NiXzYyMF9x",
    encoded_p: "Y3NiXzYyMF9w",
  },
  {
    school_code: "sb_2810",
    name: "St. Thomas (TX)",
    division: 3,
    org_id: "sb_2810",
    mascot: "Celts",
    encoded_h: "YzI4MTA=",
    encoded_c: "YzI4MTBfcQ==",
    encoded_q: "YzI4MTBfYw==",
    encoded_p: "YzI4MTBfcA==",
  },
  {
    school_code: "sb_675",
    name: "Staten Island",
    division: 3,
    org_id: "sb_675",
    encoded_h: "Y3NiXzY3NQ==",
    encoded_c: "Y3NiXzY3NV9j",
    encoded_q: "Y3NiXzY3NV9x",
    encoded_p: "Y3NiXzY3NV9w",
  },
  {
    school_code: "sb_679",
    name: "Stevens",
    division: 3,
    org_id: "sb_679",
    encoded_h: "Y3NiXzY3OQ==",
    encoded_c: "Y3NiXzY3OV9j",
    encoded_q: "Y3NiXzY3OV9x",
    encoded_p: "Y3NiXzY3OV9w",
  },
  {
    school_code: "sb_21852",
    name: "Stevenson",
    division: 3,
    org_id: "sb_21852",
    encoded_h: "Y3NiXzIxODUy",
    encoded_c: "Y3NiXzIxODUyX2M=",
    encoded_q: "Y3NiXzIxODUyX3E=",
    encoded_p: "Y3NiXzIxODUyX3A=",
  },
  {
    school_code: "sb_684",
    name: "Suffolk",
    division: 3,
    org_id: "sb_684",
    encoded_h: "Y3NiXzY4NA==",
    encoded_c: "Y3NiXzY4NF9j",
    encoded_q: "Y3NiXzY4NF9x",
    encoded_p: "Y3NiXzY4NF9w",
  },
  {
    school_code: "sb_1390",
    name: "Sul Ross St.",
    division: 3,
    org_id: "sb_1390",
    encoded_h: "Y3NiXzEzOTA=",
    encoded_c: "Y3NiXzEzOTBfYw==",
    encoded_q: "Y3NiXzEzOTBfcQ==",
    encoded_p: "Y3NiXzEzOTBfcA==",
  },
  {
    school_code: "sb_685",
    name: "Susquehanna",
    division: 3,
    org_id: "sb_685",
    encoded_h: "Y3NiXzY4NQ==",
    encoded_c: "Y3NiXzY4NV9j",
    encoded_q: "Y3NiXzY4NV9x",
    encoded_p: "Y3NiXzY4NV9w",
  },
  {
    school_code: "sb_686",
    name: "Swarthmore",
    division: 3,
    org_id: "sb_686",
    encoded_h: "Y3NiXzY4Ng==",
    encoded_c: "Y3NiXzY4Nl9j",
    encoded_q: "Y3NiXzY4Nl9x",
    encoded_p: "Y3NiXzY4Nl9w",
  },
  {
    school_code: "sb_687",
    name: "Sweet Briar",
    division: 3,
    org_id: "sb_687",
    encoded_h: "Y3NiXzY4Nw==",
    encoded_c: "Y3NiXzY4N19j",
    encoded_q: "Y3NiXzY4N19x",
    encoded_p: "Y3NiXzY4N19w",
  },
  {
    school_code: "sb_712",
    name: "TCNJ",
    division: 3,
    org_id: "sb_712",
    encoded_h: "Y3NiXzcxMg==",
    encoded_c: "Y3NiXzcxMl9j",
    encoded_q: "Y3NiXzcxMl9x",
    encoded_p: "Y3NiXzcxMl9w",
  },
  {
    school_code: "sb_1400",
    name: "Texas Lutheran",
    division: 3,
    org_id: "sb_1400",
    encoded_h: "Y3NiXzE0MDA=",
    encoded_c: "Y3NiXzE0MDBfYw==",
    encoded_q: "Y3NiXzE0MDBfcQ==",
    encoded_p: "Y3NiXzE0MDBfcA==",
  },
  {
    school_code: "sb_23121",
    name: "Texas-Dallas",
    division: 3,
    org_id: "sb_23121",
    encoded_h: "Y3NiXzIzMTIx",
    encoded_c: "Y3NiXzIzMTIxX2M=",
    encoded_q: "Y3NiXzIzMTIxX3E=",
    encoded_p: "Y3NiXzIzMTIxX3A=",
  },
  {
    school_code: "sb_30028",
    name: "Texas-Tyler",
    division: 3,
    org_id: "sb_30028",
    encoded_h: "Y3NiXzMwMDI4",
    encoded_c: "Y3NiXzMwMDI4X2M=",
    encoded_q: "Y3NiXzMwMDI4X3E=",
    encoded_p: "Y3NiXzMwMDI4X3A=",
  },
  {
    school_code: "sb_707",
    name: "Thiel",
    division: 3,
    org_id: "sb_707",
    encoded_h: "Y3NiXzcwNw==",
    encoded_c: "Y3NiXzcwN19j",
    encoded_q: "Y3NiXzcwN19x",
    encoded_p: "Y3NiXzcwN19w",
  },
  {
    school_code: "sb_2758",
    name: "Thomas (ME)",
    division: 3,
    org_id: "sb_2758",
    encoded_h: "Y3NiXzI3NTg=",
    encoded_c: "Y3NiXzI3NThfYw==",
    encoded_q: "Y3NiXzI3NThfcQ==",
    encoded_p: "Y3NiXzI3NThfcA==",
  },
  {
    school_code: "sb_1402",
    name: "Thomas More",
    division: 3,
    org_id: "sb_1402",
    encoded_h: "Y3NiXzE0MDI=",
    encoded_c: "Y3NiXzE0MDJfYw==",
    encoded_q: "Y3NiXzE0MDJfcQ==",
    encoded_p: "Y3NiXzE0MDJfcA==",
  },
  {
    school_code: "sb_28749",
    name: "Transylvania",
    division: 3,
    org_id: "sb_28749",
    encoded_h: "Y3NiXzI4NzQ5",
    encoded_c: "Y3NiXzI4NzQ5X2M=",
    encoded_q: "Y3NiXzI4NzQ5X3E=",
    encoded_p: "Y3NiXzI4NzQ5X3A=",
  },
  {
    school_code: "sb_30037",
    name: "Trine",
    division: 3,
    org_id: "sb_30037",
    encoded_h: "Y3NiXzMwMDM3",
    encoded_c: "Y3NiXzMwMDM3X2M=",
    encoded_q: "Y3NiXzMwMDM3X3E=",
    encoded_p: "Y3NiXzMwMDM3X3A=",
  },
  {
    school_code: "sb_713",
    name: "Trinity (CT)",
    division: 3,
    org_id: "sb_713",
    encoded_h: "Y3NiXzcxMw==",
    encoded_c: "Y3NiXzcxM19j",
    encoded_q: "Y3NiXzcxM19x",
    encoded_p: "Y3NiXzcxM19w",
  },
  {
    school_code: "sb_715",
    name: "Trinity (TX)",
    division: 3,
    org_id: "sb_715",
    encoded_h: "Y3NiXzcxNQ==",
    encoded_c: "Y3NiXzcxNV9j",
    encoded_q: "Y3NiXzcxNV9x",
    encoded_p: "Y3NiXzcxNV9w",
  },
  {
    school_code: "sb_23413",
    name: "Trinity Washington",
    division: 3,
    org_id: "sb_23413",
    encoded_h: "Y3NiXzIzNDEz",
    encoded_c: "Y3NiXzIzNDEzX2M=",
    encoded_q: "Y3NiXzIzNDEzX3E=",
    encoded_p: "Y3NiXzIzNDEzX3A=",
  },
  {
    school_code: "sb_717",
    name: "Tufts",
    division: 3,
    org_id: "sb_717",
    encoded_h: "Y3NiXzcxNw==",
    encoded_c: "Y3NiXzcxN19j",
    encoded_q: "Y3NiXzcxN19x",
    encoded_p: "Y3NiXzcxN19w",
  },
  {
    school_code: "sb_1255",
    name: "U New England",
    division: 3,
    org_id: "sb_1255",
    encoded_h: "Y3NiXzEyNTU=",
    encoded_c: "Y3NiXzEyNTVfYw==",
    encoded_q: "Y3NiXzEyNTVfcQ==",
    encoded_p: "Y3NiXzEyNTVfcA==",
  },
  {
    school_code: "sb_401",
    name: "UMass Boston",
    division: 3,
    org_id: "sb_401",
    encoded_h: "Y3NiXzQwMQ==",
    encoded_c: "Y3NiXzQwMV9j",
    encoded_q: "Y3NiXzQwMV9x",
    encoded_p: "Y3NiXzQwMV9w",
  },
  {
    school_code: "sb_656",
    name: "UMass Dartmouth",
    division: 3,
    org_id: "sb_656",
    encoded_h: "Y3NiXzY1Ng==",
    encoded_c: "Y3NiXzY1Nl9j",
    encoded_q: "Y3NiXzY1Nl9x",
    encoded_p: "Y3NiXzY1Nl9w",
  },
  {
    school_code: "sb_727",
    name: "Union (NY)",
    division: 3,
    org_id: "sb_727",
    encoded_h: "Y3NiXzcyNw==",
    encoded_c: "Y3NiXzcyN19j",
    encoded_q: "Y3NiXzcyN19x",
    encoded_p: "Y3NiXzcyN19w",
  },
  {
    school_code: "sb_730",
    name: "Ursinus",
    division: 3,
    org_id: "sb_730",
    encoded_h: "Y3NiXzczMA==",
    encoded_c: "Y3NiXzczMF9j",
    encoded_q: "Y3NiXzczMF9x",
    encoded_p: "Y3NiXzczMF9w",
  },
  {
    school_code: "sb_733",
    name: "Utica",
    division: 3,
    org_id: "sb_733",
    encoded_h: "Y3NiXzczMw==",
    encoded_c: "Y3NiXzczM19j",
    encoded_q: "Y3NiXzczM19x",
    encoded_p: "Y3NiXzczM19w",
  },
  {
    school_code: "sb_745",
    name: "Va. Wesleyan",
    division: 3,
    org_id: "sb_745",
    encoded_h: "Y3NiXzc0NQ==",
    encoded_c: "Y3NiXzc0NV9j",
    encoded_q: "Y3NiXzc0NV9x",
    encoded_p: "Y3NiXzc0NV9w",
  },
  {
    school_code: "sb_808",
    name: "WPI",
    division: 3,
    org_id: "sb_808",
    encoded_h: "Y3NiXzgwOA==",
    encoded_c: "Y3NiXzgwOF9j",
    encoded_q: "Y3NiXzgwOF9x",
    encoded_p: "Y3NiXzgwOF9w",
  },
  {
    school_code: "sb_750",
    name: "Wartburg",
    division: 3,
    org_id: "sb_750",
    encoded_h: "Y3NiXzc1MA==",
    encoded_c: "Y3NiXzc1MF9j",
    encoded_q: "Y3NiXzc1MF9x",
    encoded_p: "Y3NiXzc1MF9w",
  },
  {
    school_code: "sb_751",
    name: "Wash. & Jeff.",
    division: 3,
    org_id: "sb_751",
    encoded_h: "Y3NiXzc1MQ==",
    encoded_c: "Y3NiXzc1MV9j",
    encoded_q: "Y3NiXzc1MV9x",
    encoded_p: "Y3NiXzc1MV9w",
  },
  {
    school_code: "sb_753",
    name: "Washington Col.",
    division: 3,
    org_id: "sb_753",
    encoded_h: "Y3NiXzc1Mw==",
    encoded_c: "Y3NiXzc1M19j",
    encoded_q: "Y3NiXzc1M19x",
    encoded_p: "Y3NiXzc1M19w",
  },
  {
    school_code: "sb_755",
    name: "Washington-St. Louis",
    division: 3,
    org_id: "sb_755",
    encoded_h: "Y3NiXzc1NQ==",
    encoded_c: "Y3NiXzc1NV9j",
    encoded_q: "Y3NiXzc1NV9x",
    encoded_p: "Y3NiXzc1NV9w",
  },
  {
    school_code: "sb_1434",
    name: "Waynesburg",
    division: 3,
    org_id: "sb_1434",
    encoded_h: "Y3NiXzE0MzQ=",
    encoded_c: "Y3NiXzE0MzRfYw==",
    encoded_q: "Y3NiXzE0MzRfcQ==",
    encoded_p: "Y3NiXzE0MzRfcA==",
  },
  {
    school_code: "sb_759",
    name: "Webster",
    division: 3,
    org_id: "sb_759",
    encoded_h: "Y3NiXzc1OQ==",
    encoded_c: "Y3NiXzc1OV9j",
    encoded_q: "Y3NiXzc1OV9x",
    encoded_p: "Y3NiXzc1OV9w",
  },
  {
    school_code: "sb_760",
    name: "Wellesley",
    division: 3,
    org_id: "sb_760",
    encoded_h: "Y3NiXzc2MA==",
    encoded_c: "Y3NiXzc2MF9j",
    encoded_q: "Y3NiXzc2MF9x",
    encoded_p: "Y3NiXzc2MF9w",
  },
  {
    school_code: "sb_761",
    name: "Wells",
    division: 3,
    org_id: "sb_761",
    encoded_h: "Y3NiXzc2MQ==",
    encoded_c: "Y3NiXzc2MV9j",
    encoded_q: "Y3NiXzc2MV9x",
    encoded_p: "Y3NiXzc2MV9w",
  },
  {
    school_code: "sb_762",
    name: "Wentworth",
    division: 3,
    org_id: "sb_762",
    encoded_h: "Y3NiXzc2Mg==",
    encoded_c: "Y3NiXzc2Ml9j",
    encoded_q: "Y3NiXzc2Ml9x",
    encoded_p: "Y3NiXzc2Ml9w",
  },
  {
    school_code: "sb_763",
    name: "Wesley",
    division: 3,
    org_id: "sb_763",
    encoded_h: "Y3NiXzc2Mw==",
    encoded_c: "Y3NiXzc2M19j",
    encoded_q: "Y3NiXzc2M19x",
    encoded_p: "Y3NiXzc2M19w",
  },
  {
    school_code: "sb_764",
    name: "Wesleyan (CT)",
    division: 3,
    org_id: "sb_764",
    encoded_h: "Y3NiXzc2NA==",
    encoded_c: "Y3NiXzc2NF9j",
    encoded_q: "Y3NiXzc2NF9x",
    encoded_p: "Y3NiXzc2NF9w",
  },
  {
    school_code: "sb_14718",
    name: "Wesleyan (GA)",
    division: 3,
    org_id: "sb_14718",
    encoded_h: "Y3NiXzE0NzE4",
    encoded_c: "Y3NiXzE0NzE4X2M=",
    encoded_q: "Y3NiXzE0NzE4X3E=",
    encoded_p: "Y3NiXzE0NzE4X3A=",
  },
  {
    school_code: "sb_770",
    name: "Western Conn. St.",
    division: 3,
    org_id: "sb_770",
    encoded_h: "Y3NiXzc3MA==",
    encoded_c: "Y3NiXzc3MF9j",
    encoded_q: "Y3NiXzc3MF9x",
    encoded_p: "Y3NiXzc3MF9w",
  },
  {
    school_code: "sb_775",
    name: "Western New Eng.",
    division: 3,
    org_id: "sb_775",
    encoded_h: "Y3NiXzc3NQ==",
    encoded_c: "Y3NiXzc3NV9j",
    encoded_q: "Y3NiXzc3NV9x",
    encoded_p: "Y3NiXzc3NV9w",
  },
  {
    school_code: "sb_777",
    name: "Westfield St.",
    division: 3,
    org_id: "sb_777",
    encoded_h: "Y3NiXzc3Nw==",
    encoded_c: "Y3NiXzc3N19j",
    encoded_q: "Y3NiXzc3N19x",
    encoded_p: "Y3NiXzc3N19w",
  },
  {
    school_code: "sb_1450",
    name: "Westminster (MO)",
    division: 3,
    org_id: "sb_1450",
    encoded_h: "Y3NiXzE0NTA=",
    encoded_c: "Y3NiXzE0NTBfYw==",
    encoded_q: "Y3NiXzE0NTBfcQ==",
    encoded_p: "Y3NiXzE0NTBfcA==",
  },
  {
    school_code: "sb_1451",
    name: "Westminster (PA)",
    division: 3,
    org_id: "sb_1451",
    encoded_h: "Y3NiXzE0NTE=",
    encoded_c: "Y3NiXzE0NTFfYw==",
    encoded_q: "Y3NiXzE0NTFfcQ==",
    encoded_p: "Y3NiXzE0NTFfcA==",
  },
  {
    school_code: "sb_778",
    name: "Wheaton (IL)",
    division: 3,
    org_id: "sb_778",
    encoded_h: "Y3NiXzc3OA==",
    encoded_c: "Y3NiXzc3OF9j",
    encoded_q: "Y3NiXzc3OF9x",
    encoded_p: "Y3NiXzc3OF9w",
  },
  {
    school_code: "sb_779",
    name: "Wheaton (MA)",
    division: 3,
    org_id: "sb_779",
    encoded_h: "Y3NiXzc3OQ==",
    encoded_c: "Y3NiXzc3OV9j",
    encoded_q: "Y3NiXzc3OV9x",
    encoded_p: "Y3NiXzc3OV9w",
  },
  {
    school_code: "sb_8896",
    name: "Wheelock",
    division: 3,
    org_id: "sb_8896",
    encoded_h: "Y3NiXzg4OTY=",
    encoded_c: "Y3NiXzg4OTZfYw==",
    encoded_q: "Y3NiXzg4OTZfcQ==",
    encoded_p: "Y3NiXzg4OTZfcA==",
  },
  {
    school_code: "sb_781",
    name: "Whittier",
    division: 3,
    org_id: "sb_781",
    encoded_h: "Y3NiXzc4MQ==",
    encoded_c: "Y3NiXzc4MV9j",
    encoded_q: "Y3NiXzc4MV9x",
    encoded_p: "Y3NiXzc4MV9w",
  },
  {
    school_code: "sb_1454",
    name: "Whitworth",
    division: 3,
    org_id: "sb_1454",
    encoded_h: "Y3NiXzE0NTQ=",
    encoded_c: "Y3NiXzE0NTRfYw==",
    encoded_q: "Y3NiXzE0NTRfcQ==",
    encoded_p: "Y3NiXzE0NTRfcA==",
  },
  {
    school_code: "sb_783",
    name: "Widener",
    division: 3,
    org_id: "sb_783",
    encoded_h: "Y3NiXzc4Mw==",
    encoded_c: "Y3NiXzc4M19j",
    encoded_q: "Y3NiXzc4M19x",
    encoded_p: "Y3NiXzc4M19w",
  },
  {
    school_code: "sb_784",
    name: "Wilkes",
    division: 3,
    org_id: "sb_784",
    encoded_h: "Y3NiXzc4NA==",
    encoded_c: "Y3NiXzc4NF9j",
    encoded_q: "Y3NiXzc4NF9x",
    encoded_p: "Y3NiXzc4NF9w",
  },
  {
    school_code: "sb_785",
    name: "Willamette",
    division: 3,
    org_id: "sb_785",
    encoded_h: "Y3NiXzc4NQ==",
    encoded_c: "Y3NiXzc4NV9j",
    encoded_q: "Y3NiXzc4NV9x",
    encoded_p: "Y3NiXzc4NV9w",
  },
  {
    school_code: "sb_17094",
    name: "William Peace",
    division: 3,
    org_id: "sb_17094",
    encoded_h: "Y3NiXzE3MDk0",
    encoded_c: "Y3NiXzE3MDk0X2M=",
    encoded_q: "Y3NiXzE3MDk0X3E=",
    encoded_p: "Y3NiXzE3MDk0X3A=",
  },
  {
    school_code: "sb_789",
    name: "Williams",
    division: 3,
    org_id: "sb_789",
    encoded_h: "Y3NiXzc4OQ==",
    encoded_c: "Y3NiXzc4OV9j",
    encoded_q: "Y3NiXzc4OV9x",
    encoded_p: "Y3NiXzc4OV9w",
  },
  {
    school_code: "sb_1461",
    name: "Wilmington (OH)",
    division: 3,
    org_id: "sb_1461",
    encoded_h: "Y3NiXzE0NjE=",
    encoded_c: "Y3NiXzE0NjFfYw==",
    encoded_q: "Y3NiXzE0NjFfcQ==",
    encoded_p: "Y3NiXzE0NjFfcA==",
  },
  {
    school_code: "sb_12973",
    name: "Wilson",
    division: 3,
    org_id: "sb_12973",
    encoded_h: "Y3NiXzEyOTcz",
    encoded_c: "Y3NiXzEyOTczX2M=",
    encoded_q: "Y3NiXzEyOTczX3E=",
    encoded_p: "Y3NiXzEyOTczX3A=",
  },
  {
    school_code: "sb_8911",
    name: "Wis. Lutheran",
    division: 3,
    org_id: "sb_8911",
    encoded_h: "Y3NiXzg5MTE=",
    encoded_c: "Y3NiXzg5MTFfYw==",
    encoded_q: "Y3NiXzg5MTFfcQ==",
    encoded_p: "Y3NiXzg5MTFfcA==",
  },
  {
    school_code: "sb_793",
    name: "Wis.-Eau Claire",
    division: 3,
    org_id: "sb_793",
    encoded_h: "Y3NiXzc5Mw==",
    encoded_c: "Y3NiXzc5M19j",
    encoded_q: "Y3NiXzc5M19x",
    encoded_p: "Y3NiXzc5M19w",
  },
  {
    school_code: "sb_795",
    name: "Wis.-La Crosse",
    division: 3,
    org_id: "sb_795",
    encoded_h: "Y3NiXzc5NQ==",
    encoded_c: "Y3NiXzc5NV9j",
    encoded_q: "Y3NiXzc5NV9x",
    encoded_p: "Y3NiXzc5NV9w",
  },
  {
    school_code: "sb_798",
    name: "Wis.-Oshkosh",
    division: 3,
    org_id: "sb_798",
    encoded_h: "Y3NiXzc5OA==",
    encoded_c: "Y3NiXzc5OF9j",
    encoded_q: "Y3NiXzc5OF9x",
    encoded_p: "Y3NiXzc5OF9w",
  },
  {
    school_code: "sb_800",
    name: "Wis.-Platteville",
    division: 3,
    org_id: "sb_800",
    encoded_h: "Y3NiXzgwMA==",
    encoded_c: "Y3NiXzgwMF9j",
    encoded_q: "Y3NiXzgwMF9x",
    encoded_p: "Y3NiXzgwMF9w",
  },
  {
    school_code: "sb_801",
    name: "Wis.-River Falls",
    division: 3,
    org_id: "sb_801",
    encoded_h: "Y3NiXzgwMQ==",
    encoded_c: "Y3NiXzgwMV9j",
    encoded_q: "Y3NiXzgwMV9x",
    encoded_p: "Y3NiXzgwMV9w",
  },
  {
    school_code: "sb_802",
    name: "Wis.-Stevens Point",
    division: 3,
    org_id: "sb_802",
    encoded_h: "Y3NiXzgwMg==",
    encoded_c: "Y3NiXzgwMl9j",
    encoded_q: "Y3NiXzgwMl9x",
    encoded_p: "Y3NiXzgwMl9w",
  },
  {
    school_code: "sb_803",
    name: "Wis.-Stout",
    division: 3,
    org_id: "sb_803",
    encoded_h: "Y3NiXzgwMw==",
    encoded_c: "Y3NiXzgwM19j",
    encoded_q: "Y3NiXzgwM19x",
    encoded_p: "Y3NiXzgwM19w",
  },
  {
    school_code: "sb_804",
    name: "Wis.-Superior",
    division: 3,
    org_id: "sb_804",
    encoded_h: "Y3NiXzgwNA==",
    encoded_c: "Y3NiXzgwNF9j",
    encoded_q: "Y3NiXzgwNF9x",
    encoded_p: "Y3NiXzgwNF9w",
  },
  {
    school_code: "sb_805",
    name: "Wis.-Whitewater",
    division: 3,
    org_id: "sb_805",
    encoded_h: "Y3NiXzgwNQ==",
    encoded_c: "Y3NiXzgwNV9j",
    encoded_q: "Y3NiXzgwNV9x",
    encoded_p: "Y3NiXzgwNV9w",
  },
  {
    school_code: "sb_806",
    name: "Wittenberg",
    division: 3,
    org_id: "sb_806",
    encoded_h: "Y3NiXzgwNg==",
    encoded_c: "Y3NiXzgwNl9j",
    encoded_q: "Y3NiXzgwNl9x",
    encoded_p: "Y3NiXzgwNl9w",
  },
  {
    school_code: "sb_787",
    name: "Wm. Paterson",
    division: 3,
    org_id: "sb_787",
    encoded_h: "Y3NiXzc4Nw==",
    encoded_c: "Y3NiXzc4N19j",
    encoded_q: "Y3NiXzc4N19x",
    encoded_p: "Y3NiXzc4N19w",
  },
  {
    school_code: "sb_807",
    name: "Wooster",
    division: 3,
    org_id: "sb_807",
    encoded_h: "Y3NiXzgwNw==",
    encoded_c: "Y3NiXzgwN19j",
    encoded_q: "Y3NiXzgwN19x",
    encoded_p: "Y3NiXzgwN19w",
  },
  {
    school_code: "sb_809",
    name: "Worcester St.",
    division: 3,
    org_id: "sb_809",
    encoded_h: "Y3NiXzgwOQ==",
    encoded_c: "Y3NiXzgwOV9j",
    encoded_q: "Y3NiXzgwOV9x",
    encoded_p: "Y3NiXzgwOV9w",
  },
  {
    school_code: "sb_2730",
    name: "York (NY)",
    division: 3,
    org_id: "sb_2730",
    encoded_h: "Y3NiXzI3MzA=",
    encoded_c: "Y3NiXzI3MzBfYw==",
    encoded_q: "Y3NiXzI3MzBfcQ==",
    encoded_p: "Y3NiXzI3MzBfcA==",
  },
  {
    school_code: "sb_814",
    name: "Yeshiva",
    division: 3,
    org_id: "sb_814",
    encoded_h: "Y3NiXzgxNA==",
    encoded_c: "Y3NiXzgxNF9j",
    encoded_q: "Y3NiXzgxNF9x",
    encoded_p: "Y3NiXzgxNF9w",
  },
  {
    school_code: "sb_815",
    name: "York (PA)",
    division: 3,
    org_id: "sb_815",
    encoded_h: "Y3NiXzgxNQ==",
    encoded_c: "Y3NiXzgxNV9j",
    encoded_q: "Y3NiXzgxNV9x",
    encoded_p: "Y3NiXzgxNV9w",
  },
  {
    school_code: "sb_30164",
    name: "Southern Va.",
    division: 3,
    org_id: "sb_30164",
    mascot: "Knights",
    encoded_h: "Y3NiXzMwMTY0",
    encoded_c: "Y3NiXzMwMTY0X2M=",
    encoded_q: "Y3NiXzMwMTY0X3E=",
    encoded_p: "Y3NiXzMwMTY0X3A=",
  },
  {
    school_code: "sb_30166",
    name: "Valley Forge",
    division: 3,
    org_id: "sb_30166",
    mascot: "The Patriots",
    encoded_h: "Y3NiXzMwMTY2",
    encoded_c: "Y3NiXzMwMTY2X2M=",
    encoded_q: "Y3NiXzMwMTY2X3E=",
    encoded_p: "Y3NiXzMwMTY2X3A=",
  },
  {
    school_code: "sb_441",
    name: "Montana",
    division: 1,
    org_id: "sb_441",
    mascot: "Grizzlies",
    encoded_h: "Y3NiXzQ0MQ==",
    encoded_c: "Y3NiXzQ0MV9j",
    encoded_q: "Y3NiXzQ0MV9x",
    encoded_p: "Y3NiXzQ0MV9w",
  },
  {
    school_code: "sb_30165",
    name: "SUNY Canton",
    division: 3,
    org_id: "sb_30165",
    encoded_h: "Y3NiXzMwMTY1",
    encoded_c: "Y3NiXzMwMTY1X2M=",
    encoded_q: "Y3NiXzMwMTY1X3E=",
    encoded_p: "Y3NiXzMwMTY1X3A=",
  },
];
