import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ROUTES from './config/AppRoutes';

ReactDOM.render(
  <BrowserRouter>
  <Routes>
      <Route path="/" element={<App/>}>
        {ROUTES.map((route, index) => {
          return (
            <Route key={index} element={route.component} path={route.path} index={route.index}>
              {route.children ? (
                <Route
                  path={route.children.path}
                  element={route.children.component}
                />
              ) : undefined}
            </Route>
          );
        })}
    </Route>
  </Routes>
</BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
