import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Requests from "../../utils/Requests";

import get from "lodash/get";

const Verify = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const getAndSetNewToken = useCallback(async () => {
        const uniqueId = uuidv4();
        localStorage.setItem("login-token", uniqueId);
        await Requests.post(`/token/upsert`, { token: uniqueId });
      }, []);
    const verifyUser = useCallback(async () => {
        const verifyResponse = await Requests.post("/account/verify_user", {id: id})
        if (get(verifyResponse, "acknowledged")) {
            await getAndSetNewToken();
            navigate("/")
        }
    }, [id, navigate, getAndSetNewToken])

    useEffect(() => {
        verifyUser();
    }, [verifyUser])
    return (
        <div></div>
    )
}

export default Verify;