import styles from "./Pill.module.scss";

const Pill = (props: any) => {
    const { className, pills } = props;
    return (
        <div className={`${styles.pillContainer} ${className}`}>
            {pills.map((pill: any, key: number) => (
                <div
                    title={pill.title || ""}
                    key={key}
                    style={pill.style}
                    className={pill.active ? styles.activePill : styles.inactivePill}
                    onClick={pill.onClick}
                >
                    {pill.value}
                </div>
            ))}
        </div>
    )
}

export default Pill;