import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import get from "lodash/get";

import logoMark from "../../assets/logo_mark.jpg";
import TextInput from "../../components/textInput/TextInput";
import Requests from "../../utils/Requests";
import styles from "./Login.module.scss";

const Login = () => {
  let navigate = useNavigate();

  const [isValidDomain, setIsValidDomain] = useState<boolean>(true);
  const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [needsAuthentication, setNeedsAuthentication] = useState(false);
  const [validEmail, setValidEmail] = useState(true);

  const isValidEmail = useCallback((email) => {
    return email.includes(".edu") || email.includes("telemetry.fm") || email.includes("diamondcharts.com");
  }, [])

  const validateDomain = useCallback(async () => {
    if (email.length < 1) return;
    setIsLoginDisabled(true)
    const result = await Requests.post('/account/dns_lookup', { email: email })
    console.log('result', result);
    setIsValidDomain(result.success);
    setIsLoginDisabled(!result.success);
    return result.success;
  }, [email, setIsLoginDisabled, setIsValidDomain]);

  const onEmailChange = useCallback((e: any) => {
    setIsLoginDisabled(false);
    setEmail(e.target.value);
    setIsValidDomain(true);
  }, [setIsValidDomain, setEmail, setIsLoginDisabled]);

  const getAndSetNewToken = useCallback(async () => {
    const uniqueId = uuidv4();
    localStorage.setItem("login-token", uniqueId);
    await Requests.post(`/token/upsert`, { token: uniqueId });
  }, []);

  const login = useCallback(async () => {
    let res;
    const validDomain = await validateDomain();

    if (!validDomain) return;

    try {
      const params = {query: {email: email}}
      res = await Requests.get(
        "/account/get_one", null, {params}
      );
    } catch {
      res = undefined;
    }
    if (get(res, "is_verified")) {
      await getAndSetNewToken();
      navigate("/");
    } else {
      if (isValidEmail(email)) {
        setNeedsAuthentication(true);
        Requests.post(`/account/send_email`, { email: email });
      } else {
        setValidEmail(false);
      }
    }
  }, [email, setNeedsAuthentication, navigate, getAndSetNewToken, isValidEmail, validateDomain]);

  const checkForValidToken = useCallback(async () => {
    const loginToken = localStorage.getItem("login-token");
    const params = {query: {token: loginToken}}
    const isTokenValidRes = await Requests.get(
      "/token/get_one", null, {params}
    );
    if (loginToken && isTokenValidRes) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    checkForValidToken();
  }, [checkForValidToken]);

  return (
    <div className={styles.container}>
      <div className={styles.loginBox}>
        <div className={needsAuthentication || !validEmail ? styles.authLogoContainer : styles.logoContainer}>
          <img alt="logo" src={logoMark} className={styles.logo} />
        </div>
        {!needsAuthentication && validEmail && (
          <div className={styles.loginLabel}>Enter your email:</div>
        )}
        {!needsAuthentication && validEmail && (
          <div className={styles.loginFlexBox}>
            <div className={styles.emailInputContainer}>
              <TextInput
                id="email"
                type="text"
                placeholder="Email"
                value={email}
                onChange={onEmailChange}
                style={{ border: "1px solid black", borderRadius: 0 }}
              />
                <button className={styles.loginButton} onClick={login} disabled={isLoginDisabled} style={!isLoginDisabled ? {} : {opacity: 0.5}}>
                  Login
                </button>
            </div>
            {!isValidDomain && <label className={styles.invalidDomainLabel}>{"It looks like this email address isn't valid"}</label>} 
          </div>
        )}
        {needsAuthentication && (
          <div className={styles.loginLabel}>
            Check your email to authenticate
          </div>
        )}
        {!validEmail && (
          <div className={styles.loginLabel} style={{fontSize: "24px"}}>
            <span>
            We're sorry, currently only .edu email addresses can access the site. </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
