import styles from "./DefaultLayout.module.scss";

const DefaultLayout = (props: any) => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>{props.children}</div>
    </div>
  );
};

export default DefaultLayout;
