import React from "react";
import { Outlet } from "react-router-dom";

const App: React.FunctionComponent<{}> = (props) => {
  return (
    <div>
      <Outlet/>
    </div>
  )
};

export default App;
