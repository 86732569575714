import styles from "./TextInput.module.scss";

const TextInput = (props: any) => {
  const {
    type,
    id,
    label,
    value,
    onChange,
    className,
    style,
    min,
    readOnly,
    isRequired,
    border,
    placeholder,
    icon,
    onBlur
  } = props;

  return (
    <div className={`${styles.inputContainer} ${className}`}>
      {!label && (
        <div className={styles.noLabelClass}>
          <p style={{ display: "block" }}>{!isRequired ? "" : "*Required"}</p>
        </div>
      )}
      <input
        onBlur={onBlur}
        id={id}
        className={styles.inputClass}
        type={type}
        min={min || 0}
        value={value || ""}
        onChange={onChange}
        style={style}
        readOnly={readOnly}
        placeholder={placeholder}
      />
      {label && (
        <div className={styles.labelContainer}>
          <label className={styles.labelClass}>{label}</label>
          {icon}
          {!border && (
            <p className={styles.errorText}>{!isRequired ? "" : "*Required"}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TextInput;
