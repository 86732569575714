import IRoute from "../interfaces/AppRoute";
import Home from "../pages/home/Home";
import Login from "../pages/login/Login";
import Verify from "../pages/verify/Verify";
import VerifyRoute from "../pages/verify/VerifyRoute";

const ROUTES: IRoute[] = [
    {
      path: "/",
      name: "Home Page",
      index: true,
      component: <Home/>,
    },
    {
      path: "/login",
      name: "Login Page",
      component: <Login/>,
    },
    {
      path: "verify",
      name: "Verify Page",
      component: <VerifyRoute/>,
      children: {path: ":id", name: "Verify Page", component: <Verify/>},
    },
  ];
  
export default ROUTES;
  