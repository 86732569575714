import React from "react";
import styles from "./Header.module.scss"
import primaryLogo from "../../assets/logo_primary.jpg";

const Header: React.FC = () => {
    return (
        <div className={styles.container}> 
            <div>
                <img src={primaryLogo} alt="diamond-charts" />
            </div>
        </div>
    )
}

export default Header;