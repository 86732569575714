import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URI;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.withCredentials = false;

export default class Requests {
  static async setCookie(cookies: any) {
    let token = cookies["auth-token"];
    axios.defaults.headers.common["Cookie"] = `auth-token=${token}`;
  }

  static async get(url: string, headers = null, config = {}) {
    const response = await axios.get(url, config);
    return response.data;
  }

  static async post(url: string, data: any, headers = null) {
    const response = await axios.post(url, data);
    return response.data;
  }

  static async put(url: string, data: any, headers = null) {
    const response = await axios.put(url, data);
    return response.data;
  }

  static async delete(url: string, payload = null, token: any, headers = null) {
    const response = await axios.delete(url, { data: payload });
    return response.data;
  }

  static async fetchGetJSON(url: string) {
    try {
      const data = await fetch(url).then((res) => res.json());
      return data;
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  static async fetchPostJSON(url: string, data: any) {
    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data || {}),
      });
      return response;
    } catch (err: any) {
      throw new Error(err.message);
    }
  }
}