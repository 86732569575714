import styles from "./Button.module.scss";

const Button = (props: any) => {
  const { disabled, loading, style, icon, textStyle, text, className } = props;
  const onClick = (e: any) => {
    const { onClick, data } = props;

    if (data) {
      onClick(data);
    } else if (!onClick) {
      return;
    } else {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <button
      className={`${styles.button} ${className}`}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      disabled={disabled || loading}
      style={style}
    >
      {!loading && icon && icon.position === "left" && icon.render}
      <p className={styles.innerText} style={textStyle}>{text}</p>
      {!loading && icon && icon.position === "right" && icon.render}
    </button>
  );
};

export default Button;
