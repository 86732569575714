import { Search } from "react-feather";
import styles from "./SearchInput.module.scss";

const SearchInput = (props: any) => {
  const { id, value, onChange, className, style, placeholder } = props;
  return (
    <div className={`${className} ${styles.container}`} style={style}>
      <div className={styles.innerContainer}>
        <Search color={"#787A7C"} size="14" />
        <input
          id={id}
          style={{
            height: "100%",
            // flexGrow: 1,
            outline: "2px solid transparent",
            outlineOffset: "2px",
            paddingY: "0px",
            marginLeft: "4px",
            fontFamily: "Open Sans"
          }}
          min={0}
          value={value || ""}
          onChange={onChange}
          placeholder={placeholder}
          {...props}
        />
      </div>
    </div>
  );
};

export default SearchInput;
